import { useAuthValue } from 'context/AuthContext';
import Button from 'react-bootstrap/Button';
import { IoList } from 'react-icons/io5';
import { numb } from 'utils/helpers';

function BalanceCard({
    dispatchShowOffCanvasAddFunds,
    dispatchShowOffCanvasHistoryFunds
}) {

    const { user } = useAuthValue();
    const organiziation = user.agent.getSelectedOrganization();
    const { credit, subscription } = organiziation;

    return (<>
        <div className="section mt-2 mb-2">
            {/* <div className="row">
                <div className="col-md-6 col-12"> */}
            <div className="card text-white bg-balance mb-2">
                <div className="card-body">
                    <div className='float-end'>
                        <Button
                            size="sm"
                            className="btn-icon me-1 btn-light"
                            type="button"
                            onClick={() => dispatchShowOffCanvasHistoryFunds('OPEN')}
                        ><IoList /></Button>

                        <Button
                            size="sm"
                            className="btn-light"
                            type="button"
                            onClick={() => dispatchShowOffCanvasAddFunds('OPEN')}
                        >Ricarica</Button>
                    </div>
                    <div>
                        <h5 className="card-title mb-2">Credito account</h5>
                        <p className={`card-text text-25 ${credit > 0 ? 'text-success' : 'text-danger'}`}>{numb(credit)}€</p>
                        <p className="card-text">Ricarica il tuo account ed utilizza il saldo per prenotare rapidamente un servizio.</p>
                    </div>
                </div>
            </div>
            {/* </div> */}

            {/* <div className="col-md-6 col-12">
                    <div className="card text-white bg-promo mb-2" style={{minHeight: '153.2px'}}>
                        <div className="card-body d-flex w-100 justify-content-between">
                            <div>
                                <h5 className="card-title" style={{fontSize: '58px', opacity: '0.5'}}>-20%</h5>
                                <p className="card-text">Richiedi il bonus del 20% di sconto sul tuo primo rendering 3D.</p>
                            </div>
                            <div>
                                <Button
                                    size="sm"
                                    className="btn-light"
                                    type="button"
                                    style={{whiteSpace: 'nowrap'}}
                                >Scopri di più</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    </>);

}

export default BalanceCard;