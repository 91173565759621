import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import Button from 'react-bootstrap/Button';
import { IoAlertCircle, IoChevronForwardOutline, IoLeaf, IoPerson, IoTime } from 'react-icons/io5';
import moment from 'moment';
import 'moment/locale/it';
import { Accordion } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';


function useOffcanvasSetTime(props) {

    const {
        rangedSlots = {},
        nextStep,
        setSelectedTimeSlot,
        rangeSlotSelected
    } = props;

    const [accordionActiveKey, setAccordionActiveKey] = useState(['nogeolock_slots']);

    const [hour, minute] = rangeSlotSelected?.label.split(':') ?? [0,0];
    const checkStartHour = rangeSlotSelected?.start_at.clone().hour(hour).minute(minute).second(0);
    const [rangedSlotsKeys, geolock_slots, no_geolock_slots] = useMemo(() => {
        const rangedSlotsKeys = Object.keys(rangedSlots.slots || {});
        if (!rangedSlotsKeys.length) return [{}, [], []];
        const geolock_slots = Object.values(rangedSlots.slots).filter(s => s.is_geolock === true);
        const no_geolock_slots = Object.values(rangedSlots.slots).filter(s => s.is_geolock !== true);
        setAccordionActiveKey([(geolock_slots.length ? 'geolock_slots' : 'nogeolock_slots')]);
        return [rangedSlotsKeys, geolock_slots, no_geolock_slots];
    }, [rangedSlots])

    const handleSelectTime = () => {
        setSelectedTimeSlot({
            photographer_id: rangeSlotSelected.ph,
            start: rangeSlotSelected.start_at,
            travel_paid: rangeSlotSelected.travel_paid
        });
        nextStep();
    }

    return {
        rangedSlots,
        rangedSlotsKeys,
        geolock_slots, 
        no_geolock_slots,
        checkStartHour,
        handleSelectTime,
        accordionActiveKey, setAccordionActiveKey
    }
}

function SlotChip({
    slot,
    rangeSlotSelected,
    setRangeSlotSelected
}) {
    return (
        <div 
            className={`chip chip-time mx-1 mb-1 ${rangeSlotSelected && rangeSlotSelected.label === slot.label ? 'active' : ''}`}
            onClick={() => setRangeSlotSelected({ ...slot, start_at: moment(slot.start_at) })}
        >
            <span className="chip-label">{slot.label}</span>
        </div>
    );
}

function OffcanvasSetTime(props) {

    const {
        handleClose,
        rangeSlotSelected,
        setRangeSlotSelected,
    } = props;
    
    const {
        rangedSlots,
        rangedSlotsKeys,
        geolock_slots, 
        no_geolock_slots,
        checkStartHour,
        handleSelectTime,
        accordionActiveKey, setAccordionActiveKey
    } = useOffcanvasSetTime(props);


    // const ph_list = {
    //     'qf3tQU7ngIbDvmbvv4Yn7vHZYii2': 'Agnese Daupi',
    //     '4UgRCjvaHPZDUWa9SkcBiXtOYJg2': 'Lorenzo Bellelli',
    //     'VL8GJ2cP4TWZ7jZhQYyBbrOJG1w2': 'Angelo Daupi',
    //     'PSbjF2DxpjcE5oxJlDZWZnS1RrB3': 'Giusy Concolino'
    // };
    // const ph_id = rangeSlotSelected ? rangeSlotSelected.ph : '';
    // const ph_name = ph_list[ph_id] ?? 'Fotografo non in debug';

    return (<>
        <Offcanvas placement="bottom" className="action-sheet" show={rangedSlotsKeys.length} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose}/>
                <div className="section mt-3 text-center">
                    <h3>Seleziona l'orario</h3>
                    <div className="mt-2 text-center lh-20">
                        Scegli l'orario di inizio indicativo in cui effettuare il servizio:
                    </div>
                </div>

                <Accordion 
                    className='text-14 mt-3' 
                    activeKey={accordionActiveKey}
                    onSelect={id => {
                        setAccordionActiveKey(id)
                    }}
                    alwaysOpen
                >
                    {geolock_slots.length > 0 && <Accordion.Item eventKey="geolock_slots">
                        <Accordion.Header><IoPerson/> Fotografo in zona <span className='leaf-text'>(<IoLeaf/>Scelta ecologica)</span>:</Accordion.Header>
                        <Accordion.Body className='grid-time pb-0'>
                            {geolock_slots.map(slot => <SlotChip 
                                key={slot.label}
                                slot={slot}
                                rangeSlotSelected={rangeSlotSelected}
                                setRangeSlotSelected={setRangeSlotSelected}
                            />)}
                        </Accordion.Body>
                    </Accordion.Item>}
                    {no_geolock_slots.length > 0 && <Accordion.Item eventKey="nogeolock_slots">
                        <Accordion.Header><IoTime/> {geolock_slots.length > 0 ? 'Altri o' : 'O'}rari disponibili:</Accordion.Header>
                        <Accordion.Body className='grid-time pb-2'>
                            {no_geolock_slots.map(slot => <SlotChip 
                                key={slot.label}
                                slot={slot}
                                rangeSlotSelected={rangeSlotSelected}
                                setRangeSlotSelected={setRangeSlotSelected}
                            />)}
                        </Accordion.Body>
                    </Accordion.Item>}
                </Accordion>

                <div className="py-2 section">
                    {rangeSlotSelected?.delay_start && 
                        <div className="alert alert-outline-primary notice-time-variation mb-1">
                            <IoAlertCircle className="vertical-align-n2 me-1"/>
                            Per una questione organizzativa del servizio precedente il fotografo arriverà alle <b>{rangeSlotSelected?.start_at.format('HH:mm')}</b> invece che alle <b>{checkStartHour.format('HH:mm')}</b>.
                        </div>
                    }

                    {/* <div className='text-danger my-2 text-center'>DEBUG FOTOGRAFO SLOT: {ph_name}</div> */}

                    <div className="form-group basic">
                        <button type="button" className="btn btn-primary btn-block" onClick={handleSelectTime}>
                            Continua <IoChevronForwardOutline className='next-icon-btn'/>
                        </button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasSetTime;