//Logic
import { useConfirmAccount } from './useAuth';

//Assets
import verificationImage from './img/verification.svg';

//layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';
import Button from 'react-bootstrap/Button';
import OffCanvasError from 'components/offcanvas/OffCanvasError';

export default function ConfirmAccount() {

  const {
      emailResended,
      handleResendEmail,
      handleCheckAccount,
      showOfCanvasVerifyError, setShowOfCanvasVerifyError
  } = useConfirmAccount();

  return (
  <>
    <Header show_menu={false} show_logo show_logout />
    <Capsule>
      <div className="login-form mt-2">
        <div className="section">
          <h1>Verifica account</h1>
          Ti abbiamo inviato una mail di conferma con un link da cliccare per verificare il tuo account.
          <img className="body-image" alt="email" src={verificationImage}/>
          Se non ricevi l'email entro 1 minuto <u className="cursor-pointer active" onClick={handleResendEmail}>clicca qui</u>.
          { emailResended && <><br /> <span className="text-success">{emailResended}</span></>}
        </div>
        <div className="section mt-2 mb-5">
          <div className="form-button-group">
              <Button
                  onClick={() => handleCheckAccount() }
                  className="btn btn-primary btn-block"
                  size="lg"
              >Continua</Button>
          </div>
        </div>
      </div>
    </Capsule>
    <OffCanvasError 
      show={showOfCanvasVerifyError}
      handleClose={() => setShowOfCanvasVerifyError(false)}
      title="Email non verificata"
      description="Se non hai ancora ricevuto la mail puoi richiedere di reinviarla chiudendo questo avviso"
      append="close"
    />
  </>);
}