import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { debounce, deepCopy, getUrlParam } from "utils/helpers";
import { isMobile } from 'utils/helpers';
import useEventListener from "./useEventListener";
import { useSelector } from "react-redux";
import { selectAppointmentEdit } from "store/selectors";

const SKELETON_OBJ = {
    skeleton: true,
    backgroundColor: 'rgb(117, 115, 115)',
    borderColor: 'rgb(117, 115, 115)',
    editble: false
}

export default function useCalendar({
    loadPromise,
    resources,
    isResources
}) {
    const ref = useRef(null);
    const [viewTitle, setViewTitle] = useState();
    const [events, setEvents] = useState();
    const [viewDate, setViewDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isMonthView, setIsMonthView] = useState(false);

    const loadPromiseCallback = useCallback(loadPromise, []);

    const debounceLoad = useCallback(debounce((calendarApi, start_date, end_date) => {
        loadPromiseCallback(start_date, end_date)
        .then(res => {
            setEvents(res.data);
            setIsLoading(false);
        });
    }, 500), []);

    const handleViewChange = useCallback((action = null) => {
        setIsLoading(true);
        const calendarApi = ref.current.getApi();
        action && calendarApi[action]();
        const {start, end } = calendarApi.currentData.dateProfile.activeRange;
        const start_date = moment(start);
        const end_date = moment(end);
        let firstDate = start_date.clone();
        const skeleton_events = [];
        while (firstDate.isBefore(end_date, 'day')) {
            (resources || [null]).map(resource => {
                skeleton_events.push({
                    start: firstDate.format('YYYY-MM-DD') + ' 09:00:00',
                    end: firstDate.format('YYYY-MM-DD') + ' 13:00:00',
                    ...SKELETON_OBJ,
                    ...resource && { resourceId: resource.id }
                });
                skeleton_events.push({
                    start: firstDate.format('YYYY-MM-DD') + ' 15:00:00',
                    end: firstDate.format('YYYY-MM-DD') + ' 19:00:00',
                    ...SKELETON_OBJ,
                    ...resource && { resourceId: resource.id }
                });
            })
            firstDate.add(1, 'day');
        }
        setEvents(skeleton_events);
        setViewTitle(calendarApi.currentData.viewTitle);
        setViewDate(calendarApi.getDate());
        const changeIsMonthView = calendarApi.view.type === 'dayGridMonth';
        setIsMonthView(changeIsMonthView);
        if (changeIsMonthView) return setEvents([]);
        debounceLoad(calendarApi, start_date, end_date);
    }, [setEvents, setViewTitle, resources]);
    

    const navToday = useCallback(() => handleViewChange('today'), [handleViewChange]);
    const navPrev = useCallback(() => handleViewChange('prev'), [handleViewChange]);
    const navNext = useCallback(() => handleViewChange('next'), [handleViewChange]);
    const loadFirst = useCallback(() => handleViewChange(), [handleViewChange]);

    const handleDayChange = (date) => {
        const calendarApi = ref.current.getApi();
        calendarApi.changeView( isResources ? 'resourceTimeGridDay' : (isMobile() ? 'threeDay' : 'sevenDay'), date);
        handleViewChange();
    }

    const changeView = view => {
        setIsMonthView(view === 'dayGridMonth');
        ref.current.getApi().changeView(view);
    }

    const onUpdateEvent = (event, multiple = false) => {
        setEvents([
            ...multiple ? [
                ...event.filter(ev => !events.find(evExist => evExist.id === ev.id))
            ] : [],
            ...events.map(ev => {
                if (multiple) {
                    const findUpdate = event.find(eu => eu.id === ev.id);
                    if (findUpdate) {
                        return findUpdate;
                    }
                } else {
                    if (ev.id === event.id) return event;
                }
                return ev;
            })
        ]);
    }

    const onDeleteEvent =  (event_id) => {
        setEvents(
            events.filter(ev => ev.id !== event_id)
        );
    }

    const onAddEvent = (event) => {
        setEvents([
            ...events,
            event
        ]);
    }

    useEventListener({
        'appointment_updated': (e) => onUpdateEvent(e.detail.event),
        'appointment_deleted': (e) => onDeleteEvent(e.detail.event.id),
        'appointments_updated': (e) => onUpdateEvent(e.detail, true),
        'appointments_add': (e) => onAddEvent(e.detail)
    }, [events]);

    useEventListener({
        'DIALOG_confirm_appointment_update_CLOSE': (e) => { 
            setEvents(deepCopy(events));
        }
    }, [events]);

    useEffect(() => {
        const urlDate = getUrlParam('date', true);
        urlDate && handleDayChange(urlDate);
    }, []);

    return {
        ref,
        events,
        viewDate,
        viewTitle,
        navToday, navPrev, navNext,
        loadFirst,
        handleDayChange,
        isLoading,
        isMonthView,
        changeView
    }
}