const initialState = {
    isAppLoading: false
}

const appLoader = (state = initialState, action) => {
    switch(action.type){
        case 'APP_LOADER_SHOW':
            return {
                ...state,
                isAppLoading: true,
            }
        case 'APP_LOADER_HIDE':
            return {
                ...state,
                isAppLoading: false,
            }
        default:
            return state;
    }
}

export default appLoader;