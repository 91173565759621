import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { capitalizeFirstLetter, dispatchAppEvent } from 'utils/helpers';
import moment from 'moment';
import AutocompleteAddress from 'components/autocomplete/AutocompleteAddress';
import { toast } from 'react-toastify';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import { IoCalendarOutline, IoHomeOutline } from 'react-icons/io5';


const DEFAULT_FORM_DATA = {
    description: '',
    date: '',
    start_time: '',
    end_time: '',
    note: ''
};

const DEFAULT_ADDRESS_OBJECT = {
    address: {
        full: "Via Marcantonio Parenti 47, Modena, 41122, Modena (MO)",
        locality: "Modena",
        postal_code: "41122",
        state: "Modena",
        state_code: "MO",
        street: "Via Marcantonio Parenti 47"
    },
    coords: {
        lat: 44.659993,
        lng: 10.921706
    }
}


function useOffCanvasAddManualAppointment(props) {

    const {
        show,
        resourceInfo,
        handleClose,
    } = props;

    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [address, setAddress] = useState();
    const [addressObj, setAddressObj] = useState();
    const [isInProgress, setIsInProgress] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'date') {
            if (!value) return;
        }
        const updateObject = {
            ...formData,
            [name]: value,
        };
        if (name === 'start_time') {
            const [hour, minute] = value.split(':');
            const newStart = moment(formData.date).hour(hour).minute(minute);
            updateObject['end_time'] = newStart.add(30, 'minutes').format('HH:mm');
        }
        setFormData(updateObject);
    }

    const handleConfirm = () => {
        setIsInProgress(true);
        axios.post('appointments', {
            description: formData.description,
            estimated_start_at: formData.date + ' ' + formData.start_time,
            estimated_end_at: formData.date + ' ' + formData.end_time,
            photographer_id: resourceInfo.resource.id,
            note: formData.note,
            ...(addressObj ? addressObj : {})
        })
        .then(res => {
            if (res.data.status === false) return toast.error(res.data.message);
            dispatchAppEvent('appointments_add', res.data);
            handleClose();
            toast.success('Appuntamento inserito con successo');
        })
        .catch(() => toast.error('Si è verificato un errore durante l\'inserimento'))
        .finally(() => setIsInProgress(false))
    }

    const handleRetriveAddress = (autocompleteObj) => {
        setAddressObj({
            address: autocompleteObj.values,
            coords: autocompleteObj.coords
        });
    };

    const handleResetAddress = () => {
        setAddressObj();
        setAddress('');
    }

    useEffect(() => {
        if (!resourceInfo) return;
        if (!show) {
            setAddress();
            setAddressObj();
            return setFormData(DEFAULT_FORM_DATA);
        }
        const date = moment(resourceInfo.date);
        setFormData({
            ...DEFAULT_FORM_DATA,
            date: date.format('YYYY-MM-DD'),
        });
        setAddressObj(DEFAULT_ADDRESS_OBJECT);
        setAddress(DEFAULT_ADDRESS_OBJECT.address.full);
    }, [show, resourceInfo]);

    return {
        formData,
        handleChange,
        handleConfirm,
        address, setAddress,
        handleRetriveAddress, handleResetAddress,
        isInProgress
    }
}


export default function OffcanvasAddManualAppointment(props) {

    const {
        resourceInfo,
        show,
        handleClose,
    } = props;

    const {
        formData,
        handleChange,
        handleConfirm,
        address, setAddress,
        handleRetriveAddress, handleResetAddress,
        isInProgress,
    } = useOffCanvasAddManualAppointment(props); 

    const isEndTimeError = formData.start_time ? formData.start_time >= formData.end_time : false;
    
    return (<>
        <Offcanvas placement="bottom" id="view-add-appoinement" className="action-sheet" show={!!resourceInfo && show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3 className='mb-0'>Aggiungi appuntamento</h3>
                    {resourceInfo && resourceInfo.resource.title}
                </div>
                <div className="action-sheet-content py-2">
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="description">Titolo*</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                autoComplete="off"
                                value={capitalizeFirstLetter(formData.description)}
                                onChange={handleChange}
                                maxLength="200"
                            />
                        </div>
                    </div>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label">Data inizio*</label>
                            <input
                                type="date"
                                className="form-control"
                                name="date"
                                autoComplete="off"
                                value={formData.date}
                                onChange={handleChange}
                                maxLength="200"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <label className="label-bordered form-label">Ora inizio*</label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        style={{ background: '#3a3a3a' }}
                                        name="start_time"
                                        value={formData.start_time}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <label className="label-bordered form-label">Ora fine*</label>
                                    <input
                                        type="time"
                                        className={`form-control ${isEndTimeError ? 'is-invalid' : ''}`}
                                        style={{ background: '#3a3a3a' }}
                                        name="end_time"
                                        value={formData.end_time}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group boxed custom">
                        <AutocompleteAddress 
                            label="Indirizzo"
                            value={address || ''}
                            setValue={setAddress}
                            handleRetrive={handleRetriveAddress}
                            handleReset={handleResetAddress}
                            defaultLockValue
                            withGoogle
                        />
                    </div>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="label-bordered form-label">Note</label>
                            <textarea
                                rows="2"
                                className="form-control"
                                name="note"
                                value={formData.note}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group basic">
                        <Button
                            className={`btn-block ${formData.description && formData.date && formData.start_time && formData.end_time && !isEndTimeError && !isInProgress ? '' : 'disabled'}`}
                            type="button"
                            onClick={handleConfirm}
                        ><SpinnerForButton show={isInProgress} />Inserisci</Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}