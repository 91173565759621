import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import PhotographersSelect from 'components/form/PhotographersSelect';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { dispatchAppEvent } from 'utils/helpers';
import moment from 'moment';
import { toast } from 'react-toastify';

const DEFAULT_FORM_DATA = {
    lock_type: '',
};

function useOffCanvasDisableSlots(props) {

    const {
        resourceInfo,
        handleClose,
        disableDate
    } = props;

    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleConfirm = () => {
        axios.post('appointments-lock', {
            photographer: resourceInfo.resource.id,
            date: moment(disableDate).format('YYYY-MM-DD'),
            lock_type: formData.lock_type
        })
        .then(res => {
            dispatchAppEvent('appointments_updated', res.data);
            handleClose();
            toast.success('Inserimento slot di blocco eseguito con successo');
        });
    }

    useEffect(() => {
        setFormData(DEFAULT_FORM_DATA);
    }, [resourceInfo]);

    return {
        formData,
        handleChange,
        handleConfirm
    }
}

export default function OffcanvasDisableSlots(props) {

    const {
        show,
        resourceInfo,
        handleClose,
    } = props;

    const {
        formData,
        handleChange,
        handleConfirm
    } = useOffCanvasDisableSlots(props);
    

    return (<>
        <Offcanvas placement="bottom" id="view-property" className="action-sheet" show={!!resourceInfo && show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3 className='mb-0'>Disabilita slots</h3>
                    {resourceInfo && resourceInfo.resource.title}
                </div>
                <div className="action-sheet-content py-2">
                    <div className="form-group boxed custom">
                        <div className="form-check mb-2">
                            <input 
                                type='radio'
                                className="form-check-input"
                                name="lock_type"
                                id="disable_mornining"
                                value="MORNING"
                                checked={formData.lock_type === 'MORNING'}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="disable_mornining">Mattino ({'<'} 14:00)</label>
                        </div>
                        <div className="form-check mb-2">
                            <input 
                                type='radio'
                                className="form-check-input"
                                name="lock_type"
                                id="disable_afteroon"
                                value="AFTEROON"
                                checked={formData.lock_type === 'AFTEROON'}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="disable_afteroon">Pomeriggio ({'>='} 14:00)</label>
                        </div>
                        <div className="form-check">
                            <input 
                                type='radio'
                                className="form-check-input"
                                name="lock_type"
                                id="disable_full"
                                value="FULL"
                                checked={formData.lock_type === 'FULL'}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="disable_full">Intera giornata</label>
                        </div>
                    </div>
                    <div className="form-group basic">
                        <Button
                            className={`btn-block ${formData.lock_type ? '' : 'disabled'}`}
                            type="button"
                            onClick={handleConfirm}
                        >Conferma</Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}