import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { IoAlertCircle } from "react-icons/io5";

function OffCanvasError(props) {

    const {
        show,
        handleClose,
        append,
        title,
        description
    } = props;

    return (
        <Offcanvas placement="bottom" className="action-sheet" style={{ maxWidth: '500px' }} show={show} onClose={handleClose}>
            <Offcanvas.Body>
                <div className="section mt-3 text-center">
                    <h2>{title}</h2>
                </div>

                <div className="action-sheet-content py-2">
                    <div className="modal-icon text-danger mt-0">
                        <IoAlertCircle style={{ fontSize: '110px' }} />
                    </div>
                    <div className="text-center mb-1 mt-2 lh-20">
                        {description}
                    </div>

                    {append &&
                        append === 'close' ?
                        <div className="form-group basic">
                            <Button onClick={handleClose} className="btn-block btn-primary">Chiudi</Button>
                        </div> :
                        append
                    }
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default OffCanvasError;