import { IoArchive, IoBusiness, IoCamera, IoHome, IoPerson, IoSettings } from "react-icons/io5";

import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from "react-icons/io5";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../utils/helpers";


function ActionButtons({
    task,
    index,
    handleOnSave,
    size
}) {

    const navigate = useNavigate();

    return <>
        <Button
            className={`btn-${size} my-1 fw-600 ${task.needSave ? 'blinking-element' : ''} ${task.status === 'WAITING_PHOTOGRAPHER' ? 'disabled' : ''}`}
            variant="primary"
            onClick={() => handleOnSave(index)}
        >
            AGGIORNA
        </Button>
        {task.order_item.product.type === 'RENDER' && <Button
            className={`btn-${size} my-1 fw-600`}
            variant="light"
            onClick={() => navigate(`/render-manage/${task.order_item.order.id}`)}
        >
            VISUALIZZA
        </Button>}
    </>;
}

export default function PostProductionRow({
    index,
    task,
    staff,
    handleChange,
    handleOnSave,
}) {
    const { order_item } = task;
    const { product } = order_item;
    const { poll } = order_item;
    const { order } = order_item;
    const { organization } = order;
    const { appointment } = order;
    const { building } = appointment || {};

    if (isMobile()) {
        return (
            <tr style={{ borderBottom: '14px solid #121212' }}>
                <td colSpan="11">
                    <div className="text-start">
                        <div>
                            <h3 className='mb-05'>{moment(appointment?.estimated_start_at ?? task.created_at).format('DD/MM/YYYY - HH:mm')} {building ? `| RIF. ${building?.reference}` : ''}</h3>
                            <div className="text-14">
                                <IoBusiness className="vertical-align-n2" />{' '}
                                {organization.display_name}
                            </div>
                        </div>
                        <div className='text-15'>
                            <ul className="listview image-listview no-border no-after bg-272727">
                                {building?.address && <li>
                                    <div className="item px-0">
                                        <div className="in">
                                            <div>
                                                <header><IoHome className="vertical-align-n2" /> Dettagli immobile:</header>
                                                <div className='mb-05'>{building?.address.full}</div>
                                                <b>Proprietario:</b> {building?.owner_last_name} {building?.owner_first_name}<br />
                                                <b>Fotografo:</b> {staff.p[appointment?.photographer_id]}
                                            </div>
                                        </div>
                                    </div>
                                </li>}
                                <li>
                                    <div className="item pt-0 px-0">
                                        <div className="in">
                                            <div>
                                                <header><IoCamera className="vertical-align-n2" /> Servizio:</header>
                                                {product.type === 'RENDER' ? 'Rendering ' : ''} {product.description} {order_item.is_priority == true && <span className='text-warning'>({product.priority_label})</span>}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {(task.note_in || poll.is_social_post) && <li>
                                    <div className="item pt-0 px-0">
                                        <div className="in">
                                            <div>
                                                <header><IoPerson className="vertical-align-n2" /> Note fotografo:</header>
                                                {poll.is_social_post ? 'Da pubblicare sui social' : ''}<br />
                                                {task.note_in}
                                            </div>
                                        </div>
                                    </div>
                                </li>}
                                {(task.note_out) && <li>
                                    <div className="item pt-0 px-0">
                                        <div className="in">
                                            <div>
                                                <header><IoPerson className="vertical-align-n2" /> Note editor:</header>
                                                {task.note_out}
                                            </div>
                                        </div>
                                    </div>
                                </li>}
                                {appointment ? <li>
                                    <div className="item pt-0 px-0">
                                        <div className="in">
                                            <div className="flex-fill">
                                                <header><IoArchive className="vertical-align-n2" /> Quantità:</header>
                                                <div className="form-group boxed pb-0">
                                                    <div className="input-wrapper m-0">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            style={{ background: '#3a3a3a' }}
                                                            id={`task_${task.id}_quantity`}
                                                            placeholder='Numero materiale'
                                                            name="quantity"
                                                            value={task.quantity || ''}
                                                            onChange={(e) => handleChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> : ''}
                                <li>
                                    <div className="item pt-0 px-0">
                                        <div className="in">
                                            <div className="flex-fill">
                                                <header><IoSettings className="vertical-align-n2" /> Stato:</header>
                                                <div className="form-group boxed text-center">
                                                    <div className="input-wrapper">
                                                        <select
                                                            className="form-control form-select"
                                                            style={{ backgroundColor: '#3a3a3a' }}
                                                            id={`task_${task.id}_status`}
                                                            name="status"
                                                            value={task.status === 'WAITING_EDITOR' && !!task.editor_id ? `${task.status}:${task.editor_id}` : task.status}
                                                            onChange={(e) => handleChange(e, index)}
                                                        >
                                                            <option value="WAITING_PHOTOGRAPHER">Da caricare</option>
                                                            <option value="WAITING_EDITOR">Da assegnare</option>
                                                            <option value="AUTOMATIC_ASSIGN">Assegna in automatico ✨</option>
                                                            <optgroup label="Assegnato a:">
                                                                {staff.e.map(user => (
                                                                    <option
                                                                        key={user.uid}
                                                                        value={`WAITING_EDITOR:${user.editor.id}`}
                                                                        disabled={!product.edit_action ? false : !user.editor[`can_edit_${product.edit_action.toLowerCase()}`]}
                                                                    >
                                                                        {user.display_name}
                                                                    </option>
                                                                ))}
                                                            </optgroup>
                                                            <option value="DONE">
                                                                {task.status === 'DONE' ? `${moment(task.date_out).format('DD/MM HH:mm')}${task.editor_id ? ` (${(staff.e.find(u => u.editor.id === task.editor_id)?.display_name ?? 'Editor cancellato')})`: ''} - ` : ''} Completato
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ActionButtons 
                                task={task}
                                index={index}
                                handleOnSave={handleOnSave}
                                size='block'
                            />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    const note = `${task.note_in ? `F: ${task.note_in}` : ''}${task.note_out ? `\nE: ${task.note_out}` : ''}`;
    const note_td = note.substring(0, 100) + (note.lenght > 100 ? '...' : '');

    return (
        <tr>
            <td>{moment(appointment?.estimated_start_at ?? task.created_at).format('DD/MM/YYYY HH:mm')}</td>
            <td>{staff.p[appointment?.photographer_id]}</td>
            <td>{organization.display_name}</td>
            <td>{building?.owner_last_name} {building?.owner_first_name}</td>
            <td><b>{building ? `${building.address.full} - RIF ${building?.reference}` : ''}</b></td>
            <td>
                {product.type === 'RENDER' ? 'Rendering ' : ''}
                {product.description}<br />
                {order_item.is_priority == true && <span className='text-warning'>({product.priority_label})</span>}
            </td>
            <td>
                <span className={`iconedbox text-${poll.is_social_post ? 'success' : 'danger'}`}>
                    {!appointment ? '' : (poll.is_social_post ? <IoCheckmarkCircleSharp /> : <IoCloseCircleSharp />)}
                </span>
            </td>
            <td title={note}>{note_td}</td>
            <td>
                {appointment ? <div className="form-group boxed p-0 text-center">
                    <div className="input-wrapper m-0">
                        <input
                            type="number"
                            className="form-control text-center px-2 m-auto"
                            style={{ background: '#3a3a3a', width: '70px' }}
                            id={`task_${task.id}_quantity`}
                            name="quantity"
                            value={task.quantity || ''}
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div> : ''}
            </td>
            <td>
                <div className="form-group boxed text-center">
                    <div className="input-wrapper">
                        <select
                            className="form-control form-select"
                            style={{ backgroundColor: '#3a3a3a', minWidth: '150px' }}
                            id={`task_${task.id}_status`}
                            name="status"
                            value={task.status === 'WAITING_EDITOR' && !!task.editor_id ? `${task.status}:${task.editor_id}` : task.status}
                            onChange={(e) => handleChange(e, index)}
                        >
                            <option value="WAITING_PHOTOGRAPHER">Da caricare</option>
                            <option value="WAITING_EDITOR">Da assegnare</option>
                            <option value="AUTOMATIC_ASSIGN">Assegna in automatico ✨</option>
                            <optgroup label="Assegnato a:">
                                {staff.e.map(user => (
                                    <option
                                        key={user.uid}
                                        value={`WAITING_EDITOR:${user.editor.id}`}
                                        disabled={!product.edit_action ? false : !user.editor[`can_edit_${product.edit_action.toLowerCase()}`]}
                                    >
                                        {user.display_name}
                                    </option>
                                ))}
                            </optgroup>
                            <option value="DONE">
                                {task.status === 'DONE' ? `${moment(task.date_out).format('DD/MM HH:mm')}${task.editor_id ? ` (${(staff.e.find(u => u.editor.id === task.editor_id)?.display_name ?? 'Editor cancellato')})`: ''} - ` : ''} Completato
                            </option>
                        </select>
                    </div>
                </div>
            </td>
            <td>
                <ActionButtons 
                    task={task}
                    index={index}
                    handleOnSave={handleOnSave}
                    size='sm'
                />
            </td>
        </tr>
    );
}
