import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import { getUrlParam, removeUrlParameter } from 'utils/helpers';
import useUrlAction from "hooks/useUrlAction";
import useEventListener from "hooks/useEventListener";

const DEFAULT_FILTER = {
    'year': new Date().getFullYear(),
    'month': (new Date().getMonth() + 1),
    'organization_id': "",
    'payment_method': ""
}

export default function useHistory() {
    const [orders, setOrders] = useState();
    const [filter, setFilter] = useState(DEFAULT_FILTER);
    const { user } = useAuthValue();
    const [selectedAppointmentId, dispatchViewAppointment] = useOffCanvasReducer();
    const [selectedOrderId, dispatchViewOrder] = useOffCanvasReducer();
    const pageType = useMemo(() => getUrlParam('type'), []);
    const controllerRef = useRef(null);

    useUrlAction({
        show: [dispatchViewAppointment, [getUrlParam('id', true)]],
        showo: [dispatchViewOrder, [getUrlParam('order_id', true)]]
    });

    useEffect(() => {
        setOrders();
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        axios.get('/orders', {
            signal: controllerRef.current.signal,
            params: {
                extract_refunded: 1,
                ...filter,
                organization_id: user.is_admin ? filter.organization_id : user?.agent?.getSelectedOrganization().id,
                type: pageType
            }
        })
        .then(res => setOrders(res.data))
        .catch(thrown => {
            console.log(thrown);
            if (axios.isCancel(thrown)) return;
            console.error(thrown.message);
        });

        return () => (controllerRef.current && controllerRef.current.abort());
    }, [filter]);

    const onDeleteAppointment = (appointment_id) => {
        dispatchViewAppointment('CLOSE');
        setOrders(
            orders.map(order => {
                if (!order.appointment_id || order.appointment_id !== appointment_id) return order;
                order.status = 'REFUNDED';
                return order;
            })
        );
    }

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilter({
            ...filter,
            [name]: value
        });
    }

    useEventListener({
        'appointment_deleted': (e) => onDeleteAppointment(e.detail.appointment.id),
    }, [orders]);
    

    return {
        orders,
        filter,
        selectedAppointmentId,
        dispatchViewAppointment,
        handleChangeFilter,
        selectedOrderId,
        dispatchViewOrder,
        pageType
    }
}