import axios from "axios";
import moment from 'moment';
import { useState, useEffect } from "react";
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import useCalendar from "hooks/useCalendar";
import useEditAppointment from "hooks/useEditAppointment";
import useUrlAction from "hooks/useUrlAction";
import { getUrlParam } from "utils/helpers";

export default function useAdminCalendar() {

    const [resources, setResources] = useState();
    const [selectedAppointmentId, dispatchViewAppointment] = useOffCanvasReducer();
    const [showSwitchPhotographers, dispatchShowSwitchPhotographers] = useOffCanvasReducer();
    const [showDisableSlots, dispatchShowDisableSlots] = useOffCanvasReducer();
    const [showAddOrgAppointment, dispatchShowAddOrgAppointment] = useOffCanvasReducer();
    const [showNewBuilding, dispatchShowNewBuilding] = useOffCanvasReducer();
    const [showOffCanvasSettings, dispatchShowOffCanvasSettings] = useOffCanvasReducer();
    const [showAddManualAppointment, dispatchShowAddManualAppointment] = useOffCanvasReducer();
    const [showResourceSelected, dispatchShowResourceSelected] = useOffCanvasReducer();
    const [resourceInfoSelected, setResourceInfoSelected] = useState();
    const [organizationSelected, setOrganizationSelected] = useState();

    useUrlAction({
        show: [dispatchViewAppointment, [getUrlParam('id', true)]]
    });

    const calendarLogic = useCalendar({
        loadPromise: (start_date, end_date) => axios.get('appointments', {
            params: {
                start: start_date.format('YYYY-MM-DD'),
                end: end_date.format('YYYY-MM-DD'),
                events: 1,
                admin: 1
            }
        }),
        resources: resources,
        isResources: true
    });
    const { 
        checkUpdateAppointment
    } = useEditAppointment();

    const { loadFirst } = calendarLogic;

    useEffect(() => {
        axios.get('users', {
            params: {
                role: 'photographer'
            }
        })
        .then(res => setResources(
            res.data.map(r => ({ 
                id: r.photographer_id,
                uid: r.uid, 
                title: r.display_name, 
                show_priority: r.show_priority 
            }))
        ));
    }, []);

    useEffect(() => {
        if (!resources || !loadFirst) return;
        loadFirst();
    }, [resources, loadFirst]);

    const handleEventClick = (info) => {
        dispatchViewAppointment(info.event.id);
    }

    const handleEventUpdate = info => {
        const { event } = info;
        info.event.setProp('classNames', ['placeholder']);
        const [resource] = event.getResources();
        checkUpdateAppointment({
            id: event.id,
            photographer_id: +resource.id,
            estimated_start_at: moment(event.start).format('YYYY-MM-DD HH:mm'),
            estimated_end_at: moment(event.end).format('YYYY-MM-DD HH:mm')
        })
    }

    const handleSelectOrganization = organization => {
        setOrganizationSelected(organization);
    }

    const handleSelectResourceInfo = (info) => {
        setResourceInfoSelected(info);
    }

    useEffect(() => {
        if (showNewBuilding) {
            return dispatchShowAddOrgAppointment('CLOSE');
        }
        handleSelectOrganization();
    }, [showNewBuilding]);

    useEffect(() => {
        if (showAddManualAppointment) return dispatchShowResourceSelected('CLOSE');
    }, [showAddManualAppointment]);

    useEffect(() => {
        if (showAddOrgAppointment) return dispatchShowResourceSelected('CLOSE');
    }, [showAddOrgAppointment]);

    useEffect(() => {
        if (showDisableSlots) return dispatchShowResourceSelected('CLOSE');
    }, [showDisableSlots]);

    return {
        handleEventClick,
        resources,
        selectedAppointmentId, dispatchViewAppointment,
        showSwitchPhotographers, dispatchShowSwitchPhotographers,
        showDisableSlots, dispatchShowDisableSlots,
        showAddOrgAppointment, dispatchShowAddOrgAppointment,
        showAddManualAppointment, dispatchShowAddManualAppointment,
        showNewBuilding, dispatchShowNewBuilding,
        showOffCanvasSettings, dispatchShowOffCanvasSettings,
        showResourceSelected, dispatchShowResourceSelected,
        resourceInfoSelected, handleSelectResourceInfo,
        handleSelectOrganization,
        organizationSelected,
        handleEventUpdate,
        //Calendar
        calendarLogic,
        
    }
}
