import { useReducer, useState } from 'react'

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import InputDebounce from 'components/InputDebounce'
import { Pagination, Table } from 'react-bootstrap';
import { ChevronDownIcon } from '@saas-ui/react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';


export default function AdvanceDataTable({
  columns,
  data = [],
  paginate
}) {

  const [columnFilters, setColumnFilters] = useState([]);


  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <div>
      <div className="table-responsive">
        <Table className="table-striped text-center">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <IoChevronUpOutline className='d-inline-block ms-1' />,
                              desc: <IoChevronDownOutline className='d-inline-block ms-1' />,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </Table>
      </div>
      <div className="h-2" />
      <div className="flex items-center gap-2 mt-3 justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          />
          <Pagination.Prev
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          />
          <Pagination.Item>{table.getState().pagination.pageIndex + 1}</Pagination.Item>
          <Pagination.Next
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
          <Pagination.Last
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          />
        </Pagination>
        <div className="mt-05 mb-05 d-flex justify-content-between align-items-center section text-14">
          <div>
            <span>Pagina </span>
            <strong>
              {table.getState().pagination.pageIndex + 1} di{' '}
              {table.getPageCount()}
            </strong>
          </div>
          {/* <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className="border p-1 rounded w-16"
            />
          </span> */}
          <div className="form-group boxed" style={{ maxWidth: '180px' }}>
            <div className="input-wrapper">
              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
                className='form-control form-select text-14'
                style={{ height: '35px' }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Visualizza {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Filter({ column }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        <div className="form-group boxed d-flex gap-2 justify-content-center">
          <div className="input-wrapper">
            <InputDebounce
              type="number"
              value={(columnFilterValue)?.[0] ?? ''}
              onChange={value =>
                column.setFilterValue((old) => [value, old?.[1]])
              }
              placeholder={`Min`}
              className="form-control"
              style={{ minWidth: '100px', height: '35px' }}
            />
          </div>
          <div className="input-wrapper">
            <InputDebounce
              type="number"
              value={(columnFilterValue)?.[1] ?? ''}
              onChange={value =>
                column.setFilterValue((old) => [old?.[0], value])
              }
              placeholder={`Max`}
              className="form-control"
              style={{ minWidth: '100px', height: '35px' }}
            />
          </div>
        </div>
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      className='form-select'
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      <option value="">Tutti</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <div className="form-group boxed">
      <div className="input-wrapper">
        <InputDebounce
          className="form-control"
          onChange={value => column.setFilterValue(value)}
          placeholder={`Cerca...`}
          type="text"
          style={{ minWidth: '150px', height: '35px' }}
          value={(columnFilterValue ?? '')}
        />
      </div>
    </div>
  )
}
