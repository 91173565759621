//Components
import Capsule from 'layout/Capsule';
import OffCanvasViewAppointment from 'components/offcanvas/view-appointment/OffCanvasViewAppointment';
import Calendar from 'components/Calendar';

//Logic
import useCalendarUser from './useCalendarUser';

//Utils
import Header from 'layout/header/Header';
import { IoCalendarOutline } from 'react-icons/io5';

export default function CalendarUser() {

  const {
    handleEventClick,
    resources,
    selectedAppointmentId, dispatchViewAppointment,
    calendarLogic
  } = useCalendarUser();

  return (
    <>
      <Header pageTitle={'Agenda appuntamenti'} handleBack="home" show_agency />
      <Capsule className="pb-0">
          <Calendar 
            eventClick={handleEventClick}
            logic={calendarLogic}
            slotMinTime='08:00:00'
            slotMaxTime='24:00:00'
            leftActionButtons={[
              { 
                handleClick: () => calendarLogic.ref.current.getApi().changeView('dayGridMonth'),
                label: <IoCalendarOutline className='me-0'/>
              }
            ]}
          />
      </Capsule>
      <OffCanvasViewAppointment 
          appointmentId={selectedAppointmentId}
          handleClose={() => dispatchViewAppointment('CLOSE')}
      />
  </>);
}
