//Layout
import Header from 'layout/header/Header';

//Components
import ReservationService from "./service/ReservationService";
import ReservationCalendar from "./calendar/ReservationCalendar";
import ReservationCheckout from "./checkout/ReservationCheckout";
import ModalConfirm from './ModalConfirm';

//Logic
import { useReservationNew } from './useReservationNew';
import { IoCheckmarkOutline, IoChevronForward, IoPricetag, IoTime } from 'react-icons/io5';
import { useNavigate } from 'react-router';
import StripeLoader from 'components/loader/StripeLoader';
import { useAuthValue } from 'context/AuthContext';

function ExtraHeader({step }) {

    return (<>
        <div className="extraHeader p-0">
            <div className="form-wizard-section">
                <a className="button-item cursor-default">
                    <strong>1</strong>
                    <p>Immobile</p>
                </a>
                <a className={`button-item cursor-default ${step == 'service' ? 'active' : ''}`}>
                    <strong>2</strong>
                    <p>Servizio</p>
                </a>
                <a className={`button-item cursor-default ${step == 'calendar' ? 'active' : ''}`}>
                    <strong>3</strong>
                    <p>Data</p>
                </a>
                <a className={`button-item cursor-default ${step == 'checkout' ? 'active' : ''}`}>
                    <strong>
                        <IoCheckmarkOutline />
                    </strong>
                    <p>Riepilogo</p>
                </a>
            </div>
        </div>
    </>);
}

function BottomMenu(props) {

    const {
        cartItems,
        nextStep,
        subscription,
        show
    } = props;

    if (!cartItems?.products?.length || cartItems?.time <= 0 || !show) return;

    return (
        <div className="appBottomMenu service-total">
            <div className="section my-2 w-100">
                <h4 className="card-subtitle text-white">
                    <IoTime className='vertical-align-n2 me-05' />
                    Tempo totale: {cartItems.time}&nbsp;min.
                </h4>
                <h4 className="card-subtitle text-white float-right">
                    <IoPricetag className='vertical-align-n2 me-05' />
                    TOTALE: {subscription ? <><s>{cartItems.price}€</s> {cartItems.price - (cartItems.price * subscription.discount_percentage / 100)}</> : cartItems.price}€
                </h4>
                <button
                    type="button"
                    className={`btn btn-primary btn-block mt-1 mb-05 ${nextStep ? '' : 'disabled'}`}
                    onClick={nextStep}
                >
                    Continua
                    <IoChevronForward className='next-icon-btn' />
                </button>
            </div>
        </div>
    );
}

function ReservationNew() {

    const {
        step, setStep,
        buildingData,
        products,
        cartItems,
        handleSelectProduct, handleSelectProductPriority,
        handlePreviusStep,
        selectedTimeSlot, setSelectedTimeSlot,
        isInProgress,
        isCartInProgress,
        completeCheckout,
        reservationConfirmData,
        handleCloseConfirm,
        checkoutData, handleChangeCheckoutData,
        cancelOrder,
        appointmentId,
        photographers,
        nextStep
    } = useReservationNew();

    const navigate = useNavigate();
    const { user } = useAuthValue();
    const subscription = buildingData?.organization?.subscription;

    return (
        <>  
            <Header 
                pageTitle={buildingData ? `${buildingData.reference} ${buildingData.owner_last_name} ${buildingData.owner_first_name}` : ''} 
                handleBack={(isInProgress || isCartInProgress || !!appointmentId && step === 'calendar' ) ? null : handlePreviusStep}
                show_menu={false}
                handleClose={(isInProgress || isCartInProgress) ? null : () => navigate(user.is_admin ? '/admin/calendar' : '/')}
            />
            <ExtraHeader step={step} />
            {step === 'service' && <ReservationService 
                {...{
                    products,
                    buildingData,
                    handleSelectProduct, handleSelectProductPriority,
                    cartItems,
                    nextStep: nextStep,
                    photographers
                }}
            />}
            {step === 'calendar' && <ReservationCalendar 
                {...{
                    buildingData,
                    cartItems,
                    nextStep: nextStep,
                    setSelectedTimeSlot,
                    photographers
                }}
            />}
            {step === 'checkout' && <ReservationCheckout 
                {...{
                    buildingData,
                    cartItems,
                    selectedTimeSlot,
                    isInProgress,
                    isCartInProgress,
                    checkoutData,
                    handleChangeCheckoutData,
                    completeCheckout,
                    appointmentId
                }}
            />}
            <BottomMenu 
                cartItems={cartItems}
                nextStep={nextStep}
                subscription={subscription}
                show={step === 'service' || (step === 'calendar' && user.is_admin)}
            />
            <ModalConfirm 
                confirmData={reservationConfirmData}
                handleClose={handleCloseConfirm}
            />
            <StripeLoader 
                show={isInProgress} 
                onCancel={cancelOrder}
            />
        </>
    );
}

export default ReservationNew;