import { dialogOpen, dialogClose } from 'store/actions/dialogBox';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import axios from 'axios';
import { IoWarning, IoInformationCircle } from 'react-icons/io5';

const DISPATCH_UPDATE_TYPE = 'API_APPOINTMENT_UPDATE';

export default function useEditAppointment() {
    
    const dispatch = useDispatch();

    const checkUpdateAppointment = useCallback(newAppointmentData => {
        axios.put(`appointments/${newAppointmentData.id}/check`, newAppointmentData)
        .then(res => {
            if (!res.data.length) return dispatch({ type: DISPATCH_UPDATE_TYPE, payload: newAppointmentData});
            dispatch(dialogOpen({
                id: 'confirm_appointment_update',
                content: {
                    title: 'Conferma modifica',
                    body: res.data.map((alert, index) => {
                        return (
                            <p key={index} className={`text-${alert.type}`}>
                                <span className='me-1'>{alert.type === 'warning' ? <IoInformationCircle /> : <IoWarning />}</span>
                                {alert.label}
                            </p>
                        );
                    }),
                    confirm: {
                        label: 'CONFERMA',
                        dispatch: DISPATCH_UPDATE_TYPE,
                        data: newAppointmentData
                    },
                    cancel: {
                        label: 'ANNULLA',
                    },
                }
            }));
        });
    }, []);

    const checkDeleteAppointment = useCallback((appointment_id) => {
        dispatch(dialogOpen({
            id: 'confirm_appointment_delete',
            content: {
                title: 'ATTENZIONE!',
                body: 'Confermi di voler annullare la seguente prenotazione? L\'azione sarà irreversibile. Il totale della prenotazione verrà rimborsato come credito sull\'account.',
                confirm: {
                    label: 'CONFERMA',
                    dispatch: 'API_APPOINTMENT_DELETE',
                    data: {
                        appointment_id
                    }
                },
                cancel: {
                    label: 'ANNULLA',
                }
            }
        }));
    }, []);

    return { 
        checkUpdateAppointment,
        checkDeleteAppointment
    };

}

