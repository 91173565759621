import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import Button from 'react-bootstrap/Button';
import { auth } from 'utils/firebase-instance';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import em from 'utils/message';
import { toast } from 'react-toastify';

const DEFAULT_DATA = {
    current_password: '',
    password: '',
    password2: ''
}

function useOffCanvasEditPassword({
    handleClose
}) {

    const [formData, setFormData] = useState(DEFAULT_DATA);
    const [error, setError] = useState();

    const handleSubmit = e => {
        e.preventDefault();
        if (formData.password.length < 6 || formData.password.length >= 31) {
            return setError(em('auth/validate/password/length'));
        }
        if (formData.password !== formData.password2) {
            return setError(em('auth/validate/password/confirm'));
        }

        reauthenticateWithCredential(
            auth.currentUser,
            EmailAuthProvider.credential(
                auth.currentUser.email,
                formData.current_password
            )
        )
        .then(() => {
            updatePassword(auth.currentUser, formData.password)
            .then(() => {
                handleClose();
                toast.success('Password aggiornata con successo');
            }).catch((error) => {
                console.log(error);
                setError(error.message);
            });
        })
        .catch(error => {
            if (error.code === 'auth/wrong-password') {
                return setError('Password attuale non corretta');
            }
            return setError('Si è verificato un errore durante l\'aggiornamento. Riprova più tardi');
        });
    }

    const handleChange = e => {
        const { value, name} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const checkPassword = useMemo(() => {
        return !!formData.current_password.trim() && !!formData.password.trim() && !!formData.password2.trim();
    }, [formData]);

    return {
        formData,
        handleSubmit,
        handleChange,
        checkPassword,
        error
    }
}

function OffcanvasEditPassword(props) {

    const {
        show,
        handleClose,
    } = props;

    const {
        formData,
        handleSubmit,
        handleChange,
        error,
        checkPassword,
    } = useOffCanvasEditPassword(props);

    return (<>
        <Offcanvas placement="bottom" id="view-property" className="action-sheet" show={show} onHide={handleClose}>
            <Offcanvas.Body>
            <OffCanvasCloseBtn handleClose={handleClose}/>
                <div className="section mt-3 text-center">
                    <h3>Modifica password</h3>
                </div>
                <Form className="action-sheet-content py-2" onSubmit={handleSubmit}>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="password">Password attuale</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                name="current_password" 
                                autoComplete="current-password"
                                value={formData.current_password}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="password">Nuova password</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                name="password" 
                                autoComplete="new-password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="password2">Conferma nuova password</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                autoComplete="new-password"
                                name="password2"
                                value={formData.password2}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {error && (
                        <Alert className="lh-16" variant="danger">
                            {error}
                        </Alert>
                    )}
                    <div className="form-group basic">
                        <Button
                            className={`btn-block ${checkPassword ? '' : 'disabled'}`}
                            type="submit"
                        >Modifica password</Button>
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasEditPassword;