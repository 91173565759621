import { useState } from "react";

export default function useReservationService() {
    const [showOffCanvasServiceInfo, setShowOffCanvasServiceInfo] = useState(false);

    const handleProductDetail = product => {
        setShowOffCanvasServiceInfo(product);
    }

    return {
        showOffCanvasServiceInfo, setShowOffCanvasServiceInfo,
        handleProductDetail
    }
}