//import useReservationCheckout  from "./useReservationCheckout";
import Capsule from 'layout/Capsule';
import Accordion from 'react-bootstrap/Accordion';

//Assets
import amexImage from './img/amex.svg';
import visaImage from './img/visa.svg';
import mastercardImage from './img/mastercard.svg';
import { IoAlert, IoCalendarOutline, IoCardOutline, IoCashOutline, IoHomeOutline, IoWalletOutline } from 'react-icons/io5';

//Utils
import { capitalizeFirstLetter, numb } from 'utils/helpers';
import { Alert, Button, FormCheck } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useAuthValue } from 'context/AuthContext';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import OffCanvasAddFunds from 'components/offcanvas/OffCanvasAddFunds';
import SpinnerForButton from 'components/loader/SpinnerForButton';


function TemplateProduct(props) {

    const { product } = props;

    return (
        <li className={product.indent ? 'ps-3' : ''}>
            {product.indent ? '+' : ''} {product.description}
            <span className="text-muted" style={{whiteSpace: "nowrap"}}>
                {product.time ? `${product.time} min` : ''}
                <span className="right-price">{product.price ? numb(product.price) : 0}€</span>
            </span>
        </li>
    );
}

export default function ReservationCheckout(props) {

    const {
        buildingData,
        cartItems,
        selectedTimeSlot,
        isInProgress,
        isCartInProgress,
        completeCheckout,
        checkoutData,
        handleChangeCheckoutData,
    } = props;

    const { user } = useAuthValue();
    const { credit = null, subscription = null} = buildingData?.organization ?? {};
    const travel_paid = (selectedTimeSlot?.travel_paid?.km_refund ?? 0) + (selectedTimeSlot?.travel_paid?.toll ?? 0);
    const order_total = cartItems.price + travel_paid;
    const order_total_vati = order_total * 1.22;
    const order_discount = order_total * (subscription?.discount_percentage || 0) / 100;
    const real_cart_total = order_total - (subscription ? order_discount : 0);
    const real_cart_total_vi = real_cart_total * 1.22;

    const [showOffCanvasAddFunds, dispatchShowOffCanvasAddFunds] = useOffCanvasReducer();
    const { appointmentId } = useParams();

    console.log(checkoutData)

    return (
        <>
            <Capsule className='extra-header-active'>
                <div className="section mt-2 mb-2">
                    <div className="card">

                        <ul className="listview image-listview rounded">
                            <li>
                                <div className="item">
                                    <div className="icon-box bg-primary">
                                        <IoHomeOutline />
                                    </div>
                                    <div>
                                        <div className="text-14">{buildingData.address.full}</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <div className="icon-box bg-primary">
                                        <IoCalendarOutline />
                                    </div>
                                    <div className="text-14">
                                        {capitalizeFirstLetter(selectedTimeSlot.start.format('dddd D MMMM YYYY - HH:mm'))}
                                        {' -> '}
                                        {selectedTimeSlot.start.clone().add(cartItems.time, 'minutes').format('HH:mm')}
                                    </div>
                                </div>
                            </li>
                            {buildingData.resident_type === 'NOBODY' ? <li>
                                <div className="item">
                                    <div className="icon-box bg-warning">
                                        <IoAlert />
                                    </div>
                                    <div className="text-14 text-warning">
                                        Essendo che l'immobile non risulta essere abitato sarà necessario recarsi sul posto per aprire l'abitazione
                                    </div>
                                </div>
                            </li> : <li>
                                <div className="item">
                                    <div className="icon-box bg-warning">
                                        <IoAlert />
                                    </div>
                                    <div className="text-14 w-100">
                                        Consulente sul posto?
                                    </div>
                                    <FormCheck
                                        id="agent_on_site"
                                        name="agent_on_site"
                                        type="switch"
                                        label=""
                                        checked={checkoutData.agent_on_site}
                                        onChange={handleChangeCheckoutData}
                                        className='ms-1'
                                    />
                                </div>
                            </li>}
                        </ul>
                    </div>
                </div>

                {!appointmentId && <div className="section mt-2 mb-2">
                    <div className="section-title">
                        <h4 className="card-subtitle text-white text-12 mb-0">Riepilogo</h4>
                    </div>
                    <div className="card">
                        <ul className="listview simple-listview text-14 rounded">
                            {cartItems.products.sort((a, b) => {
                                if (a.type !== b.type) return a.type === 'PRIMARY' ? -1 : 1;
                                return a.order_by - b.order_by;
                            }).map(product => {
                                const return_products = [<TemplateProduct key={product.id} product={{...product, indent: product.parent_id}} />];
                                if (product.is_priority) {
                                    return_products.push(
                                        <TemplateProduct
                                            key={product.id + 'priority'}
                                            product={{
                                                description: product.priority_label,
                                                price: product.priority_price,
                                                indent: true
                                            }}
                                        />
                                    );
                                }
                                return return_products;
                            }).flat()}
                            {travel_paid > 0 && <TemplateProduct
                                key="travel_paid"
                                product={{
                                    description: 'Costo di trasferta',
                                    price: travel_paid,
                                    indent: false
                                }}
                            />}
                            <li className="bg-gray-dark fw-600">
                                Subtotale
                                <span className="active">
                                    <span className="fw-600 right-price">{numb(order_total)}€</span>
                                </span>
                            </li>
                            <li className="bg-gray-dark fw-600">
                                Totale (IVA inclusa)
                                <span className="active">
                                    {cartItems.time} min
                                    <span className="fw-600 right-price ms-2">
                                        {numb(order_total_vati)}€
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>}

                <div className='section'>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <textarea
                                className="form-control"
                                id="note"
                                name="note"
                                value={checkoutData.note}
                                onChange={handleChangeCheckoutData}
                                placeholder="Note appuntamento"
                            />
                        </div>
                    </div>
                </div>

                <div className="section inset mt-2">
                    {!appointmentId && order_total_vati > 0 && <div className="section-title">
                        <h4 className="card-subtitle text-white text-12 mb-0">Pagamento</h4>
                    </div>}

                    {!appointmentId && order_total_vati > 0 && <Accordion defaultActiveKey="CREDIT" className='text-14 accordion-payments'>
                        <Accordion.Item eventKey="CREDIT">
                            <Accordion.Header>
                                <IoWalletOutline /> <b>Utilizza credito</b> {subscription && <span className='ms-1 badge bg-primary'>SCONTO {subscription.description}</span>}
                            </Accordion.Header>
                            <Accordion.Body className='text-white'>
                                Paga rapidamente utilizzando il credito dell'attività.{' '}

                                {credit >= order_total_vati ? (
                                    <>
                                        Al momento il tuo saldo è di {numb(credit)}€. Procedendo con la conferma della prenotazione il nuovo saldo diventerà {numb(credit - real_cart_total_vi)}€.&nbsp;

                                        <ul className="listview simple-listview text-14 mt-1 mb-2 border-0 checkout">
                                            {subscription && <>
                                                <li> 
                                                    Totale ordine (iva esclusa)
                                                    <span className="text-muted">
                                                        <span className="right-price">{numb(order_total)}€</span>
                                                    </span>
                                                </li>
                                                <li className='text-primary'> 
                                                    Sconto {subscription.description}
                                                    <span className="text-muted">
                                                        <span className="right-price text-primary">-{numb(order_discount)}€</span>
                                                    </span>
                                                </li>
                                                <li> 
                                                    Totale scontato (iva esclusa)
                                                    <span className="text-muted">
                                                        <span className="right-price">{numb(real_cart_total)}€</span>
                                                    </span>
                                                </li>
                                                <li> Totale scontato (iva inclusa)
                                                    <span className="text-muted">
                                                        {numb(real_cart_total * 1.22)}€
                                                    </span>
                                                </li>
                                            </>}
                                            <li className="bg-gray-dark fw-600">
                                                Credito dopo la prenotazione
                                                <span className="active">
                                                    <span className="fw-600">{numb(credit - real_cart_total_vi)}€</span>
                                                </span>
                                            </li>
                                        </ul>

                                        <Button
                                            className={`btn-block ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                                            type="button"
                                            onClick={completeCheckout}
                                        >
                                            <SpinnerForButton show={isCartInProgress}/>
                                            Conferma pagamento
                                        </Button>
                                    </>) : (
                                    <>
                                        {user.is_admin ? <>
                                            Il seguente cliente non ha credito sufficiente per concludere la prenotazione. Il credito attuale è {credit}€.
                                        </> : <>
                                            Al momento il tuo saldo di {credit}€, quindi <b>non è sufficiente</b> a coprire l'importo dell'ordine, di seguito potrai ricaricarlo usufruendo dei nostri sconti dedicati.

                                            <Button
                                                className={`btn-block mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                                                type="button"
                                                onClick={() => dispatchShowOffCanvasAddFunds('OPEN')}
                                            >Ricarica credito</Button>
                                        </>}
                                    </>
                                )}

                            </Accordion.Body>
                        </Accordion.Item>
                        {credit < order_total_vati && order_total_vati > 0.50 && <Accordion.Item eventKey="0">
                            <Accordion.Header className='payments'>
                                <div><IoCardOutline className='d-inline' /><b>Paga con carta</b></div>
                                <div>
                                    <img className="payment-card" alt="Visa" src={visaImage} />
                                    <img className="payment-card mx-1" alt="Mastercard" src={mastercardImage} />
                                    <img className="payment-card" alt="American Express" src={amexImage} />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className='text-white'>
                                Procedendo potrai effettuare il pagamento della prenotazione medianta <b>carta di credito</b>. Cliccando il seguente bottone verrai rimandato ad una pagina esterna sicura per processare la transazione.

                                <Button
                                    className={`btn-block mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                                    type="button"
                                    onClick={completeCheckout}
                                >
                                    <SpinnerForButton show={isCartInProgress}/>
                                    Procedi al pagamento
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item>}
                        {/* <Accordion.Item eventKey="0">
                            <Accordion.Header className='payments'>
                                Paga con carta
                                <div>
                                    <img className="payment-card" alt="Visa" src={visaImage} />
                                    <img className="payment-card mx-1" alt="Mastercard" src={mastercardImage} />
                                    <img className="payment-card" alt="American Express" src={amexImage} />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className='pb-0'>

                                <div className="form-group boxed pb-0">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" autocomplete="off" placeholder="Numero carta" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 ps-1">
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <input className="form-control" type="text" placeholder="MM/AA" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 pe-1">
                                        <div className="form-group boxed">
                                            <div className="input-wrapper not-empty">
                                                <input className="form-control" type="text" placeholder="CVC" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button data-bs-toggle="modal" data-bs-target="#DialogIconedSuccess" type="button" data-bs-dismiss="offcanvas" className="btn btn-block btn-success mt-1">Conferma pagamento</button>
                                <div className="mt-1 cards-logo text-center text-12 text-secondary"><i>Pagamenti su server crittografato e sicuro</i></div>
                            </Accordion.Body>
                        </Accordion.Item> */}
                    </Accordion>}

                    {(appointmentId || order_total_vati === 0) && <button
                        type="button"
                        className={`btn btn-block btn-success mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                        onClick={completeCheckout}
                    >
                        <SpinnerForButton show={isCartInProgress}/>
                        {`${appointmentId ? 'Modifica' : 'Conferma'} prenotazione`}
                    </button>}
                </div>

            </Capsule>
            {!user.is_admin && <OffCanvasAddFunds 
                show={showOffCanvasAddFunds}
                handleClose={() => dispatchShowOffCanvasAddFunds('CLOSE')}
            />}
        </>);
}
