import InputUpload from "./InputUpload";

export function RenderInputUploadPlan({
    required = false,
    disabled,
    showDownloadable = true
}) {
    return <>
        <h4 className="card-subtitle text-white text-12">PLANIMETRIA IN FORMATO DWG O PDF:</h4>
        <InputUpload 
            gkey="render_plan"
            label="La planimetria deve essere in scala con le misure e mostrare la disposizione dell'arredamento."
            accept="image/*, .pdf, .dwg"
            disabled={disabled}
            required={required}
            showDownloadable={showDownloadable}
        />
    </>
}

export function RenderInputUploadReference({
    required = false,
    disabled,
    showDownloadable = true
}) {
    return <>
        <h4 className="card-subtitle text-white text-12 mt-2">FILE DI RIFERIMENTO:</h4>
        <InputUpload 
            gkey="render_reference"
            label="Allega foto da capitolato con: pavimenti, rivestimenti, lavabo, sanitari, battiscopa, ecc..."
            accept="image/*, .pdf"
            disabled={disabled}
            required={required}
            showDownloadable={showDownloadable}
        />
    </>
}

export function RenderInputUploadFurniture({
    required = false,
    disabled,
    showDownloadable = true
}) {
    return <>
        <h4 className="card-subtitle text-white text-12 mt-2">IMMAGINI DI RIFERIMENTO:</h4>
        <InputUpload 
            gkey="render_furniture"
            label="Foto relative allo stile di arredamento da seguire e dello stato attuale dell’immobile. Non compilare nel caso in cui vogliate lasciar fare a noi."
            accept="image/*, .pdf"
            disabled={disabled}
            required={required}
            showDownloadable={showDownloadable}
        />
    </>
}