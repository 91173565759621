//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Button from 'react-bootstrap/Button';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp, IoBusiness, IoHome, IoCamera, IoPerson, IoArchive, IoSettings, IoSettingsOutline } from 'react-icons/io5';
import moment from 'moment';

//logic
import { useFilterInput } from 'hooks/useFilterInput';
import { isMobile } from 'utils/helpers';
import { AutocompleteOrganization } from 'components/autocomplete/Autocomplete';
import useSocialPlanning from './useSocialPlanning';


function FilterBox({
    filter,
    handleChangeFilter,
}) {

    const {
        start,
        end,
    } = filter;

    return (
        <>
            <div className="section mb-2">
                <div className="card">
                    <div className="card-header">
                        Filtri di ricerca
                    </div>
                    <div className="card-body pb-2">
                        <div className="row g-3">
                            <div className="col-md-4">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Data inizio</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            style={{ background: '#3a3a3a' }}
                                            value={start}
                                            name="start"
                                            onChange={handleChangeFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Data fine</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            style={{ background: '#3a3a3a' }}
                                            value={end}
                                            name="end"
                                            onChange={handleChangeFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group boxed custom pb-0">
                                    <AutocompleteOrganization 
                                        onChange={handleChangeFilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

function PostProductionRow({
    appointment,
    index,
}) {
    const { building } = appointment;
    const { organization } = building;

    if (isMobile()) {
        return (
            <tr style={{ borderBottom: '14px solid #121212' }}>
                <td colSpan="11">
                    <div className="text-start">
                        <div>
                            <h3 className='mb-05'>{moment(appointment.estimated_start_at).format('DD/MM/YYYY - HH:mm')} | RIF. {building.reference}</h3>
                            <div className="text-14">
                                <IoBusiness className="vertical-align-n2" />{' '}
                                {organization.display_name}
                            </div>
                        </div>
                        <div className='text-15'>
                            <ul className="listview image-listview no-border no-after bg-272727">
                                {building.address && <li>
                                    <div className="item px-0">
                                        <div className="in">
                                            <div>
                                                <header><IoHome className="vertical-align-n2" /> Dettagli immobile:</header>
                                                <div className='mb-05'>{building.address.full}</div>
                                                <b>Proprietario:</b> {building.owner_last_name} {building.owner_first_name}<br />
                                            </div>
                                        </div>
                                    </div>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    return (
        <tr>
            <td>{moment(appointment.estimated_start_at).format('DD/MM/YYYY')}</td>
            <td>{organization.display_name}</td>
            <td>{building.owner_last_name} {building.owner_first_name}</td>
            <td>{building.address.full} - <b>RIF. {building.reference}</b></td>
        </tr>
    );
}

function Table({
    appointments,
    filterSearchArray,
    filterInputValue,
    handleFilterInputChange
}) {

    const filteredAppointments = appointments ? appointments.filter(filterSearchArray) : [];

    return (
        <div className="table-responsive text-center" style={{ borderRadius: '6px 6px 0 0' }}>

            <table className="table post-production-admin table-striped">
                <thead>
                    <tr>
                        <th colSpan="11">
                            <div className="form-group boxed custom py-0">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filtra per cliente, proprietario, riferimento o indirizzo"
                                        value={filterInputValue}
                                        onChange={handleFilterInputChange}
                                    />
                                </div>
                            </div>
                        </th>
                    </tr>
                    {!isMobile() && (
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Proprietario</th>
                            <th scope="col">Immobile</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {!appointments && (
                        <tr>
                            <td colSpan="11" className="text-center py-5"><span className="spinner-border"></span></td>
                        </tr>
                    )}
                    {appointments && (
                        filteredAppointments.length === 0 ? (
                            <tr>
                                <td colSpan="11" className="text-center py-5">Nessun risultato trovato.</td>
                            </tr>
                        ) : (
                            filteredAppointments.map((appointment, index) => (
                                <PostProductionRow
                                    key={appointment.id}
                                    index={index}
                                    appointment={appointment}
                                />
                            ))
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default function SocialPlanning() {

    const {
        filter,
        appointments,
        handleChangeFilter
    } = useSocialPlanning();

    const [
        filterInputValue,
        handleFilterInputChange,
        filterSearchArray
    ] = useFilterInput(appointment => {
        const { building } = appointment;
        const { organization } = building;
        return `${organization.display_name}${building.owner_first_name}${building.owner_last_name}${building.reference}${building.address.full}`;
    });

    return (
        <>
            <Header 
                pageTitle={"Planning social"}
            />
            <Capsule className='pb-0'>

                <FilterBox
                    filter={filter}
                    handleChangeFilter={handleChangeFilter}
                />

                <div className="section post-prod mb-2">
                    <Table
                        appointments={appointments}
                        filterSearchArray={filterSearchArray}
                        filterInputValue={filterInputValue}
                        handleFilterInputChange={handleFilterInputChange}
                    />
                </div>
            </Capsule>
        </>

    );
}