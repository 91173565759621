export default function PlaceHolderItemList({
    hideSecondLine = false
}) {
    return (
        <div className="in">
            <div>
                <span className="placeholder" style={{ width: '100px'}}></span>
                {!hideSecondLine && <span className="d-block text-muted">
                    <span className="placeholder" style={{ width: '230px'}}></span>
                </span>}
            </div>
        </div>
    );
}