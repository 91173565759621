export default function PhotographersSelect({
    label,
    name,
    value,
    photographers,
    handleChange,
    disablePh
}) {
    

    return (
        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <label className="form-label" htmlFor="oldpassword">
                    {label}
                </label>
                <select
                    className="form-control form-select"
                    onChange={handleChange}
                    name={name}
                    value={value}
                >
                    <option disabled value="">Seleziona fotografo</option>
                    {photographers?.map(ph => (
                        <option disabled={+disablePh === +ph.id} key={ph.id} value={ph.id}>
                            {ph.title}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}