import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import useUrlAction from "hooks/useUrlAction";
import { useEffect, useState } from "react";
import { deepCopy, getUrlParam } from "utils/helpers";

export default function useOrganizations() {
    const { user } = useAuthValue();
    const userOrganizations = user.agent?.organizations;
    const [organizations, setOrganizations] = useState();
    const [showOffCanvasAdd, dispatchOffCanvasAdd] = useOffCanvasReducer();
    const [editOrganization, dispatchEditOrganizationUsers] = useOffCanvasReducer();
    const [viewOrganizationId, dispatchViewOrganizationId] = useOffCanvasReducer();
    const [manageOrganizationId, dispathManageOranizationId] = useOffCanvasReducer();
    
    useUrlAction({
        show: [dispatchViewOrganizationId, [getUrlParam('id', true)]],
        new: [dispatchOffCanvasAdd, ['OPEN']]
    });

    useEffect(() => {
        axios.get('/organizations')
        .then(res => setOrganizations(res.data));
    }, [userOrganizations]);
    

    const handleAddOrganization = organization => {
        const organizationsCopy = deepCopy(organizations);
        organizationsCopy.push(organization);
        setOrganizations(
            organizationsCopy.sort((a, b) => (a.display_name, b.display_name))
        );
    }

    const handleUpdateData = editOrganization => {
        setOrganizations(
            organizations.map(organization => {
                if (+organization.id === +editOrganization.id) {
                    organization.display_name = editOrganization.display_name;
                }
                return organization;
            })
        )
    };

    return {
        organizations,
        showOffCanvasAdd, dispatchOffCanvasAdd,
        editOrganization, dispatchEditOrganizationUsers,
        viewOrganizationId, dispatchViewOrganizationId,
        manageOrganizationId, dispathManageOranizationId,
        handleUpdateData,
        handleAddOrganization
    }
}