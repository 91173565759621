import { getToken } from "firebase/messaging";
import { messaging } from "utils/firebase-instance";
import { dispatchAppEvent } from "./helpers";

const iosApp = !!(
    window.webkit && 
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers['push-permission-state'] &&
    window.webkit.messageHandlers['push-permission-request'] &&
    window.webkit.messageHandlers['fcm-token']
);

function checkPermissionsIOS() {
    return window.webkit.messageHandlers['push-permission-state'].postMessage('push-permission-state');
}

function requestPermissionsIOS() {
    return window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
}

function checkPermissionsWeb() {
    if (!("Notification" in window)) {
        return dispatchAppEvent('push-permission-state', false, window);
    }
    dispatchAppEvent('push-permission-state', Notification?.permission ?? false, window);
}

function requestPermissionsWeb() {
    if (!("Notification" in window)) {
        return dispatchAppEvent('push-permission-request', false, window);
    }
    Notification.requestPermission().then((permission) => {
        dispatchAppEvent('push-permission-request', permission, window);
    });
}

function requestTokenIOS() {
    return window.webkit.messageHandlers['fcm-token'].postMessage('fcm-token');
}

function requestTokenWeb() {
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
    .then((currentToken) => {
        if (currentToken) {
            dispatchAppEvent('fcm-token', currentToken, window);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
}

export function checkNotificationsPermission() {
    return iosApp ? checkPermissionsIOS() : checkPermissionsWeb();
}

export function requestNotificationPermission() {
    return iosApp ? requestPermissionsIOS() : requestPermissionsWeb();
}

export function requestNotificationToken() {
    return iosApp ? requestTokenIOS() : requestTokenWeb();
}

export function pushIsGranted(status) {
    if (status === 'default' || status === 'notDetermined') return null;
    return status === 'granted' || status === 'authorized';
}
