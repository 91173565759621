import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { IoAddOutline, IoRemoveOutline, IoTime, IoTimeOutline } from "react-icons/io5";
import { numb, stripeBalance } from "utils/helpers";
import { toast } from 'react-toastify';
import PlaceHolderItemList from "./loader/PlaceHolderItemList";

const DEFAULT_FORM_DATA = {
    amount: '',
    description: ''
}

function useStripeCredit({
    organizationId
}) {

    const [creditData, setCreditData] = useState();
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA)

    useEffect(() => {
        axios.get(`/organizations/${organizationId}/credit`, {
            params: {
                history: 1
            }
        })
            .then(res => {
                setCreditData(res.data);
            });
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        if (formData.description.length > 100) return toast.error('La descrizione non può superare i 100 caratteri');

        axios.post(`/organizations/${organizationId}/credit`, {
            ...formData,
            amount: formData.amount * (e.nativeEvent.submitter.id === 'add' ? 1 : -1)
        })
            .then(res => {
                if (!res.data.status) return toast.error(res.data.message);
                setCreditData(res.data.credit);
                setFormData(DEFAULT_FORM_DATA);
            })
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return {
        formData,
        creditData,
        handleSubmit,
        handleChange
    }
}

export default function StripeCredit(props) {

    const {
        formData,
        creditData,
        handleSubmit,
        handleChange
    } = useStripeCredit(props);

    const { user } = useAuthValue();

    // if (!creditData) return (<>
    //     <div className="text-center py-5">
    //         <span className="spinner-border"></span>
    //     </div>
    // </>);

    return (
        <>
            <div className="card text-white bg-balance mt-2 mb-3">
                <div className="card-body d-flex w-100 justify-content-between">
                    <div>
                        <h5 className="card-title mb-0">Saldo attività</h5>
                    </div>
                    <div className="align-self-center">
                        {!creditData ? 
                            <span className="placeholder" style={{ width: '40px' }}/> : 
                            <p className={`text-25 ${creditData.balance > 0 ? 'text-success' : 'text-danger'} mb-0`}>{numb(creditData.balance)}€</p>
                        }
                    </div>
                </div>
            </div>

            {user.is_admin && <Form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-auto align-self-center">
                        <button type="submit" id="dec" name="dec" className="btn btn-icon btn-lg btn-danger"><IoRemoveOutline /></button>
                    </div>
                    <div className="col text-center">
                        <div className="form-group boxed pt-0">
                            <input
                                type="number"
                                placeholder="Importo"
                                onChange={handleChange}
                                autoComplete="off"
                                name="amount"
                                value={formData.amount}
                                className="form-control mb-1 text-center"
                                required
                            />
                            <input
                                type="text"
                                placeholder="Descrizione variazione credito"
                                maxLength="50"
                                onChange={handleChange}
                                autoComplete="off"
                                name="description"
                                value={formData.description}
                                className="form-control text-center"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-auto align-self-center">
                        <button type="submit" id="add" name="add" className="btn btn-icon btn-lg btn-success"><IoAddOutline /></button>
                    </div>
                </div>
            </Form>}
            <ul className="listview image-listview no-border-bottom" style={{ maxHeight: '350px', overflowY: 'scroll'}}>
                {/* !history per indicare lo stato di isSkeleton = true */}
                {(creditData ?? { transactions: new Array(5).fill(null)}).transactions.map((history, index) => {
                    const amount = stripeBalance(history?.amount ?? 0);
                    return (
                        <li key={index}>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <div>{history ? history.description : <span className="placeholder" style={{ width: '200px'}}/>}</div>
                                        <div className="d-block text-muted">
                                            {history ? <>
                                                <IoTime className='vertical-align-n2' /> {' '} 
                                                {moment.unix(history.created).format('DD/MM/YYYY HH:mm')}
                                            </> : <span className="placeholder" style={{ width: '100px'}}/>}
                                        </div>
                                    </div>
                                    <div className={`text-16 ${history ? (amount > 0 ? 'text-success' : 'text-danger') : ''}`}>
                                        {history ? `${amount > 0 ? '+' : ''}${numb(stripeBalance(history.amount))}€` : <span className="placeholder" style={{ width: '60px'}}/>}
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </>
    );

}