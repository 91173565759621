import Capsule from 'layout/Capsule';
import { IoAlertCircle } from 'react-icons/io5';

export default function AccountDisabled() {

    return <>
        <Capsule>
            <div className="login-form mt-2">
                <div className="section">
                    <h1>Account disabilitato</h1>
                    <div className="text-danger mt-0 mb-2">
                        <IoAlertCircle style={{ fontSize: '110px' }} />
                    </div>
                    <p>Il tuo account risulta disabilitato, puoi richiedere la riattivazione contattando il tuo commerciale di riferimento.</p>
                </div>
            </div>

            </Capsule>
    </>;
}