import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dialogClose } from "store/actions/dialogBox";
import { selectDialogBox } from "store/selectors";

export default function DialogBox() {

    const prop = useSelector(selectDialogBox);
    const dialog_id = prop.contents[0]?.id;
    const content = prop.contents[0]?.content;
    const dispatch = useDispatch();

    return (
        <Modal className="dialogbox" show={prop.isOpen}>
            {content?.icon}
            <Modal.Header>
                <Modal.Title>
                    {content?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-gray'>
                {content?.body}
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-inline">
                    {content?.cancel && <a className="btn btn-text-danger" onClick={() => dispatch(
                        content.cancel.dispatch ? { 
                            type: content.cancel.dispatch, 
                            payload: content.cancel.data ?? null 
                        } : dialogClose(dialog_id))
                    }>
                        {content?.cancel?.label}
                    </a>}
                    {content?.confirm && <a className="btn btn-text-primary" onClick={() => dispatch(
                        content.confirm.dispatch ? { 
                        type: content.confirm.dispatch, 
                        payload: content.confirm.data ?? null 
                        } : dialogClose(dialog_id))
                    }>
                        {content?.confirm?.label}
                    </a>}
                </div>
            </Modal.Footer>
        </Modal>
    );
}