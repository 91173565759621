//Utils
import moment from 'moment';

//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import { IoCall, IoCamera, IoCar, IoCheckmarkCircle, IoExpandOutline, IoHome, IoHourglass, IoLeafOutline, IoMail, IoNavigate, IoPencil, IoPerson, IoRestaurant, IoTimeOutline, IoWarning } from 'react-icons/io5';
import OffCanvasPhotoPoll from './offcanvas-photo-poll/OffCanvasPhotoPoll';
import DayNavigator from 'components/DayNavigator';

//logic
import useAgenda from './useAgenda';
import { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { addressUrl } from 'utils/helpers';

//PrenotationItem non andrà più usato, vedere OffCanvasViewAppointment

function PrenotationItem({ appointment, onPhotoPoll }) {

    const isFuture = moment(appointment.estimated_start_at).startOf('day') > moment().startOf('day');
    const appointment_address = appointment.is_manual ? appointment.address?.full : appointment.building.address.full;
    const appointment_coords = appointment.is_manual ? appointment.coords : appointment.building.coords;

    return (
        <div className="card mb-2">
            <div className="card-header">
                {appointment.status === 'DONE' && <><span className="iconedbox iconedbox-lg float-end text-success h-auto w-auto">
                    <IoCheckmarkCircle />
                </span></>}
                <h3 className='mb-0'>{appointment.is_manual ? appointment.title : appointment.order.organization.display_name}</h3>
                <div className="text-14">
                    <IoTimeOutline className="vertical-align-n2" /> {' '}
                    {moment(appointment.estimated_start_at).format('HH:mm')} - {moment(appointment.estimated_end_at).format('HH:mm')}
                </div>
            </div>
            <div className="card-body p-0">
                <ul className="listview image-listview no-border no-after">
                    {appointment_address && <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header><IoHome className="vertical-align-n2" /> Indirizzo appuntamento:</header>
                                    <a target="_blank" href={addressUrl(appointment_coords)} className="active">
                                        {appointment_address} <IoNavigate className="vertical-align-n2 ms-05" />
                                    </a>
                                    <br />
                                    <span className="fw-600">Consulente sul posto?: </span> {appointment.agent_on_site ? 'SI' : 'NO'}
                                    {!appointment.is_manual && <>
                                        <br />
                                        Rif. {appointment.building.reference} - <span className="fw-600">Propietario: </span>
                                        {appointment.building.owner_last_name} {appointment.building.owner_first_name}
                                        <br />
                                        {appointment.building.building_type.description} - <IoExpandOutline className='vertical-align-n2' /> {appointment.building.sqm} m² - <IoLeafOutline className='vertical-align-n2' /> {appointment.building.garden_sqm} m² {appointment.building.floor && (<> - Piano {appointment.building.floor}</>)}
                                    </>}
                                </div>
                            </div>
                        </div>
                    </li>}
                    {!appointment.is_manual && <>      
                        <li>
                            <div className="item pt-0">
                                <div className="in">
                                    <div>
                                        <header><IoPerson className="vertical-align-n2" /> Riferimenti:</header>
                                        <span className="fw-600">Abitato da: </span>
                                        {appointment.building.resident_type === 'NOBODY' ? 'Nessuno' : <>
                                            {appointment.building.resident_type === 'OWNER' ? 'Proprietario' : 'Inquilino'}
                                            <br />
                                            {appointment.building.doorbell_name}
                                        </>}
                                    </div>
                                    {appointment.building.resident_type !== 'NOBODY' && <div className='nowrap'>
                                        <a href={`mailto:${appointment.building.resident_email}`}>
                                            <button type="button" className="btn btn-sm px-2 btn-call btn-linkedin me-1">
                                                <IoMail className="text-14 m-0" />
                                            </button>
                                        </a>
                                        <a href={`tel:${appointment.building.resident_phone}`}>
                                            <button type="button" className="btn btn-success btn-sm px-2 btn-call">
                                                <IoCall className="text-14 m-0" />
                                            </button>
                                        </a>
                                    </div>}
                                </div>
                            </div>
                        </li>
                        {appointment.agent && <li>
                            <div className="item pt-0">
                                <div className="in">
                                    <div>
                                        <header><IoPerson className="vertical-align-n2" /> Agente:</header>
                                        {appointment.agent.user.display_name}
                                    </div>
                                    <a href={`tel:${appointment.agent.user.phone}`}>
                                        <button type="button" className="btn btn-success btn-sm px-2 btn-call">
                                            <IoCall className="text-14" /> Chiama
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </li>}
                    </>}
                    {appointment.order && <li>
                        <div className="item pt-0">
                            <div className="in">
                                <div>
                                    <header><IoCamera className="vertical-align-n2" /> Servizio:</header>
                                    {appointment.order.order_items.map((item, index) => (
                                        <Fragment key={item.id}>
                                            {index > 0 && <br />}
                                            {item.product.description} {item.is_priority ? <span className="text-warning">({item.product.priority_label})</span> : ''}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </li>}
                    {appointment.note && (
                        <li>
                            <div className="item pt-0">
                                <div className="in">
                                    <div>
                                        <header><IoPencil className="vertical-align-n2" /> Note:</header>
                                        {appointment.note}
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            <div className="card-footer">
                {!appointment.is_manual && (
                    (process.env.NODE_ENV !== 'development' && (appointment.is_lock || isFuture)) ?
                    <div className='text-center text-warning text-14'>
                        <IoWarning /> {isFuture ? 'Non puoi compilare un servizio futuro' : 'Compila prima il servizio precedente!'}
                    </div> : (
                        appointment.status === 'ENDED' ?  
                        <Button className="btn-block my-1 disabled" variant="github">Questionario compilato</Button> 
                        : <Button
                            className="btn-block my-1"
                            variant={appointment.status === 'ENDED' ? 'github' : 'primary'}
                            type="button"
                            onClick={() => onPhotoPoll(appointment)}
                        >{appointment.status === 'ENDED' ? 'Modifica questionario servizio' : 'Compila servizio'}</Button>
                    )
                )}
            </div>
        </div>
    );
}

function StatisticsForm({
    survey,
    needSurveySave,
    handleChangeSurvey,
    handleSaveSurvey
}) {

    const isCompiled = survey && survey.id;

    return (
        <div className="wide-block pb-1 pt-1 rounded mb-2 placeholder-glow">
            <form onSubmit={handleSaveSurvey}>
                {survey || survey === null ?
                    <span className={`badge bg-${isCompiled ? 'success' : 'danger'} px-2 mb-1 `}>{isCompiled ? 'COMPILATO' : 'DA COMPILARE'}</span> :
                    <span className='badge px-2 mb-1 placeholder' style={{ width: '100px' }}>{' '}</span>
                }
                <ul className="listview image-listview no-line no-space flush">
                    <li>
                        <div className="item">
                            <div className="icon-box bg-primary">
                                <IoCar />
                            </div>
                            <div className="in">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <Form.Label>Usi la macchina personale oggi?</Form.Label>
                                        <Form.Select
                                            className='form-control'
                                            value={survey?.personal_car ?? ''}
                                            name="personal_car"
                                            onChange={handleChangeSurvey}
                                            required
                                        >
                                            <option value="" disabled>SELEZIONA</option>
                                            <option value="NO">No</option>
                                            <option value="MORNING">Sì, solo mattino</option>
                                            <option value="AFTERNOON">Sì, solo pomeriggio</option>
                                            <option value="FULL_DAY">Sì, tutto il giorno</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="item">
                            <div className="icon-box bg-primary">
                                <IoRestaurant />
                            </div>
                            <div className="in">
                                <div className="form-group basic">
                                    <div className="input-wrapper">

                                        <Form.Label>Rimborso pranzo?</Form.Label>
                                        <Form.Select
                                            className='form-control w-50 d-inline-block'
                                            value={survey?.lunch_refund ?? ''}
                                            name="lunch_refund"
                                            onChange={handleChangeSurvey}
                                            required
                                        >
                                            <option value="" disabled>SELEZIONA</option>
                                            <option value="0">No</option>
                                            <option value="1">Sì</option>
                                        </Form.Select>

                                        {/* di default su no, se selezionano si, scriviamo che il rimborso di default è 10€ ma se hanno pagato di più con i loro soldi lasciamo il campo da complicare. */}

                                        <input
                                            type="number"
                                            className="form-control w-50 d-inline-block ps-5"
                                            value={survey?.lunch_cost ?? ''}
                                            name="lunch_cost"
                                            onChange={handleChangeSurvey}
                                            readOnly={survey?.lunch_refund != 1}
                                            required={survey?.lunch_refund == 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                {needSurveySave && <div className='text-center my-2'>
                    <Button
                        className={`btn-sm fw-600 ${needSurveySave ? 'blinking-element' : ''}`}
                        variant="primary"
                        type="submit"
                    >
                        AGGIORNA
                    </Button>
                </div>}
            </form>
        </div>
    );
}

function FormShowCompleted({ checked, handleChange }) {
    return (<>
        <ul className="listview simple-listview no-border rounded mb-2">
            <li className="text-white">
                <div>Nascondi appuntamenti completati?</div>
                <FormCheck
                    id="main"
                    type="switch"
                    label=""
                    checked={checked}
                    onChange={handleChange}
                />
            </li>
        </ul>
    </>);
}


export default function Agenda() {

    const {
        showOffCanvasPhotoPoll, setShowOffCanvasPhotoPoll,
        next, previous, day,
        hideEnded, handleChangeShowEnded,
        dailyAppointments,
        onSavePolls,
        viewPhotoPoll, dispatchViewPhotoPoll,
        survey,
        needSurveySave,
        handleChangeSurvey,
        handleSaveSurvey
    } = useAgenda();

    return (
        <>
            <DayNavigator
                next={next}
                previous={previous}
                day={day}
            />
            <div className='section post-prod'>

                <FormShowCompleted
                    checked={hideEnded}
                    handleChange={handleChangeShowEnded}
                />
                <StatisticsForm
                    survey={survey}
                    needSurveySave={needSurveySave}
                    handleChangeSurvey={handleChangeSurvey}
                    handleSaveSurvey={handleSaveSurvey}
                />

                {dailyAppointments && dailyAppointments.map((appointment) => (!hideEnded || appointment.status === 'CONFIRMED') ? (
                    <PrenotationItem
                        key={appointment.id}
                        appointment={appointment}
                        onPhotoPoll={dispatchViewPhotoPoll}
                    />
                ) : '')}
                <OffCanvasPhotoPoll
                    photoPolls={viewPhotoPoll?.order}
                    handleClose={() => dispatchViewPhotoPoll('CLOSE')}
                    isEnded={viewPhotoPoll?.status === 'ENDED'}
                    onSavePolls={onSavePolls}
                />

            </div>
        </>

    );
}