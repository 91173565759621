import axios from "axios";
import OffCanvasCloseBtn from "components/offcanvas/OffCanvasCloseBtn";
import { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from "utils/helpers";

function useOffCanvasAddUser(props) {

    const { onAddUser, handleClose, show } = props;

    const [addUser, setAddUser] = useState({
        display_name: '',
        email: '',
        phone: '',
        password: ''
    });

    useEffect(() => {
        setAddUser({
            display_name: '',
            email: '',
            phone: '',
            password: ''
        });
    }, [show]);

    const handleAddUser = e => {
        e.preventDefault();
        axios.post('users', addUser)
        .then(res => {
            if (res.data.error) return toast.error(res.data.message);
            handleClose();
            onAddUser(res.data);
        });
    }

    const changeInput  = e => {
        const { name, value } = e.target;
        setAddUser({
            ...addUser,
            [name]: value
        });
    }

    return {
        addUser,
        handleAddUser,
        changeInput
    }
}

export default function OffCanvasAddUser(props) {

    const {
        show, 
        handleClose 
    } = props;

    const {
        handleAddUser,
        addUser,
        changeInput
    } = useOffCanvasAddUser(props);

    return (
        <Offcanvas placement="bottom" className="action-sheet placeholder-glow" show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Aggiungi utente</h3>
                </div>
                <Form className="section mt-1 mb-2" onSubmit={handleAddUser}>
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <Form.Label className="label-bordered" htmlFor="display_name">Nominativo</Form.Label>
                            <Form.Control
                                type="text"
                                name="display_name"
                                id="display_name"
                                minLength="3"
                                maxLength={50}
                                value={capitalizeFirstLetter(addUser.display_name)}
                                onChange={changeInput}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <Form.Label className="label-bordered" htmlFor="email">Email</Form.Label>
                            <Form.Control
                                value={addUser.email}
                                type="email"
                                id="email"
                                name="email"
                                onChange={changeInput}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <Form.Label className="label-bordered" htmlFor="phone">Telefono</Form.Label>
                            <Form.Control
                                value={addUser.phone.replace(/\s/g, '')}
                                type="text"
                                id="phone"
                                name="phone"
                                minLength="8"
                                maxLength="15"
                                pattern="[0-9]*"
                                onChange={changeInput}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <Form.Label className="label-bordered" htmlFor="password">Password</Form.Label>
                            <Form.Control
                                value={addUser.password}
                                type="text"
                                id="password"
                                name="password"
                                minLength="8"
                                maxLength="30"
                                onChange={changeInput}
                                required
                            />                            
                        </div>
                    </div>

                    <Button
                        className="btn-block mt-1"
                        type="submit"
                    >Aggiungi utente</Button>

                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
}