const it = {
  'alternatives.all': '{{#label}} non corrisponde a tutti i tipi richiesti',
  'alternatives.any': '{{#label}} non corrisponde a nessuno dei tipi consentiti',
  'alternatives.match': '{{#label}} non corrisponde a nessuno dei tipi consentiti',
  'alternatives.one': '{{#label}} corrisponde a più di un tipo consentito',
  'alternatives.types': '{{#label}} deve essere uno dei seguenti tipi: {{#types}}',

  'any.custom': '{{#label}} ha fallito la validazione personalizzata perché {{#error.message}}',
  'any.default': '{{#label}} ha generato un errore durante l\'esecuzione del metodo di default',
  'any.failover': '{{#label}} ha generato un errore durante l\'esecuzione del metodo di fallback',
  'any.invalid': '{{#label}} contiene un valore non valido',
  'any.only': '{{#label}} deve essere {if(#valids.length == 1, "", "uno tra ")}{{#valids}}',
  'any.ref': '{{#label}} {{#arg}} fa riferimento a "{{#ref}}" che {{#reason}}',
  'any.required': '{{#label}} è richiesto',
  'any.unknown': '{{#label}} non è consentito',

  'array.base': '{{#label}} deve essere un array',
  'array.excludes': '{{#label}} contiene un valore escluso',
  'array.hasKnown': '{{#label}} non contiene almeno una corrispondenza richiesta per il tipo "{#patternLabel}"',
  'array.hasUnknown': '{{#label}} non contiene almeno una corrispondenza richiesta',
  'array.includes': '{{#label}} non corrisponde a nessuno dei tipi consentiti',
  'array.includesRequiredBoth': '{{#label}} non contiene {{#knownMisses}} e {{#unknownMisses}} altri valore/i richiesto/i',
  'array.includesRequiredKnowns': '{{#label}} non contiene {{#knownMisses}}',
  'array.includesRequiredUnknowns': '{{#label}} non contiene {{#unknownMisses}} valore/i richiesto/i',
  'array.length': '{{#label}} deve contenere {{#limit}} elementi',
  'array.max': '{{#label}} deve contenere meno o uguale a {{#limit}} elementi',
  'array.min': '{{#label}} deve contenere almeno {{#limit}} elementi',
  'array.orderedLength': '{{#label}} deve contenere al massimo {{#limit}} elementi',
  'array.sort': '{{#label}} deve essere ordinato in ordine {#order} per {{#by}}',
  'array.sort.mismatching': '{{#label}} non può essere ordinato a causa di tipi incompatibili',
  'array.sort.unsupported': '{{#label}} non può essere ordinato a causa del tipo non supportato {#type}',
  'array.sparse': '{{#label}} non deve essere un elemento array sparso',
  'array.unique': '{{#label}} contiene un valore duplicato',

  'binary.base': '{{#label}} deve essere un buffer o una stringa',
  'binary.length': '{{#label}} deve essere di {{#limit}} byte',
  'binary.max': '{{#label}} deve essere minore o uguale a {{#limit}} byte',
  'binary.min': '{{#label}} deve essere di almeno {{#limit}} byte',

  'boolean.base': '{{#label}} deve essere un booleano',

  'date.base': '{{#label}} deve essere una data valida',
  'date.format': '{{#label}} deve essere nel formato {msg("date.format." + #format) || #format}',
  'date.greater': '{{#label}} deve essere maggiore di "{{#limit}}"',
  'date.less': '{{#label}} deve essere minore di "{{#limit}}"',
  'date.max': '{{#label}} deve essere minore o uguale a "{{#limit}}"',
  'date.min': '{{#label}} deve essere maggiore o uguale a "{{#limit}}"',

  'date.format.iso': 'data ISO 8601',
  'date.format.javascript': 'timestamp o numero di millisecondi',
  'date.format.unix': 'timestamp o numero di secondi',

  'function.arity': '{{#label}} deve avere un\'arità di {{#n}}',
  'function.class': '{{#label}} deve essere una classe',
  'function.maxArity': '{{#label}} deve avere un\'arità minore o uguale a {{#n}}',
  'function.minArity': '{{#label}} deve avere un\'arità maggiore o uguale a {{#n}}',

  'object.and': '{{#label}} contiene {{#presentWithLabels}} senza i relativi compagni richiesti {{#missingWithLabels}}',
  'object.assert': '{{#label}} non è valido perché {if(#subject.key, `"` + #subject.key + `" ha fallito il test di asserzione`, #message || "l\'asserzione ha fallito")}',
  'object.base': '{{#label}} deve essere di tipo {{#type}}',
  'object.instance': '{{#label}} deve essere un\'istanza di "{{#type}}"',
  'object.length': '{{#label}} deve avere {{#limit}} chiave{if(#limit == 1, "", "s")}',
  'object.max': '{{#label}} deve avere meno o uguale a {{#limit}} chiave{if(#limit == 1, "", "s")}',
  'object.min': '{{#label}} deve avere almeno {{#limit}} chiave{if(#limit == 1, "", "s")}',
  'object.missing': '{{#label}} deve contenere almeno una delle seguenti chiavi: {{#peersWithLabels}}',
  'object.nand': '"{{#mainWithLabel}}" non deve esistere contemporaneamente a {{#peersWithLabels}}',
  'object.oxor': '{{#label}} contiene un conflitto tra i compagni esclusivi opzionali {{#peersWithLabels}}',
  'object.pattern.match': 'le chiavi di {{#label}} non corrispondono ai requisiti del pattern',
  'object.refType': '{{#label}} deve essere un riferimento Joi',
  'object.regex': '{{#label}} deve essere un oggetto RegExp',
  'object.rename.multiple': '{{#label}} non può rinominare "{{#from}}" perché sono disabilitate le rinomine multiple e un\'altra chiave è già stata rinominata in "{{#to}}"',
  'object.rename.override': '{{#label}} non può rinominare "{{#from}}" perché la sovrascrittura è disabilitata e la destinazione "{{#to}}" esiste',
  'object.schema': '{{#label}} deve essere uno schema Joi di tipo {{#type}}',
  'object.unknown': '{{#label}} non è consentito',
  'object.with': '"{{#mainWithLabel}}" manca il compagno richiesto "{{#peerWithLabel}}"',
  'object.without': '"{{#mainWithLabel}}" è in conflitto con il compagno vietato "{{#peerWithLabel}}"',
  'object.xor': '{{#label}} contiene un conflitto tra i compagni esclusivi {{#peersWithLabels}}',
  
  'number.base': '{{#label}} deve essere un numero',
  'number.greater': '{{#label}} deve essere maggiore di {{#limit}}',
  'number.infinity': '{{#label}} non può essere infinito',
  'number.integer': '{{#label}} deve essere un numero intero',
  'number.less': '{{#label}} deve essere minore di {{#limit}}',
  'number.max': '{{#label}} deve essere minore o uguale a {{#limit}}',
  'number.min': '{{#label}} deve essere maggiore o uguale a {{#limit}}',
  'number.multiple': '{{#label}} deve essere un multiplo di {{#multiple}}',
  'number.negative': '{{#label}} deve essere un numero negativo',
  'number.port': '{{#label}} deve essere una porta valida',
  'number.positive': '{{#label}} deve essere un numero positivo',
  'number.precision': '{{#label}} non può avere più di {{#limit}} cifre decimali',
  'number.unsafe': '{{#label}} deve essere un numero sicuro',
  
  'string.alphanum': '{{#label}} può contenere solo caratteri alfanumerici',
  'string.base': '{{#label}} deve essere una stringa',
  'string.base64': '{{#label}} deve essere una stringa base64 valida',
  'string.creditCard': '{{#label}} deve essere una carta di credito',
  'string.dataUri': '{{#label}} deve essere una stringa dataUri valida',
  'string.domain': '{{#label}} deve contenere un nome di dominio valido',
  'string.email': '{{#label}} deve essere un indirizzo email valido',
  'string.empty': '{{#label}} non è consentito essere vuoto',
  'string.guid': '{{#label}} deve essere un GUID valido',
  'string.hex': '{{#label}} può contenere solo caratteri esadecimali',
  'string.hexAlign': 'la rappresentazione decodificata in esadecimale di {{#label}} deve essere allineata per byte',
  'string.hostname': '{{#label}} deve essere un nome di host valido',
  'string.ip': '{{#label}} deve essere un indirizzo IP valido con una maschera CIDR di {{#cidr}}',
  'string.ipVersion': '{{#label}} deve essere un indirizzo IP valido di una delle seguenti versioni: {{#version}} con una maschera CIDR di {{#cidr}}',
  'string.isoDate': '{{#label}} deve essere nel formato ISO',
  'string.isoDuration': '{{#label}} deve essere una durata ISO 8601 valida',
  'string.length': 'La lunghezza di {{#label}} deve essere di {{#limit}} caratteri',
  'string.lowercase': '{{#label}} deve contenere solo caratteri minuscoli',
  'string.max': 'La lunghezza di {{#label}} deve essere inferiore o uguale a {{#limit}} caratteri',
  'string.min': 'La lunghezza di {{#label}} deve essere di almeno {{#limit}} caratteri',
  'string.normalize': '{{#label}} deve essere normalizzato in unicode nella forma {{#form}}',
  'string.token': '{{#label}} può contenere solo caratteri alfanumerici e underscore',
  'string.pattern.base': '{{#label}} con valore "{[.]}" non corrisponde al pattern richiesto: {{#regex}}',
  'string.pattern.name': '{{#label}} con valore "{[.]}" non corrisponde al pattern {{#name}}',
  'string.pattern.invert.base': '{{#label}} con valore "{[.]}" corrisponde al pattern invertito: {{#regex}}',
  'string.pattern.invert.name': '{{#label}} con valore "{[.]}" corrisponde al pattern invertito {{#name}}',
  'string.trim': '{{#label}} non deve avere spazi vuoti iniziali o finali',
  'string.uri': '{{#label}} deve essere un URI valido',
  'string.uriCustomScheme': '{{#label}} deve essere un URI valido con uno schema che corrisponde al pattern {{#scheme}}',
  'string.uriRelativeOnly': '{{#label}} deve essere un URI relativo valido',
  'string.uppercase': '{{#label}} deve contenere solo caratteri maiuscoli',

  'symbol.base': '{{#label}} deve essere un simbolo',
  'symbol.map': '{{#label}} deve essere uno dei seguenti: {{#map}}'

}

export default {
  it
}