import axios from 'axios';
import { useAuthValue } from 'context/AuthContext';
import { useEffect, useMemo, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from './OffCanvasCloseBtn';
import { IoCardOutline, IoCheckmarkCircle, IoClipboardOutline, IoCloseCircle, IoCopyOutline, IoWalletOutline, IoWarning } from 'react-icons/io5';
import useOrder from 'hooks/useOrder';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import { copyToClipboard, loadLaravelUser } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { dialogOpen, dialogClose } from 'store/actions/dialogBox';
import StripeLoader from 'components/loader/StripeLoader';
import { Accordion } from 'react-bootstrap';

function useOffCanvasAddFunds({
    show,
    handleClose,
}) {

    const [products, setProducts] = useState();
    const { placeOrder, isInProgress, cancelOrder, resetPaymentData } = useOrder();
    const { user, setUser } = useAuthValue();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!show) return;
        if (products && products.length) return setProducts(products.map(p => {
            p.is_selected = false;
            return p;
        }));

        axios.get('products', {
            params: {
                type: 'CREDIT'
            }
        })
            .then(res => {
                setProducts(res.data)
            });
    }, [show]);

    const selectProduct = (product_id) => {
        setProducts(products.map(p => {
            p.is_selected = p.id === product_id;
            return p;
        }));
    }

    const selectedProduct = useMemo(() => (products || []).find(p => p.is_selected), [products]);

    useEffect(() => {
        resetPaymentData();
    }, [selectedProduct, show]);

    const handleSubmit = () => {
        placeOrder({
            organization_id: user.agent?.getSelectedOrganization().id,
            products: [{ id: selectedProduct.id, is_priority: false }]
        })
            .then(async () => {
                handleClose();
                dispatch(dialogOpen({
                    id: 'add_funds_success',
                    content: {
                        icon: <div className="modal-icon text-success"><IoCheckmarkCircle /></div>,
                        title: 'Ricarica completata con successo',
                        body: 'Il saldo della tua agenzia è stato ricaricato correttamente. Potrai ora usufruirne in fase di riepilogo per prenotare i prossimi servizi, grazie!',
                        confirm: {
                            label: 'CHIUDI',
                        }
                    }
                }));
                setUser(await loadLaravelUser());
            })
            .catch(() => {

            });
    }

    return {
        products,
        selectProduct,
        selectedProduct,
        handleSubmit,
        isInProgress,
        cancelOrder
    }
}

export default function OffCanvasAddFunds(props) {

    const {
        show,
        handleClose,
    } = props;

    const {
        products,
        selectProduct,
        selectedProduct,
        handleSubmit,
        isInProgress,
        cancelOrder
    } = useOffCanvasAddFunds(props);

    const { user } = useAuthValue();
    const organization = user.agent.getSelectedOrganization();

    const currentSubscription = (products || []).find(p => p.subscription.id === organization.subscription?.id);
    const isDowngrade = (currentSubscription && selectedProduct) && selectedProduct.order_by < currentSubscription.order_by;
    const IBAN = 'IT65H0200812932000105810502';

    return <>
        <Offcanvas placement="bottom" className="action-sheet" show={show} onHide={handleClose} backdrop={isInProgress ? 'static' : true}>
            <Offcanvas.Body>
                <div className="section mt-2 text-center">
                    <div className="iconedbox iconedbox-lg d-block m-auto text-center">
                        <IoWalletOutline />
                    </div>
                    <h3 style={{ marginTop: '-8px' }}>Ricarica credito</h3>
                    {!isInProgress && <OffCanvasCloseBtn handleClose={handleClose} />}
                </div>

                <div className="text-center px-2 max-width-600 m-auto mb-3 lh-20">Seleziona il credito che vuoi ricaricare per la tua agenzia. Potrai successivamente usufruirne per prenotare i tuoi servizi. {currentSubscription && <>Attualmente fai parte del pacchetto <b className='text-primary'>{currentSubscription.description}</b>.</>}</div>

                <div className="section choose-credit mb-3">

                    {!products && new Array(3).fill(null).map((_, index) => {
                        return <label className="credit-card" key={index}>
                            <span className="plan-details">
                                <span className="plan-type text-white">
                                    <span className="placeholder" style={{ width: '80px' }}></span>
                                </span>
                                <span className="plan-cost"><span className="placeholder" style={{ width: '40px' }}></span></span>
                                <span className="text-white fw-500 text-16"><span className="placeholder" style={{ width: '80px' }}></span></span>
                                <span className="desc"><span className="placeholder" style={{ width: '150px' }}></span></span>
                            </span>
                        </label>
                    })}

                    {products && products.map(product => (
                        <label className="credit-card" htmlFor={`radio_${product?.id}`} key={product.id}>
                            <input
                                type="radio"
                                name="credit"
                                className="radio"
                                checked={!!product.is_selected}
                                id={`radio_${product.id}`}
                                onChange={() => selectProduct(product.id)}
                                disabled={isInProgress}
                            />
                            <span className="plan-details">
                                <span className="plan-type">
                                    {product.description}
                                </span>
                                <span className="plan-cost">{product.price_matrix.price}€</span>
                                <span className="text-white fw-500 text-16">+IVA</span>
                                {product?.subscription.discount_percentage && (
                                    <span className="desc"><b>{product?.subscription.discount_percentage}%</b> di sconto su tutti i servizi</span>
                                )}
                            </span>
                        </label>
                    ))}
                </div>


                {isDowngrade && <div className='section mt-2 text-center lh-16 mx-3 rounded py-2'>
                    <IoWarning /> Attualmente ti trovi nel pacchetto <b>{currentSubscription.description}</b>. Procedendo con questa ricarica perderai lo sconto del <b>{currentSubscription.subscription.discount_percentage}%</b> sul credito rimasto e passerai al <b>{selectedProduct.subscription.discount_percentage}%</b>.
                </div>}

                {selectedProduct && <div className="form-group basic px-3 py-3">
                    <h4 className='ps-2 text-bold'>Metodo di pagamento:</h4>
                    <Accordion defaultActiveKey="CARD" className='text-14'>
                        <Accordion.Item eventKey="BACS">
                            <Accordion.Header>
                                <IoWalletOutline /> <b>Bonifico bancario</b>
                            </Accordion.Header>
                            <Accordion.Body className='text-white'>
                                Effettua il pagamento tramite bonifico bancario. Usa la tua P.IVA come causale dell’ordine. Il tuo credito non verrà ricaricato finché i fondi non risulteranno trasferiti nel nostro conto corrente. Per velocizzare l'operazione ti chiediamo cortesemente di inviare a <a>info@photodaf.com</a> la contabile del bonifico effettuato.
                                <br />
                                IBAN: <b className="cursor-pointer" onClick={() => copyToClipboard(IBAN)}>{IBAN} <IoClipboardOutline /></b>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="CARD">
                            <Accordion.Header>
                                <IoCardOutline /> <b>Carta di credito</b>
                            </Accordion.Header>
                            <Accordion.Body className='text-white'>
                                <button
                                    type="submit"
                                    className={`btn btn-primary btn-block ${selectedProduct && !isInProgress ? '' : 'disabled'}`}
                                    onClick={handleSubmit}
                                >
                                    <SpinnerForButton show={isInProgress} />
                                    Acquista con carta
                                </button>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>}

            </Offcanvas.Body>
        </Offcanvas>
        <StripeLoader
            show={isInProgress}
            onCancel={cancelOrder}
        />
    </>;

}