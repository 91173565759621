import loaderImage from 'assets/img/icon-photodaf.png';

export default function StripeLoader({
    show,
    onCancel
}) {
    if (!show) return;

    return (
        <div className='loader-stripe'>
            <div>
                <img src={loaderImage}/>
                <u className='cursor-pointer' onClick={onCancel}>Annulla</u>
            </div>
        </div>
    );
}