import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSearchBoxCore, useSearchSession } from '@mapbox/search-js-react';
import { useAuthValue } from "context/AuthContext";
import { mapBoxFeatureFormat } from 'utils/helpers';
import axios from 'axios';

//Joi
import Joi from 'joi';
import joi_messages from 'utils/joi-translation';


export function useStepAddress(props) {
    const { 
        setStepAddressData,
        nextStep,
        setAddressError,
        organizationSelected
    } = props;
    const [addressValue, setAddressValue] = useState('');
    const [results, setResults] = useState();
    const [isInProgress, setIsInProgress] = useState(false);
    const addressRef = useRef(null);
    const searchInstance = useSearchBoxCore({
        accessToken: process.env.REACT_APP_MAPBOX_API,
        language: 'it',
        //country: 'it',
        limit: 4,
        types: ['street', 'address'],
        proximity: `${organizationSelected.coords.lng},${organizationSelected.coords.lat}`
    });
    const searchSession = useSearchSession(searchInstance);
    
    const handleAddressChange = async (event) => {
        setAddressValue(event.target.value);
    }

    const handleSelectPlace = async suggestion => {
        setIsInProgress(suggestion.mapbox_id);
        const { features } = await searchInstance.retrieve(suggestion, { sessionToken: searchSession.sessionToken });
        axios.get(process.env.REACT_APP_FIREBASE_FUNCTIONS_URL +'calendar-pointszone', {
            params: {
                points: features[0].geometry.coordinates.join(','),
                full_zone: 1
            }
        })
        .then(res => {
            const [ zone ] = res.data;
            setStepAddressData(features[0]);
            //Zona non coperta
            (!zone || !zone.id || !zone.is_reservable ) && setAddressError(true);
            nextStep();
            setIsInProgress(false);
        })
    }

    useEffect(() => {
        setIsInProgress(false);
        addressRef.current.focus();
        setAddressError(false);
    }, []);

    useEffect(() => {
        if (!addressValue || addressValue.trim().length < 3) {
            return setResults();
        }
        setResults(null);
        const delayTimer = setTimeout(async () => {
            const result = await searchInstance.suggest(addressValue, { sessionToken: searchSession.sessionToken });
            setResults(result.suggestions);
        }, 800); // Ritardo di 800 ms

        return () => clearTimeout(delayTimer);
    }, [addressValue]);

    return {
        addressRef,
        addressValue,
        handleAddressChange,
        handleSelectPlace,
        results,
        isInProgress
    }
}


export function useStepMap(props) {

    const {
        previusStep,
        nextStep,
        stepAddressData,
        setStepAddressData
    } = props;

    const [showMap, setShowMap] = useState(false);
    const [isEditMap, setIsEditMap] = useState(false);

    const changeLocation = (coords) => {
        setStepAddressData({
            properties: {
                ...stepAddressData.properties,
                coordinates: {
                    ...stepAddressData.properties.coordinates,
                    latitude: coords[1],
                    longitude: coords[0],
                }
            },
            geometry: {
                coordinates: coords
            }
        });
        handleNextStep();
    }

    const handleNextStep = () => {
        setShowMap(null);
    }

    const handlePreviusStep = () => {
        setShowMap();
    }

    const handleIsEditMap = event => {
        if (
            typeof event?.target?.className === 'string' &&
            event?.target?.className?.includes('MinimapButtonAdjust')
        ) {
            setIsEditMap(true)
        }
    }

    useEffect(() => {
        setShowMap(true);
    }, []);

    useEffect(() => {
        if (showMap === null) return nextStep();
        if (typeof showMap === 'undefined') return previusStep();
    }, [showMap])

    return {
        handleNextStep,
        handlePreviusStep,
        showMap,
        changeLocation,
        isEditMap, 
        handleIsEditMap
    }
}

export function useStepInfo(props) {
    const {
        stepInfo,
        setStepInfo,
        buildingTypes,
        nextStep,
        organizationSelected,
        isEdit
    } = props;

    const [error, setError] = useState();

    const handleChange = (e) => {
        const { name, value} = e.target;
        setStepInfo({...stepInfo, [name]: value });
    }

    const handleChangeBuildingType = (e) => {
        const buildingType = buildingTypes.find(bt => +bt.id === +e.target.value);
        setStepInfo({...stepInfo, buildingType });
    }

    const handleContinue = () => {
        const schema = Joi.object({
            show_floor: Joi.boolean().required(),
            ask_owner_detail: Joi.boolean().required(),
            reference: Joi.string().label('Riferimento immobile').min(1).max(30),
            owner_first_name: Joi.string().label('Nome proprietario').when('ask_owner_detail', {
                is: true,
                then: Joi.string().label('Nome proprietario').min(3).max(50),
                otherwise: Joi.string().empty(''),
            }),
            owner_last_name: Joi.string().label('Cognome proprietario').when('ask_owner_detail', {
                is: true,
                then: Joi.string().label('Cognome proprietario').min(3).max(50),
                otherwise: Joi.string().empty(''),
            }),
            floor: Joi.string().label('Piano').when('show_floor', {
                is: true,
                then: Joi.string().label('Piano').min(1).max(10),
                otherwise: Joi.string().empty(''),
            })
        }).messages(joi_messages.it);

        const { error } = schema.validate({
            show_floor: !!stepInfo.buildingType.show_floor,
            ask_owner_detail: !!stepInfo.buildingType.ask_owner_detail,
            reference: stepInfo.reference,
            owner_first_name: stepInfo.owner_first_name ?? '',
            owner_last_name: stepInfo.owner_last_name ?? '',
            floor: (stepInfo.floor || '')
        });
        if (error) {
            return setError(error.details[0].message);
        }
        axios.get('helper/buildings-check', {
            params: {
                organization_id: organizationSelected.id,
                reference: stepInfo.reference,
                owner_first_name: !!stepInfo.buildingType.ask_owner_detail ? stepInfo.owner_first_name : 'B&B',
                owner_last_name: !!stepInfo.buildingType.ask_owner_detail ? stepInfo.owner_last_name : 'B&B',
                is_edit: isEdit ? 1 : 0
            }
        })
        .then(res => {
            if (res.data.exists) {
                return setError(res.data.message);
            }
            nextStep();
        });
    }

    return {
        handleChange,
        handleChangeBuildingType,
        handleContinue,
        error,
        organizationSelected
    }
}

export function useStepInfoResident(props) {
    const {
        stepInfo,
        stepInfoResident, setStepInfoResident,
        onFormComplete,
        organizationSelected,
    } = props;

    const [error, setError ] = useState(false);

    const handleChange = (e) => {
        const { name, value} = e.target;
        const updateFields = { [name]: value};
        if (stepInfo && name === 'resident_type' && !stepInfoResident.doorbell_name && value === 'OWNER') {
            updateFields['doorbell_name'] = stepInfo.owner_first_name + ' ' + stepInfo.owner_last_name;
        }
        setStepInfoResident({
            ...stepInfoResident,
            ...updateFields
        });
    }
    

    const handleSubmit = () => {
        const schema = Joi.object({
            ask_owner_detail: Joi.boolean().required(),
            resident_type: Joi.string().label('Riferimento immobile').when('ask_owner_detail', {
                is: true,
                then: Joi.string().label('Riferimento immobile').min(1).max(30),
                otherwise: Joi.string().empty(''),
            }),
            doorbell_name: Joi.string().label('Nominativo sul campanello').when('resident_type', {
                is: 'NOBODY',
                then: Joi.string().empty(''),
                otherwise: Joi.string().min(1).max(80)
            }),
            resident_phone: Joi.string().label('Telefono del residente').when('ask_owner_detail', {
                is: true,
                then: Joi.string().label('Telefono del residente').min(3).max(15),
                otherwise: Joi.string().empty(''),
            }),
            resident_email: Joi.string().label('Email del residente').when('ask_owner_detail', {
                is: true,
                then: Joi.string().label('Email del residente').max(320).email({tlds:{allow: false}}),
                otherwise: Joi.string().empty(''),
            }),
        }).messages(joi_messages.it);
        const { error } = schema.validate({
            ask_owner_detail: !!stepInfo?.buildingType.ask_owner_detail ?? true,
            ...stepInfoResident
        });
        if (error) {
            return setError(error.details[0].message);
        }
        onFormComplete();
    }

    return {
        error, setError,
        handleChange,
        handleSubmit,
        organizationSelected
    };
}

export function useOffcanvasAddBuilding(props) {

    const { 
        show,
        handleClose,
        isReservation,
        organization,
        onBuildingAdd,
    } = props;

    const { user } = useAuthValue();
    const navigate = useNavigate();
    const [step, setStep ] = useState('address');
    const [createdBuilding, setCreatedBuilding ] = useState();
    const [isInProgress, setIsInProgress] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [stepAddressData, setStepAddressData] = useState();
    const [buildingTypes, setBuildingTypes] = useState();
    const [stepInfo, setStepInfo] = useState();
    const [stepInfoResident, setStepInfoResident] = useState();

    const organizationSelected = useMemo(() => organization ?? user?.agent?.getSelectedOrganization(), [organization]);
    
    useEffect(() => {
        setIsInProgress(false);
        if (show) {
            if (!buildingTypes) {
                axios.get('buildings-types')
                .then(res => {
                    setBuildingTypes(res.data);
                });
            }
            return;
        };
        setTimeout(() => {
            setStep('address');
            setStepAddressData();
            setStepInfo({
                reference: '',
                owner_first_name: '',
                owner_last_name: '',
                buildingType: null,
                floor: '',
                sqm: 0,
                garden_sqm: 0
            });
            setStepInfoResident({
                resident_type: '',
                doorbell_name: '',
                resident_phone: '',
                resident_email: ''
            });
        }, 500);
    }, [show, buildingTypes]);

    const stepBefore = useCallback(() => {
        if (step === 'map') return setStep('address');
        if (step === 'info') return setStep('map');
        if (step === 'info-resident') return setStep('info');
    }, [step]);

    const handleNavigateReservation = useCallback(() => {
        handleClose();
        navigate(`/reservation/new/${createdBuilding.id}`);
    }, [createdBuilding, setCreatedBuilding]);

    const insertBuilding = () => {
        setIsInProgress(true);
        const position = mapBoxFeatureFormat(stepAddressData);
        const stepInfoData = Object.fromEntries(
            Object.entries(stepInfo).map(([key, value]) => {
                if (key === 'buildingType') {
                    return ['building_type_id', value.id];
                }
                return [key, value];
            })
        );
        axios.post('buildings', {
            organization_id: organizationSelected.id,
            ...position,
            ...stepInfoData,
            ...stepInfoResident
        })
        .then(res => {
            if (isReservation) {
                handleClose();
                return navigate(`/reservation/new/${res.data.id}`)
            };
            setCreatedBuilding(res.data);
            setStep('done');
            onBuildingAdd && onBuildingAdd(res.data);
        });
    }
    
    return {
        isInProgress,
        step, setStep,
        stepBefore,
        stepAddressData, setStepAddressData,
        stepInfo, setStepInfo,
        stepInfoResident, setStepInfoResident,
        buildingTypes,
        insertBuilding,
        handleNavigateReservation,
        addressError, setAddressError,
        organizationSelected
    }
}