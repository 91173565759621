//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';
import { useAuthValue } from 'context/AuthContext';
import moment from 'moment';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { IoBusiness, IoHourglass, IoTime } from 'react-icons/io5';
import { toast } from 'react-toastify';


function useNotifications() {
    const { user, setUser } = useAuthValue();
    const [notifications, setNotifications] = useState();

    useEffect(() => {
        axios.get('notifications')
        .then(res => {
            setNotifications(res.data)
        });
    }, []);

    // useEffect(() => {
    //     const unread = notifications.filter(n => !n.read_at).map(n => n.id);
    //     if (!unread.length) return;
    //     axios.post('notifications/read', {
    //         ids: unread
    //     })
    //         .then(() => {
    //             setUser({
    //                 ...user,
    //                 notifications: notifications.map(n => {
    //                     n.read_at = 'OK';
    //                     return n;
    //                 })
    //             })
    //         });
    // }, []);

    const handleConfirmInvite = (id) => {
        axios.put(`organization-pending-agents/${id}/action/accept`)
            .then(() => {
                toast.success("Invito accettato con successo");
                const updatedPendings = user.agent.pendings.filter(pending => pending.id !== id);
                setUser({
                    ...user,
                    agent: {
                        ...user.agent,
                        pendings: updatedPendings,
                    }
                });
            })
            .catch((error) => {
                toast.error("Errore durante l'accettazione dell'invito.");
            });
    };

    const handleRevokeInvite = (id) => {
        axios.put(`organization-pending-agents/${id}/action/decline`)
            .then(() => {
                toast.success("Invito rifiutato con successo");
                const updatedPendings = user.agent.pendings.filter(pending => pending.id !== id);
                setUser({
                    ...user,
                    agent: {
                        ...user.agent,
                        pendings: updatedPendings,
                    }
                });
            })
            .catch((error) => {
                toast.error("Errore durante l'aggiornamento dell'invito.");
            });
    };

    return {
        user,
        notifications,
        handleConfirmInvite,
        handleRevokeInvite
    }
}

function NotificationItem({
    notification
}) {

    const navigate = useNavigate();

    return (<>
        <div className={`notification-dialog ios-style ${notification.data.url ? 'cursor-pointer' : ''}`} onClick={() => notification.data.url ? navigate(notification.data.url) : null}>
            <div className="notification-header">
                <div className="right w-100">
                    <span className="w-100 text-14 text-white">
                        {notification.data.message}
                    </span>
                    {!notification.read_at && <span className="notification-dot"></span>}
                </div>
            </div>
            <div className="notification-content">
                <div className="in">
                    {notification.data.description && <div className="text text-14">
                        {notification.data.description}
                    </div>}
                    <div className="mt-1 text-12 text-muted">
                        <IoTime className="vertical-align-n2" /> {moment(notification.created_at).format('DD/MM/YYYY HH:mm')}
                    </div>
                </div>
            </div>
        </div>
    </>);
}

function PendingInvitationsItem({ pending, handleConfirmInvite, handleRevokeInvite }) {
    return (
        <li className='px-0'>
            <span className='text-14'>
                <span className='text-20 me-1'><IoBusiness /></span>
                {pending.organization.display_name}
            </span>
            <span>
                <button className='btn btn-primary btn-sm me-1' onClick={() => handleConfirmInvite(pending.id)}>
                    Accetta
                </button>
                <button className='btn btn-github btn-sm' onClick={() => handleRevokeInvite(pending.id)}>
                    Rifiuta
                </button>
            </span>
        </li>
    )
}

function PendingInvitations({ user }) {

    const { handleConfirmInvite, handleRevokeInvite } = useNotifications();

    return (<>
        <div className="notification-dialog ios-style">
            <div className="notification-header">
                <div className="right w-100 p-0">
                    <span className="w-100 text-14 text-white">
                        <IoHourglass className='me-05' /> Inviti in attesa
                    </span>
                </div>
            </div>
            <div className="notification-content d-block pb-1">
                <div className="text text-14">
                    Sei stato invitato a far parte delle seguenti attività. Puoi decidere di <b>accettare o rifiutare l'invito</b>:
                </div>

                <ul className="listview simple-listview mt-1 bg-transparent border-0">
                    {user.agent.pendings.map(pending => (
                        <PendingInvitationsItem
                            key={pending.id}
                            pending={pending}
                            handleConfirmInvite={handleConfirmInvite}
                            handleRevokeInvite={handleRevokeInvite}
                        />
                    ))}
                </ul>

            </div>
        </div>
        <hr className='line-divider' />
    </>);
}

function Notifications() {

    const { user, notifications } = useNotifications();

    return (
        <>
            <Header
                pageTitle={'Notifiche'}
                handleBack="home"
                show_agency={false}
            />
            <Capsule>
                {(user.agent && user.agent.pendings.length != 0) &&
                    <PendingInvitations
                        user={user}
                    />
                }
                {notifications ? <>
                    {notifications.map(notification => (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                        />
                    ))}
                    {!notifications.length && <div className='p-4 text-center'>Nessuna notifica recente</div>}
                </> : <div className='p-4 text-center'>Caricamento...</div>}
            </Capsule>
        </>
    );
}

export default Notifications;