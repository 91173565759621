import { IoBusiness } from 'react-icons/io5';
import PlaceHolderItemList from './loader/PlaceHolderItemList';
import moment from 'moment';

export default function OrganizationItem({ 
    children,
    organization,
    isList = false,
    onClick = () => {},
    isSkeleton
}) {

    const ListComponent = isList ? 'a' : 'div';
    const isNew = organization?.created_at && moment(organization?.created_at).isAfter(moment().subtract(7, 'days'));

    return (
        <li onClick={() => onClick(organization)}>
            <ListComponent className="item">
                <div className={`icon-box me-05`}>
                    {isNew ? <span className="badge badge-new badge-primary fw-bold">NEW</span> : <IoBusiness />}
                </div>
                {isSkeleton ? <PlaceHolderItemList hideSecondLine /> : <>
                    <div className="in">
                        <div>
                            <span>
                                {organization.display_name}
                            </span>
                        </div>
                        <div>
                            {children}
                        </div>
                    </div>
                </>}
            </ListComponent>
        </li>
    );
}