import Login from './Login';
import Register from './Register';
import ConfirmAccount from './ConfirmAccount';

function Auth() {}

Auth.Login = Login;
Auth.Register = Register;
Auth.ConfirmAccount = ConfirmAccount;

export default Auth;