import Capsule from "layout/Capsule";
import Header from "layout/header/Header";

import DayNavigator from "components/DayNavigator";

import useDaySelector from "hooks/useDaySelector";
import { useEffect, useState } from "react";
import axios from "axios";

import defaultAvatar from 'assets/img/avatar_default.jpg';
import PlaceHolderItemList from "components/loader/PlaceHolderItemList";

function useStatsEditor() {

    const [stats, setStats] = useState();

    const {
        next, 
        previous, 
        day,
        formatDate
    } = useDaySelector();
    
    useEffect(() => {
        setStats();
        axios.get('stats/editor', {
            params: {
                day: formatDate.start
            }
        })
        .then(res => setStats(res.data));
    }, [day]);

    return {
        next, 
        previous, 
        day,
        stats
    }
}

function EditorLine({
    stat,
    isSkeleton
}) {

    const percentage = isSkeleton ? 0 : stat.completed * 100 / (stat.content_x_day ?? 1); 

    return (
        <li>
            <div className="item align-items-start pe-4">
                <div className="avatar me-2">
                    <img src={stat?.avatar_url || defaultAvatar} alt="avatar" className="imaged rounded w36" />
                </div>
                {isSkeleton ? <PlaceHolderItemList hideSecondLine /> :
                    <div className="in">
                        <div>
                            {stat.display_name}
                            <br />
                            <span className="text-12 text-gray">
                                {stat.editor_roles.join(', ')}
                            </span>
                        </div>
                        <div className={`progress position-relative w-50 ${isSkeleton ? 'w-100 placeholder' : ''}`}>
                            <div 
                                className={`progress-bar bg-${percentage >= 100 ? 'success' : (percentage < 50 ? 'danger' : 'warning')}`}
                                role="progressbar" 
                                style={{width: isSkeleton ? 0 : (percentage > 100 ? 100 : percentage) + '%'}} 
                                aria-valuenow="taskEdited" 
                                aria-valuemin="0" 
                                aria-valuemax="100"
                            ><span className="progress-text">{stat.completed}/{stat.content_x_day}</span></div>
                        </div>
                    </div>
                }
            </div>
        </li>
    )
}

export default function StatsEditor() {

    const {
        next, 
        previous, 
        day,
        stats
    } = useStatsEditor();
    
    return (
        <>
            <Header
                show_agency={false}
                pageTitle="Statistiche editor" 
            />
            <Capsule>
                <DayNavigator
                    next={next}
                    previous={previous}
                    day={day}
                />
                <div className='section'>
                    <div className="card">
                        <div className="card-body">
                            <h3 className="mb-0">Servizi editati</h3>
                        </div>
                        <ul className="listview image-listview rounded placeholder-glow">
                            {!stats && Array(5).fill(null).map((_, index) => <EditorLine key={index} isSkeleton />)}
                            {stats && stats.map(stat => <EditorLine key={stat.editor_id} stat={stat}/>)}
                        </ul>
                    </div>
                </div>
            </Capsule>
        </>
    );
}