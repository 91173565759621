//Components
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasBackBtn from 'components/offcanvas/OffCanvasBackBtn';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    IoArchiveOutline,
    IoCallOutline,
    IoCheckmark,
    IoCloseOutline,
    IoCreateOutline,
    IoExpandOutline,
    IoLeafOutline,
    IoMailOutline,
    IoTrashOutline
} from 'react-icons/io5';

import {
    StepInfo as StepEditInfo,
    StepInfoResident as StepEditInfoResident
} from 'components/offcanvas/add-building/OffcanvasAddBuilding';

//Logic
import useOffcanvasViewBuilding from './useOffCanvasViewBuilding';
import { useNavigate } from 'react-router';
import { useAuthValue } from 'context/AuthContext';
import { isMobile } from 'utils/helpers';

function appointmentStatus(buildingData) {

    const defaultRedirect = `/reservation/new/${buildingData?.id}`;
    const appointment = buildingData?.appointments[0];

    switch (appointment?.status) {
        case 'CONFIRMED':
            return {
                color: 'warning',
                label: 'Servizio prenotato',
                redirect: `/calendar?action=show&id=${appointment.id}&date=${moment(appointment.estimated_start_at).format('YYYY-MM-DD')}`
            }
        case 'ENDED':
            return { color: 'success', label: 'Prenota ancora', redirect: defaultRedirect }
        default:
            return { color: 'primary', label: 'Prenota servizio', redirect: defaultRedirect };
    }
}

function StepViewBuilding(props) {

    const {
        buildingData,
        navigate,
        residentType,
        handleClose,
        handleEditInfo,
        handleEditInfoResident,
        headerImage,
        isConfirmDelete, handleDelete
    } = props;

    const status = appointmentStatus(buildingData);
    const { user } = useAuthValue();

    const DeleteBtn = isConfirmDelete ? IoCheckmark : IoArchiveOutline;


    return <>
        <div
            style={{
                ...headerImage && { backgroundImage: `url(${headerImage})` }
            }}
            className="property-card-header"
        >
            {buildingData && <>
                {<DeleteBtn className="delete-btn" onClick={handleDelete} />}
                <a className="offcanvas-close" onClick={handleClose} >
                    <IoCloseOutline />
                </a>
                {buildingData.matterport_link && <a
                    className='add-prenotation-btn matterport bg-primary'
                    target='_blank'
                    href={buildingData.matterport_link}
                >
                   Apri Matterport 
                </a>}
                <span
                    className={`add-prenotation-btn bg-${status.color}`}
                    onClick={() => {
                        handleClose();
                        navigate(status.redirect);
                    }}
                >{status.label}</span>
            </>}
        </div>

        <div className="action-sheet-content py-2 px-0 noarrow">

            <div className="listview-title fw-700 pb-0">
                Dati immobile:
                <a className="active fw-500" onClick={handleEditInfo}>
                    <IoCreateOutline className="edit-btn-icon" /> Modifica
                </a>
            </div>

            <ul className="listview image-listview no-border">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Riferimento immobile</header>
                                {buildingData ? buildingData.reference :
                                    <span className="placeholder" style={{ width: '100px' }} />
                                }
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Proprietario</header>
                                {buildingData ? `${buildingData.owner_last_name} ${buildingData.owner_first_name}` :
                                    <span className="placeholder" style={{ width: '200px' }} />
                                }
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Indirizzo</header>
                                {buildingData ? buildingData.address.full :
                                    <span className="placeholder" style={{ width: '240px' }} />
                                }
                            </div>
                        </div>
                    </div>
                </li>
                {buildingData ? <>
                    <li>
                        <div className="item">
                            <div className="in">
                                <div className="flex-fill">
                                    <div className='building-grid d-flex justify-content-between'>
                                        <span><b>Tipologia</b>{buildingData.building_type.description}</span>
                                        {buildingData.floor && (
                                            <span><b>Piano</b>
                                                {buildingData.floor}
                                            </span>
                                        )}
                                        <span><b>Metratura</b><IoExpandOutline className='vertical-align-n2' /> {buildingData.sqm}m²</span>
                                        <span><b>Giardino</b><IoLeafOutline className='vertical-align-n2' /> {buildingData.garden_sqm || 0}m²</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </> : ''}
            </ul>

            <hr />

            <div className="listview-title fw-700 py-0">
                Riferimenti:<a className="active fw-500" onClick={handleEditInfoResident}>
                    <IoCreateOutline className="edit-btn-icon" /> Modifica</a>
            </div>

            <ul className="listview image-listview no-border">
                {buildingData?.agent && <>
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header>Agente</header>
                                    {buildingData.agent.user.display_name}
                                </div>
                            </div>
                        </div>
                    </li>
                </>}
                {buildingData && residentType[buildingData.resident_type] && (
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header>Abitato da</header>
                                    {buildingData ? residentType[buildingData.resident_type] :
                                        <span className="placeholder" style={{ width: '150px' }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                )}
                {buildingData && buildingData.doorbell_name && (
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header>Nome e cognome sul campanello</header>
                                    {buildingData ? buildingData.doorbell_name :
                                        <span className="placeholder" style={{ width: '200px' }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                )}
                {buildingData && (buildingData.resident_phone || buildingData.resident_email) && buildingData?.resident_type !== 'NOBODY' ?
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header>Recapiti</header>
                                    {buildingData ? <>
                                        {buildingData.resident_phone && <><IoCallOutline className='vertical-align-n2' /> {buildingData.resident_phone}</>}
                                        {buildingData.resident_email && <><IoMailOutline className='vertical-align-n2 ms-3' /> {buildingData.resident_email}</>}
                                    </> :
                                        <span className="placeholder" style={{ width: '250px' }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </li> : ''}
            </ul>

            {buildingData?.appointments && buildingData?.appointments.length > 0 && (
                <>
                    <hr className='mt-0' />

                    <div className="listview-title fw-700 py-0">
                        Servizi svolti:
                    </div>

                    <ul className="listview image-listview white-arrow no-border px-2">
                        {buildingData?.appointments.map(appointment => (
                            <li key={appointment.id}>
                                <Link to={`${user.is_admin ? '/admin' : ''}/orders?action=show&id=${appointment.id}`} className="item">
                                    <div className="in">
                                        <div>
                                            <span>{moment(appointment.estimated_start_at).format('D MMMM YYYY')}</span>
                                        </div>
                                        <div className="active pe-1">{appointment.order.final_price} € + IVA</div>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </>
            )}


        </div>
    </>;
}

function OffcanvasViewBuilding(props) {

    const {
        buildingId,
        handleClose
    } = props;

    const {
        buildingData,
        residentType,
        step,
        stepBefore,
        isInProgress,
        buildingTypes,
        editStepInfo, setEditStepInfo,
        editStepInfoResident, setEditStepInfoResident,
        handleEditInfo, handleSaveInfo,
        handleEditInfoResident, handleSaveInfoResident,
        headerImage,
        isConfirmDelete,
        handleDelete
    } = useOffcanvasViewBuilding(props);

    const navigate = useNavigate();
    const { user } = useAuthValue();
    const organizationSelected = user?.agent?.getSelectedOrganization();

    return (<>
        <Offcanvas
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={!!buildingId}
            onHide={handleClose}
        >
            <Offcanvas.Body>
                {step === 'view' ? <StepViewBuilding
                    {...{
                        navigate,
                        buildingData,
                        residentType,
                        handleClose,
                        handleEditInfo,
                        handleEditInfoResident,
                        headerImage,
                        isConfirmDelete,
                        handleDelete
                    }}
                /> : <>
                    <OffCanvasBackBtn handleBack={stepBefore} />
                    <div className="section mt-3 text-center">
                        <h3>Modifica immobile</h3>
                    </div>
                    <div className="action-sheet-content py-1">
                        {step === 'edit_info' && <StepEditInfo
                            stepInfo={editStepInfo}
                            setStepInfo={setEditStepInfo}
                            buildingTypes={buildingTypes}
                            isInProgress={isInProgress}
                            nextStep={handleSaveInfo}
                            isEdit
                            organizationSelected={organizationSelected}
                        />}
                        {step === 'edit_info_resident' && <StepEditInfoResident
                            stepInfoResident={editStepInfoResident}
                            setStepInfoResident={setEditStepInfoResident}
                            isInProgress={isInProgress}
                            onFormComplete={handleSaveInfoResident}
                            isEdit
                            organizationSelected={organizationSelected}
                        />}
                    </div>
                </>}
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasViewBuilding;