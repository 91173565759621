import { IoAlertCircleOutline, IoHourglassOutline, IoThumbsDownOutline, IoThumbsUpOutline } from "react-icons/io5"

export const PAYMENT_METHODS = {
    'COD': 'Contrassegno',
    'CREDIT': 'Credito APP',
    'BACS': 'Bonfico',
    'CARD': 'Carta di credito',
    'NONE': 'Nessuno'
}

export const UPLOAD_DEFAULT = {
    isDeletable: true,
    uuid: '',
    name: '',
    path: '',
    process: 0,
    completed: false,
    error: null,
    downloadURL: null
}

export const RENDER_INSTRUCTIONS_FIELDS = {
    'frame_type': {
        'SHUTTER': 'Tapparelle',
        'BLIND': 'Scuri'
    }, 
    'shutter_type': {
        'MANUAL': 'Manuali',
        'AUTOMATIC': 'Elettriche'
    },
    'shutter_box': {
        'VISIBLE': 'Cassone tapparella a vista',
        'NOT_VISIBLE': 'Cassone incassato nel muro'
    },
    'heating': {
        'RADIATOR': 'Termosifoni',
        'FLOOR': 'Riscaldamento a pavimento'
    },
    'ceiling': {
        'NO_BEAMS': 'Tetto piano',
        'BEAMS_ROOF_EVEN': 'Tetto piano con travi',
        'NO_BEAMS_SLOPING': 'Tetto piano',
        'BEAMS_ROOF_SLOPING': 'Tetto inclinato con travi'
    }
}

export const STATUSES = {
    NONE: {
        is_global: true,
        global: {
            textColor: 'gray',
            badgeColor: '',
            badgeLabel: '',
            Icon: IoHourglassOutline
        }
    },
    APPROVED: {
        is_global: true,
        global: {
            textColor: 'success',
            badgeColor: 'success',
            badgeLabel: 'APPROVATA',
            Icon: IoThumbsUpOutline
        }
    },
    REJECTED: {
        is_global: true,
        global: {
            textColor: 'danger',
            badgeColor: 'danger',
            badgeLabel: 'RIFIUTATA',
            Icon: IoThumbsDownOutline
        }
    },
    WAITING_EDITOR: {
        agent: {
            textColor: 'gray',
            badgeColor: 'amazon',
            badgeLabel: 'IN LAVORAZIONE',
            Icon: IoHourglassOutline
        },
        editor: {
            textColor: 'warning',
            badgeColor: 'warning',
            badgeLabel: 'DA FARE',
            Icon: IoAlertCircleOutline
        }
    },
    WAITING_CUSTOMER: {
        agent: {
            textColor: 'warning',
            badgeColor: 'warning',
            badgeLabel: 'DA REVISIONARE',
            Icon: IoAlertCircleOutline
        },
        editor: {
            textColor: 'gray',
            badgeColor: 'amazon',
            badgeLabel: 'IN ATTESA',
            Icon: IoHourglassOutline
        }
    },
}