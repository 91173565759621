import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import { IoExpand, IoSaveOutline } from 'react-icons/io5';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from 'react-bootstrap';
import { reorderArrayItem } from 'utils/helpers';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';

import { db } from 'utils/firebase-instance.js';
import { collection, query, doc, updateDoc, getDocs, getDoc, orderBy, addDoc, deleteDoc } from "firebase/firestore"; 
import AutocompleteAddress from 'components/autocomplete/AutocompleteAddress';

//Logic
function useOffCanvasSettings({
    show,
    photographers
}) {

    const [reorderPhotographers, setReorderPhotographers] = useState([]);
    const [photographersDocs, setPhotographersDocs] = useState(); 
    

    const handleReorderPh = (result) => {
        if (!result.destination) {
            return;
        }
        const newPhotographers = reorderArrayItem(reorderPhotographers, result.source.index, result.destination.index)

        setReorderPhotographers(newPhotographers);
    };

    const handleConfirm = () => {
        axios.put('photographers-priority', {
            photographers: reorderPhotographers.map(ph => ph.id)
        })
            .then(res => {
                toast.success('Ordine fotografi aggiornato con successo. La seguente pagina verà ricaricata', {
                    onClose: () => window.location.reload()
                });
            });
    }

    useEffect(() => {
        if (!show) return;
        new Promise(resolve => {
            if (photographersDocs) return resolve(photographersDocs);
            const photographersRef = collection(db, 'photographers');
            const q = query(photographersRef);
            getDocs(q)
            .then(photographers => {
                const photographersDocsArr = [];
                photographers.forEach(phDoc => photographersDocsArr.push(phDoc));
                setPhotographersDocs(photographersDocsArr);
                resolve(photographersDocsArr);
            });
        })
        .then(phDocs => {
            setReorderPhotographers(photographers?.map(ph => {
                const docData = phDocs.find(phDoc => phDoc.id === ph.uid).data();
                ph.coords = [docData.start_position.longitude, docData.start_position.latitude];
                ph.address = docData.start_position.address;
                return ph;
            }).sort((a, b) => a.show_priority - b.show_priority));
        })
    }, [show, photographersDocs]);

    return {
        handleReorderPh,
        reorderPhotographers,
        handleConfirm,
        photographersDocs
    }

}


function PhotographerItem({ 
    photographer,
}) {

    const [address, setAddress] = useState(photographer.address?.full ?? '');

    const handleRetriveAddress = () => {};
    const handleResetAddress = () => {};

    return (
        <div className="item ps-2">
            <div className="in d-flex flex-column">
                <div className="col-12">
                    <span>
                        {photographer.title}
                    </span>
                </div>
            </div>
            <IoExpand className='ms-1 cursor-drag' style={{ fontSize: '30px' }} />
        </div>

    );
}

export default function OffCanvasSettings(props) {

    const {
        show,
        handleClose,
    } = props;

    const {
        handleReorderPh,
        handleConfirm,
        reorderPhotographers,
        photographersDocs
    } = useOffCanvasSettings(props);

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        background: isDragging ? "#000" : "#3a3a3a",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (<>
        <Offcanvas
            placement="bottom"
            id="view-property"
            className="action-sheet"
            show={show}
            onHide={handleClose}
        >
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Modifica ordine visualizzazioni fotografi</h3>
                </div>

                {!reorderPhotographers && <OffCanvasLoader />}

                <div className='section py-2'>
                    <DragDropContext onDragEnd={handleReorderPh}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="listview image-listview pt-1"
                                >
                                    {!reorderPhotographers ? (
                                        <OffCanvasLoader />
                                    ) : (
                                        reorderPhotographers.map((photographer, index) => (
                                            <Draggable
                                                key={photographer.id}
                                                draggableId={'p' + photographer.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <li
                                                        key={photographer.id}
                                                        className="rounded mt-1"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <PhotographerItem 
                                                            photographer={photographer} 
                                                        />
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))
                                    )}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className="form-group basic">
                        <Button
                            className="btn-block mt-1"
                            type="button"
                            onClick={handleConfirm}
                        >Conferma</Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}   