import { useEffect, useMemo, useState } from "react";
import { db } from 'utils/firebase-instance.js';
import { doc } from "firebase/firestore"; 
import { reorderArrayItem } from 'utils/helpers'; 

export default function useOffCanvasEditZone(props) {
    const { 
        editZone,
        handleEditZone,
        saveZoneData,
        deleteZone
    } = props;

    const [isConfirmDelete, setIsConfirmDelete] = useState(false);

    const { zoneData } = editZone || {};

    useEffect(() => {
        setIsConfirmDelete(false);
    }, [editZone])
  
    const zonePhotographersIds = useMemo(() => {
        return zoneData?.photographers.map(photographRef => {
            return photographRef.id;
        }) || [];
    }, [zoneData]);

    const handleChangeData = (name, value, save = false) => {
        handleEditZone({
            ...editZone,
            zoneData: {
                ...editZone.zoneData,
                [name]: value
            },
            save
        });
    }

    const handleChangeInput = (e) => {
        const { name, value, type } = e.target;
        handleChangeData(name, type === 'number' && value !== '' ? +value : value);
    }

    const handleSave = (e) => {
        e.preventDefault();
        saveZoneData();
    }

    const handleChangePhotographer = (uuid, selected) => {
        let zonePhotographers = zoneData.photographers;
        if (selected) {
            const phRef = doc(db, 'photographers', uuid);
            zonePhotographers.push(phRef);
        } else {
            zonePhotographers = zonePhotographers.filter(phRef => {
                return phRef.id !== uuid;
            });
        }
        handleChangeData('photographers', zonePhotographers, true);
    }

    const handleDelete = (confirm = false) => {
        if (!confirm) return setIsConfirmDelete(true);
        deleteZone();
    }

    const handleReorderPh = (result) => {
        if (!result.destination) {
            return;
        }
        let zonePhotographers = zoneData.photographers;
        const newZonePhotographers = reorderArrayItem(zonePhotographers, result.source.index, result.destination.index);
        handleChangeData('photographers', newZonePhotographers, true);
    }

    return {
        zonePhotographersIds,
        handleChangeData,
        handleChangeInput,
        handleSave,
        handleChangePhotographer,
        isConfirmDelete,
        handleDelete,
        handleReorderPh
    }
}