import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import PhotographersSelect from 'components/form/PhotographersSelect';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { dispatchAppEvent } from 'utils/helpers';
import moment from 'moment';
import { toast } from 'react-toastify';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';
import SpinnerForButton from 'components/loader/SpinnerForButton';

const DEFAULT_FORM_DATA = {
    photographer1: '',
    photographer2: '',
    switch_type: '',
};

function usePhotographersSelect(props) {

    const {
        show,
        handleClose,
        switchDate
    } = props;

    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [isInProgress, setIsInProgess] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleConfirm = () => {
        setIsInProgess(true);
        axios.post('appointments-switch', {
            ...formData,
            date: moment(switchDate).format('YYYY-MM-DD')
        })
        .then(res => {
            dispatchAppEvent('appointments_updated', res.data);
            handleClose();
            toast.success('Scambio appuntamenti effettuato con successo');
            setIsInProgess(false);
        });
    }

    useEffect(() => {
        setFormData(DEFAULT_FORM_DATA);
    }, [show]);

    return {
        formData,
        handleChange,
        handleConfirm,
        isInProgress
    }
}

export default function OffcanvasSwitchSlots(props) {

    const {
        show,
        photographers,
        handleClose,
    } = props;

    const {
        formData,
        isInProgress,
        handleChange,
        handleConfirm
    } = usePhotographersSelect(props);
    

    return (<>
        <Offcanvas placement="bottom" id="view-property" className="action-sheet" show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Scambia appuntamenti fotografi</h3>
                </div>
                {!photographers && <OffCanvasLoader/>}
                <div className="action-sheet-content py-2">
                    <PhotographersSelect
                        label="Fotografo 1"
                        name="photographer1"
                        value={formData.photographer1}
                        photographers={photographers}
                        handleChange={handleChange}
                        disablePh={formData.photographer2}
                    />
                    <PhotographersSelect
                        label="Fotografo 2"
                        name="photographer2"
                        value={formData.photographer2}
                        photographers={photographers}
                        handleChange={handleChange}
                        disablePh={formData.photographer1}
                    />

                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="newpassword2">Slot da invertire</label>
                            <select
                                className="form-control form-select"
                                name="switch_type"
                                value={formData.switch_type}
                                onChange={handleChange}
                            >
                                <option disabled value="">Seleziona slot</option>
                                <option value="MORNING">Mattino ({'<'} 14:00)</option>
                                <option value="AFTEROON">Pomeriggio ({'>='} 14:00)</option>
                                <option value="FULL">Intera giornata</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group basic">
                        <Button
                            className={`btn-block ${formData.photographer1 && formData.photographer2 && formData.switch_type ? '' : 'disabled'} ${isInProgress ? 'disabled' : ''}`}
                            type="button"
                            onClick={handleConfirm}
                        ><SpinnerForButton show={isInProgress} />
                            Conferma</Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}