import { IoCloseOutline } from 'react-icons/io5';

function OffCanvasCloseBtn({ handleClose }) {
    return (
        <a className="offcanvas-close close-btn-modal text-25" onClick={handleClose} >
            <IoCloseOutline className='md' />
        </a>
    );
}

export default OffCanvasCloseBtn;