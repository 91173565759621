import moment from 'moment';
import { useMemo, useState } from 'react';

export default function useDaySelector() {
    const [day, setDay] = useState(moment());

    const next = () => {
        changeDay(+1);
    }

    const previous = () => {
        changeDay(-1);
    }

    const changeDay = (operation) => {
        const newDay = day.clone().add(operation, 'days')
        setDay(newDay);
    }

    const formatDate = useMemo(() => {
        return {
            start: day.format('YYYY-MM-DD'),
            end: day.clone().add(1, 'days').format('YYYY-MM-DD')
        }
    }, [day]);

    return { next, previous, day, formatDate };

}