import '@splidejs/react-splide/css';

//Components
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import useOnboarding from 'hooks/useOnboarding';

import fotografiaImage from './../img/fotografia.webp';
import personalBrandImage from './../img/personal-brand.webp';
import renderingImage from './../img/rendering.webp';
import planimetriaImage from './../img/planimetria.webp';
import ripreseAereeImage from './../img/riprese-aeree.webp';
import videoImage from './../img/video.webp';
import virtualTourImage from './../img/virtual-tour.webp';
import logo_white from 'assets/img/logo-white.png';


function WelcomeUser() {

    useOnboarding();

    const onboardingSlides = [
        {
            image: fotografiaImage,
            text: 'Fotografia',
            description: 'Valorizza al meglio i tuoi immobili in vendita o B&B'
        },
        {
            image: videoImage,
            text: 'Video',
            description: 'Scegli il video perfetto per ogni tipologia di immobile'
        },
        {
            image: renderingImage,
            text: 'Rendering',
            description: 'Realizzare ciò che ancora non esiste per attirare potenziali acquirenti, anticipando la vendita'
        },
        {
            image: virtualTourImage,
            text: 'Virtual Tour',
            description: 'Un\'esperienza immersiva all\'interno degli ambienti che consente di velocizzare il processo di vendita'
        },
        {
            image: planimetriaImage,
            text: 'Planimetrie',
            description: 'La Planimetria 3D consente ai potenziali clienti di capire subito la disposizione degli spazi dell\'immobile'
        },
        {
            image: ripreseAereeImage,
            text: 'Riprese Aeree',
            description: 'Stupire i clienti con prospettive straordinarie e riprese mozzafiato'
        },
        {
            image: personalBrandImage,
            text: 'Personal brand',
            description: 'Eleva la tua immagine con foto e video che ti posizionano come punto di riferimento del tuo settore'
        },
    ];

    return <>
        <div id='onboarding'>
            <img className="logo" src={logo_white} style={{ position: 'fixed', zIndex: 9999, top: 'calc(env(safe-area-inset-top) + 10px)', maxHeight: '37px', left: '50%', transform: 'translateX(-50%)' }} />
            <Splide
                aria-label="slide"
                options={{
                    type: 'loop',
                    rewind: true,
                    pagination: false
                }}
            >
                {onboardingSlides.map((slide, index) => (
                    <SplideSlide key={index}>
                        <div
                            className="onboarding-container"
                            style={{
                                backgroundImage: `url(${slide.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className="overlay p-2">
                                <h1 className="text-white">{slide.text}</h1>
                                <p className="text-16 mb-1">{slide.description} </p>
                            </div>
                        </div>
                    </SplideSlide>
                ))}
            </Splide>

            <div className="carousel-button-footer">
                <div className="row">
                    <div className="col-6">
                        <Link to="/register">
                            <Button
                                className={`btn-block`}
                                size="lg"
                                variant="primary"
                            >
                                Registrati
                            </Button>
                        </Link>
                    </div>
                    <div className="col-6">
                        <Link to="/login">
                            <Button
                                className={`btn-block`}
                                size="lg"
                                variant="amazon"
                            >
                                Accedi
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default WelcomeUser;