import { useState, useEffect } from "react";
import axios from "axios";
import useDaySelector from "hooks/useDaySelector";
import { deepCopy } from "utils/helpers";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";

export default function useAgenda() {

    const [hideEnded, setHideEnded] = useState(true);
    const [showOffCanvasPhotoPoll, setShowOffCanvasPhotoPoll] = useState(false);
    const [dailyAppointments, setDailyAppointments] = useState();
    const [survey, setSurvey] = useState();
    const [viewPhotoPoll, dispatchViewPhotoPoll] = useOffCanvasReducer();
    const [needSurveySave, setNeedSurveySave] = useState(false);

    const {
        next, 
        previous, 
        day, 
        formatDate,
    } = useDaySelector();

    useEffect(() => {
        loadAppointments();
    }, [day]);

    const handleChangeShowEnded = () => setHideEnded(!hideEnded);

    const onSavePolls = () => {
        loadAppointments();
        dispatchViewPhotoPoll('CLOSE');
    }

    const loadAppointments = () => {
        setSurvey();
        setDailyAppointments();
        setNeedSurveySave(false);
        Promise.all([
            axios.get('appointments', {
                params: {
                    start: formatDate.start,
                    end: formatDate.end,
                    detailed: 1,
                    photographer: 1
                }
            }),
            axios.get('photographers-surveys', {
                params: {
                    start: formatDate.start,
                    end: formatDate.start,
                }
            })
        ])
        .then(([resAppointment, resSurvey]) => {
            setDailyAppointments(resAppointment.data);
            setSurvey(resSurvey.data.length > 0 ? resSurvey.data[0] : null);
        });
    }

    const handleChangeSurvey = (e) => {
        const { value, name } = e.target;
        const updateSurvey = {
            ...(survey ?? { personal_car: '', lunch_refund: '', lunch_cost : '' }),
            [name]: value
        };
        if (name === 'lunch_refund') {
            if (value == 1) {
                updateSurvey.lunch_cost = 10;
            }
            if (value == 0) {
                updateSurvey.lunch_cost = '';
            }
        }
        setSurvey(updateSurvey);
        setNeedSurveySave(true);
    }

    const handleSaveSurvey = (e) => {
        e.preventDefault();
        const surveyData = {
            personal_car: survey.personal_car,
            lunch_refund: survey.lunch_refund,
            lunch_cost: survey.lunch_cost,
            ...(survey.id ? {} : { date: formatDate.start })
        };
        (survey.id ? axios.put(`photographers-surveys/${survey.id}`, surveyData) : axios.post('photographers-surveys', surveyData))
        .then(res => {
            setSurvey(res.data)
            setNeedSurveySave(false);
        });
    }

    return { 
        showOffCanvasPhotoPoll, setShowOffCanvasPhotoPoll,
        next, previous, day,
        hideEnded, handleChangeShowEnded,
        dailyAppointments,
        onSavePolls,
        viewPhotoPoll, dispatchViewPhotoPoll,
        survey,
        needSurveySave,
        handleChangeSurvey,
        handleSaveSurvey
    };

}