import { UPLOAD_DEFAULT } from "constants";
import { ref, uploadBytesResumable, deleteObject, getDownloadURL } from "firebase/storage";
import { uploadUpdateStatus } from "store/actions/uploads";
import { storage } from "utils/firebase-instance";
import { createUUID, getFileExtension } from "utils/helpers";

const caseUploadFiles = (store, next, action) => {

    const {
        user_uuid,
        key,
        files,
        metadata
    } = action.payload;

    const uploadFiles = Object.fromEntries(
        [...files].map(file => {
            const extention =  getFileExtension(file.name);
            const uuid = createUUID() + (extention ? `.${extention}` : '');
            const path = `uploads/${user_uuid}/${uuid}`;
            const uploadRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(uploadRef, file, {
                customMetadata: {
                    name: file.name
                }
            });
            const updateUploadSnapshot = data => store.dispatch(uploadUpdateStatus(key, uuid, data));
            
            uploadTask.on('state_changed', 
                (snapshot) => updateUploadSnapshot({
                    process: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                }),
                (error) => updateUploadSnapshot({ error }), 
                () => {
                    getDownloadURL(uploadRef).then((downloadURL) => {
                        console.log(downloadURL);
                        updateUploadSnapshot({ 
                            completed: true,
                            downloadURL
                        })
                    });
                }
            );
            return [uuid, {
                ...UPLOAD_DEFAULT,
                uuid,
                name: file.name,
                path,
                metadata
            }];
        })
    );
    
    action.payload = {
        key,
        files: uploadFiles,
    }
    next(action);
}

const caseDeleteFile = (store, next, action) => {
    const { key, uuid } = action.payload;
    const { uploads } = store.getState();
    const file = uploads[key][uuid];
    if (file) {
        deleteObject(ref(storage, file.path))
        .then(() => {
            next(action);
        });
    }
}

const uploads = store => next => action => {

    switch(action.type) {
        case 'UPLOAD_FILES':
            caseUploadFiles(store, next, action);   
        case 'DELETE_FILE':
            caseDeleteFile(store, next, action);            
            break;
    }

    return next(action);
};

export default uploads;