import { Offcanvas } from "react-bootstrap";
import { IoCalendarOutline, IoHomeOutline, IoLockClosed } from "react-icons/io5";
import { isMobile } from "utils/helpers";

export default function OffCanvasResourceSelected({
    show,
    handleClose,
    resourceInfo,
    handleSelectManual,
    handleSelectClient,
    handleSelectLock
}) {

    return (<>
        <Offcanvas 
            placement="bottom"
            className="action-sheet" 
            show={show} 
            onHide={handleClose} 
            style={{ minHeight: 0, maxWidth: 450 }}
        >
            <Offcanvas.Header>
                <div className="offcanvas-title fw500 text-16 text-active py-2">
                    {resourceInfo && resourceInfo.resource.title}
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ul className="action-button-list">
                    <li onClick={handleSelectManual}>
                        <a className="btn btn-list">
                            <span>
                                <IoCalendarOutline /> <span className='ms-1'>Aggiungi appuntamento manuale</span>
                            </span>
                        </a>
                    </li>
                    <li onClick={handleSelectClient}>
                        <a className="btn btn-list">
                            <span>
                                <IoHomeOutline /> <span className='ms-1'>Aggiungi appuntamento cliente</span>
                            </span>
                        </a>
                    </li>
                    {isMobile() && <li onClick={handleSelectLock}>
                        <a className="btn btn-list">
                            <span>
                                <IoLockClosed /> <span className='ms-1'>Blocca slot orario</span>
                            </span>
                        </a>
                    </li>}
                </ul>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}