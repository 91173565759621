import { useEffect } from "react";


export default function useEventListener(events, dependencies, listener = document) {

    const subscribe = () => {
        Object.entries(events).forEach(([name, callback]) => {
            listener.addEventListener(name, callback);
        });
    }

    const unsubscribe = () => {
        Object.entries(events).forEach(([name, callback]) => {
            listener.removeEventListener(name, callback);
        });
    }

    useEffect(() => {
        subscribe();
        return () => unsubscribe();
    }, dependencies)

    return {
        
    }
}