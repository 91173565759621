import products from "./products";

const renderState = {
    type: null,
    ambients: [],
    product_id: null,
}

const initialState = {
    step: 0,
    products: [],
    metadata: {},
}

const cart = (state = initialState, action) => {
    switch(action.type){
        case 'CART_SET_DATA':
            return {
                ...state,
                ...action.payload
            };
        case 'CART_NEXT_STEP':
            return {
                ...state,
                step: state.step + 1
            };
        case 'CART_PREVIOUS_STEP':
            return {
                ...state,
                step: state.step - 1
            };
        case 'CART_ADD_PRODUCT': 
            return {
                ...state,
                products: [
                    ...state.products,
                    action.payload
                ]
            }
        case 'CART_EMPTY': 
            return {
                ...state,
                products: []
            }
        case 'CART_RESET':
            return initialState;
        case 'CART_SET_RENDER_TEMPLATE':
            if (state.metadata.render) return state;
            return {
                ...initialState,
                metadata: {
                    render: renderState
                }
            };
        case 'CART_SET_RENDER': 
            return {
                ...state,
                metadata: {
                    render: action.payload
                }
            }
        default:
            return state;
    }
}

export default cart;