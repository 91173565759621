import axios from 'axios';
import { dialogClose } from 'store/actions/dialogBox';
import { appShowLoader, appHideLoader } from 'store/actions/appLoader';
//import { appointmentEditStart, appointmentEditEnd } from 'store/actions/appointmentEdit';
import { dispatchAppEvent } from 'utils/helpers';
import { toast } from 'react-toastify';
import { setProducts } from 'store/actions/products';
import { setRenderAmbients } from 'store/actions/renderAmbients';

const apiAsync = store => next => action => {

    switch(action.type) {
        case 'API_APPOINTMENT_UPDATE':
            store.dispatch(appShowLoader());
            axios.put(`appointments/${action.payload.id}`, action.payload)
            .then(res => {
                store.dispatch(appHideLoader());
                //store.dispatch(appointmentEditEnd());
                store.dispatch(dialogClose('confirm_appointment_update'));
                toast.success(res.data.message);
                dispatchAppEvent('appointment_updated', res.data);
            });
            break;
        case 'API_APPOINTMENT_DELETE':
            store.dispatch(appShowLoader());
            //store.dispatch(appointmentEditStart(action.payload.id));
            axios.put(`appointments/${action.payload.appointment_id}/cancel`)
            .then(res => {
                //store.dispatch(appointmentEditEnd());
                store.dispatch(appHideLoader());
                store.dispatch(dialogClose('confirm_appointment_delete'));
                toast.success(res.data.message);
                dispatchAppEvent('appointment_deleted', res.data);
            });
            break;
        case 'API_PRODUCTS_GET':
            axios.get(`products`, {
                params: action.payload
            })
            .then(res => {
                store.dispatch(setProducts(action.payload, res.data));
            });
            break;
        case 'API_RENDER_AMBIENTS_GET':
                axios.get(`render-ambients`)
                .then(res => {
                    store.dispatch(setRenderAmbients(action.payload, res.data));
                });
                break;
    }

    return next(action);
};

export default apiAsync;