import { STATUSES } from "constants";
import { useAuthValue } from "context/AuthContext";
import { IoAlertCircleOutline, IoChatbubbleEllipses, IoCheckmarkCircleOutline, IoCloseCircleOutline, IoCloudUploadOutline, IoHourglassOutline } from "react-icons/io5";


export default function RenderViewBlock({
    view,
    backgroundImage,
    handleViewClick
}) {

    const { user } = useAuthValue();
    const role = user.is_agent ? 'agent' : 'editor';
    const status = view.last_revision?.status ?? (role === 'editor' && !view.last_revision ? 'WAITING_EDITOR' : 'NONE');
    const commentCount = view.last_revision?.comments.length ?? null;
    const lastComment = commentCount ? view.last_revision.comments[commentCount - 1] : null; 
    const statusData = STATUSES[status].is_global ? STATUSES[status].global : STATUSES[status][role];

    return (
        <div 
            className={`custom-file-upload revision ${view.last_revision?.image ? 'with-image' : ''}`} 
            style={{ backgroundImage: view.last_revision?.image?.url ? `url(${view.last_revision?.image?.url})` : null }}
            onClick={() => (view.last_revision || status === 'WAITING_EDITOR') && handleViewClick()}
        >
            {status && <span className={`z-3 end-0 top-0 text-white position-absolute rounded fw-600 text-12 px-2 m-1 bg-${statusData.badgeColor}`}>
                {status === 'WAITING_EDITOR' && view.last_revision?.image ? 'DA INVIARE' : statusData.badgeLabel}
            </span>}
            {!!view.last_revision && view.last_revision.status === 'REJECTED' && 
                <span 
                    className={`z-3 start-0 top-0 text-white position-absolute rounded fw-600 text-14 px-2 m-1 ${!lastComment || lastComment[role] ? '' : 'bg-primary'}`} 
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.27)' }}
                >
                    <IoChatbubbleEllipses className='vertical-align-n2' /> {commentCount}
                </span>
            }
            <label 
                style={{ backgroundColor: backgroundImage ? '#000000ab' : null }}
            >
                <span>
                    <strong className={`text-${statusData.textColor} lh-1 text-14`}>
                        <statusData.Icon className={`text-${statusData.textColor} mb-05`} />
                        <i className="fw-medium">{view.render_ambient.label} - Ambiente {view.ambient_index} - Vista {view.view_index}</i>
                    </strong>
                </span>
            </label>
        </div>
    );
};