import axios from "axios";
import OffCanvasLoader from "components/loader/OffCanvasLoader";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import OffCanvasCloseBtn from "./OffCanvasCloseBtn";
import { OffCanvasBody as OffCanvasBodyPlan } from 'components/offcanvas/OffCanvasViewPlan';

export default function OffCanvasViewOrder({
    orderId,
    handleClose
}) {

    const [orderData, setOrderData] = useState();

    useEffect(() => {
        if (!orderId) return setOrderData();

        axios.get(`/orders/${orderId}`, {
            params: {
                detailed: 1
            }
        })
        .then(res => setOrderData(res.data));
    }, [orderId]);

    return (
        <Offcanvas
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={!!orderId}
            onHide={handleClose}
        >
            {!orderData && <OffCanvasLoader />}
            <Offcanvas.Body>
                {orderData?.has_plan && <OffCanvasBodyPlan 
                    orderData={orderData}
                    handleClose={handleClose}
                />}
            </Offcanvas.Body>
        </Offcanvas>
    );

}