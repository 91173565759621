import useLocalStorage from "hooks/useLocalStorage";
import { Modal } from "react-bootstrap";
import { IoNotificationsCircle } from "react-icons/io5";
import { requestNotificationPermission } from 'utils/app-notifications.js';

export default function ModalRequestNotifications(props) {

    const {
        show,
        handleClose
    } = props;

    const [, setNotificationDenied] = useLocalStorage('notification_permission_denied');

    return (
        <Modal className="dialogbox full-height notification" show={show}>
            <div className="modal-icon text-light mt-2">
                <IoNotificationsCircle />
            </div>
            <Modal.Header>
                <Modal.Title>
                    Vuoi ricevere le notifiche?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-gray px-3'>
                Ciao! Prima di procedere ci piacerebbe avere il <b>permesso di inviarti notifiche</b>. Ecco cosa puoi aspettarti:
                <br /><br />
                🌟 <b>Aggiornamenti importanti:</b> Ricevi le ultime notizie e gli aggiornamenti sullo stato delle tue prenotazioni.
                <br /><br />
                📷 <b>Consegna materiale:</b> Ricevi notifiche quando le foto dei servizi richieste saranno pronte.
                <br /><br />
                🔔 <b>Promozioni esclusive:</b> Sii il primo a sapere delle nostre offerte speciali e promozioni.
                <br /><br />
                Nessun spam, promesso! Puoi modificare le tue preferenze di notifica in qualsiasi momento nelle impostazioni dell'app.
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-inline">
                    <a 
                        className="btn text-danger"
                        onClick={() => {
                            setNotificationDenied(true);
                            handleClose();
                        }}
                    >
                        Più tardi
                    </a>
                    <a 
                        className="btn text-success"
                        onClick={() => {
                            setNotificationDenied(true);
                            requestNotificationPermission();
                            handleClose();
                        }}
                    >
                        Acconsento
                    </a>
                </div>
            </Modal.Footer>
        </Modal>
    )
}