import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

const DEFAULT_FILTER = {
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
    organization_id: ''
}

export default function useSocialPlanning() {

    const [filter, setFilter] = useState(DEFAULT_FILTER);
    const [appointments, setAppointments] = useState();

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        if (['from', 'to'].includes(name) && !value) return;
        setFilter({
            ...filter,
            [name]: value
        });
    }

    useEffect(() => {
        axios.get('appointments', {
            params: {
                is_social_post: 1,
                status: 'ENDED',
                ...filter
            }
        })
        .then(res => setAppointments(res.data));
    }, [filter]);

    return {
        filter,
        handleChangeFilter,
        appointments
    }
}