import { useEffect } from 'react';
import { getUrlParam, removeUrlParameter } from 'utils/helpers';

export default function useUrlAction(callbacks) {

    useEffect(() => {
        const action = getUrlParam('action');
        if (!action || !callbacks[action]) return;
        callbacks[action][0](...callbacks[action][1]);
        removeUrlParameter('action');
    }, []);
}