import { Offcanvas } from "react-bootstrap";
import { IoCloseOutline, IoDocument, IoDownload, IoOpenOutline, IoPencil, IoPricetags, IoTime } from "react-icons/io5";
import OffCanvasCloseBtn from "./OffCanvasCloseBtn";
import moment from "moment";
import { PAYMENT_METHODS } from "constants";

export function OffCanvasBody({
    orderData,
    handleClose
}) {
    if (!orderData) return;

    const completed = orderData.plan.task?.status === 'DONE';

    return (<>
        <div
            style={{ backgroundImage: `url('${process.env.REACT_APP_PUBLIC_URL}img/plan.jpeg')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundColor: '#f6f6f6' }}
            className="property-card-header small"
        >

            <OffCanvasCloseBtn handleClose={handleClose} />

        </div>

        <div className="action-sheet-content py-2 px-0 noarrow">
            <div className="listview-title pb-0">
                <h3 className="mb-0 w-100">
                    {orderData.plan.product}
                    <span 
                        className={`badge bg-${completed ? 'success' : 'primary'} ms-1 float-end`}
                    >{completed ? 'COMPLETATO' : 'IN CORSO'}</span>
                </h3>
            </div>
            <ul className="listview image-listview no-border">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>
                                    <IoTime /> Data richiesta:
                                </header>
                                {moment(orderData.created_at).format('DD/MM/YYYY HH:mm')}
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>
                                    <IoPricetags /> Totale pagato:
                                </header>
                                {orderData.total} € + IVA ({PAYMENT_METHODS[orderData.payment_method]})
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>
                                    <IoDocument /> Planimetria da elaborare:
                                </header>
                                <a 
                                    className='active'
                                    href={orderData.plan.instructions_file.url}
                                    target="_blank"
                                >{orderData.plan.instructions_file.name} <IoOpenOutline className='vertical-align-n2'/></a>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div className="flex-fill">
                                <header>
                                    <IoPencil /> Note aggiuntive:
                                </header>
                                {orderData.plan.instructions}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div className="form-group mb-2 section">
            <a 
                type="button" 
                className={`btn-block btn btn-primary ${(completed && orderData.plan?.delivery_file?.url) ? '' : 'disabled'}`}
                href={orderData.plan?.delivery_file?.url}
                target="_blank"
            >
                <IoDownload/>Scarica planimetria
            </a>
        </div>

    </>)
}

export default function OffCanvasViewPlan(props) {

    return (<>
        <Offcanvas
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={true}
        //show={!!plantId}
        //onHide={handleClose}
        >
            <Offcanvas.Body>
                <OffCanvasBody />
            </Offcanvas.Body>
        </Offcanvas>
    </>);

    }