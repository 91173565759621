import RenderTabs from "components/RenderTabs";
import Capsule from "layout/Capsule";
import Header from "layout/header/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { completedUploads } from "utils/helpers";
import { uploadReset, uploadSet } from "store/actions/uploads";
import { useDispatch } from "react-redux";

import axios from "axios";


export default function RenderManage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orderId } = useParams();
    const [orderData, setOrderData] = useState();

    const handleBack = () => navigate('/orders?type=RENDER');

    const handleSetUploads = (uploads) => {
        console.log(uploads);
        dispatch(uploadSet({
            'render_plan': Object.fromEntries(uploads.plan.map((u) => completedUploads(u))),
            'render_reference': Object.fromEntries(uploads.reference.map((u) => completedUploads(u))),
            'render_furniture': Object.fromEntries(uploads.furniture.map((u) => completedUploads(u))),
        }))
    }

    useEffect(() => {
        if (orderData) {
            if (orderData.render.instruction) {
                handleSetUploads(orderData.render.instruction.uploads);
            }
            return;
        }
        dispatch(uploadReset());
        axios.get(`orders/${orderId}`, {
            params: {
                detailed: 1
            }
        })
        .then(res => {
            setOrderData(res.data);
        });
    }, [orderId, orderData]);


    if (!orderId) return handleBack();

    return (
        <>
            <Header
                pageTitle={orderData?.title ?? 'Caricamento...'}
                handleBack={handleBack}
            />
            <Capsule>
                <RenderTabs 
                    orderData={orderData}
                    setOrderData={setOrderData}
                />
            </Capsule>
        </>
    );
}