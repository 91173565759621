import axios from "axios";
import InputUpload from "components/InputUpload";
import SpinnerForButton from "components/loader/SpinnerForButton";
import OffCanvasCloseBtn from "components/offcanvas/OffCanvasCloseBtn";
import { useAuthValue } from "context/AuthContext";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { IoSend, IoSendOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUploads } from "store/selectors";
import { uploadsField } from "utils/helpers";

export default function OffcanvasExternal({
    show, 
    handleClose, 
}) {

    const { user } = useAuthValue();
    const { render_external } = useSelector(selectUploads);
    const renderExternalUploads = Object.values(render_external ?? {});

    return (<>
        <Offcanvas placement="bottom" className="action-sheet" show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose}/>
                <Formik
                    initialValues={{
                        note:  '',
                    }}
                    onSubmit={(values) => {
                        return axios.post('utils/render-external', {
                            organization_id: user.agent?.getSelectedOrganization().id,
                            note: values.note,
                            uploads: uploadsField(render_external, 'path'),
                        })
                        .then(res => {
                            toast.success('Richiesta inviata con successo');
                            handleClose();
                        });
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        values,
                    }) => (
                        <Form>
                            <div className="section mt-3 text-center">
                                <h3 className='mb-0'>Preventivo rendering esterno</h3>
                            </div>

                            {/* Open space 2 viste di default attive */}
                            <div className='section py-3'>
                                <div className="bg-gray-dark rounded p-2">
                                A differenza degli interni interni, per i rendering esterni abbiamo la necessità di valutare di volta in volta il progetto da sviluppare tramite uno studio dettaglio e un preventivo personalizzato. Di seguito ti chiediamo alcune informazioni necessarie utili alla nostra valutazione. Riceverai riscontro entro 24 ore lavorative. 
                                </div>
                            </div>

                            <div className="section">
                                <h4 className="card-subtitle text-white text-12 mt-1">DETTAGLIO DEL PROGETTO:</h4>
                                <div className="form-group boxed custom pt-0">
                                    <div className="input-wrapper">
                                            <Field
                                                component="textarea"
                                                id="note" 
                                                name="note"
                                                className="form-control text-15"
                                                rows="6"
                                                required
                                                disabled={isSubmitting}
                                                placeholder="Vorrei un render esterno del mio immobile situato in una zona rurale, con facciate in pietra chiara e inserti in legno. Prevediamo una piscina a sfioro, una pergola e un giardino con ulivi e lavanda. Preferiamo uno stile moderno con richiami alla tradizione locale, ambientato in una giornata di primavera verso le 17:00. Allego planimetrie e foto del sito per maggiori dettagli."
                                            />
                                    </div>
                                </div>

                                <h4 className="card-subtitle text-white text-12 mt-1">PLANIMETRIA & ALLEGATI:</h4>
                                <InputUpload 
                                    gkey="render_external"
                                    required
                                />
                            </div>

                            <div className='section mb-3'>
                                <Button
                                    className={`text-center btn-block d-block m-auto mt-2 ${isSubmitting || !isValid || !values.note || !renderExternalUploads.length || renderExternalUploads.filter(u => !u.completed).length ? 'disabled' : ''}`}
                                    type="submit"
                                >
                                    <SpinnerForButton show={isSubmitting} />
                                    Invia richiesta <IoSend className="next-icon-btn" />
                                </Button>
                            </div>
                        </Form>
                    )} 
                </Formik>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}