import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from './OffCanvasCloseBtn';
import { Button } from 'react-bootstrap';
import StripeCredit from 'components/StripeCredit';
import { useAuthValue } from 'context/AuthContext';
import { useNavigate } from 'react-router';

export default function OffCanvasHistoryFunds(props) {

    const {
        show,
        handleClose
    } = props;

    const { user } = useAuthValue();
    const organization = user.agent.getSelectedOrganization();
    const navigate = useNavigate();

    return (
        <Offcanvas placement="bottom" className="action-sheet" show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Storico ricariche</h3>
                </div>

                <div className='section mb-3'>
                    <StripeCredit
                        organizationId={organization.id}
                    />

                    <Button
                        className="text-center btn-block d-block m-auto mt-2"
                        type="button"
                        onClick={() => navigate('orders')}
                    >
                        Visualizza tutti gli ordini
                    </Button>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    );

}