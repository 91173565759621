import { useEffect, useRef, useState } from "react";

export default function usePolling(callback, timeout = 5000) {
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        let intervalId;
        if (isEnabled) {
            console.log("start new polling");
            intervalId = setInterval(() => {
                console.log("invoke callback");
                callback();
            }, timeout);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isEnabled, callback]);

    const startPolling = () => {
        setIsEnabled(true);
    };
    const stopPolling = () => setIsEnabled(false);

    return { startPolling, stopPolling };
};
