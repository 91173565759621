import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import { IoExpand } from 'react-icons/io5';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Form } from 'react-bootstrap';
import { reorderArrayItem } from 'utils/helpers';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';
import SpinnerForButton from 'components/loader/SpinnerForButton';

//Logic
function useOffCanvasManageEditor({
    show,
    editors
}) {

    const [reorderEditors, setReorderEditors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleReorderPh = (result) => {
        if (!result.destination) {
            return;
        }
        const newEditors = reorderArrayItem(reorderEditors, result.source.index, result.destination.index)

        setReorderEditors(newEditors);
    };

    const handleConfirm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put('editors-priority', {
            editors: reorderEditors.map(user => ({ id: user.editor.id, content_x_day: user.editor.content_x_day }))
        })
        .then(res => {
            toast.success('Impostazioni di post-produzione aggiornate con successo. La seguente pagina verà ricaricata', {
                onClose: () => window.location.reload()
            });
        });
    }

    const handleChangeInput = (user_id, e) => {
        const { name, value } = e.target;
        setReorderEditors(reorderEditors.map(user => {
            if (user.id !== user_id) return user;
            user.editor[name] = value;
            return user;
        }));
    }

    useEffect(() => {
        if (!show) return;
        setReorderEditors(editors?.sort((a, b) => a.editor.priority - b.editor.priority));
    }, [show]);

    return {
        handleReorderPh,
        reorderEditors,
        handleConfirm,
        handleChangeInput,
        isLoading
    }

}


function EditorItem({ 
    user,
    handleChangeInput
}) {

    return (
        <div className="item ps-2">
            <div className="in">
                <div>
                    <span>
                        {user.display_name}
                    </span>
                </div>
                <input 
                    className='form-control input-li me-1'
                    value={user.editor.content_x_day}
                    type="number"
                    name="content_x_day"
                    onChange={e => handleChangeInput(user.id, e)}
                    placeholder='Qta'
                    required
                />
            </div>
            <IoExpand className='ms-1 cursor-drag' style={{ fontSize: '30px' }} />
        </div>
    );
}

export default function OffCanvasManageEditor(props) {

    const {
        show,
        handleClose,
    } = props;

    const {
        handleReorderPh,
        handleConfirm,
        reorderEditors,
        handleChangeInput,
        isLoading
    } = useOffCanvasManageEditor(props);

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        background: isDragging ? "#000" : "#3a3a3a",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (<>
        <Offcanvas
            placement="bottom"
            id="view-property"
            className="action-sheet"
            show={show}
            onHide={handleClose}
        >
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Impostazioni post-produzione</h3>
                </div>

                {!reorderEditors && <OffCanvasLoader />}

                <Form className='section py-2' onSubmit={handleConfirm}>
                    <DragDropContext onDragEnd={handleReorderPh}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="listview image-listview pt-1"
                                >
                                    {!reorderEditors ? (
                                        <OffCanvasLoader />
                                    ) : (
                                        reorderEditors.map((user, index) => (
                                            <Draggable
                                                key={user.id}
                                                draggableId={'e' + user.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <li
                                                        key={user.id}
                                                        className="rounded mt-1"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <EditorItem 
                                                            user={user} 
                                                            handleChangeInput={handleChangeInput}
                                                        />
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))
                                    )}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className="form-group basic">
                        <Button
                            className={`btn-block mt-1 ${isLoading ? 'disabled' : ''}`}
                            type="submit"
                        >
                            <SpinnerForButton show={isLoading} />
                            Conferma
                        </Button>
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}