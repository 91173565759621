import { IoSearchOutline } from "react-icons/io5";

export default function FilterInput({ 
    placeHolder,
    onChange,
    value
}) {
    return (
        <div className="form-group boxed pt-0 p-2">
            <div className="input-wrapper not-empty">
                <IoSearchOutline className='input-icon-search cursor-pointer'/>
                <input 
                    type="text"
                    className="form-control"
                    placeholder={placeHolder}
                    autoComplete="off"
                    onChange={onChange}
                    value={value}
                />
            </div>
        </div>
    )
}