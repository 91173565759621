import { Fragment } from 'react';

//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import OffcanvasViewAppointment from 'components/offcanvas/view-appointment/OffCanvasViewAppointment';
import PlaceHolderItemList from 'components/loader/PlaceHolderItemList';
import AutocompleteSelect from 'react-select';


//Assets
import historyImage from './img/history_img.svg';
import historyPlan from './img/history_plan.png';
import historyRender from './img/history_render.png';
import { IoAddCircle, IoAddOutline, IoCamera, IoCube, IoLogoAppleAr, IoSearchOutline, IoTime, IoWallet } from 'react-icons/io5';

//Logic
import useHistory from './useHistory';

//Utils
import moment from 'moment';
import { AutocompleteOrganization } from 'components/autocomplete/Autocomplete';
import { useAuthValue } from 'context/AuthContext';
import OffCanvasViewOrder from 'components/offcanvas/OffCanvasViewOrder';
import OffCanvasViewPlan from 'components/offcanvas/OffCanvasViewPlan';
import { useNavigate } from 'react-router';

const HEADER_IMAGE = {
    'PLAN': {
        image: historyPlan,
        title: 'Planimetrie'
    },
    'RENDER': {
        image: historyRender,
        title: 'Rendering'
    }
}

function appointmentStatus(order_status, appointment_status) {
    if (order_status === 'REFUNDED') return { color: 'danger', label: `Stornato`}
    if (!appointment_status) return null;
    switch(appointment_status) {
        case 'CONFIRMED':
            return { color: 'warning', label: `Prenotato`}
        case 'ENDED':
            return { color: 'success', label: `Svolto`}
    }
}

function ViewHistoryItem(props) {

    const {
        isSkeleton,
        order,
        filter,
        handleClickDetail,
    } = props;

    //const ElementType = order?.appointment_id ? 'a' : 'div';
    const status = appointmentStatus(order?.status, order?.appointment_status)

    return (
        <li key={order?.id} onClick={handleClickDetail ? () => handleClickDetail(order?.id) : null}>
            <a className={`item align-items-start pe-4 ${handleClickDetail ? '' : 'no-after'}`}>
                <div className={`icon-box mt-05 bg-secondary ${isSkeleton ? 'placeholder' : ''}`}>
                    {!isSkeleton && (
                        <>
                            {order?.appointment_id === null ? (
                                order?.has_render ? <IoCube /> : (order?.has_plan ? <IoLogoAppleAr /> : <IoWallet />)
                            ) : (
                                <IoCamera />
                            )}
                        </>
                    )}
                </div>
                {isSkeleton ? <PlaceHolderItemList /> :
                    <div className="in">
                        <div>
                            <span>{order?.title}</span>
                            <span className="d-block text-muted">
                                {status ? <><span className={`text-${status.color}`}>{status.label}</span><br /></> : (order.status_label && <><span className={`text-${order.status_label[1]}`}>{order.status_label[0]}</span><br /></>)} 
                                {order?.organization_business_name && <><i>{order.organization_business_name}</i><br /></>}
                                {order?.items[0]}
                                <br />
                                {order?.items.map((item, index) => {
                                    if (index === 0) return;
                                    return <Fragment key={index}>
                                        <IoAddCircle className='vertical-align-n2' /> {item}
                                        <br />
                                    </Fragment>;
                                })}
                                <IoTime className='vertical-align-n2' /> {moment(order?.created_at).format('DD/MM/YYYY HH:mm')}
                            </span>
                        </div>
                        <div>
                            {order?.status === 'REFUNDED' ? <s>{order?.total.toFixed(2)}€</s> : `${order?.total.toFixed(2)}€`}
                        </div>
                    </div>
                }
            </a>
        </li>
    );
}

function History() {

    const {
        orders,
        filter,
        selectedAppointmentId,
        dispatchViewAppointment,
        handleChangeFilter,
        selectedOrderId, dispatchViewOrder,
        pageType
    } = useHistory();

    const { user } = useAuthValue();
    const navigate = useNavigate();

    return (
        <>
            <Header
                pageTitle={HEADER_IMAGE[pageType]?.title ?? 'Storico ordini'}
                handleBack="home"
                RightComponent={pageType === 'PLAN' ? () => (
                    <a className='headerButton'>
                        <IoAddOutline 
                            className='text-light'
                            onClick={() => navigate('/plan')}
                        />
                    </a>
                ) : () => {}}
            />
            <Capsule>
                <div className="form-group boxed pt-0 p-2">
                    <div>
                        <img src={HEADER_IMAGE[pageType]?.image ?? historyImage} height="150" className="history-image" />
                    </div>
                    {user.is_admin && <div className="input-wrapper d-flex mb-1">
                        <div className="form-group boxed custom pb-0">
                            <AutocompleteOrganization
                                onChange={handleChangeFilter}
                            />
                        </div>
                    </div>}
                    <div className="input-wrapper d-flex">
                        <div className="form-group boxed custom p-0">
                            <select
                                name="year"
                                className="form-control form-select text-center"
                                style={{ borderRadius: '6px 0px 0px 6px', borderRight: '4px solid #121212' }}
                                value={filter.year}
                                onChange={handleChangeFilter}
                            >
                                {[
                                    new Date().getFullYear() - 2,
                                    new Date().getFullYear() - 1,
                                    new Date().getFullYear()
                                ].map(year => (
                                    <option key={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group boxed custom p-0">
                            <select
                                name="month"
                                className="form-control form-select text-center"
                                style={{ borderRadius: '0px 6px 6px 0px' }}
                                value={filter.month}
                                onChange={handleChangeFilter}
                            >
                                {[
                                    'Gennaio',
                                    'Febbraio',
                                    'Marzo',
                                    'Aprile',
                                    'Maggio',
                                    'Giugno',
                                    'Luglio',
                                    'Agosto',
                                    'Settembre',
                                    'Ottobre',
                                    'Novembre',
                                    'Dicembre'
                                ].map((month, index) => (
                                    <option
                                        key={month}
                                        value={index + 1}
                                    >{month}</option>
                                ))}
                            </select>
                        </div>
                        
                    </div>
                </div>

                <div className="section">
                    <ul className={`listview image-listview rounded placeholder-glow`}>
                        {orders && orders.length == 0 && <><li><div className="text-center p-5">Nessun ordine trovato per il mese selezionato.</div></li></>}
                        {orders && orders.map(order => <ViewHistoryItem
                            key={order.id}
                            order={order}
                            handleClickDetail={order?.status === 'REFUNDED' ? null : () => 
                                order.appointment_id ? 
                                dispatchViewAppointment(order.appointment_id) : 
                                order.has_render ? navigate(`/render-manage/${order.id}`) : dispatchViewOrder(order.id)
                            }
                        />)}
                        {!orders && Array(5).fill(null).map((_, index) => <ViewHistoryItem key={index} isSkeleton />)}
                    </ul>
                </div>

            </Capsule>
            <OffcanvasViewAppointment
                appointmentId={selectedAppointmentId}
                handleClose={() => dispatchViewAppointment('CLOSE')}
            />
            <OffCanvasViewOrder
                orderId={selectedOrderId}
                handleClose={() => dispatchViewOrder('CLOSE')}
            />
        </>

    );
}

export default History;