import { useEffect, useState } from 'react';

//Utils
import axios from 'axios';
import moment from 'moment';
import { capitalizeFirstLetter } from 'utils/helpers';

//Components
import { Link } from 'react-router-dom';
import OffcanvasViewAppointment from 'components/offcanvas/view-appointment/OffCanvasViewAppointment';
//Logic
import { useAuthValue } from 'context/AuthContext';
import { Button } from 'react-bootstrap';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import useEventListener from 'hooks/useEventListener';
function useNextAppointment() {

  const [nextAppointments, setNextAppointments] = useState();
  const [selectedAppointmentId, dispatchViewAppointment] = useOffCanvasReducer();
  const { user } = useAuthValue();


  const onDeleteEvent = () => {
    dispatchViewAppointment('CLOSE');
    loadNextAppointment();
  }

  const loadNextAppointment = () => {
    axios.get('/appointments', {
      params: {
        next: 1,
        events: 1,
        ...(user.is_agent && {
          organization_id: user.agent.getSelectedOrganization().id
        })
      }
    })
    .then(res => setNextAppointments(res.data));
  }

  useEventListener({
    'appointment_deleted': onDeleteEvent,
  }, []);

  useEffect(() => { 
    loadNextAppointment();
  }, []);

  return {
    nextAppointments,
    selectedAppointmentId, dispatchViewAppointment
  }
}

function AppointmentCard(props) {
  const { 
    appointment,
    handleSelect
  } = props;

  return (
    <>
      <div className='mt-2'>
        <h3 className="card-subtitle text-white text-14 mb-1">{moment(appointment.start).format('dddd D MMMM YYYY')}</h3>
      </div>
      <div className="card-appointment" style={{ backgroundColor: `${appointment.color}` }} onClick={() => handleSelect()}>
        <b className='float-end text-14 text-end'>{moment(appointment.start).format('HH:mm')}<br />{moment(appointment.end).format('HH:mm')}</b>
        <div className="fw-600">{capitalizeFirstLetter(appointment.title)}</div>
        <div>{appointment.location}</div>
      </div>
    </>
  );
}

export default function NextAppointments() {

  const { 
    nextAppointments,
    selectedAppointmentId, dispatchViewAppointment
  } = useNextAppointment();

  if (!nextAppointments || !nextAppointments.length) return;
  return (
    <>
      <hr className="mt-0 mb-2 line-divider" />
      <div className="section full">
        <div className="card bg-transparent">
          <div className="card-body py-0">
            <h2 className="text-white mb-2">
              Appuntamento
              <Link to="/calendar">
                <Button
                  size="sm"
                  className="btn-light float-right me-2"
                  type="button"
                >Agenda</Button>
              </Link>
            </h2>
            <div id="appointments-list">
              {nextAppointments && nextAppointments.map(appointment => (<AppointmentCard
                key={appointment.id}
                appointment={appointment}
                handleSelect={() => dispatchViewAppointment(appointment.id)}
              />)
              )}
            </div>
          </div>
        </div>
      </div>
      <OffcanvasViewAppointment 
          appointmentId={selectedAppointmentId}
          handleClose={() => dispatchViewAppointment('CLOSE')}
      />
    </>
  );
}