import { upload } from "@testing-library/user-event/dist/upload";
import { useAuthValue } from "context/AuthContext";
import { useId, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import { IoCheckboxOutline, IoCheckmark, IoCloseCircleOutline, IoCloudDownloadOutline, IoCloudUploadOutline, IoDocument } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { uploadDeleteFile, uploadFiles } from "store/actions/uploads";
import { selectUploads } from "store/selectors";

export default function InputUpload({
    gkey,
    accept,
    label = 'Tocca per caricare',
    limit = Infinity,
    required = false,
    showDownloadable = true,
    hideLabelOnLimit = true,
    showPreview = false,
    metadata = {}
}) {

    const id = useId();
    const ref = useRef();
    const dispatch = useDispatch();
    const user = useAuthValue();
    const uploads = Object.values(useSelector(selectUploads)[gkey] ?? {});

    const handleChange = (e) => {
        const user_uuid = user.firebaseUser.uid;
        dispatch(uploadFiles({
            user_uuid,
            key: gkey,
            files: e.target.files,
            metadata
        }));
        e.target.value = '';
    };
    const uploadsEnded = uploads.filter(upload => upload.completed).length === limit;
    const renderableUploads = uploads.filter(u => (showDownloadable ? true : (!u.downloadURL || !u.isUploaded)));
    
    return <>
        <div>
            <div 
                className={`custom-file-upload bg-amazon position-relative ${renderableUploads.length ? 'upload-no-bb' : '' }`} 
                style={{ 
                    height: '90px', 
                    backgroundColor: '#3a3a3a',
                    backgroundImage: showPreview && limit === 1 && uploads.length > 0? `url(${uploads[0].downloadURL})` : null
                }}
            >
                <input
                    ref={ref}
                    type="file"
                    accept={accept}
                    onChange={handleChange}
                    disabled={uploads.length >= limit}
                    id={`file_upload_${id}`}
                    multiple={limit > 1}
                    required={uploads.length === 0 ? required : false}
                />
                <label htmlFor={`file_upload_${id}`}>
                    <span>
                        {uploadsEnded ? <strong className='text-success'>
                            <IoCheckmark className='text-success' />
                            <i className='text-success'>
                                {hideLabelOnLimit ? 
                                    <>File pront{uploads.length > 1 ? 'i' : 'o'} per essere caricat{uploads.length > 1 ? 'i' : 'o'}</>
                                    : label
                                }
                            </i>
                        </strong> : 
                        <strong className='text-gray'>
                            <IoCloudUploadOutline className='text-gray' />
                            <i>{label}</i>
                        </strong>
                        }
                    </span>
                 </label>
            </div>
            {!!renderableUploads.length && <ul className={`listview image-listview uploads-list overflow-hidden ${renderableUploads.length ? 'no-border' : ''}`}>
                {renderableUploads.map(upload => (
                    <li className={`position-relative ${upload.completed ? '' : 'is-uploading'}`} key={upload.uuid}>
                        <span className="item py-0">
                            <IoDocument className="me-1"/>
                            <div className="in">
                                <div className="flex-fill pe-1 text-14 lh-1">
                                    {upload.name}
                                    {upload.completed && upload.isDeletable && <IoCloseCircleOutline 
                                        className="text-16 cursor-pointer text-danger ms-1"
                                        title="Rimuovi file"
                                        onClick={() => dispatch(uploadDeleteFile(gkey, upload.uuid))}
                                    />}
                                </div>
                                {upload.downloadURL && <a href={upload.downloadURL} target="_blank" className="text-12 active">
                                    SCARICA
                                </a>}
                            </div>
                        </span>
                        {!upload.completed && <ProgressBar now={upload.process} />}
                    </li>
                ))}
            </ul>}
        </div>
    </>
}