//Components
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'components/Slider';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';

function useOffCanvasServiceInfo(props) {
    const { product } = props;
    const [productDetail, setProductDetail] = useState();

    useEffect(() => {
        if (!product) return setProductDetail();
        axios.get(`/products/${product}`, { params: {
            detailed: 1
        }})
        .then(res => {
            setProductDetail(res.data);
        });
    }, [product]);

    return {
        productDetail
    }
}

function OffcanvasServiceInfo(props) {

    const {
        product,
        handleClose
    } = props;

    const { productDetail } = useOffCanvasServiceInfo(props);

    return (<>
        <Offcanvas placement="bottom" className="action-sheet max-width-600 z-index-power" show={!!product} onHide={handleClose}>
            <Offcanvas.Body>
                {!productDetail && <OffCanvasLoader />}
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="action-sheet-content py-2 px-0">
                    <div className="listview-title fw-700 mb-1 justify-content-center text-center">
                        <h2 className="mb-0">{productDetail?.description}</h2>
                    </div>
                    {/* {productDetail?.image_urls.map(image => (
                        <div className="p-3 pb-0">
                            <img src={image.url} style={{ maxWidth: '100%' }}/>
                        </div>
                    ))} */}
                    {!!productDetail?.image_urls.length && <div className="section mb-0">
                        <Slider 
                            fullWidth 
                            navInset
                            className="service-slider"
                        >
                            {productDetail?.image_urls.map(image => (
                                <Slider.Slide key={image.name}>
                                    <img src={image.url} style={{ maxWidth: '100%' }} />
                                </Slider.Slide>
                            ))}
                        </Slider>
                    </div>}
                    {productDetail?.embed && (
                        <div className="section mb-1 service-iframe">
                            <div classNsme="iframe-container" dangerouslySetInnerHTML={{ __html: productDetail.embed }} />
                        </div>
                    )}
                    <div className="section mb-2 text-16">{productDetail?.description_extend}</div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasServiceInfo;