const messages = {
    'default': 'Si è verificato un errore',
    //Auth
    'auth/user-not-found': 'Username o password errati',
    'auth/wrong-password': 'Username o password errati',
    'auth/too-many-requests': 'Hai fatto troppi tentativi, riprova più tardi',
    'auth/validate/name/length': 'Nome e cognome devono essere almeno 3 caratteri',
    'auth/validate/phone/invalid': 'Numero di telefono non valido',
    'auth/validate/password/confirm': 'Le password non coincidono',
    'auth/validate/password/length': 'La password deve contenere da 6 a 30 caratteri',
    'auth/validate/gdpr/accept': 'Per poter continuare è obbligatorio accettare la privacy policy e i termini e condizioni',
    'auth/register/success': 'Email reinviata con successo',
    'auth/register/mail_exists': 'Esiste già un account con la seguente email, se non ricordi le credenziali prova a recuperare la password',
}


export default function labelMessage(error_code = null) {
    if (!error_code || !messages[error_code]) return messages['default'];
    return messages[error_code];
}