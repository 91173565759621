//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import OffcanvasManageUsers from './components/OffcanvasManageUsers';
import OffcanvasAddActivity from 'components/offcanvas/add-activity/OffcanvasAddActivity';
import { IoAdd, IoCashOutline, IoHomeSharp, IoLogoEuro, IoPeopleSharp, IoRepeat, IoSettings } from 'react-icons/io5';
import { useAuthValue } from 'context/AuthContext';
import useOrganizations from './useOrganizations';
import FilterInput from 'components/FilterInput';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

//Logic
import { useFilterOrganization } from 'hooks/useFilterInput';
import OffcanvasViewOrganization from './components/OffCanvasViewOrganization';
import OffCanvasManageOrganization from './components/OffCanvasManageOrganization';
import OrganizationItem from 'components/OrganizationItem';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';


function Organizations() {

    const {
        organizations,
        showOffCanvasAdd, dispatchOffCanvasAdd,
        editOrganization, dispatchEditOrganizationUsers,
        viewOrganizationId, dispatchViewOrganizationId,
        manageOrganizationId, dispathManageOranizationId,
        handleUpdateData,
        handleAddOrganization
    } = useOrganizations();

    const { user } = useAuthValue();

    const [
        filterInputValue,
        handleFilterInputChange,
        filterSearchArray
    ] = useFilterOrganization();

    const totalOrganizations = organizations ? organizations.length : 0;
    const [sortByCreatedAt, setSortByCreatedAt] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <Header
                show_agency={false}
                pageTitle={totalOrganizations > 0 ? `Elenco attività (${totalOrganizations})` : 'Elenco attività'}
                handleBack="home"
            />
            <Capsule>
                {user.is_admin && <>
                    <FilterInput
                        placeHolder="Ricerca attività"
                        onChange={handleFilterInputChange}
                        value={filterInputValue}
                    />
                    <div className="section mb-2">
                        <Button
                            size="sm"
                            variant="github"
                            className='btn-block rounded-2 text-uppercase'
                            onClick={() => setSortByCreatedAt(!sortByCreatedAt)}
                        >
                            <IoRepeat /> Ordina per {sortByCreatedAt ? 'Nome' : 'Data'}
                        </Button>
                    </div>
                </>}

                <div className="section">
                    <ul className="listview image-listview rounded">
                        <li className='cursor-pointer' onClick={() => dispatchOffCanvasAdd('OPEN')}>
                            <div className="item">
                                <div className="icon-box bg-success">
                                    <IoAdd />
                                </div>
                                <div className="in text-success">
                                    <div className="fw-500">Crea nuova attività</div>
                                </div>
                            </div>
                        </li>
                        {!organizations && new Array(4).fill(null).map((_, index) => (
                            <OrganizationItem
                                key={index}
                                isSkeleton
                            />
                        ))}
                        {organizations && organizations.filter(filterSearchArray).sort((a, b) =>
                            sortByCreatedAt ? new Date(b.created_at) - new Date(a.created_at) : 0
                        ).map(organization => {
                            const navigateBuildings = () => navigate(`/admin/buildings?organization_id=${organization.id}&d=${encodeURIComponent(organization.display_name)}`);
                            return (
                                <OrganizationItem
                                    key={organization.id}
                                    organization={organization}
                                >
                                    <>
                                        <Dropdown className="d-block d-sm-none ps-2">
                                            <Dropdown.Toggle id="dropdown-basic" className='btn-sm btn-light'>
                                                Modifica
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => dispatchViewOrganizationId(organization.id)}>Modifica attività</Dropdown.Item>
                                                <Dropdown.Item onClick={() => dispatchEditOrganizationUsers(organization)}>Modifica utenti</Dropdown.Item>
                                                {user.is_admin && <Dropdown.Item onClick={navigateBuildings}>Visualizza immobili</Dropdown.Item>}
                                                {user.is_admin && <Dropdown.Item onClick={() => dispathManageOranizationId(organization.id)}>Credito</Dropdown.Item>}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Button
                                            size="sm"
                                            className={`d-none d-sm-inline btn-light ${organization.pivot?.role === 'AGENT' ? 'disabled' : ''}`}
                                            onClick={() => dispatchEditOrganizationUsers(organization)}
                                        ><IoPeopleSharp className='m-0' /></Button>
                                        {user.is_admin && <Button
                                            size="sm"
                                            className="d-none d-sm-inline ms-1 btn-light"
                                            onClick={navigateBuildings}
                                        ><IoHomeSharp className='m-0' /></Button>}
                                        {user.is_admin && <Button
                                            size="sm"
                                            className="d-none d-sm-inline btn-light ms-1"
                                            onClick={() => dispathManageOranizationId(organization.id)}
                                        ><IoLogoEuro className='m-0' /></Button>}
                                        <Button
                                            size="sm"
                                            className={`d-none d-sm-inline ms-1 btn-primary ${organization.pivot?.role === 'AGENT' ? 'disabled' : ''}`}
                                            onClick={() => dispatchViewOrganizationId(organization.id)}
                                        >Modifica</Button>
                                    </>
                                </OrganizationItem>
                            )
                        })}
                    </ul>
                </div>

            </Capsule>
            <OffcanvasAddActivity
                show={showOffCanvasAdd}
                handleClose={() => dispatchOffCanvasAdd('CLOSE')}
                onAddOrganization={handleAddOrganization}
            />
            <OffcanvasViewOrganization
                organizationId={viewOrganizationId}
                handleClose={() => dispatchViewOrganizationId('CLOSE')}
                onUpdateData={handleUpdateData}
            />
            <OffCanvasManageOrganization
                organizationId={manageOrganizationId}
                handleClose={() => dispathManageOranizationId('CLOSE')}
            />
            <OffcanvasManageUsers
                organization={editOrganization}
                handleClose={() => dispatchEditOrganizationUsers('CLOSE')}
            />
        </>

    );
}

export default Organizations;