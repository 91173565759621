import { Card } from "react-bootstrap";
import { isMobile } from "utils/helpers";

export function CardGraph({
    um = ' ',
    label,
    value,
    round = 2,
}) {

    const loaded = typeof value !== 'undefined';
    const is_mobile = isMobile();
    return (
        <Card className={`gradient ${loaded ? '' : 'placeholder'}`} style={{ 
            height: '115px',
            width: is_mobile ? '100%' : '300px',
            flex: '1 1 auto',
        }}>
            {loaded ? <Card.Body className="py-3">
                <h3>{label}</h3>
                <h1 className="mb-0">
                    {value.toLocaleString('it-IT', { minimumFractionDigits: round, maximumFractionDigits: round })}
                    <span style={{ fontSize: '18px', verticalAlign: 'super' }}>{um} </span>
                </h1>
            </Card.Body> : ''}
        </Card>
    )
}