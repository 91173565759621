import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoAdd, IoCheckmark, IoCloudUploadOutline, IoTrash } from 'react-icons/io5';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';
import PriceMatrix from 'components/PriceMatrix';
import { toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import axios from 'axios';
import usePriceMatrix from 'hooks/usePriceMatrix';


function useOffCanvasManageProduct({ manageProduct, onUpdateProduct }) {

    const [productDetail, setProductDetail] = useState();
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);

    useEffect(() => {
        if (!manageProduct) {
            setProductDetail();
            setIsConfirmDelete();
            return
        }
        axios.get(`/products/${manageProduct.id}`, {
            params: {
                detailed: 1
            }
        })
        .then(res => setProductDetail(res.data));
    }, [manageProduct])

    const handleChangeInput = (e) => {
        const { name, value, type } = e.target;
        setProductDetail({
            ...productDetail,
            [name]: type === 'checkbox' ? !productDetail[name] : value
        });
    }

    const setPriceMatrix = (matrix) => {
        setProductDetail({
            ...productDetail,
            price_matrix: matrix
        })
    }

    const handleSaveProduct = (e) => {
        e.preventDefault();
        axios.put(`products/${productDetail.id}`, {
            parent_id: productDetail.parent_id,
            description: productDetail.description,
            description_extend: productDetail.description_extend,
            edit_action: productDetail.edit_action,
            embed: productDetail.embed,
            poll_active: +productDetail.poll_active,
            priority_active: +productDetail.priority_active,
            priority_label: productDetail.priority_active ? productDetail.priority_label : null,
            priority_price: productDetail.priority_active ? productDetail.priority_price : null,
            social_active: +productDetail.social_active,
            price_matrix: productDetail.price_matrix
        })
        .then(res => {
            onUpdateProduct(res.data);
            toast.success("Servizio aggiornato con successo!");
        })
        .catch(error => {
            toast.error("Errore durante il salvataggio.");
        });
    }

    const handleDelete = (confirm) => {
        if (!confirm) return setIsConfirmDelete(true);
        axios.delete(`products/${productDetail.id}`)
        .then(res => {
            toast.success('Prodotto eliminato con successo');
            window.location.reload();
        });
    }

    const handleChangeUpload = e => {
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        axios({
            method: 'post',
            url: `/products/${productDetail.id}/image/upload`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setProductDetail({
                ...productDetail,
                image_urls: [
                    ...productDetail.image_urls,
                    res.data.image
                ]
            });
        });
    }

    const handleRemoveUpload = (name) => {
        axios.delete(`/products/${productDetail.id}/image/delete`, {
            data: {
                name
            }
        })
        .then(res => {
            setProductDetail({
                ...productDetail,
                image_urls: productDetail.image_urls.filter(i => i.name !== name)
            });
        });
    }

    return {
        productDetail,
        handleChangeInput,
        setPriceMatrix,
        handleSaveProduct,
        isConfirmDelete,
        handleDelete,
        handleChangeUpload,
        handleRemoveUpload
    }
}

function FileUpload({
    handleChange
}) {
    return (<>
        <div className="custom-file-upload mb-1 mx-2">
            <input 
                type="file"
                id="fileuploadInput"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={handleChange} 
            />
            <label htmlFor="fileuploadInput">
                <span>
                    <strong>
                        <IoCloudUploadOutline />
                        <i className="lh-16">Tocca per caricare</i>
                    </strong>
                </span>
            </label>
        </div>
    </>);
}


function OffcanvasManageProduct(props) {

    const {
        manageProduct, 
        handleClose, 
        products
    } = props;

    const { 
        productDetail,
        handleChangeInput,
        setPriceMatrix,
        handleSaveProduct,
        isConfirmDelete,
        handleDelete,
        handleChangeUpload,
        handleRemoveUpload
    } = useOffCanvasManageProduct(props);

    const { price_matrix } = productDetail || {};
    const isSimple = !['PRIMARY', 'EXTRA'].includes(productDetail?.type);

    const { 
        handleChangeMatrix,
        handleAddRow,
        handleDeleteRow
    } = usePriceMatrix({
        priceMatrix: price_matrix,
        setPriceMatrix,
        isSimple
    });

    return (<>
        <Offcanvas placement="bottom" className="action-sheet" show={!!manageProduct} onHide={handleClose}>
            <Offcanvas.Body>
                <a 
                    className="close-btn-modal text-25 back-btn-modal text-danger"
                    onClick={() => handleDelete(isConfirmDelete)}
                >
                    {isConfirmDelete ? <IoCheckmark /> : <IoTrash />}
                </a>
                <div className="section mt-3 text-center">
                    <h3>Modifica servizio</h3>
                </div>
                <OffCanvasCloseBtn handleClose={handleClose}/>

                {!productDetail && <OffCanvasLoader />}

                <div className='section py-2'>
                    <Tabs
                        defaultActiveKey="info"
                        id="uncontrolled-tab-example"
                        className="mb-1 capsuled"
                    >
                        <Tab eventKey="info" title="Informazioni">

                            <Form className="section px-0 py-2" onSubmit={handleSaveProduct}>
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor='description'>Nome servizio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            minLength="3"
                                            maxLength="100"
                                            id="description"
                                            name="description"
                                            value={productDetail?.description || ''}
                                            onChange={handleChangeInput}
                                            required
                                        />
                                    </div>
                                </div>

                                {!isSimple && <>   
                                    <div className="form-group boxed custom">
                                        <div className="input-wrapper">
                                            <Form.Label className="label-bordered" htmlFor='parent_id'>Prodotto padre</Form.Label>
                                            <Form.Select
                                                className='form-control'
                                                value={productDetail?.parent_id || ''}
                                                id="parent_id"
                                                name="parent_id"
                                                onChange={handleChangeInput}
                                            >
                                                <option value="">NO</option>
                                                {products && productDetail && products.filter(p => p.id !== productDetail.id && p.type === productDetail.type).map(p => (
                                                    <option key={p.id} value={p.id}>{p.description}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>

                                    <div className="form-group boxed custom">
                                        <div className="input-wrapper">
                                            <Form.Label className="label-bordered" htmlFor='description_extend'>Descrizione</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={productDetail?.description_extend || ''}
                                                id="description_extend"
                                                name="description_extend"
                                                onChange={handleChangeInput}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col ps-1">
                                            <ul className="listview simple-listview no-border mt-1 mb-2 rounded form-group">
                                                <li className="form-control text-white">
                                                    <div className='lh-1'>Gestione social attiva?</div>
                                                    <FormCheck
                                                        type="switch"
                                                        label=""
                                                        id="social_active"
                                                        name="social_active"
                                                        onChange={handleChangeInput}
                                                        checked={productDetail?.social_active || false}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col pe-1">
                                            <ul className="listview simple-listview no-border mt-1 mb-2 rounded form-group">
                                                <li className="form-control text-white">
                                                    <div className='lh-1'>Attiva sondaggio?</div>
                                                    <FormCheck
                                                        type="switch"
                                                        label=""
                                                        id="poll_active"
                                                        name="poll_active"
                                                        onChange={handleChangeInput}
                                                        checked={productDetail?.poll_active || false}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row">
                                            <div className="col ps-1">
                                                <div className="form-group boxed custom">
                                                    <div className="input-wrapper">
                                                        <Form.Label className="label-bordered" htmlFor='edit_action'>Azione di editing</Form.Label>
                                                        <Form.Select
                                                            className='form-control'
                                                            value={productDetail?.edit_action || ''}
                                                            id="edit_action"
                                                            name="edit_action"
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value="">Nessuna</option>
                                                            <option value="PHOTO">Foto</option>
                                                            <option value="VIDEO">Video</option>
                                                            <option value="PLAN">Planimetria</option>
                                                            <option value="MATTERPORT">Matterport</option>
                                                        </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col pe-1">
                                            <ul className="listview simple-listview no-border mt-1 mb-2 rounded form-group">
                                                <li className="form-control text-white">
                                                    <div className='lh-1'>Consegna rapida?</div>
                                                    <FormCheck
                                                        type="switch"
                                                        label=""
                                                        id="priority_active"
                                                        name="priority_active"
                                                        onChange={handleChangeInput}
                                                        checked={productDetail?.priority_active || false}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {productDetail?.priority_active ? <div className="row">
                                        <div className="col ps-1">
                                            <div className="form-group boxed custom">
                                                <div className="input-wrapper">
                                                    <Form.Label className="label-bordered">Etichetta consegna rapida</Form.Label>
                                                    <Form.Control
                                                        minLength="3"
                                                        maxLength="50"
                                                        type="text"
                                                        value={productDetail?.priority_label || ''}
                                                        onChange={handleChangeInput}
                                                        name="priority_label"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col pe-1">
                                            <div className="form-group boxed custom">
                                                <div className="input-wrapper not-empty">
                                                    <Form.Label className="label-bordered">Prezzo consegna rapida</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={productDetail?.priority_price || ''}
                                                        min="0"
                                                        onChange={handleChangeInput}
                                                        name="priority_price"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}

                                    <div className="form-group boxed custom">
                                        <div className="input-wrapper">
                                            <Form.Label className="label-bordered" htmlFor='embed'>Embed (Youtube/Matterport)</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                id="embed"
                                                name="embed"
                                                value={productDetail?.embed || ''}
                                                onChange={handleChangeInput}
                                            />
                                        </div>
                                    </div>

                                    <Form.Label className="ms-1 mb-1 text-white">Immagini servizio:</Form.Label>

                                    <div className='d-flex'>
                                        {productDetail?.image_urls.map((image, index) => (
                                            <div className="custom-file-upload mb-1 mx-2" key={index}>
                                                <label className="file-uploaded" style={{ backgroundImage: `url("${image.url}")` }}>
                                                    <span className="text-danger py-1 fw-600" onClick={() => handleRemoveUpload(image.name)} >Rimuovi</span>
                                                </label>
                                            </div>
                                        ))}
                                        <FileUpload 
                                            handleChange={handleChangeUpload}
                                        />
                                    </div>
                                </>}

                                <div className="form-group basic">
                                    <button 
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >Modifica servizio</button>
                                </div>

                            </Form>
                        </Tab>

                        <Tab eventKey="prices" title="Tariffe">
                            
                            <Form onSubmit={handleSaveProduct}>
                                <PriceMatrix
                                    matrix={price_matrix || []}
                                    handleChangeMatrix={handleChangeMatrix}
                                    handleAddRow={handleAddRow}
                                    handleDeleteRow={handleDeleteRow}
                                    isSimple={isSimple}
                                    product={productDetail}
                                />

                                <div className="form-group basic">
                                    <button type="submit" className="btn btn-primary btn-block">Aggiorna tariffe</button>
                                </div>
                            </Form>

                        </Tab>
                    </Tabs>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasManageProduct;