import { weekDayName } from "utils/helpers";

export default function CalendarTable({
    options,
    onChange
}) {

    const dayOptions = {
        1: [false, false],
        2: [false, false],
        3: [false, false],
        4: [false, false],
        5: [false, false],
        6: [false, false],
        0: [false, false],
        ...options
    }

    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Giorno</th>
                    <th className='text-center'>Mattina</th>
                    <th className='text-center'>Pomeriggio</th>
                </tr>
            </thead>
            <tbody>
                {[1,2,3,4,5,6,0].map(week_day => (
                    <tr key={week_day}>
                        <td>{weekDayName(week_day)}</td>
                        <td>
                            <div className="form-check text-center">
                                <input 
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`week_day_morning_${week_day}`}
                                    checked={dayOptions[week_day][0]}
                                    onChange={() => onChange({
                                        ...dayOptions,
                                        [week_day]: [!dayOptions[week_day][0], dayOptions[week_day][1]]
                                    })}
                                />
                                <label className="form-check-label" htmlFor={`week_day_morning_${week_day}`}></label>
                            </div>
                        </td>
                        <td>
                            <div className="form-check text-center">
                                <input 
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`week_day_afteroon_${week_day}`}
                                    checked={dayOptions[week_day][1]}
                                    onChange={() => onChange({
                                        ...dayOptions,
                                        [week_day]: [dayOptions[week_day][0], !dayOptions[week_day][1]]
                                    })}
                                />
                                <label className="form-check-label" htmlFor={`week_day_afteroon_${week_day}`}></label>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}