import { useState } from "react"
import { filterValue } from "utils/helpers";

export function useFilterInput(bindQuery) {

    const [filterInputValue, setFilterInputValue ] = useState('');

    const filterSearchArray = item => {
        return filterValue(
            filterInputValue,
            bindQuery(item)
        ) ? item : false;
    }
    

    const handleFilterInputChange = (e) => {
        setFilterInputValue(e.target.value);
    }

    return [
        filterInputValue,
        handleFilterInputChange,
        filterSearchArray
    ]
}

export function useFilterBuilding() {
    
    return useFilterInput(building => {
        return `${building.address.full}${building.title}`;
    });
}

export function useFilterOrganization() {
    
    return useFilterInput(organization => {
        return `${organization.display_name}${organization.vat_code}${organization.fiscal_code}${organization.business_name}`;
    });
}
