import { useState } from 'react';
import { getLocalStorage } from 'utils/helpers';

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => getLocalStorage(key, initialValue));

  const setValue = (value) => {
    try {
      if (typeof value === 'undefined') {
        setStoredValue();
        return window.localStorage.removeItem(key);
      }
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;