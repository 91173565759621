import { Accordion } from "react-bootstrap";
import { AutocompleteOrganization } from "./autocomplete/Autocomplete";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import OffCanvasLoader from "./loader/OffCanvasLoader";
import { getStaffUser } from "utils/helpers";
import axios from "axios";

export default function FilterBox({
    staff,
    products,
    organizations,
    filter,
    handleChangeFilter,
}) {

    const [isLoad, setIsLoad] = useState(false);
    const [staffData, setStaffdata] = useState(null);
    const [productsData, setProductsData] = useState(null);
    const [organizationsData, setOrganizationsData] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const handleOpenAccordion = (activeIndex) => {
        const newValue = !!activeIndex;
        setTimeout(() => {
            setIsOpen(newValue);
        }, (newValue ? 0 : 340));
    }

    useEffect(() => {
        if (staff && products && organizations) {
            setStaffdata(staff);
            setProductsData(products);
            setOrganizationsData(organizations);
        }

        Promise.all([
            (staff ? Promise.resolve() : getStaffUser('photographer')),
            (staff ? Promise.resolve() : getStaffUser('editor')),
            (products ? Promise.resolve() : axios.get('products')),
            (organizations ? Promise.resolve() : axios.get('organizations?autocomplete=1'))
        ])
        .then(([resp, rese, respr, resor]) => {
            if (!staff) {
                const photographers = Object.fromEntries(
                    resp.data.map(p => ([p.photographer_id, p.display_name]))
                );
                setStaffdata({
                    p: photographers,
                    e: rese.data
                });
            }
            !products && setProductsData(respr.data);
            !organizations && setOrganizationsData(resor.data);
            setIsLoad(true);
        });
    }, [staff, products, organizations]);

    const {
        from,
        to,
        photographer_id,
        editor_id,
        product_id
    } = filter;

    return (
        <div className="section mb-2">
            <Accordion className="no-border" onSelect={handleOpenAccordion}>
                <Accordion.Item eventKey="1" className="single-accordion">
                    <Accordion.Header className={`rounded${isOpen ? '-top' : ''} overflow-hidden`}>Filtri di ricerca</Accordion.Header>
                    <Accordion.Body className="rounded-bottom">
                        {isLoad ? <div className="row g-3">
                            <div className="col-md-3">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Data inizio</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            style={{ background: '#3a3a3a' }}
                                            value={from}
                                            name="from"
                                            onChange={handleChangeFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Data fine</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            style={{ background: '#3a3a3a' }}
                                            value={to}
                                            name="to"
                                            onChange={handleChangeFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Fotografo</label>
                                        <select
                                            className="form-control form-select"
                                            style={{ background: '#3a3a3a' }}
                                            value={photographer_id}
                                            name="photographer_id"
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Tutti i fotografi</option>
                                            {staffData && Object.entries(staffData.p).map(([id, name]) => (
                                                <option
                                                    key={id}
                                                    value={id}
                                                >{name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Editor</label>
                                        <select
                                            className="form-control form-select"
                                            style={{ background: '#3a3a3a' }}
                                            value={editor_id}
                                            name="editor_id"
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Tutti gli editor</option>
                                            {staffData && staffData.e.map(({ editor, display_name }) => (
                                                <option
                                                    key={editor.id}
                                                    value={editor.id}
                                                >{display_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group boxed custom pb-0">
                                    <AutocompleteOrganization 
                                        autoload={false}
                                        organizations={organizationsData}
                                        onChange={handleChangeFilter}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group boxed custom pb-0">
                                    <div className="input-wrapper">
                                        <label className="label-bordered form-label">Servizio</label>
                                        <select
                                            className="form-control form-select"
                                            style={{ background: '#3a3a3a' }}
                                            value={product_id}
                                            name="product_id"
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Tutti i servizi</option>
                                            {productsData && productsData.map(product => (
                                                <option
                                                    key={product.id}
                                                    value={product.id}
                                                >{product.description}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="position-relative" style={{ minHeight: '152px' }}>
                            <OffCanvasLoader />
                        </div>}
                    </Accordion.Body> 
                </Accordion.Item>
            </Accordion>
        </div>);
}