import { IoChevronBackOutline } from 'react-icons/io5';

function OffCanvasBackBtn({ handleBack }) {
    return (
        <a className="back-btn-modal" onClick={handleBack}>
            <IoChevronBackOutline className="md"/>
        </a>
    );
}

export default OffCanvasBackBtn;