import { useEffect, useReducer, useState } from 'react';
//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Button from 'react-bootstrap/Button';
import OffcanvasManageProduct from './components/OffcanvasManageProduct';
import { IoAdd, IoCamera, IoExpand } from 'react-icons/io5';
import PlaceHolderItemList from 'components/loader/PlaceHolderItemList';
import { toast } from 'react-toastify';

//Utils
import axios from 'axios';

//Logic
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorderArrayItem } from 'utils/helpers';

function useProducts() {

    const [products, setProducts] = useState();
    const [manageProduct, dispatchManageProduct] = useOffCanvasReducer();


    const handleReorderProduct = (result, type) => {
        if (!result.destination) {
            return;
        }
        const newProducts = reorderArrayItem(products.filter(p => p.type === type), result.source.index, result.destination.index);
        axios.put('products-order', {
            products: newProducts.map(p => p.id)
        })
            .then(res => {
                toast.success('Ordine prodotti aggiornato con successo');
                setProducts([
                    ...products.filter(p => p.type !== type),
                    ...newProducts
                ]);
            });
    }

    const handleAddProduct = type => {
        axios.post('products', {
            type
        })
            .then(res => {
                setProducts([
                    ...products,
                    res.data
                ]);
                toast.success('Prodotto aggiunto con successo, compilare di seguito i dati');
                dispatchManageProduct(res.data);
            });
    }

    const onUpdateProduct = product => {
        setProducts(products.map((p) => {
            if (p.id !== product.id) return p;
            return product;
        }));
    }

    useEffect(() => {
        axios.get('products')
            .then(res => setProducts(res.data))
    }, []);

    return {
        products,
        manageProduct, dispatchManageProduct,
        handleReorderProduct,
        handleAddProduct,
        onUpdateProduct
    }
}

function ProductItem({ products, product, onEditProduct, isSkeleton }) {

    return (
        <div className="item">
            <div className="icon-box me-05">
                {!product.parent_id && <IoCamera />}
            </div>
            {isSkeleton ? <PlaceHolderItemList /> : <>
                <div className="in">
                    <div>
                        <span>
                            {product.parent_id && <>
                                <div className='text-active text-12 lh-1 mb-05'>{products.find(p => p.id === product.parent_id).description}</div>
                            </>}
                            {product.description}
                        </span>
                    </div>
                    <div style={{ minWidth: '120px', textAlign: 'right' }}>
                        <Button
                            size="sm"
                            className='btn-light'
                            onClick={() => onEditProduct(product)}
                        >
                            Modifica
                        </Button>
                        <IoExpand className='ms-1 cursor-drag' style={{ fontSize: '20px' }} />
                    </div>
                </div>
            </>}
        </div>
    );
}

function AddProduct({ onClick, title }) {
    return (
        <li className='cursor-pointer' onClick={onClick}>
            <div className="item">
                <div className="icon-box bg-success">
                    <IoAdd />
                </div>
                <div className="in text-success">
                    <div className="fw-500">Aggiungi servizi {title}</div>
                </div>
            </div>
        </li>
    );
}

function ProductSection({
    products,
    title,
    type,
    onEditProduct,
    handleReorderProduct,
    handleAddProduct = null,
    isSkeleton
}) {

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        background: isDragging ? "#000" : "#292929",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <div className={`section ${isSkeleton ? 'no-pointer-events' : ''}`}>
            <div className="section-title">
                <h4 className="card-subtitle text-white mt-1 text-12">SERVIZI {title}:</h4>
            </div>
            <DragDropContext onDragEnd={result => handleReorderProduct(result, type)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="listview image-listview rounded"
                        >
                            {handleAddProduct && <AddProduct title={title} onClick={() => !isSkeleton && handleAddProduct(type)} />}
                            {products && products.filter((p, index) => p.type === type || p.isSkeleton)
                                .map((product, index) => {
                                    return <Draggable key={index} draggableId={'p' + index} index={index}>
                                        {(provided, snapshot) => (
                                            <li
                                                key={product.id}
                                                className='rounded'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <ProductItem
                                                    key={product.id}
                                                    product={product}
                                                    onEditProduct={onEditProduct}
                                                    products={products}
                                                    isSkeleton={product.isSkeleton}
                                                />
                                            </li>
                                        )}
                                    </Draggable>
                                })}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    )
}

export default function Products() {

    const {
        products,
        manageProduct, dispatchManageProduct,
        handleReorderProduct,
        handleAddProduct,
        onUpdateProduct
    } = useProducts();

    const isSkeleton = !products;
    const productsArray = products ?? new Array(5).fill({ isSkeleton: true });

    return (
        <>
            <Header pageTitle={'Gestione servizi'} />
            <Capsule>
                <ProductSection
                    title="principali"
                    type="PRIMARY"
                    products={productsArray}
                    onEditProduct={dispatchManageProduct}
                    handleReorderProduct={handleReorderProduct}
                    handleAddProduct={handleAddProduct}
                    isSkeleton={isSkeleton}
                />
                <ProductSection
                    title="extra"
                    type="EXTRA"
                    products={productsArray}
                    onEditProduct={dispatchManageProduct}
                    handleReorderProduct={handleReorderProduct}
                    handleAddProduct={handleAddProduct}
                    isSkeleton={isSkeleton}
                />
                <ProductSection
                    title="planimetrie"
                    type="PLAN"
                    products={productsArray}
                    onEditProduct={dispatchManageProduct}
                    handleReorderProduct={handleReorderProduct}
                    isSkeleton={isSkeleton}
                />
                <ProductSection
                    title="rendering"
                    type="RENDER"
                    products={productsArray}
                    onEditProduct={dispatchManageProduct}
                    handleReorderProduct={handleReorderProduct}
                    isSkeleton={isSkeleton}
                />
                <ProductSection
                    title="credito"
                    type="CREDIT"
                    products={productsArray}
                    onEditProduct={dispatchManageProduct}
                    handleReorderProduct={handleReorderProduct}
                    isSkeleton={isSkeleton}
                />
            </Capsule>
            <OffcanvasManageProduct
                manageProduct={manageProduct}
                handleClose={() => dispatchManageProduct('CLOSE')}
                products={products}
                onUpdateProduct={onUpdateProduct}
            />
        </>

    );
}
