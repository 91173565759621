import axios from "axios";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import useUrlAction from "hooks/useUrlAction";
import { useEffect, useState } from "react";
import { deepCopy, getUrlParam } from "utils/helpers";

export default function useUsers() {

    const [users, setUsers] = useState();
    const [showOffCanvasAddUser, dispatchAddUser] = useOffCanvasReducer();
    const [editUser, dispatchEditUser] = useOffCanvasReducer();

    useUrlAction({
        show: [dispatchEditUser, [getUrlParam('id', true)]]
    });

    useEffect(() => {
        axios.get('/users')
        .then(res => setUsers(res.data));
    }, []);

    const onAddUser = user => {
        const updateUsers = deepCopy(users);
        updateUsers.push(user);
        setUsers(
            updateUsers.sort((a, b) => (a.display_name - b.display_name))
        );
        dispatchEditUser(user.id);
    }

    const onUpdateUser = user => {
        setUsers(
            users.map(u => {
                if (u.id === user.id) {
                    return {...u, ...user};
                }
                return u;
            }).sort((a, b) => (a.display_name - b.display_name))
        );
    }

    return {
        users,
        editUser, dispatchEditUser,
        showOffCanvasAddUser, dispatchAddUser,
        onAddUser,
        onUpdateUser
    }

}