export const cartSetData = (data) => ({
    type: 'CART_SET_DATA',
    payload: data,
});

export const cartNextStep = () => ({
    type: 'CART_NEXT_STEP',
});

export const cartPreviousStep = () => ({
    type: 'CART_PREVIOUS_STEP',
});

export const cartAddProduct = (data) => ({
    type: 'CART_ADD_PRODUCT',
    payload: data,
});

export const cartEmpty = () => ({
    type: 'CART_EMPTY',
});

export const cartSetRenderTemplate = () => ({
    type: 'CART_SET_RENDER_TEMPLATE',
});

export const cartSetRender = (data) => ({
    type: 'CART_SET_RENDER',
    payload: data,
});