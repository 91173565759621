import Slider from "components/Slider";
import moment from "moment";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { isMobile } from "utils/helpers";

function useWeekDayNavigator(props) {
    const { 
        activeDate,
        onDayClick,
    } = props;

    const [weeksDays, setWeeksDays] = useState();

    const momentActiveDate = useMemo(() => activeDate ? moment(activeDate) : null, [activeDate]);
    const slideValue = useMemo(() => {
        if (!weeksDays || !momentActiveDate) return;
        const dayOfWeek = momentActiveDate.weekday();
        const activeMonday = momentActiveDate.clone().subtract(dayOfWeek, 'days');
        const value = weeksDays.findIndex(wd => wd[0].isSame(activeMonday, 'day'));
        return value + 1;
    }, [weeksDays, momentActiveDate]);

    useEffect(() => {
        const generateWeek = (date) => {
            const daysOfWeek = [];
            for (let i = 0; i < 7; i++) {
                daysOfWeek.push(date.clone().add(i, 'days'));
            }
            return daysOfWeek;
        };

        const date =  moment();
        const dayOfWeek = date.weekday();
        const monday = date.subtract(dayOfWeek, 'days').subtract(40, 'weeks');
        const weekArray = [];
        for(let i=0;i<80;i++) {
            weekArray.push(
                generateWeek(monday.add(1, 'weeks'))
            )
        }
        setWeeksDays(weekArray);
    }, []);

    const handleChangeSlide = ({ currSlide }) => {
        if (!momentActiveDate) return;
        const week = weeksDays[currSlide - 1];
        let dayOfWeek = momentActiveDate.weekday();
        onDayClick(week[dayOfWeek].toDate());
    }

    return {
        weeksDays,
        momentActiveDate,
        handleChangeSlide,
        slideValue
    };
}

function WeekDayNavigator(props) {

    const {
        viewTitle,
        navToday,
        navPrev,
        navNext,
        onDayClick,
        isLoading,
        isSingleDay,
        leftActionButtons = [],
        rightActionButtons = []
    } = props;

    const {
        weeksDays,
        momentActiveDate,
        handleChangeSlide,
        slideValue
    } = useWeekDayNavigator(props);

    const today = moment();

    return <>
        <div className="cal-topbar fix-space">
            <Dropdown className="d-block d-sm-none">
                <Dropdown.Toggle id="dropdown-basic" className='btn-sm btn-primary ellipsis'>
                    <IoEllipsisVerticalOutline className="me-0" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {leftActionButtons.map((btn, index) => ( 
                        <Dropdown.Item key={index} onClick={btn.handleClick}>
                            {btn.label}
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Item onClick={navToday}>Oggi</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div className="d-none d-sm-inline">
                {[
                    ...leftActionButtons, { handleClick: navToday, label: 'Oggi' },].map((btn, index) => (
                    <button key={index} onClick={btn.handleClick} className="d-none d-sm-inline fc-today-button btn btn-primary btn-sm text-14 me-1">
                        {btn.icon ?? btn.label}
                    </button>
                ))}
            </div>
            <div className='align-self-center fw-600 text-16 text-capitalize text-center'>{viewTitle}</div>
            <div className="text-end">
                {(!isSingleDay || !isMobile()) && (
                    <div className="btn-group ms-1">
                        <button className={`fc-prev-button btn btn-primary btn-sm px-1`}  onClick={navPrev}>
                            <FaChevronLeft className='me-0 text-16'/>
                        </button>
                        <button className="fc-next-button btn btn-primary btn-sm px-1" onClick={navNext}>
                            <FaChevronRight className='me-0 text-16'/>
                        </button>
                    </div>
                )}
                {rightActionButtons.map((btn, index) => (
                    <button key={index} onClick={btn.handleClick} className="d-sm-inline fc-today-button btn btn-primary btn-sm text-14 ms-1">
                        {btn.icon ?? btn.label}
                    </button>
                ))}
            </div>
        </div>
        {isSingleDay && <Slider 
            defaultValue={slideValue || 10}
            onChange={handleChangeSlide}
            blockScrolling={isLoading}
        >
            {weeksDays && weeksDays.map((weekDays, index) => (
                <Slider.Slide 
                    key={index}
                >
                    <div className="day-navigator">
                        {weekDays.map(wd => (
                            <div 
                                className={`weekday`}
                                key={wd}
                            >
                                {wd.format('dddd').charAt(0).toUpperCase()}
                            </div>
                        ))}
                        {weekDays.map(wd => (
                            <div 
                                key={wd}
                                className={`day ${wd.isSame(today, 'day') ? 'current' : ''} ${momentActiveDate && wd.isSame(momentActiveDate, 'day') ? 'active' : ''}`}
                                onClick={() => onDayClick(wd.toDate())}
                            >{wd.format('DD')}</div>
                        ))}
                    </div>
                </Slider.Slide>
            ))}
        </Slider>}
    </>
}

export default WeekDayNavigator;