import { useEffect, useState } from "react";
import { useAuthValue } from 'context/AuthContext';
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import { loadLaravelUser } from "utils/helpers";
import useLocalStorage from "hooks/useLocalStorage";

export function useHomeAgent() {
    const [showOffCanvasAddBuilding, setShowOffCanvasAddBuilding] = useState(false);
    const [showOffCanvasAddFunds, dispatchShowOffCanvasAddFunds] = useOffCanvasReducer();
    const [showOffCanvasHistoryFunds, dispatchShowOffCanvasHistoryFunds] = useOffCanvasReducer();
    const { setUser } = useAuthValue();

    useEffect(() => {
        loadLaravelUser().then(u => setUser(u));
    }, []);

    return {
        showOffCanvasAddBuilding, setShowOffCanvasAddBuilding,
        showOffCanvasAddFunds, dispatchShowOffCanvasAddFunds,
        showOffCanvasHistoryFunds, dispatchShowOffCanvasHistoryFunds
    }
}

export function useHome() {

    const [showRequestNotifications, dispatchShowRequestNotifications] = useOffCanvasReducer();
    const [notificationDenied] = useLocalStorage('notification_permission_denied');
    const { user, notification } = useAuthValue();
    const { notificationPermission } = notification;

    useEffect(() => {
        setTimeout(() => {
            if (notificationPermission !== null || notificationDenied === true) return;
            dispatchShowRequestNotifications('OPEN');
        }, 1000);
    }, [notificationPermission]);

    return {
        user,
        showRequestNotifications, dispatchShowRequestNotifications
    }

}