import { useState, useEffect } from 'react';
//Components
import Offcanvas from 'react-bootstrap/Offcanvas';
import PlaceHolderItemList from 'components/loader/PlaceHolderItemList';
import { 
    IoArchiveOutline, 
    IoCallOutline, 
    IoCloseOutline, 
    IoCreateOutline, 
    IoExpandOutline,
    IoLeafOutline, 
    IoMailOutline, 
    IoPhonePortraitOutline, 
    IoTrashOutline 
} from 'react-icons/io5';

import { 
    StepInfoFirst as StepEditInfoFirst,
    StepInfoSecond as StepEditInfoSecond,
    StepColor as StepEditColor
} from 'components/offcanvas/add-activity/OffcanvasAddActivity';
import axios from 'axios';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import OffCanvasBackBtn from 'components/offcanvas/OffCanvasBackBtn';

function useOffcanvasViewOrganization(props) {

    const { 
        organizationId,
        onUpdateData
    } = props;

    const [organizationData, setOrganizationData] = useState();
    const [step, setStep] = useState('view');
    const [organizationTypes, setOrganizationsTypes] = useState();
    const [colors, setColors] = useState();
    const [editStepInfoFirst, setEditStepInfoFirst] = useState();
    const [editStepInfoSecond, setEditStepInfoSecond] = useState();
    const [editStepColor, setEditStepColor] = useState();
    const [isInProgress, setIsInProgress] = useState(false);
    //const [headerImage, setHeaderImage] = useState();

    useEffect(() => {
        if (!organizationId) return handleCloseOffCanvas();
        axios.get(`organizations/${organizationId}`)
        .then(res => {
            setOrganizationData(res.data)
            // imageURLToBase64(res.data.image_url)
            // .then(image => {
            //     setHeaderImage(image);
            // })
            // .catch(error => console.log(error))
            // .finally(() => {
            //     setOrganizationData(res.data)
            // });
        })
    }, [organizationId]);

    const handleCloseOffCanvas = () => {
        setStep('view');
        setOrganizationData();
        //setHeaderImage();
    }

    const updateOrganizationData = (updateData) => {
        axios.put(`organizations/${organizationId}`, updateData)
        .then(res => {
            setOrganizationData({
                ...organizationData,
                ...res.data
            });
            onUpdateData(res.data);
            setIsInProgress(false);
            setStep('view');
        });
    }

    const handleEditInfoFirst = async () => {
        if (!organizationTypes) {
            const organizationsTypesRes = await axios.get('organizations-types');
            setOrganizationsTypes(organizationsTypesRes.data);
        }
        setEditStepInfoFirst({
            organizationType: organizationData.organization_type,
            display_name: organizationData.display_name,
            address: organizationData.address,
            coords: organizationData.coords,
            phone: organizationData.phone,
            email: organizationData.email,

        });
        setStep('edit_info_first');
    }

    const handleSaveInfoFirst = () => {
        setIsInProgress(true);
        const updateData = { 
            organization_type_id: editStepInfoFirst.organizationType.id,
            ...editStepInfoFirst,
        }
        delete updateData.organizationType;
        updateOrganizationData(updateData);
    }

    const handleEditInfoSecond = async () => {
        setEditStepInfoSecond({
            business_name: organizationData.business_name,
            first_name: organizationData.first_name,
            last_name: organizationData.last_name,
            vat_code: organizationData.vat_code,
            fiscal_code: organizationData.fiscal_code,
            pec_sdi: organizationData.pec_sdi
        });
        setStep('edit_info_second');
    }

    const handleSaveInfoSecond = () => {
        setIsInProgress(true);
        updateOrganizationData(editStepInfoSecond);
    }

    const handleEditColor = async () => {
        if (!colors) {
            const colorsRes = await axios.get('colors');
            setColors(colorsRes.data);
        }
        setEditStepColor({
            color: organizationData.color,
        });
        setStep('edit_color');
    }

    const handleSaveColor = () => {
        setIsInProgress(true);
        updateOrganizationData({
            color_id: editStepColor.color.id
        });
    }

    const stepBefore = () => setStep('view');

    return {
        organizationData,
        step,
        stepBefore,
        isInProgress,
        organizationTypes,
        colors,
        editStepInfoFirst, setEditStepInfoFirst, handleEditInfoFirst, handleSaveInfoFirst,
        editStepInfoSecond, setEditStepInfoSecond, handleEditInfoSecond, handleSaveInfoSecond,
        editStepColor, setEditStepColor, handleEditColor, handleSaveColor
    }

}

function StepViewOrganization(props) {
    
    const {
        organizationData,
        handleClose,
        handleEditInfoFirst,
        handleEditInfoSecond,
        handleEditColor
    } = props;

    return <>
        <div className="section mt-3 text-center">
            <h3>Dettagli attività</h3>
        </div>
        <OffCanvasCloseBtn handleClose={handleClose}/>
        <div className="action-sheet-content py-2 px-0 noarrow">

            <div className="listview-title fw-700 pb-0">
                Informazioni attività:
                <a className="active fw-500" onClick={handleEditInfoFirst}>
                    <IoCreateOutline className="edit-btn-icon" /> Modifica
                </a>
            </div>

            <ul className="listview image-listview no-border">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Tipo attività</header>
                                {organizationData ? organizationData.organization_type.description :
                                    <span className="placeholder" style={{ width: '100px'}} />
                                }
                            </div>
                        </div>
                    </div>
                </li>
                {!organizationData?.organization_type.is_private && <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Nome attività</header>
                                {organizationData ? organizationData.display_name :
                                    <span className="placeholder" style={{ width: '200px'}} />
                                }
                            </div>
                        </div>
                    </div>
                </li>}
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Indirizzo</header>
                                {organizationData ? organizationData.address.full :
                                    <span className="placeholder" style={{ width: '340px'}} />
                                }
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                <header>Recapiti</header>
                                {organizationData ? <>
                                    <IoCallOutline className='vertical-align-n2'/> {organizationData.phone}
                                    <IoMailOutline className='vertical-align-n2 ms-3' /> {organizationData.email}
                                </> : 
                                    <span className="placeholder" style={{ width: '250px'}} />
                                }
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <hr />

            <div className="listview-title fw-700 py-0">
                Dati fatturazione:<a className="active fw-500" onClick={handleEditInfoSecond}>
                <IoCreateOutline className="edit-btn-icon" /> Modifica</a>
            </div>

            <ul className="listview image-listview no-border">
                {organizationData ? <>
                    {organizationData.organization_type.is_private ? <>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>Nominativo</header>
                                        {organizationData ? `${organizationData.last_name} ${organizationData.first_name}` :
                                            <span className="placeholder" style={{ width: '100px'}} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                    </> : <>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>Ragione sociale</header>
                                        {organizationData ? organizationData.business_name :
                                            <span className="placeholder" style={{ width: '100px'}} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>Partita IVA</header>
                                        {organizationData ? organizationData.vat_code :
                                            <span className="placeholder" style={{ width: '100px'}} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                    </>}
                    <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header>Codice fiscale</header>
                                    {organizationData ? organizationData.fiscal_code :
                                        <span className="placeholder" style={{ width: '100px'}} />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                    {!organizationData.organization_type.is_private && <li>
                        <div className="item">
                            <div className="in">
                                <div>
                                    <header>Codice univoco/PEC</header>
                                    {organizationData ? organizationData.pec_sdi :
                                        <span className="placeholder" style={{ width: '100px'}} />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>}
                </> : <>
                    {new Array(4).fill(null).map((_,i) => (
                        <li key={i}>
                            <div className="item">
                                <PlaceHolderItemList />
                            </div>
                        </li>
                    ))}
                </>}
            </ul>

            <hr />
            
            <div className="listview-title fw-700 pt-0">
                Colore calendario:
                <a className="active fw-500" onClick={handleEditColor}>
                    <IoCreateOutline className="edit-btn-icon" /> Modifica
                </a>
            </div>

            <div id="color-list" className='ps-2 mb-1'>
                {organizationData ? <>
                    <span style={{ backgroundColor: `#${organizationData.color.hex}` }} className="color-box"></span>
                    {organizationData.color.description}
                </> : <span className="placeholder" style={{ width: '100px'}}></span>}
            </div>

        </div>
    </>;
}


export default function OffcanvasViewOrganization(props) {

    const {
        organizationId,
        handleClose
    } = props;

    const {
        organizationData,
        step, 
        stepBefore,
        isInProgress,
        organizationTypes,
        colors,
        editStepInfoFirst, setEditStepInfoFirst, handleEditInfoFirst, handleSaveInfoFirst,
        editStepInfoSecond, setEditStepInfoSecond, handleEditInfoSecond, handleSaveInfoSecond,
        editStepColor, setEditStepColor, handleEditColor, handleSaveColor
    } = useOffcanvasViewOrganization(props);

    const { organization_type } = organizationData || {};

    return (<>
        <Offcanvas 
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={!!organizationId}
            onHide={isInProgress ? () => {} : handleClose}
        >
            <Offcanvas.Body>
                {step === 'view' ? <StepViewOrganization 
                    {...{
                        organizationData,
                        handleClose,
                        handleEditInfoFirst,
                        handleEditInfoSecond,
                        handleEditColor
                    }}
                /> : <>
                    <OffCanvasBackBtn handleBack={stepBefore} />
                    <div className="section mt-3 text-center">
                        <h3>Modifica attività</h3>
                    </div>
                    <div className="action-sheet-content py-1">
                        {step === 'edit_info_first' && <StepEditInfoFirst
                            handleSubmit={handleSaveInfoFirst}
                            stepInfoFirst={editStepInfoFirst}
                            setStepInfoFirst={setEditStepInfoFirst}
                            organizationTypes={organizationTypes}
                            isInProgress={isInProgress}
                            nextStep={handleSaveInfoFirst}
                            isEdit
                        />}
                        {step === 'edit_info_second' && <StepEditInfoSecond
                            handleSubmit={handleSaveInfoSecond}
                            stepInfoFirst={{
                                organizationType: organization_type
                            }}
                            stepInfoSecond={editStepInfoSecond}
                            setStepInfoSecond={setEditStepInfoSecond}
                            isInProgress={isInProgress}
                            nextStep={handleSaveInfoSecond}
                            isEdit
                        />}
                        {step === 'edit_color' && <StepEditColor
                            stepInfoColor={editStepColor}
                            setStepInfoColor={setEditStepColor}
                            isInProgress={isInProgress}
                            insertOrganization={handleSaveColor}
                            colors={colors}
                            isEdit
                        />}
                    </div>
                </>}
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}
