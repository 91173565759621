export const getProducts = (params) => ({
    type: 'API_PRODUCTS_GET',
    payload: params,
});

export const setProducts = (params, data) => ({
    type: 'PRODUCTS_SET',
    payload: {
        params, 
        data
    },
});

export const resetProducts = () => ({
    type: 'PRODUCTS_RESET',
});