import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import useUrlAction from "hooks/useUrlAction";
import { getUrlParam } from "utils/helpers";


export default function useBuildings() {

    const [buildings, setBuildings] = useState();
    const [showOffCanvasAddBuilding, setShowOffCanvasAddBuilding] = useState(false);
    const [selectedBuildingId, setSelectedBuildingId] = useState();
    const [organizationSelected, setOrganizationSelected] = useState();
    const { user } = useAuthValue();
    const [paramOrganizationId] = useState(getUrlParam('organization_id'));

    useUrlAction({
        show: [setSelectedBuildingId, [getUrlParam('id', true)]]
    });

    useEffect(() => {
        if (buildings || !user) return;
        axios.get('buildings', {
            params: {
                organization_id: paramOrganizationId ?? user.agent?.getSelectedOrganization().id
            }
        })
        .then(res => {
            setBuildings(res.data);
            if (paramOrganizationId) {
                axios.get(`/organizations/${paramOrganizationId}`)
                .then(res => {
                    setOrganizationSelected(res.data);
                });
            }
        });
    }, [paramOrganizationId, buildings, user]);
    
    const handleBuildingAdd = (buildingData) => {
        setBuildings([
            buildingData,
            ...buildings
        ]);
    }

    const handleClickDetail = (building_id) => {
        setSelectedBuildingId(building_id);
    }

    const handleCloseViewBuilding = () => {
        setSelectedBuildingId();
    }

    const handleUpdateData = editBuilding => {
        setBuildings(
            buildings.map(building => {
                if (+building.id === +editBuilding.id) {
                    building.reference = editBuilding.reference;
                    building.owner_first_name = editBuilding.owner_first_name;
                    building.owner_last_name = editBuilding.owner_last_name;
                }
                return building;
            })
        )
    };

    const handleDeleteData = building_Id => {
        setBuildings(
            buildings.filter(building => {
                return +building.id !== +building_Id;
            })
        )
    }

    return { 
        showOffCanvasAddBuilding, setShowOffCanvasAddBuilding,
        handleCloseViewBuilding,
        handleBuildingAdd,
        buildings,
        handleClickDetail,
        selectedBuildingId,
        handleUpdateData,
        handleDeleteData,
        organizationSelected
    };

}