import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import Button from 'react-bootstrap/Button';
import { useAuthValue } from 'context/AuthContext';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import { convertIfHEIC } from 'utils/helpers';
import { IoCloudUploadOutline } from 'react-icons/io5';

function useOffcanvasEditProfile({
    show,
    handleClose
}) {
    const { user, setUser } = useAuthValue();
    const [formData, setFormData] = useState();
    const [isInProgess, setIsInProgess] = useState(false);

    useEffect(() => {
        if (!show) {
            setFormData();
            setIsInProgess(false);
            return;
        }
        setFormData({
            display_name: user.display_name,
            phone: user.phone
        });
    }, [show]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsInProgess(true);
        axios.put('/user', formData)
        .then(res => {
            setUser({
                ...user,
                ...res.data
            });
            toast.success('Profilo aggiornato con successo');
            handleClose();
        })
        .finally(() => setIsInProgess(false));
    }

    const handleUpload = (e) => {
        const uploadData = new FormData();
        setIsInProgess(true);
        convertIfHEIC(e.target.files[0])
        .then(file => {
            uploadData.append('image', file);
            axios({
                method: 'post',
                url: `/user-avatar`,
                data: uploadData,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(res => {
                toast.success('Avatar aggiornato con successo');
                setUser({
                    ...user,
                    avatar_url: res.data.avatar_url
                })
                handleClose();
            })
            .catch(() => {
                toast.error('Errore durante il caricamento dell\'avatar');
            })
            .finally(() => setIsInProgess(false))
        })
    }

    return {
        user,
        formData,
        handleChange,
        handleSubmit,
        handleUpload,
        isInProgess
    }
}

function OffcanvasEditProfile(props) {


    const {
        show,
        handleClose
    } = props;

    const { 
        user, 
        formData,
        handleChange,
        handleSubmit,
        handleUpload,
        isInProgess
    } = useOffcanvasEditProfile(props);

    return (<>
        <Offcanvas placement="bottom" id="view-property" className="action-sheet" show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose}/>
                <div className="section mt-3 text-center">
                    <h3>Modifica profilo</h3>
                </div>
                <div className="action-sheet-content py-2">
                    <Form onSubmit={handleSubmit}>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <label className="form-label" for="email">Email</label>
                                <input 
                                    type="email" 
                                    id="email" 
                                    value={user.email}
                                    className="form-control" 
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="display_name">Nominativo</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="display_name"
                                    minLength="3"
                                    value={formData?.display_name ?? ''}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    required
                                    disabled={isInProgess}
                                />
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <label className="form-label" for="phone">Telefono</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={formData?.phone ?? ''}
                                    autoComplete="off"
                                    name="phone"
                                    pattern="[0-9]*"
                                    onChange={handleChange}
                                    required
                                    disabled={isInProgess}
                                />
                            </div>
                        </div>
                        <label className="form-label ms-1 mb-1" for="avatar-edit">Cambia immagine profilo:</label>
                        <div className="custom-file-upload mb-1" id="avatar-edit">
                            <input 
                                type="file" 
                                id="fileuploadInput" 
                                accept=".png, .jpg, .jpeg, .heic" 
                                onChange={handleUpload}
                                disabled={isInProgess}
                            />
                            <label for="fileuploadInput">
                                <span>
                                    <strong>
                                         <IoCloudUploadOutline />
                                        <i>Tocca per caricare</i>
                                    </strong>
                                </span>
                            </label>
                        </div>
                        <div className="form-group basic">
                            <Button
                                className={`btn-block ${isInProgess ? 'disabled' : ''}`}
                                type="submit"
                            >
                                <SpinnerForButton show={isInProgess} />
                                Modifica
                            </Button>
                        </div>
                    </Form>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasEditProfile;