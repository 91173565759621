import useApp from './useApp';
import { Routes, Route, useLocation } from "react-router-dom";
import './assets/scss/style.scss';

//Componenets
import Auth from './pages/auth/Auth';
import Home from './pages/home/Home';
import Welcome from './pages/welcome/Welcome';
import LoaderFullScreen from 'components/loader/LoaderFullScreen';
import OrderHistory from './pages/history/History';
import ReservationNew from './pages/reservation/new/ReservationNew';
import Organizations from './pages/organizations/Organizations';
import Buildings from 'pages/buildings/Buildings';
import Profile from 'pages/profile/Profile';
import Users from 'pages/users/Users';
import Zones from 'pages/zones/Zones';
import Products from 'pages/products/Products';
import Notifications from 'pages/notifications/Notifications';
import AccountDisabled from 'components/AccountDisabled';
import AdminCalendar from 'pages/admin-calendar/AdminCalendar';
import CalendarUser from 'pages/calendar-user/CalendarUser';
import PostProduction from 'pages/post-production/PostProduction';
import PostproductionAdmin from 'pages/post-production/PostProductionAdmin';
import Settings from 'pages/settings/Settings';
import WindowClose from 'components/WindowClose';
import SocialPlanning from 'pages/social-planning/SocialPlanning';
import Plan from 'pages/plan/Plan';
import Render from 'pages/render/request/Render';
import StatsEditor from 'pages/stats/StatsEditor';

//Context
import { AuthProvider } from './context/AuthContext';

//Security
import { 
    VerifiedUserRoute,
    AuthUserRoute,
    AgentRoute,
    AdminRoute,
    EditorRoute,
    SocialManagerRoute,
    PhotographerRoute,
    OnboardingRoute,
    AccountantRoute
} from 'SecurityRoutes';

//Transition
import DialogBox from 'components/DialogBox';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { selectAppLoader } from 'store/selectors';
import ViewOrder from 'pages/render/mange/RenderManage';
import RenderManage from 'pages/render/mange/RenderManage';
import StatsDashboard from 'pages/stats/StatsDashboard';
import CalculateTransfer from 'pages/calculate-transfer/CalculateTransfer';

function App() {

  const {
    user, setUser,
    firebaseUser, setFirebaseUser,
    loaded, notificationLogic
  } = useApp();

  const { isAppLoading } = useSelector(selectAppLoader);

  return (
    <AuthProvider value={{ user, setUser, firebaseUser, setFirebaseUser, notification: notificationLogic }}>
      <div className="App">
        {loaded ?
          <>
            {isAppLoading && <LoaderFullScreen />}
            {user && !user.roles.length ? <AccountDisabled /> : <>
              <Routes>
                <Route path="/register" element={<Auth.Register />} />
                <Route path="/login" element={<Auth.Login />} />
                <Route path="/onboarding" element={<Welcome.User />} />
                <Route path="/window-close" element={<WindowClose />} />
                <Route path="/" element={<OnboardingRoute />}>
                  {/* Only logged user */}
                  <Route path="/" element={<AuthUserRoute />}>
                    <Route path="/confirm-account" element={<Auth.ConfirmAccount />} />
                    {/* Only verified user */}
                    <Route path="/" element={<VerifiedUserRoute />}>
                      <Route path="/" element={<Home />} />
                      <Route path="/calendar" element={<CalendarUser />} />
                      <Route path="/notifications" element={<Notifications />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/reservation/new/:buildingId" element={<ReservationNew />} />
                        {/* Only admin*/}
                      <Route path="/" element={<AdminRoute />}>
                        <Route path="/admin/buildings" element={<Buildings />} />
                        <Route path="/admin/organizations" element={<Organizations />} />
                        <Route path="/admin/orders" element={<OrderHistory />} />
                        <Route path="/admin/calculate-transfer" element={<CalculateTransfer />} />
                        <Route path="/admin/users" element={<Users />} />
                        <Route path="/admin/zones" element={<Zones />} />
                        <Route path="/admin/products" element={<Products />} />
                        <Route path="/admin/calendar" element={<AdminCalendar />} />
                        <Route path="/admin/post-production" element={<PostproductionAdmin/>} />
                        <Route path="/admin/settings" element={<Settings/>} />
                        <Route path="/stats/editor" element={<StatsEditor />} />
                        <Route path="/stats/dashboard" element={<StatsDashboard />} />
                      </Route>
                      {/* Only agent user */}
                      <Route path="/" element={<AgentRoute />}>
                        <Route path="/welcome-agent" element={<Welcome.Agent />} />
                        <Route path="/buildings" element={<Buildings />} />
                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/reservation/:appointmentId/edit" element={<ReservationNew />} />
                        <Route path="/orders" element={<OrderHistory />} />
                        <Route path="/plan" element={<Plan />} />
                        <Route path="/render" element={<Render />} />
                      </Route>
                      {/* Only ph user */}
                      <Route path="/" element={<PhotographerRoute />}>
                        <Route path="/post-production-p" element={<PostProduction pageRole="photographer"/>}/>
                        <Route path="/home/photographer" element={<Home.Photographer />}/>
                      </Route>
                      <Route path="/" element={<EditorRoute />}>
                        <Route path="/post-production-e" element={<PostProduction pageRole="editor"/>}/>
                      </Route>
                      <Route path="/" element={<SocialManagerRoute />}>
                        <Route path="/social-planning" element={<SocialPlanning />}/>
                      </Route>
                      <Route path="/render-manage/:orderId" element={<RenderManage />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </>}
            <DialogBox />
            <ToastContainer 
              position={`${isMobile() ? 'bottom' : 'top'}-center`}
              autoClose={2000}
              hideProgressBar
              theme="colored"
              transition={Slide}
            />
          </>
        : <LoaderFullScreen />}
      </div>
    </AuthProvider>
  );
}

export default App;
