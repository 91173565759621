import { useAuthValue } from "context/AuthContext";
import { Accordion, Button } from "react-bootstrap";
import { IoCardOutline, IoWalletOutline } from "react-icons/io5";
import { numb } from "utils/helpers";
import SpinnerForButton from "./loader/SpinnerForButton";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import OffCanvasAddFunds from "./offcanvas/OffCanvasAddFunds";

import amexImage from 'pages/reservation/new/checkout/img/amex.svg';
import visaImage from 'pages/reservation/new/checkout/img/visa.svg';
import mastercardImage from 'pages/reservation/new/checkout/img/mastercard.svg';

export default function Checkout({
    cartItems,
    isInProgress,
    isCartInProgress,
    completeCheckout
}) {
    
    const [showOffCanvasAddFunds, dispatchShowOffCanvasAddFunds] = useOffCanvasReducer();
    const { user } = useAuthValue();
    const { credit = null, subscription = null} = user.agent?.getSelectedOrganization();
    const order_total = cartItems.price;
    const order_total_vati = order_total * 1.22;
    const order_discount = order_total * (subscription?.discount_percentage || 0) / 100;
    const real_cart_total = order_total - (subscription ? order_discount : 0);
    const real_cart_total_vi = real_cart_total * 1.22;

    return <>
        <div className="section inset mt-2">
            {order_total_vati > 0 && <div className="section-title">
                <h4 className="card-subtitle text-white text-12 mb-0">Pagamento</h4>
            </div>}

            {order_total_vati > 0 && <Accordion defaultActiveKey="CREDIT" className='text-14 accordion-payments'>
                <Accordion.Item eventKey="CREDIT">
                    <Accordion.Header>
                        <IoWalletOutline /> <b>Utilizza credito</b> {subscription && <span className='ms-1 badge bg-primary'>SCONTO {subscription.description}</span>}
                    </Accordion.Header>
                    <Accordion.Body className='text-white'>
                        Paga rapidamente utilizzando il credito dell'attività.{' '}

                        {credit >= order_total_vati ? (
                            <>
                                Al momento il tuo saldo è di {numb(credit)}€. Procedendo con la conferma della prenotazione il nuovo saldo diventerà {numb(credit - real_cart_total_vi)}€.&nbsp;

                                <ul className="listview simple-listview text-14 mt-1 mb-2 border-0 checkout">
                                    {subscription && <>
                                        <li> 
                                            Totale ordine (iva esclusa)
                                            <span className="text-muted">
                                                <span className="right-price">{numb(order_total)}€</span>
                                            </span>
                                        </li>
                                        <li className='text-primary'> 
                                            Sconto {subscription.description}
                                            <span className="text-muted">
                                                <span className="right-price text-primary">-{numb(order_discount)}€</span>
                                            </span>
                                        </li>
                                        <li> 
                                            Totale scontato (iva esclusa)
                                            <span className="text-muted">
                                                <span className="right-price">{numb(real_cart_total)}€</span>
                                            </span>
                                        </li>
                                        <li> Totale scontato (iva inclusa)
                                            <span className="text-muted">
                                                {numb(real_cart_total * 1.22)}€
                                            </span>
                                        </li>
                                    </>}
                                    <li className="bg-gray-dark fw-600">
                                        Credito dopo la prenotazione
                                        <span className="active">
                                            <span className="fw-600">{numb(credit - real_cart_total_vi)}€</span>
                                        </span>
                                    </li>
                                </ul>

                                <Button
                                    className={`btn-block ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                                    type="button"
                                    onClick={completeCheckout}
                                >
                                    <SpinnerForButton show={isCartInProgress}/>
                                    Conferma pagamento
                                </Button>
                            </>) : (
                            <>
                                {user.is_admin ? <>
                                    Il seguente cliente non ha credito sufficiente per concludere la prenotazione. Il credito attuale è {credit}€.
                                </> : <>
                                    Al momento il tuo saldo di {credit}€, quindi <b>non è sufficiente</b> a coprire l'importo dell'ordine, di seguito potrai ricaricarlo usufruendo dei nostri sconti dedicati.

                                    <Button
                                        className={`btn-block mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                                        type="button"
                                        onClick={() => dispatchShowOffCanvasAddFunds('OPEN')}
                                    >Ricarica credito</Button>
                                </>}
                            </>
                        )}

                    </Accordion.Body>
                </Accordion.Item>
                {credit < order_total_vati && order_total_vati > 0.50 && <Accordion.Item eventKey="0">
                    <Accordion.Header className='payments'>
                        <div><IoCardOutline className='d-inline' /><b>Paga con carta</b></div>
                        <div>
                            <img className="payment-card" alt="Visa" src={visaImage} />
                            <img className="payment-card mx-1" alt="Mastercard" src={mastercardImage} />
                            <img className="payment-card" alt="American Express" src={amexImage} />
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='text-white'>
                        Procedendo potrai effettuare il pagamento della prenotazione medianta <b>carta di credito</b>. Cliccando il seguente bottone verrai rimandato ad una pagina esterna sicura per processare la transazione.

                        <Button
                            className={`btn-block mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                            type="button"
                            onClick={completeCheckout}
                        >
                            <SpinnerForButton show={isCartInProgress}/>
                            Procedi al pagamento
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>}
            </Accordion>}

            {(order_total_vati === 0) && <button
                type="button"
                className={`btn btn-block btn-success mt-2 ${(isInProgress || isCartInProgress) ? 'disabled' : ''}`}
                onClick={completeCheckout}
            >
                <SpinnerForButton show={isCartInProgress}/>
                Conferma ordine
            </button>}
        </div>
        <OffCanvasAddFunds 
            show={showOffCanvasAddFunds}
        />
    </>
}