
const initialState = {
    object: undefined,
    data: undefined,
    params: undefined,
};

const render_ambients = (state = initialState, action) => {
    switch(action.type){
        case 'RENDER_AMBIENTS_SET':
            return {
                ...state,
                object: Object.fromEntries(action.payload.data.map(a => [a.id, a])),
                data: action.payload.data,
                params: action.payload.params
            };
        case 'RENDER_AMBIENTS_RESET':
            return initialState;
        default:
            return state;
    }
}

export default render_ambients;