import { deepCopy, dispatchAppEvent } from "utils/helpers";

const initialState = {
    isOpen: false,
    contents: [],
}

const dialogBox = (state = initialState, action) => {
    if ([action.type].includes('DIALOG_BOX_OPEN', 'DIALOG_BOX_CLOSE') && !action.payload.id) {
        console.error('DialogBox ID obbligatorio');
        return state;
    }
    switch(action.type){
        case 'DIALOG_BOX_OPEN':
            if (!action?.payload?.id) {
                console.error('DialogBox ID obbligatorio');
                return state;
            }
            document.body.classList.add('dialog-open');
            const matchIndex = state.contents.findIndex(dialog => dialog.id === action.id);
            if (matchIndex > -1) {
                const updateState = deepCopy(state);
                state[matchIndex] = action.payload;
                return updateState;
            }
            return {
                isOpen: true,
                contents: [
                    ...state.contents,
                    action.payload
                ],
            };
        case 'DIALOG_BOX_CLOSE':
            if (!action.payload) {
                console.error('DialogBox ID obbligatorio', action.payload);
                return state;
            }
            if (state.contents.find(dialog => dialog.id !== action.payload)) {
                console.error('DialogBox ID non trovato', action.payload);
                return state;
            }
            const newContents = state.contents.filter(dialog => dialog.id !== action.payload);
            const isOpen = newContents.length;
            !isOpen && document.body.classList.remove('dialog-open');
            dispatchAppEvent(`DIALOG_${action.payload}_CLOSE`)
            return {
                isOpen,
                contents: newContents
            }
        default:
            return state;
    }
}

export default dialogBox;