import OffCanvasCloseBtn from "components/offcanvas/OffCanvasCloseBtn";
import { useEffect, useMemo, useState } from "react";
import { Button, FormCheck, Offcanvas } from "react-bootstrap";
import { IoChevronDownOutline, IoChevronForwardOutline, IoInformationCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectCart, selectRender, selectRenderAmbients  } from "store/selectors";

export default function OffcanvasCustomPackage({ 
    show, 
    handleClose, 
    calculateProducts,
    handleNextStep
}) {
    
    const [customViews, setCustomViews] = useState({});
    const cart = useSelector(selectCart);
    const selectedProduct = useMemo(() => cart.products.length ? calculateProducts.find(p => p.id === cart.products[0].id) : null, [cart.products]);
    // const [isDowngradable, isUpgradable] = useMemo(() => {
    //     if (!selectedProduct) return [false, false];
    //     const index = calculateProducts.findIndex(p => p.id === selectedProduct.id);
    //     return [index > 0, index < calculateProducts.length - 1];
    // }, [selectedProduct]);

    useEffect(() => {
        if (!show) return setCustomViews({});
    }, [show]);

    const extraTotal = useMemo(() => { 
        if (!selectedProduct) return;
        return Object.values(customViews).reduce((acc, v) => acc + v, 0) * selectedProduct.price_matrix.price_x_view;
    }, [selectedProduct, customViews]);

    if (!selectedProduct) return;

    const editCustomQuantity = (uuid, quantity) => {
        const customQty = (customViews[uuid] ?? 0) + quantity;
        const packQty = selectedProduct.expanded.find(a => a.uuid === uuid).views;
        const totalQty = customQty + packQty;
        if (totalQty < selectedProduct.expanded.find(a => a.uuid === uuid).views) {
            return toast.error('Per ridurre il numero di viste è necessario passare al pacchetto minore');
        }
        setCustomViews({
            ...customViews,
            [uuid]: customQty
        })
    }

    return (<>
        <Offcanvas placement="bottom" className="action-sheet" show={selectedProduct && show} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose}/>
                <div className="section mt-3 text-center">
                    <h3 className='mb-0'>Piano personalizzato</h3>
                </div>

                {/* Open space 2 viste di default attive */}
                <div className='section'>
                    <div className='text-center mb-2'>Aggiungi il numero di viste per ogni ambiente.</div>
                    <table cellPadding="4" className='w-100'>
                        <thead>
                            <tr>
                                <th className='p-0'></th>
                                <th className='text-center p-0' style={{ width: '150px' }}>Numero viste</th>
                                <th className='text-center p-0' style={{ width: '65px' }}>Prezzo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedProduct.expanded.map(a => {
                                const totalQty = (a.views + (customViews[a.uuid] ?? 0));
                                const isDowngradable = (customViews[a.uuid] ?? 0) > 0;

                                return (
                                    <tr key={a.uuid}>
                                        <td className='fw-600 lh-1 nowrap'>
                                            {a.label} {a.counter}
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                <div className="stepper stepper-primary circle-button-container">
                                                    <a 
                                                        className={`stepper-button stepper-down render-ambient ${(!isDowngradable)? 'disabled' : ''}`}
                                                        onClick={() => editCustomQuantity(a.uuid, -1)}
                                                    >-</a>
                                                    <input 
                                                        value={totalQty}
                                                        type="text" 
                                                        className="form-control text-white text-14" 
                                                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: '0 3px', width: '33px', height: '25px' }}
                                                        readOnly
                                                    />
                                                    <a 
                                                        className={`stepper-button stepper-up render-ambient`}
                                                        onClick={() => editCustomQuantity(a.uuid, +1)}
                                                    >+</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>{totalQty > 0 ? (+selectedProduct.price_matrix.price_x_ambient + (totalQty * selectedProduct.price_matrix.price_x_view)) : 0}€</td>
                                    </tr>
                                );
                            })}
                            {(selectedProduct.price_matrix.plan || selectedProduct.price_matrix.emotional_view > 0 || selectedProduct.price_matrix.virtual_tour) && (
                                <tr>
                                    <td></td>
                                    <td colSpan={2}>
                                        <hr />
                                    </td>
                                </tr>
                            )}
                            {selectedProduct.price_matrix.plan && <tr>
                                <td className='fw-600 lh-1'>Planimetria</td>
                                <td className='text-center'>1</td>
                                <td className='text-center'>{selectedProduct.price_matrix.plan_price}€</td>
                            </tr>}
                            {selectedProduct.price_matrix.emotional_view > 0 && <tr>
                                <td className='fw-600 lh-1'>Viste emozionali</td>
                                <td className='text-center'>{selectedProduct.price_matrix.emotional_view}</td>
                                <td className='text-center lh-1'><strike>100€</strike> <span className="text-12">OMAGGIO</span></td>
                            </tr>}
                            {selectedProduct.price_matrix.virtual_tour && <tr>
                                <td className='fw-600 lh-1'>Virtual tour</td>
                                <td className='text-center'>1</td>
                                <td className='text-center'>{selectedProduct.price_matrix.virtual_tour}</td>
                            </tr>}
                            <tr>
                                <td></td>
                                <td colSpan={2}>
                                    <hr />
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-600'>
                                    <b>Totale:</b>
                                </td>
                                <td colSpan={2} className='text-end pe-2'>
                                    <b>{selectedProduct.price + extraTotal}€ + IVA</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <hr />
                    <table cellPadding="4" className='w-100'>
                        <tbody>
                            <tr>
                                <td className='fw-600'>Planimetria 3D</td>
                                <td className='text-center' style={{ width: '150px' }}>
                                    <FormCheck
                                        id="switchEnabled"
                                        type="switch"
                                        label=""
                                    />
                                </td>
                                <td className='text-center' style={{ width: '65px' }}><IoInformationCircle className='text-16 text-active me-05 vertical-align-n2' /> 10€</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="text-gray text-14 lh-1 mt-05">La planimetria 3D è disponibile solo se verranno costruiti tutti gli ambienti. Assicurati di aver richiesto i render per tutte le stanze.</div>

                    <table cellPadding="4" className='w-100 mt-2'>
                        <tbody>
                            <tr>
                                <td className='lh-1 fw-600'>Virtual tour interattivo</td>
                                <td className='text-center' style={{ width: '150px' }}>
                                    <FormCheck
                                        id="switchEnabled"
                                        type="switch"
                                        label=""
                                    />
                                </td>
                                <td className='text-center nowrap' style={{ width: '65px' }}><IoInformationCircle className='text-16 text-active me-05 vertical-align-n2' /> 100€</td>
                            </tr>
                        </tbody>
                    </table>

                    <table cellPadding="4" className='w-100 mt-2'>
                        <tbody>
                            <tr>
                                <td className='fw-600'>Viste emozionali</td>
                                <td className='text-center' style={{ width: '150px' }}>
                                    <div>
                                        <div className="stepper stepper-primary" style={{ height: '25px', width: '90px', minWidth: '90px', borderRadius: '6px', display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'space-between', overflow: 'hidden' }}>
                                            <a href="#" className="stepper-button stepper-down" style={{ backgroundColor: '#7b7b7b', borderColor: '#7b7b7b', width: '25px', height: '25px', color: '#fff', display: 'flex', fontSize: '20px', borderRadius: '100%', paddingBottom: '3px' }}>-</a>
                                            <input value="1" type="text" className="form-control text-white text-14" style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: '0 3px', width: '33px', height: '25px' }} />
                                            <a href="#" className="stepper-button stepper-up" style={{ backgroundColor: '#135CFA', borderColor: '#135CFA', width: '25px', height: '25px', color: '#fff', display: 'flex', fontSize: '20px', borderRadius: '100%', paddingBottom: '3px' }}>+</a>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-center' style={{ width: '65px' }}><IoInformationCircle className='text-16 text-active me-05 vertical-align-n2' /> 50€</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="text-gray text-14 lh-1 mt-05">2 render emozionali in omaggio se i render interni selezionati sono almeno 5</div> */}
                </div>

                <div className='section mb-3'>
                    <Button
                        className="text-center btn-block d-block m-auto mt-2"
                        type="button"
                        onClick={() => handleNextStep(customViews, selectedProduct.price + extraTotal)}
                    >
                        Continua<IoChevronForwardOutline className="next-icon-btn" />
                    </Button>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}