import { useLayoutEffect, useState } from "react";

const calculateWindowSize = (window) => ({
    innerHeight: window.innerHeight, 
    innerWidth: window.innerWidth,
    isLandscape: window.innerWidth > window.innerHeight 
});

export default function useWindowSize() {
    const [size, setSize] = useState(calculateWindowSize(window));
    useLayoutEffect(() => {
        function updateSize() {
            setSize(calculateWindowSize(window));
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    return size;
}