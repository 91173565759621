import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { db } from 'utils/firebase-instance.js';
import { collection, query, doc, updateDoc, getDocs, getDoc, orderBy, addDoc, deleteDoc } from "firebase/firestore"; 
import axios from "axios";
import { isMobile, textToColor, reorderArrayItem } from 'utils/helpers'; 
import { toast } from "react-toastify";
import { appShowLoader, appHideLoader } from 'store/actions/appLoader';
import { useDispatch } from 'react-redux';


export default function useZones() {
    const mapContainer = useRef(null);
    const drawRef = useRef(null);
    const mapRef = useRef(null);
    const editZoneIdRef = useRef(null);
    const zonesDocsRef = useRef(null);
    const [lng, setLng] = useState(10.9210891);
    const [lat, setLat] = useState(44.6467189);
    const [zoom, setZoom] = useState(7);
    const [zonesDocs, setZoneDocs] = useState();
    const [photographers, setPhotographers] = useState();
    const [editZone, setEditZone] = useState();
    const dispatch = useDispatch();

    const is_mobile = useMemo(() => isMobile(), []);
    //Mapbox
    useEffect(() => {
        if (!is_mobile) {
            mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;
            mapRef.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/dark-v10',
                center: [lng, lat],
                zoom: zoom
            });
            

            // Add navigation control (the +/- zoom buttons)
            mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

            drawRef.current = new MapboxDraw({
                displayControlsDefault: false,
                keybindings: false,
                userProperties: true,
                styles: [{
                    'id': 'gl-draw-polygon-fill-inactive',
                    'type': 'fill',
                    'filter': ['all', ['==', 'active', 'false']],
                    'paint': {
                        'fill-color': ['get', 'user_color'],
                        'fill-opacity': 0.3
                    },
                }, ...MapboxDraw.lib.theme.filter(gl => gl.id !== 'gl-draw-polygon-fill-inactive')],
            });
            mapRef.current.addControl(drawRef.current);

            mapRef.current.on('move', () => {
                setLng(mapRef.current.getCenter().lng.toFixed(4));
                setLat(mapRef.current.getCenter().lat.toFixed(4));
                setZoom(mapRef.current.getZoom().toFixed(2));
            });

            mapRef.current.on('draw.selectionchange', function(e) {
                if (e.features.length > 0) {
                    var selectedFeature = e.features[0];
                    if (!editZoneIdRef.current || editZoneIdRef.current !== selectedFeature.id) {
                        const zoneDoc = zonesDocsRef.current.find(z => z.id === selectedFeature.id);
                        handleEditZone({
                            zoneDoc,
                            zoneData: zoneDoc.data()
                        });
                    }
                } else {
                    handleEditZone();
                }
            });
        }

        const zonesRef = collection(db, 'zones');
        const q = query(zonesRef, orderBy('priority'));
        getDocs(q)
        .then(zones => {
            const zonesDocs = [];
            zones.forEach(zoneDoc => zonesDocs.push(zoneDoc));
            setZoneDocs(zonesDocs);
        });

        axios.get('users', {
            params: {
                role: 'photographer'
            }
        })
        .then(res => setPhotographers(res.data))

        // Clean up on unmount
        return () => (mapRef.current && mapRef.current.remove());
    }, []);

    useEffect(() => {
        if (!editZone) {
            editZoneIdRef.current = null;
            handleShowAll();
            drawRef.current && drawRef.current.changeMode('simple_select');
            return;
        }
        editZoneIdRef.current = editZone.zoneDoc.id;
        if (editZone.save) {
            saveZoneData();
            return;
        }
        if (!is_mobile) {
            if (drawRef.current.getSelectedIds().includes(editZone.zoneDoc.id)) {
                return;
            }
            drawRef.current.changeMode('simple_select', { featureIds: [editZone.zoneDoc.id] });
        }
    }, [editZone]);

    useEffect(() => {
        zonesDocsRef.current = zonesDocs;
        handleShowAll();
    }, [zonesDocs]);

    const handleEditZone = (data = null) => {
        data ? setEditZone(data) : setEditZone();
    }
    
    const saveZoneData = async () => {
        const zoneRef = doc(db, 'zones', editZone.zoneDoc.id);
        await updateDoc(zoneRef, editZone.zoneData);
        const updateZoneDoc = await getDoc(zoneRef);
        setZoneDocs(zonesDocs.map(zoneDoc => {
            if (zoneDoc.id === updateZoneDoc.id) {
                return updateZoneDoc;
            }
            return zoneDoc;
        }));
        setEditZone();
        toast.success("Zona aggiornata con successo.");
    }

    const handleResetSelectZone = () => {
        const confirm = window.confirm('Annullare il processo di disegno?');
        if (!confirm) return;
        handleShowAll();
        setEditZone();
    }

    const fitMap = () => {
        // Calcola i limiti del poligono
        const bounds = new mapboxgl.LngLatBounds();
        drawRef.current.getAll().features.forEach(feature => {
            if (feature.id !== editZone.zoneDoc.id) return;
            feature.geometry.coordinates[0].forEach(coord => {
                bounds.extend(coord);
            });
        });
        if (!Object.keys(bounds).length) return;
        // Esegui lo zoom sulla mappa per visualizzare il poligono
        mapRef.current.fitBounds(bounds, { padding: 60 });
    }

    const handleSavePoligon = () => {
        const newCoordinates = drawRef.current.get(editZone.zoneDoc.id).geometry.coordinates[0].map(([lng, lat]) => ({ lng, lat }));
        setEditZone({
            ...editZone,
            zoneData: {
                ...editZone.zoneData,
                area: newCoordinates
            },
            save: true
        });
    }

    const insertZone = async () => {
        const newZoneRef = await addDoc(collection(db, 'zones'), {
            area: [
                { lng: 12.39042738119258, lat: 44.64522834736519 },
                { lng: 12.726226956637532, lat: 44.63967669041239 },
                { lng: 12.724975033247489, lat: 44.42982243728957 },
                { lng: 12.389203893434711, lat: 44.433515698836175 },
                { lng: 12.39042738119258, lat: 44.64522834736519 }
            ],
            extra_time: 0,
            geolock_area: 0,
            is_single_ph: false,
            isochrone_mode: 'driving',
            isochrone_time: 0,
            geo_min_days: 0,
            geo_max_days: 30,
            km_refund: 0,
            is_reservable: false,
            name: 'Nuova zona',
            priority: zonesDocs[zonesDocs.length-1]?.data().priority+1 ?? 1,
            photographers: [],
            calendar: {
                1: [true, true],
                2: [true, true],
                3: [true, true],
                4: [true, true],
                5: [true, true],
                6: [false, false],
                0: [false, false],
            }
        });
        const newZoneDoc = await getDoc(newZoneRef);
        setZoneDocs([
            ...zonesDocs,
            newZoneDoc
        ]);
        setEditZone({
            zoneDoc: newZoneDoc,
            zoneData: newZoneDoc.data(),
            showEdit: true
        });
        toast.success("Nuova zona creata, compila ora i dettagli nel modale.")
    }

    const handleShowAll = () => {
        if (!zonesDocs || is_mobile) return;

        drawRef.current.set({
            type: 'FeatureCollection',
            features: [...zonesDocs].reverse().map(zoneDoc => {
                return {
                    id: zoneDoc.id,
                    type: 'Feature',
                    properties: {
                        color: textToColor('zone' + zoneDoc.id)
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [zoneDoc.data().area.map(({ lng, lat }) => ([lng, lat]))],
                    },
                }
            })
        });
        drawRef.current.changeMode('simple_select');
    };

    const handleOrderZones = async (result) => {
        if (!result.destination) {
            return;
        }

        const newOrderZones = reorderArrayItem(zonesDocs, result.source.index, result.destination.index);

        Promise.all(newOrderZones.map(async (zoneDoc, index) => {
            const zoneRef = doc(db, 'zones', zoneDoc.id);
            await updateDoc(zoneRef, { priority: index+1 });
            const updateZoneDoc = await getDoc(zoneRef);
            return updateZoneDoc;
        }))
        .then(newZonesArray => setZoneDocs(newZonesArray))
    }

    const deleteZone = async () => {
        const zoneRef = doc(db, 'zones', editZone.zoneDoc.id);
        const currentDocId = editZone.zoneDoc.id;
        await deleteDoc(zoneRef);
        toast.success("Zona eliminata con successo");
        setZoneDocs(zonesDocs.filter(zoneDoc => {
            if (zoneDoc.id !== currentDocId) return true;
            return false;
        }));
        setEditZone();
    }

    useEffect(() => {
        if ((!is_mobile && !mapRef.current) || !photographers || !zonesDocs) {
            dispatch(appShowLoader());
            return;
        }
        dispatch(appHideLoader());
    }, [zonesDocs, photographers, mapRef]);

    return {
        mapContainer,
        zonesDocs,
        editZone,
        photographers,
        handleEditZone,
        saveZoneData,
        handleSavePoligon,
        insertZone,
        handleShowAll,
        handleOrderZones,
        deleteZone,
        handleResetSelectZone,
        is_mobile
    }
}