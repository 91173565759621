import axios from "axios";
import Capsule from "layout/Capsule";
import Header from "layout/header/Header";
import { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";

function useSettings() {

    const [variables, setVariables] = useState();

    useEffect(() => {
        axios.get('variables')
        .then(res => setVariables(res.data))
    }, []);

    const handleChange = e => {
        const { name, value } = e.target;
        setVariables(variables.map(variable => {
            if (variable.key !== name) return variable;
            variable.value = value;
            variable.needSave = true;
            return variable;
        }));
    }

    const handleSaveVariable = key => {
        const variable = variables.find(v => v.key === key);
        axios.put(`variables/${variable.key}`, {
            value: variable.value
        })
        .then(res => {
            setVariables(variables.map(variable => {
                if (variable.key !== key) return variable;
                return res.data;
            }));
        });
    }

    return {
        variables,
        handleChange,
        handleSaveVariable
    }
}

export default function Settings() {

    const {
        variables,
        handleChange,
        handleSaveVariable
    } = useSettings();

    const categories = (variables || []).reduce((acc, variable) => {
        if (!acc.includes(variable.category)) {
            acc.push(variable.category);
        }
        return acc;
    }, []);

    return (
        <>
            <Header pageTitle="Impostazioni APP" />
            <Capsule>
                <div className="section">
                    <Accordion>
                        {categories && categories.map(cat => (
                            <Accordion.Item eventKey={cat} key={cat}>
                                <Accordion.Header>{cat}</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="listview image-listview">
                                        {variables.filter(v => v.category === cat).map(variable => (
                                            <li key={variable.key}>
                                                <div className="item">
                                                    <div className="in">
                                                        <div className="form-group boxed custom pb-0">
                                                            <div className="input-wrapper">
                                                                <label className="label-bordered form-label">
                                                                    {variable.description} [{variable.key}]
                                                                </label>
                                                                {variable.type === 'BOOLEAN' ? <>
                                                                    <select 
                                                                        value={variable.value}
                                                                        onChange={handleChange}
                                                                        name={variable.key}
                                                                    >
                                                                        <option value="1">SI</option>
                                                                        <option value="0">NO</option>
                                                                    </select>
                                                                </> : <>
                                                                    <input
                                                                        type={variable.type === 'VARCHAR' ? 'text' : variable.type === 'DATETIME' ? 'datetime-local' : variable.type === 'PASSWORD' ? 'password' : 'number'}
                                                                        pattern={variable.type === 'INTEGER' ? '^[-/d]/d*$"' : ''}
                                                                        step={variable.type === 'DATETIME' ? '1' : ''}
                                                                        className="form-control"
                                                                        style={{ background: '#3a3a3a' }}
                                                                        name={variable.key}
                                                                        value={variable.value}
                                                                        onChange={handleChange}
                                                                    />
                                                                </>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                        <Button 
                                                            className={`btn-success ms-2 ${variable.needSave ? 'blinking-element' : ''}`}
                                                            onClick={() => handleSaveVariable(variable.key)}
                                                        >Aggiorna</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </Capsule>
        </>
    );
}