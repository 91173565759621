import { createSelector } from 'reselect';

const selectSelf = state => state;

//Add new activity
const selectDialogBox = createSelector(selectSelf, state => state.dialogBox);
const selectAppointmentEdit = createSelector(selectSelf, state => state.appointmentEdit);
const selectAppLoader = createSelector(selectSelf, state => state.appLoader);
const selectCart = createSelector(selectSelf, state => state.cart);
const selectRender = createSelector(selectCart, state => state.metadata.render);
const selectProducts = createSelector(selectSelf, state => state.products);
const selectRenderAmbients = createSelector(selectSelf, state => state.renderAmbients);
const selectUploads = createSelector(selectSelf, state => state.uploads);

export {
    selectDialogBox,
    selectAppointmentEdit,
    selectAppLoader,
    selectCart,
    selectRender,
    selectProducts,
    selectRenderAmbients,
    selectUploads
}

