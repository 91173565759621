import { IoOpenOutline } from "react-icons/io5";

export default function FilesList({ files }) {
    return <>
        {files.map((file) => (
            <a 
                className='active me-1'
                target="_blank"
                href={file.url}
                key={file.path}
            >
                {file.name} <IoOpenOutline className='vertical-align-n2' />
            </a>
        ))}
    </>
}