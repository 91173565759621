export const getRenderAmbients = (params) => ({
    type: 'API_RENDER_AMBIENTS_GET',
    payload: params,
});

export const setRenderAmbients = (params = {}, data) => ({
    type: 'RENDER_AMBIENTS_SET',
    payload: {
        params, 
        data
    },
});

export const resetRenderAmbients = () => ({
    type: 'RENDER_AMBIENTS_RESET',
});