
const initialState = {
    data: undefined,
    params: undefined,
};

const products = (state = initialState, action) => {
    switch(action.type){
        case 'PRODUCTS_SET':
            return {
                ...state,
                data: action.payload.data,
                params: action.payload.params
            };
        case 'PRODUCTS_RESET':
            return initialState;
        default:
            return state;
    }
}

export default products;