import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';

import FormCheck from 'react-bootstrap/FormCheck';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Logic
import useOffCanvasPhotoPoll from './useOffCanvasPhotoPoll';
import { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import SpinnerForButton from 'components/loader/SpinnerForButton';

export function PollServiceItem({ 
    pollBuildingConditions,
    item,
    index,
    handleChange
}) {

    const poll = item.poll;
    return (<>
        <h3>{item.product.description}:</h3>

        <div className='d-flex justify-content-between mt-2 mb-1'>
            <label className="fw-500 text-14">Hai svolto "{item.product.description}"?</label>
            <FormCheck
                type="switch"
                label=""
                id={`is_done_${poll.id}`}
                name="is_done"
                checked={poll.is_done || false}
                onChange={e => handleChange(index, e)}
            />
        </div>

        {poll.is_done && item.product.social_active ? <div className='d-flex justify-content-between mt-2 mb-1'>
            <label className="fw-500 text-14">Da pubblicare sui social?</label>
            <FormCheck
                type="switch"
                label=""
                id={`is_social_post_${poll.id}`}
                name="is_social_post"
                checked={poll.is_social_post || false}
                onChange={e => handleChange(index, e)}
            />
        </div> : ''}

        {poll.is_done && item.product.type === 'PRIMARY' ? <div className="form-group boxed">
            <div className="input-wrapper not-empty">
                <label className="form-label text-14" htmlFor="building_condition_id">In che stato era l'immobile?</label>
                <select
                    className="form-control form-select"
                    id="building_condition_id"
                    name="building_condition_id"
                    value={poll.building_condition_id || ""}
                    onChange={e => handleChange(index, e)}
                    required
                >
                    <option value="" disabled>Seleziona</option>
                    {pollBuildingConditions?.map(pbc => (
                        <option key={pbc.id} value={pbc.id}>{pbc.description}</option>
                    ))}
                </select>
            </div>
        </div> : ''}

        {item.poll.is_done ? <div className="form-group boxed">
            <div className="input-wrapper not-empty">
                <label className="form-label text-14">Indicazioni per il postproduttore (opzionale)</label>
                <textarea
                    rows="2"
                    className="form-control" 
                    name="note_in"
                    value={poll.note_in || ''}
                    onChange={e => handleChange(index, e)}
                />
            </div>
        </div> : ''}

        {!item.poll.is_done ? <div className="form-group boxed">
            <div className="input-wrapper not-empty">
                <label className="form-label text-14">Come mai non è stato svolto?</label>
                <textarea
                    rows="2"
                    className="form-control"
                    name="not_done_reason"
                    value={poll.not_done_reason || ''}
                    onChange={e => handleChange(index, e)}
                    required
                />
            </div>
        </div> : ''}
    </>);
}


export default function OffCanvasPhotoPoll(props) {

    const {
        handleClose,
        isEnded
    } = props;

    const { 
        pollBuildingConditions,
        updatePhotoPolls,
        handleChange,
        handleSavePolls,
        isInProgress
    } = useOffCanvasPhotoPoll(props);

    return (<>
        <Offcanvas 
            placement="bottom"
            id="view-property"
            className="action-sheet"
            show={!!updatePhotoPolls}
            onHide={handleClose}
            backdrop="static"
        >
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose}/>
                <div className="section mt-3 text-center">
                    <h3>Informazioni servizio</h3>
                </div>
                <div className="action-sheet-content py-2 px-0 noarrow">
                    <div className="section mt-1">
                        <Form onSubmit={handleSavePolls}>
                            {updatePhotoPolls && <>
                                {updatePhotoPolls.length ? updatePhotoPolls.map((item, index) => (
                                    <Fragment key={item.id}>
                                        {index > 0 && <hr className="line-divider" />}
                                        <PollServiceItem 
                                            pollBuildingConditions={pollBuildingConditions}
                                            index={index}
                                            item={item}
                                            handleChange={handleChange}
                                        />
                                    </Fragment>
                                )) : <p>Nessun sondaggio da compilare, è sufficente cliccare su invia</p>}
                                </>
                            }
                            <Button
                                className={`btn-block btn-success mt-2 mb-1 ${isInProgress ? 'disabled' : ''}`}
                                type="submit"
                            >
                                <SpinnerForButton show={isInProgress} />
                                Invia
                            </Button>
                        </Form>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}