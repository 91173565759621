//Components
import Alert from 'react-bootstrap/Alert';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasBackBtn from 'components/offcanvas/OffCanvasBackBtn';
import Button from 'react-bootstrap/Button';
import SkeletonInput from 'components/loader/SkeletonInput';
import AutocompleteAddress from 'components/autocomplete/AutocompleteAddress';
import { 
    IoChevronForwardOutline,
    IoCheckmarkOutline,
    IoCheckmarkCircle
} from "react-icons/io5";

//Logic
import { 
    useOffcanvasAddActivity,
    useStepInfoFirst,
    useStepInfoSecond,
    useStepColor
} from './useOffcanvasAddActivity';
import { useNavigate } from 'react-router-dom';
import OffCanvasCloseBtn from '../OffCanvasCloseBtn';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import { useAuthValue } from 'context/AuthContext';
import { capitalizeFirstLetter } from 'utils/helpers';

export function StepInfoFirst(props) {

    const {
        stepInfoFirst,
        organizationTypes,
        isEdit,
        isInProgress
    } = props;

    const {
        handleChange,
        error,
        handleSubmit,
        handleRetriveAddress, handleResetAddress,
        addressValue, setAddressValue
    } = useStepInfoFirst(props);

    const canContinue = 
        !!stepInfoFirst.organizationType &&
        !!(stepInfoFirst.display_name.trim() || (stepInfoFirst.organizationType && stepInfoFirst.organizationType.is_private)) &&
        !!stepInfoFirst.phone.trim() &&
        !!stepInfoFirst.email.trim() &&
        !!stepInfoFirst.address.street;
        
    return (<>
        <div className="form-group boxed custom">
            <div className="input-wrapper">
                {organizationTypes === null ? <SkeletonInput /> : <>
                    <label className="form-label" htmlFor="organization_type_id">Tipo attività*</label>
                    <select
                        className="form-control form-select"
                        id="organization_type_id"
                        name="organization_type_id"
                        value={stepInfoFirst.organizationType ? stepInfoFirst.organizationType.id : 0}
                        onChange={handleChange}
                        disabled={isEdit && stepInfoFirst.organizationType.is_private}
                    >
                        <option value="0" disabled>Seleziona categoria</option>
                        {organizationTypes.map(organizationType => {
                            if (isEdit && !stepInfoFirst.organizationType.is_private && organizationType.is_private) return;
                            return <option 
                                value={organizationType.id}
                                key={organizationType.id}
                            >{organizationType.description}</option>
                        })}
                    </select>
                </>}
            </div>
        </div>

        {(!stepInfoFirst.organizationType || !stepInfoFirst.organizationType.is_private) &&
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    {organizationTypes === null ? <SkeletonInput /> : <>
                        <label className="form-label" htmlFor="display_name">Nome attività*</label>
                        <input
                            type="text"
                            className="form-control"
                            id="display_name"
                            name="display_name"
                            autoComplete="off"
                            value={capitalizeFirstLetter(stepInfoFirst.display_name)}
                            onChange={handleChange}
                            maxLength="50"
                        />
                    </>}
                </div>
            </div>
        }

        <div className="form-group boxed custom">
            <div className="input-wrapper">
                {organizationTypes === null ? <SkeletonInput /> : <>
                    <AutocompleteAddress 
                        value={addressValue}
                        setValue={setAddressValue}
                        handleRetrive={handleRetriveAddress}
                        handleReset={handleResetAddress}
                        defaultLock={stepInfoFirst.address?.full ? true : false}
                        withGoogle
                    />
                </>}
            </div>
        </div>

        <div className="form-group boxed custom">
            <div className="input-wrapper">
                {organizationTypes === null ? <SkeletonInput /> : <>
                    <label className="form-label" htmlFor="phone">Telefono*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        autoComplete="off"
                        value={stepInfoFirst.phone.replace(/\s/g, '')}
                        pattern="[0-9]*"
                        onChange={handleChange}
                        maxLength="15"
                    />
                </>}
            </div>
        </div>

        <div className="form-group boxed custom">
            <div className="input-wrapper">
                {organizationTypes === null ? <SkeletonInput /> : <>
                    <label className="form-label" htmlFor="email">Email*</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        autoComplete="off"
                        value={stepInfoFirst.email}
                        onChange={handleChange}
                        maxLength="320"
                    />
                </>}
            </div>
        </div>

        <div className="form-group basic">
            {error && (
                <Alert variant="danger" className="mb-3">
                    {error}
                </Alert>
            )}
            <Button
                className={`btn-block ${canContinue ? '' : 'disabled'}`}
                type="button"
                onClick={handleSubmit}
            >
                <SpinnerForButton show={isInProgress} />
                {isEdit ? 'Salva' : <>
                    Continua
                    <IoChevronForwardOutline className='next-icon-btn'/>
                </>}
            </Button>
        </div>
    </>);
}

export function StepInfoSecond(props) {

    const { 
        stepInfoFirst,
        stepInfoSecond,
        isEdit,
        isInProgress
    } = props;

    const {
        handleChange,
        handleSubmit,
        error,
    } = useStepInfoSecond(props);

    const canContinue = 
        !!stepInfoSecond.fiscal_code.trim() &&
        !!(stepInfoFirst.organizationType.is_private ? (
            !!stepInfoSecond.first_name.trim() &&
            !!stepInfoSecond.last_name.trim()
        ) : (
            stepInfoSecond.business_name.trim() &&
            stepInfoSecond.vat_code.trim() &&
            stepInfoSecond.pec_sdi.trim()
        ));

    return (<>
        {stepInfoFirst.organizationType.is_private ? <>
            <div className="row">
                <div className="col ps-1">
                    <div className="form-group boxed custom">
                        <div className="input-wrapper">
                            <label className="form-label label-bordered" htmlFor="first_name">Nome*</label>
                            <input
                                type="text"
                                className="form-control"
                                id="first_name"
                                name="first_name"
                                autoComplete="off"
                                value={stepInfoSecond.first_name}
                                onChange={handleChange}
                                maxLength="30"
                            />
                        </div>
                    </div>
                </div>
                <div className="col pe-1">
                    <div className="form-group boxed custom">
                        <div className="input-wrapper not-empty">
                            <label className="form-label label-bordered" htmlFor="last_name">Cognome*</label>
                            <input
                                type="text"
                                className="form-control"
                                id="last_name"
                                name="last_name"
                                autoComplete="off" 
                                value={stepInfoSecond.last_name}
                                onChange={handleChange}
                                maxLength="30"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </> : <>
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="business_name">Ragione sociale*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="business_name"
                        name="business_name"
                        autoComplete="off"
                        value={stepInfoSecond.business_name}
                        onChange={handleChange}
                        maxLength="200"
                    />
                </div>
            </div>
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="vat_code">Partita IVA*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="vat_code"
                        name="vat_code"
                        autoComplete="off" 
                        value={stepInfoSecond.vat_code.toUpperCase()}
                        onChange={handleChange}
                        maxLength="11"
                    />
                </div>
            </div>
        </>}

        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <label className="form-label" htmlFor="fiscal_code">Codice fiscale*</label>
                <input
                    type="text"
                    className="form-control"
                    id="fiscal_code"
                    name="fiscal_code"
                    autoComplete="off" 
                    value={stepInfoSecond.fiscal_code.toUpperCase()}
                    onChange={handleChange}
                    maxLength="16"
                />
            </div>
        </div>

        {!stepInfoFirst.organizationType.is_private && 
            <div className="form-group boxed custom">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="pec_sdi">Codice univoco/PEC*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="pec_sdi"
                        name="pec_sdi"
                        autoComplete="off" 
                        value={stepInfoSecond.pec_sdi.toUpperCase()}
                        onChange={handleChange}
                        maxLength="50"
                    />
                </div>
            </div>
        }

        <div className="form-group basic">
            {error && (
                <Alert variant="danger" className="mb-3">
                    {error}
                </Alert>
            )}
            <Button
                className={`btn-block ${canContinue ? '' : 'disabled'}`}
                type="button"
                onClick={handleSubmit}
            >
                <SpinnerForButton show={isInProgress} />
                {isEdit ? 'Salva' : <>
                    Continua
                    <IoChevronForwardOutline className='next-icon-btn' />
                </>}
            </Button>
        </div>
    </>);
}

export function StepColor(props) {

    const {
        stepInfoColor,
        colors,
        isInProgress,
        insertOrganization,
        isEdit
    } = props;
    
    const {
        selectColor
    } = useStepColor(props);

    return (<>
        <h4 className="fw-500 ps-1">Scegli un colore identificativo:</h4>
        <ul id="color-list">
            {colors.map(color => {
                return (
                    <li key={color.id} className="cursor-pointer" onClick={() => selectColor(color)}>
                        <span style={{ backgroundColor: `#${color.hex}` }} className="color-box"></span>
                        {color.description}
                        {stepInfoColor.color?.id === color.id && 
                            <IoCheckmarkOutline className='checked'/>
                        }
                    </li>
                )
            })}
        </ul>
        <div className="form-group basic">
            <Button
                className={`btn-block btn-${isEdit ? 'primary' : 'success'} ${!stepInfoColor.color || isInProgress ? 'disabled' : ''}`}
                type="button"
                onClick={insertOrganization}
            >
                <SpinnerForButton show={isInProgress} />
                {isEdit ? 'Salva' : 'Crea attività'}
            </Button>
        </div>
    </>);
}

function StepDone({ handleClose }) {

    const navigate = useNavigate();
    const { user } = useAuthValue();

    return (<>
        <div className="section mt-3 text-center">
            <h2>Attività creata</h2>
        </div>

        <div className="action-sheet-content pt-0">
            <div className="modal-icon text-success mt-0 mb-1">
            <IoCheckmarkCircle style={{ fontSize: '80px'}}/>
            </div>
            <div className="text-center mb-1 mt-2 lh-20">
                {user.is_agent && <>Congratulazioni, l'attività è stata <b>creata con successo</b>! Puoi ora accedere a tutti i servizi dell'app e prenotare il tuo primo servizio!</>}
            </div>
            <div className="form-group basic">
            <Button
                className="btn-block btn-primary"
                type="button"
                onClick={() => user.is_admin ? handleClose() : navigate('/')}
            >{user.is_admin ? 'Chiudi' : 'Vai alla home'}</Button>
            </div>
        </div>
    </>);
}


function OffcanvasAddActivity(props) {

    const { 
        show, handleClose
    } = props;

    const {
        stepInfoFirst, setStepInfoFirst,
        stepInfoSecond, setStepInfoSecond,
        stepInfoColor, setStepInfoColor,
        organizationTypes,
        colors,
        step,
        setStep,
        stepBefore,
        isInProgress,
        insertOrganization
    } = useOffcanvasAddActivity(props);

    if (step === 'done') return (
        <Offcanvas placement="bottom" className="action-sheet" style={{ maxWidth: '500px' }} show={show} onHide={handleClose}>
            <Offcanvas.Body>
                <StepDone handleClose={handleClose}/>
            </Offcanvas.Body>
        </Offcanvas>
    );

    return (
        <Offcanvas placement="bottom" className="action-sheet placeholder-glow" show={show} onHide={handleClose}>
            <Offcanvas.Body>
                {step !== 'infofirst' && !isInProgress && <OffCanvasBackBtn 
                    handleBack={stepBefore}
                />}
                {handleClose && <OffCanvasCloseBtn handleClose={handleClose}/>}
                <div className="section mt-3 text-center">
                    <h3>Crea un'attività</h3>
                </div>
                <div className="action-sheet-content py-1">
                    {step === 'infofirst' && <StepInfoFirst 
                        {...{
                            stepInfoFirst, setStepInfoFirst,
                            organizationTypes,
                            nextStep: () => setStep('infosecond')
                        }}
                    />}
                    {step === 'infosecond' && <StepInfoSecond 
                        {...{
                            stepInfoFirst,
                            stepInfoSecond, setStepInfoSecond,
                            nextStep: () => setStep('color') 
                        }}
                    />}
                    {step === 'color' && <StepColor 
                        {...{
                            stepInfoColor, setStepInfoColor,
                            colors,
                            isInProgress,
                            insertOrganization  
                        }}
                    />}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );

}

export default OffcanvasAddActivity;