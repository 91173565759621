import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

import FilterInput from 'components/FilterInput';
import { useFilterBuilding, useFilterOrganization } from 'hooks/useFilterInput';
import { useEffect, useState } from 'react';
import axios from 'axios';
import OrganizationItem from 'components/OrganizationItem';
import ViewBuildingItem from 'components/ViewBuildingItem';
import { Navigate, useNavigate } from 'react-router';
import OffCanvasBackBtn from 'components/offcanvas/OffCanvasBackBtn';
import { IoAdd } from 'react-icons/io5';
import moment from 'moment';


function useOffCanvasAddOrgAppointment(props) {

    const {
        show,
        organizationSelected,
    } = props;

    const [organizations, setOrganizations] = useState();
    const [buildings, setBuildings] = useState();

    useEffect(() => {
        if (!show || organizations) return;

        axios.get('organizations')
            .then(res => setOrganizations(res.data));

    }, [show]);

    useEffect(() => {
        setBuildings();
        if (!organizationSelected) return;

        axios.get('buildings', {
            params: {
                organization_id: organizationSelected.id,
                status: 'RESERVABLE'
            }
        })
            .then(res => setBuildings(res.data))
    }, [organizationSelected]);

    return {
        organizations,
        buildings
    }
}


export default function OffCanvasAddAppointment(props) {

    const {
        show,
        handleClose,
        handleSelectOrganization,
        organizationSelected,
        handleNewBuilding,
        resourceInfo
    } = props;

    const {
        organizations,
        buildings
    } = useOffCanvasAddOrgAppointment(props);

    const [filterInputOrganization, handleFilterOrganization, searchArrayOrganization] = useFilterOrganization();
    const [filterInputBuilding, handleFilterBuilding, searchArrayBuilding] = useFilterBuilding();

    const filteredBuildings = buildings?.filter(searchArrayBuilding);
    const filteredOrganizations = organizations?.filter(searchArrayOrganization);
    const navigate = useNavigate();

    return (<>
        <Offcanvas placement="bottom" style={{minHeight: "90vh"}} className="action-sheet" show={!!resourceInfo && show} onHide={handleClose}>
            <Offcanvas.Body>
                {organizationSelected && <OffCanvasBackBtn handleBack={() => handleSelectOrganization()} />}
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Nuova prenotazione</h3>
                </div>
                <div className="action-sheet-content py-2 px-1">
                    <FilterInput
                        placeHolder={`Ricerca ${organizationSelected ? 'immobile' : 'attività'}`}
                        onChange={organizationSelected ? handleFilterBuilding : handleFilterOrganization}
                        value={organizationSelected ? filterInputBuilding : filterInputOrganization}
                    />
                    <ul className="listview image-listview text no-border">
                        {!organizationSelected ? <>
                            {!organizations && Array(5).fill(null).map((_, i) => <OrganizationItem key={i} isSkeleton />)}
                            {organizations && organizations.filter(searchArrayOrganization).map(organization => (
                                <OrganizationItem
                                    key={organization.id}
                                    organization={organization}
                                    isList
                                    onClick={handleSelectOrganization}
                                />
                            ))}
                            {filteredOrganizations && !filteredOrganizations?.length &&
                                <li>
                                    <div className="text-center py-5 px-4">
                                        Non è stata trovata nessuna attività con questo nome
                                    </div>
                                </li>
                            }
                        </> : <>
                            <li onClick={handleNewBuilding}>
                                <a className="item no-after">
                                    <div className="icon-box bg-success">
                                        <IoAdd />
                                    </div>
                                    <div className="in text-success">
                                        <div className="fw-500">Aggiungi immobile</div>
                                    </div>
                                </a>
                            </li>
                            {!buildings && Array(5).fill(null).map((_, i) => <ViewBuildingItem key={i} isSkeleton />)}
                            {filteredBuildings?.map(building => <ViewBuildingItem
                                key={building.id}
                                {...{
                                    building,
                                    handleClickDetail: () => navigate(`/reservation/new/${building.id}?date=${moment(resourceInfo.date).format('YYYY-MM-DD')}&ph=${resourceInfo?.resource.extendedProps.uid}`)
                                }}
                            />)}
                            {filteredBuildings && !filteredBuildings?.length &&
                                <li>
                                    <div className="text-center py-5 px-4">
                                        Non è stata trovata nessuna immobile con questo nome
                                    </div>
                                </li>
                            }
                        </>}
                    </ul>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}