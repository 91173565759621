import axios from "axios";
import { useEffect } from "react";
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import useCalendar from "hooks/useCalendar";
import { useAuthValue } from "context/AuthContext";
import useUrlAction from "hooks/useUrlAction";
import { getUrlParam } from "utils/helpers";

export default function useCalendarUser() {

    const [selectedAppointmentId, dispatchViewAppointment] = useOffCanvasReducer();
    const { user } = useAuthValue();

    useUrlAction({
        show: [dispatchViewAppointment, [getUrlParam('id', true)]]
    });

    const calendarLogic = useCalendar({
        loadPromise: (start_date, end_date) => axios.get('appointments', {
            params: {
                start: start_date.format('YYYY-MM-DD'),
                end: end_date.format('YYYY-MM-DD'),
                events: 1,
                organization_id: user?.agent?.getSelectedOrganization().id,
                ...user.is_photographer && ({ photographer: 1 })
            }
        })
    });

    const { loadFirst } = calendarLogic;

    useEffect(() => {
        loadFirst();
    }, [loadFirst]);

    const handleEventClick = (info) => {
        dispatchViewAppointment(info.event.id);
    }


    return {
        handleEventClick,
        selectedAppointmentId, dispatchViewAppointment,
        calendarLogic
    }
}
