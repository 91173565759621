import { IoAdd, IoTime, IoTimeOutline, IoTrash } from "react-icons/io5";
import { Form, FormCheck } from "react-bootstrap";

function PriceItemSimple({
    handleChangeMatrix,
    matrix
}) {
    return (
        <div className="row gy-2 mt-1 mx-auto">
            <div className="col-md-12 pe-0">
                <div className="form-group boxed input-group position-relative pt-0">
                    <Form.Control
                        type="number"
                        className='px-2 text-center'
                        min="0"
                        value={matrix.price || ''}
                        required
                        name="price"
                        onChange={handleChangeMatrix}
                    />
                </div>
            </div>
            {/* <div className="col-md-6 pe-0">
                <div className="form-group boxed input-group position-relative pt-0">
                    <Form.Control
                        type="number"
                        className='px-2 text-center'
                        max={(matrix.price - 1)}
                        value={matrix.discount_price || ''}
                        name="discount_price"
                        onChange={handleChangeMatrix}
                    />
                </div>
            </div> */}
        </div>
    )
}

function PriceItem({
    row,
    index,
    all,
    handleChangeMatrix,
    handleDeleteRow,
    isCustomPrice,
    customPriceField
}) {
    const first = index === 0;
    const infinite = all.length === 1 || index === all.length - 1;
    const noMax = infinite || index === all.length - 2;

    return (
        <div className="row gy-2 mt-1 mx-auto">
            <div className="col-md-4 pe-0">
                <div className="form-group boxed input-group position-relative pt-0">
                    <span className="input-group-text px-2 fw-600 text-14 input-price-icon">Fino a</span>
                    <Form.Control
                        type={infinite ? 'text' : 'number'}
                        className='px-2 text-center'
                        min={first ? 1 : all[index - 1].range[1] + 1}
                        max={noMax ? 999999999999999999999 : all[index + 1].range[1] - 1}
                        value={infinite ? '∞' : row.range[1]}
                        required
                        disabled={infinite}
                        onChange={e => handleChangeMatrix(index, 'range', e.target.value)}
                    />
                </div>
            </div>
            <div className={`${isCustomPrice ? `col-md-4 col-8 ${customPriceField === 'price2' ? 'd-none' : ''}` : 'col-md-2 col-4'} pe-0`}>
                <div className="form-group boxed input-group position-relative pt-0 ">
                    <span className="input-group-text px-2 fw-600 input-price-icon ">€</span>
                    <Form.Control
                        type="number"
                        className='px-2'
                        value={row.price ?? ""}
                        required
                        onChange={e => handleChangeMatrix(index, 'price', e.target.value)}
                    />
                </div>
            </div>
            <div className={`${isCustomPrice ? `col-md-4 col-8 ${customPriceField === 'price1' ? 'd-none' : ''}` : 'col-md-2 col-4'} pe-0`}>
                <div className="form-group boxed input-group position-relative pt-0">
                    <span className="input-group-text px-2 fw-600 input-price-icon ">€</span>
                    <Form.Control
                        type="number"
                        className='px-2'
                        value={row.price2 ?? (customPriceField === 'price1' ? 0 : "")}
                        required
                        onChange={e => handleChangeMatrix(index, 'price2', e.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-2 col-4 pe-0">
                <div className="form-group boxed input-group position-relative pt-0">
                    <span className="input-group-text px-2 fw-600 input-price-icon "><IoTime /></span>
                    <Form.Control
                        type="number"
                        className='px-2'
                        value={row.time ?? ""}
                        required
                        onChange={e => handleChangeMatrix(index, 'time', e.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-2">
                <button
                    type="button"
                    style={{ height: '46px' }}
                    className={`btn btn-danger text-xl p-0 w-100 rounded-2`}
                    onClick={() => handleDeleteRow(index)}
                >
                    <IoTrash className='m-0' />
                </button>
            </div>
        </div>
    );

}

function PriceItemRender({
    matrix,
    handleChangeMatrix
}) {
        return (
        <div className="row gy-2 mt-1 mx-auto">
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="price_x_ambient">Prezzo per ambiente</Form.Label>
                        <Form.Control
                            id="price_x_ambient"
                            type="number"
                            min="0"
                            value={matrix.price_x_ambient ?? ''}
                            required
                            name="price_x_ambient"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="price_x_ambient">Prezzo per ambiente</Form.Label>
                        <Form.Control
                            id="price_x_ambient"
                            type="number"
                            min="0"
                            value={matrix.price_x_ambient ?? ''}
                            required
                            name="price_x_ambient"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="emotional_view">Viste emozionali omaggio</Form.Label>
                        <Form.Control
                            id="emotional_view"
                            type="number"
                            min="0"
                            value={matrix.emotional_view ?? ''}
                            required
                            name="emotional_view"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="price_x_ambient">Living: minimo viste</Form.Label>
                        <Form.Control
                            id="views_x_living"
                            type="number"
                            min="0"
                            value={matrix.views_x_living ?? ''}
                            required
                            name="views_x_living"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="views_x_living">Essenziali: x ambiente</Form.Label>
                        <Form.Control
                            id="views_x_living"
                            type="number"
                            min="0"
                            value={matrix.view_x_required ?? ''}
                            required
                            name="views_x_living"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="view_x_required_max">Essenziali: max per tipo</Form.Label>
                        <Form.Control
                            id="view_x_required_max"
                            type="number"
                            min="0"
                            value={matrix.view_x_required_max ?? ''}
                            required
                            name="view_x_required_max"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="view_x_others">Altri: x ambiente</Form.Label>
                        <Form.Control
                            id="view_x_others"
                            type="number"
                            min="0"
                            value={matrix.view_x_others ?? ''}
                            required
                            name="view_x_others"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="view_x_others_max">Altri: max per tipo</Form.Label>
                        <Form.Control
                            id="view_x_others_max"
                            type="number"
                            min="0"
                            value={matrix.view_x_others_max ?? ''}
                            required
                            name="view_x_others_max"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-6 pe-0">
                <ul className="listview simple-listview no-border mt-1 mb-1 rounded form-group">
                    <li className="form-control text-white">
                        <div className='lh-1'>Planimetria?</div>
                        <FormCheck
                            type="switch"
                            label=""
                            id="plan"
                            name="plan"
                            onChange={() => handleChangeMatrix({ target: { name: 'plan', value: !matrix.plan } })}
                            checked={matrix.plan || false}
                        />
                    </li>
                </ul>
            </div>
            <div className="col-6 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="plan_price">Prezzo planimetria</Form.Label>
                        <Form.Control
                            id="plan_price"
                            type="number"
                            min="0"
                            value={matrix.plan_price ?? ''}
                            required={matrix.plan}
                            name="plan_price"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
            <div className="col-6 pe-0">
                <ul className="listview simple-listview no-border mt-1 rounded form-group">
                    <li className="form-control text-white">
                        <div className='lh-1'>Virtual tour?</div>
                        <FormCheck
                            type="switch"
                            label=""
                            id="virtual_tour"
                            name="virtual_tour"
                            onChange={() => handleChangeMatrix({ target: { name: 'virtual_tour', value: !matrix.virtual_tour } })}
                            checked={matrix.virtual_tour || false}
                        />
                    </li>
                </ul>
            </div>
            <div className="col-6 pe-0">
                <div className="form-group boxed custom">
                    <div className="input-wrapper">
                        <Form.Label className="label-bordered" htmlFor="virtual_tour_price">Prezzo virtual tour</Form.Label>
                        <Form.Control
                            id="virtual_tour_price"
                            type="number"
                            min="0"
                            value={matrix.virtual_tour_price ?? ''}
                            required={matrix.virtual_tour}
                            name="virtual_tour_price"
                            onChange={handleChangeMatrix}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function PriceMatrix({
    matrix,
    handleChangeMatrix,
    handleAddRow,
    handleDeleteRow,
    isSimple = false,
    isCustomPrice = false,
    product
}) {

    if (!product) return;

    return <>
        <div className="row mx-auto mb-n3 text-white fw-500 d-none d-md-flex text-center" style={{ marginBottom: '-5px' }}>
            {product.type === 'RENDER' ? <>
            </> : (
                isSimple ? <>
                <div className="col-md-12 pe-0">Prezzo di listino</div>
            </> : <>
                <div className="col-md-4 pe-0">Superficie (m²)</div>
                <div className={`${product.custom_price_field ? `col-md-4 col-8 ${product.custom_price_field === 'price2' ? 'd-none' : ''}` : 'col-md-2 col-4'} pe-0`}>Prezzo1 (€)</div>
                <div className={`${product.custom_price_field ? `col-md-4 col-8 ${product.custom_price_field === 'price1' ? 'd-none' : ''}` : 'col-md-2 col-4'} pe-0`}>Prezzo2 (€)</div>
                <div className="col-md-2 pe-0">Durata (min)</div>
                <div className="col-md-2 pe-0"></div>
            </>
            )}
        </div>
        <div id="price-list">
            {product.type === 'RENDER' ? <>
                <PriceItemRender 
                    matrix={matrix}
                    handleChangeMatrix={handleChangeMatrix}
                />
            </> : isSimple ? <PriceItemSimple 
                matrix={matrix}
                handleChangeMatrix={handleChangeMatrix}
            /> : matrix.map((row, index, all) => (
                <PriceItem
                    key={index}
                    row={row}
                    index={index}
                    all={all}
                    handleChangeMatrix={handleChangeMatrix}
                    handleDeleteRow={handleDeleteRow}
                    isCustomPrice={isCustomPrice}
                    customPriceField={product.custom_price_field}
                />
            ))}
        </div>
        {!isSimple && <div className='text-center mt-1'>
            <button type="button" className="btn btn-icon btn-success me-1 mb-1" onClick={handleAddRow}>
                <IoAdd />
            </button>
        </div>}
    </>;

}