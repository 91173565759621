import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deepCopy } from "utils/helpers";

export default function useOffCanvasPhotoPoll(props) {

    const { 
        photoPolls,
        onSavePolls
    } = props; 

    const [updatePhotoPolls, setUpdatePhotoPolls] = useState();
    const [isInProgress, setIsInProgress] = useState(false);

    const [pollBuildingConditions, setPollBuildingConditions] = useState();

    useEffect(() => {
        axios.get('/poll-building-conditions')
        .then(res => setPollBuildingConditions(res.data));
    }, []);

    useEffect(() => {
        console.log(photoPolls);
        setUpdatePhotoPolls(photoPolls ? deepCopy(photoPolls.order_items.filter(i => i.poll)) : undefined);
    }, [photoPolls]);

    const handleChange = (index, e) => {
        const { name, value, type } = e.target;
        const currentPolls = deepCopy(updatePhotoPolls);
        const updateValue = type === 'checkbox' ? +e.target.checked : value;
        currentPolls[index].poll[name] = updateValue;
        if (name === 'is_done') {
            currentPolls[index].poll.is_social_post = null;
            currentPolls[index].poll.building_condition_id = null;
            currentPolls[index].poll.note_in = null;
            currentPolls[index].poll.not_done_reason = null;
        }
        setUpdatePhotoPolls(currentPolls);
    }

    const handleSavePolls = e => {
        e.preventDefault();
        setIsInProgress(true);
        axios.put(`order-items-polls`, {
            order_id: photoPolls.id,
            polls: updatePhotoPolls.map(item => ({
                ...item.poll,
                is_done: +!!item.poll.is_done
            }))
        })
        .then(() => {
            toast.success('Appuntamento completato con successo');
            onSavePolls();
        })
        .finally(() => setIsInProgress(false));
    }

    return {
        pollBuildingConditions,
        updatePhotoPolls,
        handleChange,
        handleSavePolls,
        isInProgress
    }

}