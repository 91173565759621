import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import personalBrand from './img/service/personal-brand.webp';
import fotoCitta from './img/service/foto-citta.webp';
import teamInAgenzia from './img/service/team-in-agenzia.webp';
import agenziaImmobiliare from './img/service/agenzia-immobiliare.webp';
import salaPosa from './img/service/sala-posa.webp';

function ServiceList(props) {

    return (<>
        <div className="section full mt-2 mb-0">
            <h2 className="mb-2 ms-2">Eleva il tuo personal brand</h2>
            <ul id="scroll-service">
                <li>
                    <div className="card product-card bg-transparent">
                        <div className="card-body">
                            <img src={fotoCitta} className="image" alt="product image" />
                            <h2 className="title">Foto della città</h2>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="card product-card bg-transparent">
                        <div className="card-body">
                            <img src={salaPosa} className="image" alt="product image" />
                            <h2 className="title">Foto professionali in sala posa</h2>
                        </div>
                    </div>
                </li>


                <li>
                    <div className="card product-card bg-transparent">
                        <div className="card-body">
                            <img src={personalBrand} className="image" alt="product image" />
                            <h2 className="title">Reel Personal Brand</h2>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="card product-card bg-transparent">
                        <div className="card-body">
                            <img src={agenziaImmobiliare} className="image" alt="product image" />
                            <h2 className="title">Foto agenzia immobiliare</h2>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="card product-card bg-transparent">
                        <div className="card-body">
                            <img src={teamInAgenzia} className="image" alt="product image" />
                            <h2 className="title">Foto team in agenzia immobiliare</h2>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    </>);

}

export default ServiceList;