//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Button from 'react-bootstrap/Button';
import { IoAdd, IoCheckmarkCircle, IoClose, IoCloseCircle, IoExpand, IoSaveOutline, IoSettingsSharp } from "react-icons/io5";
import OffCnvasEditZone from './components/OffCanvasEditZone';
import { Toast } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//logic
import useZones from './useZones';

//CSS
import 'mapbox-gl/dist/mapbox-gl.css';

//Utils
import { isMobile } from 'utils/helpers';

function ZoneItem(props) {

    const {
        editZone,
        zoneDoc = {},
        handleEditZone,
        handleShowAll,
        is_mobile
    } = props;

    const zoneData = handleShowAll ? {} : zoneDoc?.data();
    const selected = editZone?.zoneDoc.id === zoneDoc.id;
    return (
        <div className="item ps-1">
            {!is_mobile && <div className="icon-box me-05">
                <div className="form-check">
                    <input 
                        type='radio'
                        className="form-check-input"
                        name="selected-zone"
                        id={`zone_${zoneDoc.id}`}
                        checked={selected}
                        onChange={() => handleShowAll ? null : handleEditZone({
                            zoneDoc,
                            zoneData,
                            showEdit: false
                        })}
                        onClick={() => handleShowAll ? handleShowAll() : null}
                    />
                    <label className="form-check-label" htmlFor={`zone_${zoneDoc.id}`} />
                </div>
            </div>}
            <div className="in">
                <div>
                    <span>{handleShowAll ? 'Tutte le zone' : zoneData.name}</span>
                </div>
                {(is_mobile || selected) && !handleShowAll && <div>
                    <Button 
                        size="sm"
                        className='btn-light ms-1'
                        onClick={() => handleEditZone({
                            zoneDoc,
                            zoneData,
                            showEdit: true
                        })}
                    >
                        <IoSettingsSharp className='m-0' />
                    </Button>
                </div>}
            </div>
            {!handleShowAll && <IoExpand className='ms-1 cursor-drag' style={{ fontSize: '30px'}}/>}
        </div>
    );
}

export default function Zones() {

    const {
        mapContainer,
        zonesDocs,
        editZone,
        photographers,
        handleEditZone,
        saveZoneData,
        handleSavePoligon,
        handleShowAll,
        insertZone,
        handleOrderZones,
        deleteZone,
        handleResetSelectZone,
        is_mobile
    } = useZones();
    

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        
        // change background colour if dragging
        background: isDragging ? "#000" : "",
        
        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <>
            <Header pageTitle={'Elenco Zone Geografiche'} />
            <div style={{ height: '100vh'}}>
                <Capsule className="h-100">
                    <>
                        <div>
                            <div ref={mapContainer} className="map-container" />
                        </div>
                        {<div className={`section w-${isMobile() ? '100' : '25'} mt-2 sidebar-zones`}>
                            <DragDropContext onDragEnd={handleOrderZones}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <ul
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="listview image-listview rounded" 
                                        id="zone-list"
                                    >
                                    {zonesDocs && zonesDocs.map((zoneDoc, index) => (
                                        <Draggable key={zoneDoc.id} draggableId={zoneDoc.id} index={index}>
                                            {(provided, snapshot) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <ZoneItem 
                                                        key={zoneDoc.id}
                                                        editZone={editZone}
                                                        zoneDoc={zoneDoc} 
                                                        handleEditZone={handleEditZone}
                                                        is_mobile={is_mobile}
                                                    />
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                        <li className='cursor-pointer bg-transparent'>
                                            <div className="item p-0 justify-content-center">
                                                <div className="icon-box bg-success me-0" onClick={insertZone}>
                                                    <IoAdd />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                                </Droppable>
                            </DragDropContext>
                        </div>}
                    </>
                </Capsule>
                <OffCnvasEditZone
                    editZone={editZone}
                    handleEditZone={handleEditZone}
                    handleClose={() => {
                        handleEditZone({
                            ...editZone,
                            showEdit: false
                        });
                    }}
                    photographers={photographers}
                    saveZoneData={saveZoneData}
                    deleteZone={deleteZone}
                />
            </div>
            
            {(editZone && !is_mobile) && <>
                <div 
                    className="btn btn-icon btn-lg btn-primary me-1 mb-1"
                    style={{
                        position: 'fixed',
                        right: '20px',
                        bottom: isMobile() ? '50px' : '30px',
                        width: '60px',
                        height: '60px'
                    }}
                    onClick={() => handleSavePoligon()}
                >
                    <IoSaveOutline />
                </div>
                <div 
                    className="btn btn-icon btn-lg btn-danger me-1 mb-1"
                    style={{
                        position: 'fixed',
                        left: '20px',
                        bottom: isMobile() ? '50px' : '30px',
                        width: '60px',
                        height: '60px'
                    }}
                    onClick={() => handleResetSelectZone()}
                >
                    <IoClose />
                </div>
            </>}
        </>

    );
}