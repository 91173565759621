import axios from 'axios';
import { auth } from './firebase-instance';

const AXIOS_BASE_URL = process.env.REACT_APP_BACKEND_URL + 'api/';

async function axiosSetup(only_url = false) {
    axios.defaults.baseURL = AXIOS_BASE_URL;
    const bearer_token = await auth.currentUser.getIdToken();
    axiosSetInterceptorsRequest();
    axiosSetInterceptorsResponse();
}

function axiosSetInterceptorsRequest() {
    // Aggiungi l'intercettore di richiesta
    axios.interceptors.request.use(async (config) => {
        const user = await auth.currentUser;
        if (!user) return;
        const tokenResult = await user.getIdTokenResult();
        //console.info(Date.now(), new Date(tokenResult.expirationTime));
        const isTokenExpired = Date.now() >= new Date(tokenResult.expirationTime);
    
        if (isTokenExpired) {
            const newToken = await user.getIdToken(true);
            config.headers.Authorization = `Bearer ${newToken}`;
        } else {
            config.headers.Authorization = `Bearer ${tokenResult.token}`;
        }

        return config;
    }, (error) => {
        // Gestisci eventuali errori di intercettazione
        return Promise.reject(error);
    });
}

function axiosSetInterceptorsResponse() {
    axios.interceptors.response.use((response) => {
          // Gestisci la risposta con stato di successo qui, se necessario
          return response;
        },
        async (error) => {
          // Gestisci gli errori della risposta qui
          if (error.response && error.response.status === 401) {
            // Reindirizza l'utente al login
            await auth.signOut();
            //window.location.href = '/login'; // Cambia '/login' con l'URL del tuo percorso di login
          }
      
          // Restituisci l'errore per ulteriori gestioni
          return Promise.reject(error);
        }
      );
}

function axiosReset() {
    delete axios.defaults.baseURL
    delete axios.defaults.headers.common['Authorization'];
}


export {
    axiosSetup,
    axiosReset,
    AXIOS_BASE_URL
}
