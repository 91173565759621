//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Assets
import { IoAttach, IoBuild, IoCheckmark, IoCheckmarkOutline, IoChevronBackOutline, IoChevronDownOutline, IoChevronForwardOutline, IoClose, IoCloseOutline, IoCloudUploadOutline, IoCog, IoDocument, IoDownload, IoInformationCircle, IoListCircle, IoOpenOutline, IoPencil, IoPricetags, IoStar, IoTime, IoWarning } from 'react-icons/io5';
//Logic
import useRender from './useRender';
import { Button, FormCheck, Offcanvas } from 'react-bootstrap';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import { useSelector } from 'react-redux';
import { selectCart, selectProducts, selectRender, selectRenderAmbients } from 'store/selectors';
import { useEffect, useState } from 'react';
import { cartPreviousStep, cartSetRender } from 'store/actions/cart';
import { useDispatch } from 'react-redux';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import { useNavigate } from 'react-router';
import { getProducts } from 'store/actions/products';
import { getRenderAmbients } from 'store/actions/renderAmbients';
import { createUUID } from 'utils/helpers';


function OffcanvasViewRender(props) {

    return (<>
        <Offcanvas
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={false}
        //show={!!plantId}
        //onHide={handleClose}
        >
            <Offcanvas.Body>
                <div
                    style={{ backgroundImage: `url('https://i.imgur.com/0b2Awvx.jpeg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#f6f6f6' }}
                    className="property-card-header small"
                >

                    <a className="offcanvas-close">
                        <IoCloseOutline />
                    </a>

                </div>

                <div className="action-sheet-content py-2 px-0 noarrow">
                    <div className="listview-title pb-0">
                        <h3 className="mb-0 w-100">Rendering Interno<span className="badge bg-primary ms-1 float-end">IN CORSO</span></h3>
                    </div>
                    <ul className="listview image-listview no-border">
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoTime /> Data richiesta:
                                        </header>
                                        Mercoledì 20 marzo 2024
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoListCircle /> Riepilogo:
                                        </header>
                                        <ul className='ps-2'>
                                            <li><b>Viste:</b> 2 open space, 2 camere, 1 bagno</li>
                                            <li>Planimetria 3D</li>
                                            <li>Virtual tour interattivo</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoInformationCircle /> Specifiche:
                                        </header>
                                        <ul className='ps-2'>
                                            <li>Altezza soffitto 2,70</li>
                                            <li>Tapparelle - Tapparella con corda - Cassone incassato nel muro</li>
                                            <li>Riscaldamento a pavimento</li>
                                            <li>Soffitto Con travi, tetto pari</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoPricetags /> Totale pagato:
                                        </header>
                                        120 € + IVA (tramite credito)
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoDocument /> Planimetria in formato dwg o pdf:
                                        </header>
                                        <span className='active'>Visualizza <IoOpenOutline className='vertical-align-n2' /></span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoAttach /> File di riferimento:
                                        </header>
                                        <span className='active me-1'>nomefile.pdf <IoOpenOutline className='vertical-align-n2' /></span>  <span className='active me-1'>sanitari.png <IoOpenOutline className='vertical-align-n2' /></span>  <span className='active me-1'>rivestimenti.pdf <IoOpenOutline className='vertical-align-n2' /></span>  <span className='active me-1'>battiscopa.pdf <IoOpenOutline className='vertical-align-n2' /></span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>
                                        <header>
                                            <IoAttach /> Immagini di riferimento:
                                        </header>
                                        <span className='active me-1'>file1.png <IoOpenOutline className='vertical-align-n2' /></span>  <span className='active me-1'>file1.png <IoOpenOutline className='vertical-align-n2' /></span>  <span className='active me-1'>file2.png <IoOpenOutline className='vertical-align-n2' /></span>  <span className='active me-1'>file3.jpeg <IoOpenOutline className='vertical-align-n2' /></span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div className="flex-fill">
                                        <header>
                                            <IoPencil /> Note aggiuntive:
                                        </header>
                                        <div className="form-group boxed"><textarea rows="3" className="form-control" name="note">Finestra soggiorno: H180 L90 - Altezza da terra 90, Finestra bagno: H100 L60 - Altezza da terra 120, Link lavabo: ...</textarea></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="form-group mb-2 section">
                    <button type="button" className="btn-block btn btn-primary disabled"><IoDownload />Visualizza rendering</button>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

function Render() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector(selectCart);
    const products = useSelector(selectProducts);
    const render = useSelector(selectRender);
    const renderAmbients = useSelector(selectRenderAmbients);

    useEffect(() => {
        if (!renderAmbients.data) {
            dispatch(getRenderAmbients());
            return;
        }
        dispatch(cartSetRender({
            ...render,
            ambients: renderAmbients.data.filter(a => a.preselected).map(a => ({
                id: a.id,
                uuid: createUUID(),
                label: renderAmbients.object[a.id].label
            }))
        }));
    }, [renderAmbients.data]);

    useEffect(() => {
        if (!products.data || products?.params?.type !== 'RENDER') {
            dispatch(getProducts({
                type: 'RENDER'
            }));
        }
    }, []);

    return (
        <>
            <Header
                pageTitle={'Rendering'}
                handleBack={() => {
                    if (cart.step === 0) return navigate('/');
                    dispatch(cartPreviousStep());
                }}
            />
            <div className="extraHeader p-0 pb-1">
                <div className="form-wizard-section">
                    <a className={`button-item ${cart.step === 0 ? 'active' : ''}`}>
                        <strong>1</strong>
                        <p>Tipologia</p>
                    </a>
                    <a className={`button-item ${cart.step === 1 ? 'active' : ''}`}>
                        <strong>2</strong>
                        <p>Ambienti</p>
                    </a>
                    <a className={`button-item ${cart.step === 2 ? 'active' : ''}`}>
                        <strong>3</strong>
                        <p>Piano</p>
                    </a>
                    <a className={`button-item ${cart.step === 3 ? 'active' : ''}`}>
                        <strong>
                            <IoCheckmarkOutline />
                        </strong>
                        <p>Riepilogo</p>
                    </a>
                </div>
            </div>
            <Capsule className='extra-header-active pb-3'>
                {cart.step === 0 && <FirstStep />}
                {cart.step === 1 && <SecondStep />}
                {cart.step === 2 && <ThirdStep />}
                {cart.step === 3 && <FourthStep />}
            </Capsule>
            <OffcanvasViewRender />
        </>

    );
}

export default Render;