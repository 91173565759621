import { useState, useEffect } from 'react';

export function HoldElement(props) {

    const {
        children,
        onHold,
        ...otherProps
    } = props;

    const [pressTimer, setPressTimer] = useState(null);

    const handleMouseDown = () => {
        // Imposta un timer di 2 secondi
        const timer = setTimeout(() => {
            onHold();
        }, 600);

        setPressTimer(timer);
    };

    const handleMouseUp = () => {
        // Cancella il timer se il pulsante viene rilasciato prima di 2 secondi
        clearTimeout(pressTimer);
        setPressTimer(null);
    };

    useEffect(() => {
        // Cleanup in caso di smontaggio del componente
        return () => {
            if (pressTimer) {
                clearTimeout(pressTimer);
            }
        };
    }, [pressTimer]);

    return (
        <div
            onTouchStart={handleMouseDown} 
            onTouchEnd={handleMouseUp} 
            onTouchCancel={handleMouseUp}
            {...{...otherProps}}
        >
            {children}
        </div>
    );
}