export default function SelectOptions({
    options,
    withEmpty = true,
}) {
    return <>
        {withEmpty && <option value='' disabled>Seleziona</option>}
        {Object.entries(options).map(([value, label ]) => (
            <option key={value} value={value}>{label}</option>
        ))}
    </>;
}