import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword,
    updateProfile,
    sendEmailVerification,
    sendPasswordResetEmail
} from 'firebase/auth';
import { useAuthValue } from 'context/AuthContext';
//Utils
import { axiosSetup } from 'utils/axios-instance';
import { auth } from 'utils/firebase-instance';
import em from 'utils/message';
import axios from 'axios';
import { loadLaravelUser } from 'utils/helpers';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';
import useOnboarding from 'hooks/useOnboarding';


function useLogin() {
    const [email, setEmail] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const [passwordResertConfirm, setPasswordResertConfirm] = useState(false);
    const [showLostPassword, dispatchShowLostPassword] = useOffCanvasReducer(false);
    const [isInProgress, setIsInProgess] = useState(false);
    useOnboarding();

    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        setIsInProgess(true);
        setError();
        signInWithEmailAndPassword(auth, email, password)
        .then(() => navigate('/'))
        .catch((error) => {
            setError(em(error.code));
        })
        .finally(() => {
            setIsInProgess(false);
        });
    };

    const showOfcanvasLostPassword = () => {
        setResetEmail(email);
        setPasswordResertConfirm(false);
        dispatchShowLostPassword('OPEN');
    }
    const hideOfcanvasLostPassword = () => {
        dispatchShowLostPassword('CLOSE');
    }

    const handleResetPassword = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, resetEmail)
        .then(() => {
            setPasswordResertConfirm(true);
        })
        .catch(() => {
            setPasswordResertConfirm(true);
        })
    }

    useEffect(() => {
        if (auth.currentUser) {
            if (auth.currentUser.emailVerified) return navigate('/');
            navigate('/confirm-account');
        }
    }, []);

    return {
        email, setEmail,
        password, setPassword,
        showLostPassword, 
        showOfcanvasLostPassword,
        hideOfcanvasLostPassword, 
        error,
        handleLogin,
        handleResetPassword,
        resetEmail, setResetEmail,
        passwordResertConfirm,
        isInProgress
    }
}

function useRegister() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [gdprCheck, setGdprCheck] = useState(false);
    const [error, setError] = useState();
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [isInProgress, setIsInProgess] = useState(false);
    const navigate = useNavigate();
    useOnboarding();

    const handleRegister = (event) => {
        event.preventDefault();
        setError();
        const phoneNumber = phone.trim().replace('+39', '');
        if (firstName.trim().length < 3) {
            return setError(em('auth/validate/name/length'));
        }
        if (lastName.trim().length < 3) {
            return setError(em('auth/validate/name/length'));
        }
        if (phoneNumber.length < 8 || phoneNumber.length > 15) {
            return setError(em('auth/validate/phone/invalid'));
        }
        if (password.length < 6 || password.length >= 31) {
            return setError(em('auth/validate/password/length'));
        }
        if (password !== password2) {
            return setError(em('auth/validate/password/confirm'));
        }
        if (!gdprCheck) {
            return setError(em('auth/validate/gdpr/accept'))
        }

        setIsInProgess(true);
        
        createUserWithEmailAndPassword(auth, email, password)
        .then(async userAuth => {
            await axiosSetup();
            await updateProfile(userAuth.user, {
                displayName: firstName + ' ' + lastName
            })
            await axios.post('users/f', {
                phone: phoneNumber,
                display_name: firstName + ' ' + lastName
            })
            await sendEmailVerification(userAuth.user);
            navigate('/confirm-account');
        })
        .catch((error) => {
            setError(em(error.message = 'EMAIL_EXISTS' ? 'auth/register/mail_exists' : error.code));
        })
        .finally(() => {
            setIsInProgess(false);
        });
    };

    useEffect(() => {
        if (auth.currentUser) {
            if (auth.currentUser.emailVerified) return navigate('/');
            navigate('/confirm-account');
        }
    }, []);

    return {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        phone, setPhone,
        password, setPassword,
        password2, setPassword2,
        gdprCheck, setGdprCheck,
        error,
        handleRegister,
        showPrivacyPolicy, setShowPrivacyPolicy,
        isInProgress
    }

}

function useConfirmAccount() {

    const navigate = useNavigate();
    const [emailResended, setEmailResended] = useState();
    const [showOfCanvasVerifyError, setShowOfCanvasVerifyError] = useState(false);
    const { setUser, firebaseUser, setFirebaseUser } = useAuthValue();

    const handleCheckAccount = () => {
        auth.currentUser.reload().then(async () => {
            if (auth.currentUser.emailVerified) {
                setUser(await loadLaravelUser());
                setFirebaseUser({...firebaseUser, emailVerified: true});
                return navigate('/welcome-agent');
            }
            setShowOfCanvasVerifyError(true);
        });
    }

    const handleResendEmail = () => {
        sendEmailVerification(auth.currentUser)
        .then(() => {
            setEmailResended(em('auth/register/success'));
        })
        .catch(error => {
            setEmailResended(em(error.code));
        });
    }


    return {
        emailResended,
        handleResendEmail,
        handleCheckAccount,
        showOfCanvasVerifyError, setShowOfCanvasVerifyError
    }
}

export { useLogin, useRegister, useConfirmAccount }
