import Modal from 'react-bootstrap/Modal';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';

function ModalConfirm(props) {

    const { show, handleClose } = props;

    return (<>
        <Modal className="dialogbox" show={show}>
            <div className="modal-icon text-success">
                <IoCheckmarkCircle />
            </div>
            <Modal.Header>
                <Modal.Title>Ordine completato</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-14 px-2 lh-16 text-gray'>Grazie per il tuo acquisto. Riceverai per email la conferma d'ordine. Potrai monitorare lo stato dei lavori dallo storico ordini</Modal.Body>
            <Modal.Footer>
                <div className="btn-inline">
                    <a className="btn" onClick={handleClose}>
                        VISUALIZZA LAVORAZIONE 
                    </a>
                </div>
            </Modal.Footer>
        </Modal>
    </>);

}

export default ModalConfirm;