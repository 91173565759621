import { useEffect, useState } from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';

//Assets
import defaultAvatar from 'assets/img/avatar_default.jpg';
import { IoChevronForwardCircleOutline, IoChevronForwardOutline, IoTrashOutline } from "react-icons/io5";
import { IoTime } from "react-icons/io5";
import { IoAddCircle } from "react-icons/io5";

//Utils
import axios from 'axios';
import { useAuthValue } from 'context/AuthContext';
import { Navigate, useNavigate } from 'react-router';


function useOffcanvasManageUsers({ organization }) {

    const [users, setUsers] = useState();
    const [error, setError] = useState();
    const [addEmailInput, setAddEmailInput] = useState('');
    const [addRoleInput, setAddRoleInput] = useState('AGENT');

    useEffect(() => {
        if (!organization) return setUsers();
        axios.get(`organizations/${organization.id}/agents`)
        .then(res => setUsers(res.data))
    }, [organization]);

    const handleChangeAgentRole = (pivot_id, role) => {
        axios.put(`agents-organizations/${pivot_id}`, {
            role: role
        })
        .then(() => setUsers({
            ...users,
            agents: users.agents.map(agent => {
                if (agent.pivot_id === pivot_id) {
                    agent.role = role;
                }
                return agent;
            }),
        }));
    }

    const handleInviteUser = e => {
        e.preventDefault();
        setError();
        axios.post('organization-pending-agents', {
            email: addEmailInput,
            role: addRoleInput,
            organization_id: organization.id
        })
        .then(res => {
            if (res.data.error) {
                return setError(res.data.message);
            }
            setAddEmailInput('');
            setUsers({
                ...users,
                pendings: [
                    ...users.pendings,
                    res.data
                ],
            });
        });
    }

    const handleChangeAddEmailInput = e => {
        setAddEmailInput(e.target.value);
    }

    const handleChangeAddRoleInput = e => {
        setAddRoleInput(e.target.value);
    }

    const handleRevokeInvite = (pending_id) => {
        axios.put(`organization-pending-agents/${pending_id}/action/revoke`)
        .then(() => setUsers({
            ...users,
            pendings: users.pendings.filter(iu => iu.id !== pending_id)
        }))
    }

    const handleRemoveExistsAgent = (pivot_id) => {
        axios.delete(`agents-organizations/${pivot_id}`)
        .then(() => setUsers({
            ...users,
            agents: users.agents.filter(a => a.pivot_id !== pivot_id)
        }));
    }

    return {
        users,
        handleChangeAgentRole,
        addEmailInput, handleChangeAddEmailInput,
        addRoleInput, handleChangeAddRoleInput,
        handleInviteUser,
        error,
        handleRevokeInvite,
        handleRemoveExistsAgent
    }
}


function UserItem({ 
    agent, 
    invited = false,
    handleChangeAgentRole,
    handleRemoveAgent
}) {
    const { user } = useAuthValue();
    const navigate = useNavigate();

    return (
        <li>
            <div className="item">
                <div className="avatar me-2">
                    <img src={agent.user_avatar || defaultAvatar} alt="avatar" className="imaged rounded w36" />
                </div>
                <div className="in">
                    <div className='flex-fill'>
                        {invited ? agent.agent_email : agent.display_name}
                        {invited && <div className="text-warning text-12">
                            <IoTime/> Invito in attesa
                        </div>}
                    </div>
                    {!invited && <div className="form-group boxed w-auto">
                        <Form.Select 
                            size="sm"
                            className='form-control'
                            value={agent.role}
                            onChange={e => handleChangeAgentRole(agent.pivot_id, e.target.value )}
                            disabled={agent.user_id === user.id}
                        >
                            <option disabled>Seleziona ruolo</option>
                            <option value="OWNER">Titolare</option>
                            <option value="AGENT">Agente</option>
                        </Form.Select>
                    </div>}
                    {(agent.user_id !== user.id || invited) && <div className="iconedbox ms-1" onClick={() => handleRemoveAgent(agent.id ?? agent.pivot_id)}>
                        <IoTrashOutline className="text-danger cursor-pointer" />
                    </div>}
                    {(user.is_admin) && <div className="iconedbox" onClick={() => navigate(`/admin/users?action=show&id=${agent.user_id}`)}>
                        <IoChevronForwardOutline className="cursor-pointer" />
                    </div>}
                </div>
            </div>
        </li>
    );
}

function OffcanvasManageUsers(props) {

    const { organization, handleClose } = props;

    const { 
        users,
        handleChangeAgentRole,
        handleInviteUser,
        addEmailInput, handleChangeAddEmailInput,
        addRoleInput, handleChangeAddRoleInput,
        error,
        handleRevokeInvite,
        handleRemoveExistsAgent
    } = useOffcanvasManageUsers(props);

    return (<>
        <Offcanvas placement="bottom" style={{ maxWidth: 500 }} className="action-sheet" show={!!organization} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Utenti {organization?.display_name}</h3>
                </div>
                
                {!users && <OffCanvasLoader />}

                <form className='section' onSubmit={handleInviteUser}>
                    <div className="row">
                        <div className="col-5">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <Form.Label>Ruolo</Form.Label>
                                    <Form.Select 
                                        className='form-control'
                                        value={addRoleInput}
                                        onChange={handleChangeAddRoleInput}
                                    >
                                        <option value="AGENT">Agente</option>
                                        <option value="OWNER">Titolare</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="form-group boxed custom">
                                <div className="input-wrapper">
                                    <button type="submit" className='input-icon-search p-0 m-0 bg-transparent no-border'>
                                        <IoAddCircle className='text-success cursor-pointer align-baseline' />
                                    </button>
                                    <label className="form-label" htmlFor="user-invite-email">Utente</label>
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        className="form-control"
                                        id="user-invite-email"
                                        autoComplete="off"
                                        value={addEmailInput.replace(/\s/g, '')}
                                        onChange={handleChangeAddEmailInput}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {error && 
                        <Alert className="lh-16 mt-1" variant="danger" >
                            {error}
                        </Alert>
                    }
                </form>

                <hr className="my-1 line-divider" />

                <ul className="listview image-listview no-border">
                    {users && <>
                        {users.pendings.map(agent => (
                            <UserItem 
                                invited
                                key={agent.id}
                                agent={agent}
                                handleChangeAgentRole={handleChangeAgentRole}
                                handleRemoveAgent={handleRevokeInvite}
                            />   
                        ))}
                        {users.agents.map(agent => (
                            <UserItem 
                                key={agent.pivot_id}
                                agent={agent}
                                handleChangeAgentRole={handleChangeAgentRole}
                                handleRemoveAgent={handleRemoveExistsAgent}
                            />   
                        ))}
                    </>}
                </ul>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasManageUsers;