import Capsule from 'layout/Capsule';
import OffcanvasSetTime from './components/OffcanvasSetTime.js';
import OffCanvasViewAppointment from 'components/offcanvas/view-appointment/OffCanvasViewAppointment';

//Fullcalendar
import FullCalendar from '@fullcalendar/react' // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';

//Logic
import { useReservationCalendarUser, useReservationCalendarAdmin } from './useReservationCalendar';

//Utils
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useAuthValue } from 'context/AuthContext.js';
import { Form } from 'react-bootstrap';
import { IoLeaf } from 'react-icons/io5';

function EventContent({ event }) {
  const disabled = event.extendedProps.disabled;
  const skeleton = event.extendedProps.skeleton;

  if (skeleton) return '';
  const is_geolock = Object.values(event.extendedProps?.ranged_slots ?? {}).find(s => s.is_geolock);
  return (
    <div className="fc-event-time">
      {event.title ? event.title : (
        !disabled ? 
        (is_geolock ? <><IoLeaf /> Fotografo in zona</> : 'Fotografo disponibile') : 
        'Nessun fotografo disponibile'
      )}
    </div>
  );
}

function ReservationCalendarAdmin(props) {  

  const { photographers } = props;

  const {
    formData,
    handleChange
  } = useReservationCalendarAdmin(props);

  return (
    <Form className="section py-2">
        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <Form.Label>Fotografo</Form.Label>
                <Form.Select 
                    className='form-control'
                    value={formData.photographer_id || ''}
                    name="photographer_id"
                    onChange={handleChange}
                >
                    <option value="" disabled>SELEZIONA</option>
                    {photographers && photographers.map(ph => (
                      <option key={ph.uid} value={ph.uid}>{ph.display_name}</option>
                    ))}
                </Form.Select>
            </div>
        </div>
        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <Form.Label>Data</Form.Label>
                <input
                    type="date"
                    className='form-control'
                    value={formData.date || ''}
                    name="date"
                    onChange={handleChange}
                />
            </div>
        </div>
        <div className="form-group boxed custom">
            <div className="input-wrapper">
                <Form.Label>Ora</Form.Label>
                <Form.Control
                    type="time"
                    className='form-control'
                    value={formData.time || ''}
                    name="time"
                    onChange={handleChange}
                />
            </div>
        </div>
    </Form>
  );
}


function ReservationCalendarUser(props) {

  const { 
    nextStep,
    setSelectedTimeSlot
  } = props;

  const {
    handleEventClick,
    rangedSlots,
    rangeSlotSelected, setRangeSlotSelected,
    handleCloseOffCanvasSetTime,
    calendarEvents,
    calendarRef,
    handleViewChange,
    viewTitle,
    backDisabled,
    selectedAppointmentId, dispatchViewAppointment,
    user
  } = useReservationCalendarUser(props);


  return (
    <>
      <div className="card mb-0">
          <div className="card-body p-0 placeholder-glow" id="calendar">
            <div className="cal-topbar">
              <button onClick={() => handleViewChange('today')} className="fc-today-button btn btn-primary btn-sm text-14">Oggi</button>
              <span className='align-self-center fw-600 text-16'>{viewTitle}</span>
              <div className="btn-group">
                <button className={`fc-prev-button btn btn-primary btn-sm px-1 ${backDisabled ? 'disabled' : ''}`} onClick={() => handleViewChange('prev')}><FaChevronLeft className='me-0 text-16'/></button>
                <button className="fc-next-button btn btn-primary btn-sm px-1" onClick={() => handleViewChange('next')}><FaChevronRight className='me-0 text-16'/></button>
              </div>
            </div>
            <FullCalendar
              ref={calendarRef}
              plugins={[ dayGridPlugin, timeGridPlugin, bootstrapPlugin ]}
              events={calendarEvents}
              views={{
                  threeDay: {
                      type: 'timeGrid',
                      duration: { days: 3 },
                      buttonText: '3 giorni'
                  }
              }} 
              eventContent={EventContent}
              initialView="threeDay"
              locale='it'
              height='calc(100vh - 178px - env(safe-area-inset-top))'
              validRange={{
                start:  moment().isAfter(moment().set({ hour: 13, minute: 0, second: 0 })) ? 
                  new Date().setDate(new Date().getDate() + 1) : new Date()
              }}
              slotMinTime='08:00:00'
              slotMaxTime='20:00:00'
              allDaySlot={false}
              headerToolbar={false}
              eventColor='#1797589c'
              eventTextColor='#FFFFFF'
              themeSystem='bootstrap'
              slotEventOverlap={false}
              eventClassNames={info => {
                if ( info.event.extendedProps.disabled ) return 'ev-disabled';
                if ( info.event.extendedProps.skeleton ) return 'placeholder';
                return '';
              }}
              eventClick={handleEventClick}
          />
          </div>
      </div>
      <OffcanvasSetTime 
        {...{
          nextStep,
          rangedSlots,
          rangeSlotSelected,
          setRangeSlotSelected,
          handleClose: handleCloseOffCanvasSetTime,
          setSelectedTimeSlot
        }}
      />
      <OffCanvasViewAppointment 
          appointmentId={selectedAppointmentId}
          handleClose={() => dispatchViewAppointment('CLOSE')}
          canEdit={false}
      />
  </>);
}

export default function ReservationCalendar(props) {

  const { user } = useAuthValue();

  return (
    <Capsule className='extra-header-active pb-0'>
      <div className="section section-full-mobile mb-0 mt-2">
        {user.is_admin ? 
          <ReservationCalendarAdmin {...props} /> : 
          <ReservationCalendarUser {...props} />
        }
      </div>
    </Capsule>
  );
}
