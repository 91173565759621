import { useReducer } from "react";

export default function useOffCanvasReducer(defaultValue = false) {

    const [value, dispatchValue] = useReducer((_, action) => {
        if (action === 'CLOSE') return false;
        if (action === 'OPEN') return true;
        return action;
    }, defaultValue);

    return [value, dispatchValue];
}