import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoAddCircle, IoCheckmark, IoCloseCircle, IoExpand, IoTrash } from 'react-icons/io5';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OffCanvasLoader from 'components/loader/OffCanvasLoader';

//Logic
import useOffCanvasEditZone from './useOffCanvasEditZone';
import { weekDayName } from 'utils/helpers';
import CalendarTable from 'components/CalendarTable';

function PhotographerItem({ photographer, canDrag, handleChange }) {

    const BtnComponent = canDrag ? IoCloseCircle : IoAddCircle;
    return (
        <div className="item ps-1">
            <div className="in">
                <div>
                    <span>
                        <BtnComponent 
                            className={`text-${canDrag ? 'danger': 'success'} text-25 me-1 align-list-btn`}
                            onClick={() => handleChange(photographer.uid, !canDrag)}
                        />
                        {photographer.display_name}
                    </span>
                </div>
            </div>
            {canDrag && <IoExpand className='ms-1 cursor-drag' style={{ fontSize: '30px'}}/>}
        </div>
    );
}

function OffcanvasEditZone(props) {

    const { 
        editZone,
        handleClose,
        photographers,
    } = props;

    const {
        zonePhotographersIds,
        handleChangeData,
        handleChangeInput,
        handleSave,
        handleChangePhotographer,
        isConfirmDelete,
        handleDelete,
        handleReorderPh
    } = useOffCanvasEditZone(props);

    const { zoneData } = editZone || {};

    const avaiablePhotographers = photographers?.filter(ph => !zonePhotographersIds.includes(ph.uid)) || [];
    const selectedPhotographers = zonePhotographersIds?.map(uid => {
        return photographers.find(ph => ph.uid === uid);
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        
        // change background colour if dragging
        background: isDragging ? "#000" : "#3a3a3a",
        
        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (<>
        <Offcanvas 
            placement="bottom" 
            id="view-property" 
            className="action-sheet" 
            show={editZone && editZone.showEdit} 
            onHide={handleClose}
        >
            <Offcanvas.Body>
                <a 
                    className="close-btn-modal text-25 back-btn-modal text-danger"
                    onClick={() => handleDelete(isConfirmDelete)}
                >
                    {isConfirmDelete ? <IoCheckmark /> : <IoTrash />}
                </a>
                <OffCanvasCloseBtn handleClose={handleClose} />
                <div className="section mt-3 text-center">
                    <h3>Modifica zona</h3>
                </div>

                {!zoneData && <OffCanvasLoader />}

                <div className='section py-2'>
                    <Tabs
                        defaultActiveKey="info"
                        id="uncontrolled-tab-example"
                        className="mb-1 capsuled"
                    >
                        <Tab eventKey="info" title="Informazioni">
                            <Form className="section px-0 py-2" onSubmit={handleSave}>
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="name">Nome zona</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={zoneData?.name ?? ''}
                                            onChange={handleChangeInput}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="km_refund">Rimborso chilometrico cent / km</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="km_refund"
                                            name="km_refund"
                                            value={zoneData?.km_refund  ?? ''}
                                            onChange={handleChangeInput}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group boxed custom pt-0">
                                    <ul className="listview simple-listview no-border">
                                        <li>
                                            <div>Accetta prenotazioni</div>
                                            <FormCheck
                                                id="is_reservable"
                                                name="is_reservable"
                                                type="switch"
                                                label=""
                                                checked={zoneData?.is_reservable ?? false}
                                                onChange={() => handleChangeData('is_reservable', !zoneData?.is_reservable)}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="form-group boxed custom pt-0">
                                    <ul className="listview simple-listview no-border">
                                        <li>
                                            <div>Zona multifotografo</div>
                                            <FormCheck
                                                id="is_single_ph"
                                                name="is_single_ph"
                                                type="switch"
                                                label=""
                                                checked={!zoneData?.is_single_ph}
                                                onChange={() => handleChangeData('is_single_ph', !zoneData?.is_single_ph)}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="isochrone_mode">Tipo isochrono</Form.Label>
                                        <select
                                            id="isochrone_mode"
                                            name="isochrone_mode"
                                            className='form-control form-select'
                                            value={zoneData?.isochrone_mode  ?? ''}
                                            onChange={handleChangeInput}
                                        >
                                            <option value="driving">Auto</option>
                                            <option value="cycling">Bici</option>
                                            <option value="walking">Piedi</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col ps-1">
                                        <div className="form-group boxed custom">
                                            <div className="input-wrapper">
                                                <Form.Label className="label-bordered" htmlFor="isochrone_time">Minuti isochrono</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="isochrone_time"
                                                    name="isochrone_time"
                                                    value={zoneData?.isochrone_time  ?? ''}
                                                    onChange={handleChangeInput}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col pe-1'>
                                        <div className="form-group boxed custom">
                                            <div className="input-wrapper">
                                                <Form.Label className="label-bordered" htmlFor="geolock_area">Area geodisponibilità (km)</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="geolock_area"
                                                    name="geolock_area"
                                                    value={zoneData?.geolock_area  ?? ''}
                                                    onChange={handleChangeInput}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col ps-1">
                                        <div className="form-group boxed custom">
                                            <div className="input-wrapper">
                                                <Form.Label className="label-bordered" htmlFor="geo_min_days">Geodisp min giorni</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="geo_min_days"
                                                    name="geo_min_days"
                                                    value={zoneData?.geo_min_days  ?? ''}
                                                    onChange={handleChangeInput}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col pe-1'>
                                        <div className="form-group boxed custom">
                                            <div className="input-wrapper">
                                                <Form.Label className="label-bordered" htmlFor="geo_max_days">Geodisp max giorni</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="geo_max_days"
                                                    name="geo_max_days"
                                                    value={zoneData?.geo_max_days  ?? ''}
                                                    onChange={handleChangeInput}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='my-1' onClick={handleSave}>
                                    <Button className="btn-block" type="submit">Modifica informazioni</Button>
                                </div>
                            </Form>
                        </Tab>
                        <Tab eventKey="calendar" title="Calendario">
                            <Form className="section px-0 py-2" onSubmit={handleSave}>
                                <CalendarTable 
                                    options={zoneData?.calendar}
                                    onChange={options => handleChangeInput({
                                        target: {
                                            name: 'calendar', 
                                            value: options, 
                                            type: 'object'
                                        }
                                    })}
                                />
                                <div className='my-1'>
                                    <Button className="btn-block" type="submit">Modifica informazioni</Button>
                                </div>
                            </Form>
                        </Tab>
                        <Tab eventKey="roles" title="Fotografi" className='pb-2'>
                            {selectedPhotographers.length ? <>
                            <h3 className='mt-2'>Fotografi abilitati:</h3>
                                <DragDropContext onDragEnd={handleReorderPh}>
                                    <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <ul
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="listview image-listview"
                                        >
                                            {selectedPhotographers
                                                .map((photographer, index) => {
                                                return <Draggable key={photographer.uid} draggableId={photographer.uid} index={index}>
                                                    {(provided, snapshot) => (
                                                        <li
                                                            key={photographer.uid}
                                                            className='rounded mt-1'
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <PhotographerItem
                                                                photographer={photographer} 
                                                                canDrag
                                                                handleChange={handleChangePhotographer}
                                                            />
                                                        </li>
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                    </Droppable>
                                </DragDropContext>
                            </>: ''}
                            {avaiablePhotographers.length ? <>
                                <hr className='line-divider'/>
                                <h3>Altri fotografi:</h3>
                                <ul className="listview image-listview">
                                    {avaiablePhotographers.map(photographer => (
                                        <li 
                                            key={photographer.uid}
                                            className='rounded mt-1'
                                            style={{ background: '#3a3a3a'}}
                                        >
                                            <PhotographerItem 
                                                photographer={photographer} 
                                                canDrag={false}
                                                handleChange={handleChangePhotographer}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </> : ''}
                        </Tab>
                    </Tabs>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasEditZone;