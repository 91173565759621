import axios from 'axios';
import { useEffect, useState } from 'react';
import AutocompleteSelect from 'react-select';

export function Autocomplete({
    options,
    label,
    defaultValue = '',
    name,
    onChange,
    placeholder
}) {
    return (
        <div className="input-wrapper">
        <label className="label-bordered form-label" style={{zIndex: '10'}}>{label}</label>
            <AutocompleteSelect 
                defaultValue={defaultValue}
                className="form-control"
                classNamePrefix="react-select"
                unstyled
                isClearable
                onChange={autocomplete => onChange({
                    target: {
                        name: name,
                        value: (autocomplete?.value ?? "")
                    }
                })}
                options={(options ?? [])}
                placeholder={placeholder}
                name={name}
                noOptionsMessage={() => "Nessun risultato trovato"}
            />
        </div>
    )
}

export function AutocompleteOrganization({
    autoload = true,
    organizations,
    onChange
}) {

    const [organizationsData, setOrganizationsData] = useState();
    
    useEffect(() => {
        if (!autoload && !organizationsData) {
            return setOrganizationsData(organizations);
        }

        axios.get('organizations?autocomplete=1')
        .then(res => setOrganizationsData(res.data));

    }, [autoload, organizations]);

    return <Autocomplete
        name="organization_id"
        onChange={onChange}
        placeholder="Tutti i clienti"
        label="Cliente"
        options={organizationsData ?? []}
    />
}