import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'; 
import { capitalizeFirstLetter } from 'utils/helpers';

export default function DayNavigator({ next, previous, day }) {
    return (
        <div className="appHeader position-relative">
            <div className="left">
                <a className="headerButton" onClick={previous}>
                    <IoChevronBackOutline />
                </a>
            </div>
            <div className="pageTitle">{capitalizeFirstLetter(day.format('dddd D MMMM YYYY'))}</div>
            <div className="right">
                <a className="headerButton" onClick={next}>
                    <IoChevronForwardOutline />
                </a>
            </div>
        </div>
    );
}