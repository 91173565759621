//Logic
import { useLogin } from './useAuth';

//Assets
import loginImage from './img/login.svg';

//layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//components
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import Alert from 'react-bootstrap/Alert';
import InputPassword from 'components/InputPassword';
import { IoCheckmarkCircle, IoMail } from 'react-icons/io5';
import SpinnerForButton from 'components/loader/SpinnerForButton';

function Login() {

    const {
        email, setEmail,
        password, setPassword,
        showLostPassword, 
        showOfcanvasLostPassword,
        hideOfcanvasLostPassword, 
        error,
        handleLogin,
        handleResetPassword,
        resetEmail, setResetEmail,
        passwordResertConfirm,
        isInProgress
    } = useLogin();

    return (
        <>
            <Header show_menu={false} show_logo/>

            <Capsule>
                <div className="login-form">
                    <div className="section">
                        <img src={loginImage} alt="" className="form-image" width="200" height="156"/>
                    </div>
                    <div className="section">
                        <h2>Accedi</h2>
                        <h3 className="text-14 fw-500">Inserisci i dati per accedere all'app</h3>
                    </div>
                    <Form className="section mt-1 mb-5" onSubmit={handleLogin}>
                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="login-email">Email</Form.Label>
                                <Form.Control
                                    value={email.replace(/\s/g, '')}
                                    type="email"
                                    id="login-email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="login-password">Password</Form.Label>
                                <InputPassword
                                    value={password}
                                    id="login-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        {error && (
                            <Alert className="lh-16" variant="danger">
                                {error}
                            </Alert>
                        )}

                        <div className="form-links mt-1">
                            <div>
                                {isInProgress ? 
                                    'Registrati' :
                                    <Link to="/register" className="active">Registrati</Link>
                                }
                            </div>
                            <div>
                                <a onClick={showOfcanvasLostPassword}>Password dimenticata?</a>
                            </div>
                        </div>

                        <div className="form-button-group login-btn">
                            <Button
                                className={`btn-block ${isInProgress ? 'disabled' : ''}`}
                                type="submit"
                                size="lg"
                            >
                                <SpinnerForButton show={isInProgress} />
                                Accedi
                            </Button>
                        </div>
                    </Form>
                </div>
            </Capsule>

            {/* Lost password*/}
            <Offcanvas placement="bottom" show={showLostPassword} onHide={hideOfcanvasLostPassword}>
                <OffCanvasCloseBtn handleClose={hideOfcanvasLostPassword} />
                <Offcanvas.Body>
                    {passwordResertConfirm ? <>
                        <div className="modal-icon text-success">
                            <IoCheckmarkCircle style={{ fontSize: '110px'}}/>
                            </div>
                            <div className="text-center mb-1 mt-2 lh-20">
                               Richiesta di <b>recupero password</b> inviata con successo. Riceverai una email a breve all'indirizzo <b>{resetEmail}</b> con le istruzioni per procedere.
                            </div>
                    </> :
                    <div className="login-form">
                        <div className="section mt-1">
                            <IoMail className="text-large"/>
                            <h2 className='mt-1'>Inserisci la tua mail</h2>
                            <h3 className="text-14 fw-500">Riceverai via email un link per reimpostare la tua password.</h3>
                        </div>
                        <div className="section mt-4">
                            <Form onSubmit={handleResetPassword}>
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="reset-email">Email</Form.Label>
                                        <Form.Control
                                            value={resetEmail}
                                            type="email"
                                            id="reset-email"
                                            onChange={(e) => setResetEmail(e.target.value)}
                                            required
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <button 
                                        type="submit"
                                        className={`btn btn-primary btn-block btn-lg ${isInProgress || !resetEmail ? 'disabled' : ''}`}
                                    >Recupera password</button>
                                </div>
                            </Form>
                        </div>
                    </div>}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Login;