import { useState } from "react";
import { Form } from "react-bootstrap";
import { IoEye, IoEyeOff } from "react-icons/io5";

function useInputPassword() {
    const [showPassword, setShowPassword] = useState(false);

    return {
        showPassword, setShowPassword
    }
}

export default function InputPassword({
    required = false,
    onChange,
    id,
    value
}) {

    const {
        showPassword, setShowPassword
    } = useInputPassword();

    const IconComponent = showPassword ? IoEyeOff : IoEye;

    return <>
        <Form.Control
            value={value}
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            required={required}
            {...{
                ...id && { id },
                ...onChange && { onChange }
            }}
        />
        <IconComponent 
            className="input-icon-search cursor-pointer"
            style={{ zIndex: 100 }}
            onClick={() => setShowPassword(!showPassword)}
        />
    </>
}