import useLocalStorage from 'hooks/useLocalStorage';
import { Outlet, Navigate } from 'react-router-dom'
import { isMobile } from 'utils/helpers';
import { useAuthValue } from './context/AuthContext';

export function AuthUserRoute() {
  const { firebaseUser } = useAuthValue();
  return firebaseUser ? <Outlet /> : <Navigate to='/login' />
}

export function VerifiedUserRoute() {
  const { firebaseUser } = useAuthValue();
  return firebaseUser.emailVerified ? <Outlet /> : <Navigate to='/confirm-account' />
}

export function AgentRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return user.is_agent ? <Outlet /> : <Navigate to='/' />
}

export function AdminRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return user.is_admin ? <Outlet /> : <Navigate to='/' />
}

export function StaffRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return !user.is_agent && !!user.roles.length ? <Outlet /> : <Navigate to='/' />
}

export function PhotographerRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return user.is_photographer ? <Outlet /> : <Navigate to='/' />
}

export function EditorRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return user.is_editor ? <Outlet /> : <Navigate to='/' />
}

export function SocialManagerRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return user.is_social_manager ? <Outlet /> : <Navigate to='/' />
}

export function AccountantRoute() {
  const { user } = useAuthValue();
  if (!user) return;
  return user.is_accountant ? <Outlet /> : <Navigate to='/' />
}

export function OnboardingRoute() {
  const [onboarding, setOnboarding] = useLocalStorage('onboarding', !isMobile());
  if (onboarding) return <Outlet />;
  return <Navigate to='/onboarding' />
}
