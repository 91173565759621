const initialState = {}

const uploads = (state = initialState, action) => {
    switch(action.type){
        case 'UPLOAD_FILES':
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    ...action.payload.files
                },
            }
        case 'UPLOAD_SET_FILES':
                return {
                    ...state,
                    [action.payload.key]: action.payload.files,
                }
        case 'UPLOAD_SET':
            return {
                ...state,
                ...action.payload,
            }
        case 'UPLOAD_UPDATE_STATUS':
            if (!state[action.payload.key] || !state[action.payload.key][action.payload.uuid]) return state;
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.uuid]: {
                        ...state[action.payload.key][action.payload.uuid],
                        ...action.payload.data
                    } 
                }
            }
        case 'UPLOAD_RESET': 
            if (action.payload.key === '*') return initialState;
            return {
                ...state,
                [action.payload.key]: {}
            }
        case 'UPLOAD_DELETE_FILE':
            const { [action.payload.uuid]: _, ...rest } = state[action.payload.key];
            return {
                ...state,
                [action.payload.key]: rest
            }
        default:
            return state;
    }
}

export default uploads;