export const uploadFiles = (data) => ({
    type: 'UPLOAD_FILES',
    payload: data,
});

export const uploadSetFiles = (key, files) => ({
    type: 'UPLOAD_SET_FILES',
    payload: {key, files},
});

export const uploadSet = (object) => ({
    type: 'UPLOAD_SET',
    payload: object,
});

export const uploadUpdateStatus = (key, uuid, data) => ({
    type: 'UPLOAD_UPDATE_STATUS',
    payload: { key, uuid, data },
});

export const uploadReset = (key) => ({
    type: 'UPLOAD_RESET',
    payload: { key },
});

export const uploadDeleteFile = (key, uuid) => ({
    type: 'UPLOAD_DELETE_FILE',
    payload: { key, uuid },
});