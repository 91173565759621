export default function DonutGraph({
    data,
    label,
    centerColor = null
}) {

    const total = data.reduce((acc, d) => acc + d.value, 0);
    const percentages = [];
    const conicGradient = data.map((d, i) => {
        const start = i === 0 ? 0 : percentages[i - 1];
        const percentage = d.value * 100 / total;
        const end = start + (percentage > 100 ? 100 : percentage);
        percentages.push(percentage);
        return `${d.color} ${start}% ${end}%`;
    }).join(', ');

    return (
        <div 
            className="donut-chart"
            style={{background: `conic-gradient(${conicGradient})`}}
        >
            {centerColor && <div className="donut-chart-background" style={{ backgroundColor: centerColor }} />}
            <div className="donut-chart__value">{label || ''}</div>
        </div>
    );
}