import { Modal } from "react-bootstrap";
import { IoWarningOutline } from "react-icons/io5";
import { auth } from 'utils/firebase-instance';
import { deleteUser } from "firebase/auth";
import { toast } from 'react-toastify';
import axios from "axios";
import { useState } from "react";
import SpinnerForButton from "components/loader/SpinnerForButton";

function useModalDeleteAccount() {

    const [isInProgress, setIsInProgress] = useState(false);

    const handleDeleteAccount = () => {
        setIsInProgress(true);
        axios.delete('/me')
        .then(() => {
            toast.success('Account eliminato con successo', {
                onClose: () => window.location.reload()
            });
        }).catch((error) => {
            toast.error(error.message);
            setIsInProgress(false);
        });
    }

    return {
        handleDeleteAccount,
        isInProgress
    }
}

export default function ModalDeleteAccount(props) {

    const {
        show,
        handleClose
    } = props;

    const { 
        handleDeleteAccount,
        isInProgress
     } = useModalDeleteAccount(props);
    return (
        <Modal className="dialogbox full-width full-height" show={show}>
            <div className="modal-icon text-danger">
                <IoWarningOutline />
            </div>
            <Modal.Header>
                <Modal.Title>
                Cosa significa cancellare il tuo account?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-gray' style={{ textAlign: 'left' }}>
                <ul>
                    <li><strong>Cancellazione dei Dati:</strong> Tutti i tuoi dati personali, le impostazioni, le preferenze e le informazioni salvate saranno definitivamente cancellati dal nostro sistema.</li>
                    <li><strong>Perdita dell'Accesso:</strong> Non sarai più in grado di accedere ai servizi e alle funzionalità offerte dal nostro sito/app con questo account.</li>
                    <li><strong>Nessuna Possibilità di Recupero:</strong> Una volta che l'account è stato cancellato, non potremo recuperare o ripristinare alcun dato o informazione associata.</li>
                </ul>
                Ti preghiamo di considerare attentamente questa decisione. Se hai bisogno di maggiori informazioni o desideri discutere alternative alla cancellazione completa del tuo account, siamo a disposizione per assisterti.
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-inline">
                    <a className={`btn ${isInProgress ? 'disabled' : ''}`} onClick={handleClose}>
                        Annulla
                    </a>
                    <a className={`btn btn-text-danger ${isInProgress ? 'disabled' : ''}`} onClick={handleDeleteAccount}>
                        <SpinnerForButton show={isInProgress}/>
                        Cancella account
                    </a>
                </div>
            </Modal.Footer>
        </Modal>
    )
}