import { useEffect, useState } from 'react';
import { objectFromGooglePlace } from 'utils/helpers';

export default function useAutocompleteAddress(props) {

    const {
        handleRetrive,
        handleReset,
        setValue,
        defaultLockValue = false
    } = props;


    const [isLocked, setIsLocked] = useState(false);

    useEffect(() => {
        setIsLocked(defaultLockValue);
    }, [defaultLockValue]);

    const onRetrive = (res, withGoogle) => {
        setIsLocked(true);
        if (withGoogle) {
            const google = objectFromGooglePlace(res);
            setValue && setValue(google.street_address);
            return handleRetrive({
                feature: res,
                values: {
                    street: `${google.route}${google.street_number ? `, ${google.street_number}` : ''}`,
                    locality: google.locality,
                    postal_code: google.postal_code,
                    state: google.aa_level_3,
                    state_code: google.state_code,
                    full: google.street_address,
                    country_code: google.country
                },
                coords: {
                    lat: res.geometry.location.lat(),
                    lng: res.geometry.location.lng()
                },
                full_address: google.street_address
            });
        }
        const feature = res.type === 'FeatureCollection' ? res.features[0] : res;
        const values = {
            street: feature.properties.matching_name,
            locality: feature.properties.place,
            postal_code: feature.properties.postcode,
            state: feature.properties.region.replace('provincia di', '').trim(),
            state_code: feature.properties.region_code,
            country: feature.properties.country
        }
        const full_address = `${values.street}, ${values.locality}, ${values.postal_code}, ${values.state} (${values.state_code})`;
        values.full = full_address;
        setValue && setValue(full_address);
        return handleRetrive({
            feature,
            values,
            coords: {
                lat: feature.geometry.coordinates[1],
                lng: feature.geometry.coordinates[0]
            },
            full_address
        })
    }

    const onReset = () => {
        setIsLocked(false);
        setValue('');
        handleReset && handleReset(null);
    }

    return {
        onRetrive,
        onReset,
        isLocked
    }

}