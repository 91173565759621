import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoBusiness, IoLogInOutline, IoTrash } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthValue } from 'context/AuthContext';
import { useNavigate } from 'react-router';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';
import { toast } from 'react-toastify';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'utils/firebase-instance';
import { appHideLoader, appShowLoader } from 'store/actions/appLoader';
import { useDispatch } from 'react-redux';
import { logout } from 'utils/helpers';

function useOffcanvasEditUser({ editUser, onUpdateUser, handleClose }) {

    const [showUser, setShowUser] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!editUser) return setShowUser();
        axios.get(`/user/${editUser}`)
            .then(res => setShowUser(res.data));
    }, [editUser]);

    const switchRoles = (role, status) => {
        axios.put(`/user/${showUser.id}/switch-roles`, {
            role,
            status: status ? 0 : 1
        })
            .then(res => {
                toast.success("L'utente è stato cambiato di ruolo.");
                setShowUser({
                    ...showUser,
                    [role]: res.data.status,
                    roles: res.data.roles
                });
                onUpdateUser({
                    id: showUser.id,
                    roles: res.data.roles
                });
            });
    }

    const changeInput = (e) => {
        const { name, value } = e.target;
        setShowUser({
            ...showUser,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`/user/${showUser.id}`, {
            display_name: showUser.display_name,
            phone: showUser.phone,
            email: showUser.email
        })
        .then(res => {
            onUpdateUser(res.data);
            handleClose();
            toast.success("Dati utente aggiornati con successo!");
        })
        .catch(error => {
            toast.error("Errore durante il salvataggio, riprova.");
        });
    }
    
    const handleSendResetPassword = () => {
        axios.post(`/user/${showUser.id}/send-reset-password`)
        .then(() => toast.success('Email inviata con successo'));
    }

    const handleLoginAsUser = () => {
        dispatch(appShowLoader());
        axios.post(`/user/${showUser.id}/login-as`)
        .then(res => {
            logout();
            signInWithCustomToken(auth, res.data.token)
            .then((userCredential) => {
                dispatch(appHideLoader());
            })
            .catch((error) => {
                toast.error("Errore durante il login utente")
            });
        })
    }

    return {
        showUser,
        switchRoles,
        changeInput,
        handleSubmit,
        handleSendResetPassword,
        handleLoginAsUser
    }

}


function OffcanvasEditUser(props) {

    const { editUser, handleClose } = props;
    const { user } = useAuthValue();

    const {
        showUser,
        switchRoles,
        changeInput,
        handleSubmit,
        handleSendResetPassword,
        handleLoginAsUser
    } = useOffcanvasEditUser(props);

    const navigate = useNavigate();
    const is_system_block = showUser?.is_system && !user.is_system;

    return (<>
        <Offcanvas placement="bottom" id="view-property" className="action-sheet" show={!!editUser} onHide={handleClose}>
            <Offcanvas.Body>
                <OffCanvasCloseBtn handleClose={handleClose} />
                {/* <a className="close-btn-modal text-25 back-btn-modal text-danger">
                    <IoTrash />
                </a> */}
                <div className="section mt-3 text-center">
                    <h3>Modifica utente</h3>
                </div>
                {!showUser && <OffCanvasLoader />}

                <div className='section py-2'>
                    <Tabs
                        defaultActiveKey="info"
                        className="mb-1 capsuled"
                    >
                        <Tab eventKey="info" title="Informazioni">

                            <Form className="section px-0 py-2" onSubmit={handleSubmit}>
                                {user.is_system && <>
                                    <div className='row'>
                                        <div className="col-3">
                                            <div className="form-group boxed custom">
                                                <div className="input-wrapper">
                                                    <Form.Label className="label-bordered" htmlFor="display_name">User ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        minLength="3"
                                                        defaultValue={showUser?.id}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group boxed custom">
                                                <div className="input-wrapper">
                                                    <Form.Label className="label-bordered" htmlFor="display_name">Agent ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        minLength="3"
                                                        defaultValue={showUser?.agent_id}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group boxed custom">
                                                <div className="input-wrapper">
                                                    <Form.Label className="label-bordered" htmlFor="display_name">Ph ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        minLength="3"
                                                        defaultValue={showUser?.photographer_id}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group boxed custom">
                                                <div className="input-wrapper">
                                                    <Form.Label className="label-bordered" htmlFor="display_name">Editor ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        minLength="3"
                                                        defaultValue={showUser?.editor_id}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group boxed custom">
                                        <div className="input-wrapper">
                                            <Form.Label className="label-bordered" htmlFor="display_name">ID Firebase</Form.Label>
                                            <Form.Control
                                                type="text"
                                                minLength="3"
                                                defaultValue={showUser?.uuid}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </>}
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="display_name">Nominativo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="display_name"
                                            id="display_name"
                                            minLength="3"
                                            value={showUser?.display_name ?? ''}
                                            onChange={changeInput}
                                            required
                                            disabled={is_system_block}
                                        />
                                    </div>
                                </div>

                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="user-email">Email</Form.Label>
                                        {!is_system_block && <IoLogInOutline 
                                            className="input-icon-search cursor-pointer" 
                                            onClick={handleLoginAsUser}
                                        />}
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            id="email"
                                            defaultValue={showUser?.email ?? ''}
                                            onChange={changeInput}
                                            required
                                            disabled={is_system_block}
                                        />
                                    </div>
                                    {!is_system_block && <a className="active d-block mt-1 text-end" onClick={handleSendResetPassword}>Invia email reset password</a>}
                                </div>

                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="phone">Telefono</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            minLength="8"
                                            maxLength="15"
                                            value={showUser?.phone ?? ''}
                                            pattern="[0-9]*"
                                            onChange={changeInput}
                                            required
                                            disabled={is_system_block}
                                        />
                                    </div>
                                </div>

                                {showUser?.agent?.organizations.length > 0 && (
                                    <h4 className="card-subtitle text-white text-12 mb-05 mt-1">Attività abilitate:</h4>
                                )}
                                
                                <ul className="listview image-listview border-top-0 border-bottom-0">
                                    {showUser?.agent?.organizations.map(organization => (
                                        <li key={organization.id} onClick={() => navigate(`/admin/organizations?action=show&id=${organization.id}`)}>
                                            <a className="item px-0">
                                                <div className="icon-box me-05">
                                                    <IoBusiness />
                                                </div>
                                                <div className="in">
                                                    <div>{organization.display_name}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>

                                <div className='my-1'>
                                    <Button 
                                        className={`btn-block ${is_system_block ? 'disabled' : ''}`}
                                        type="submit"
                                    >
                                            Salva utente
                                    </Button>
                                </div>
                            </Form>
                        </Tab>
                        <Tab eventKey="roles" title="Ruoli">

                            <ul className="listview simple-listview no-border">
                                <li>
                                    <div>Utente APP</div>
                                    <FormCheck
                                        id="is_agent"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_agent ?? false}
                                        onChange={() => switchRoles('is_agent', showUser?.is_agent)}
                                        disabled={is_system_block || user?.id === showUser?.id || showUser?.is_staff}
                                    />
                                </li>
                                <li>
                                    <div>Amministratore</div>
                                    <FormCheck
                                        id="is_admin"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_admin ?? false}
                                        onChange={() => switchRoles('is_admin', showUser?.is_admin)}
                                        disabled={is_system_block || user?.id === showUser?.id || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Disattiva statistiche</div>
                                    <FormCheck
                                        id="is_accountant"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_accountant ?? false}
                                        onChange={() => switchRoles('is_accountant', showUser?.is_accountant)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Social manager</div>
                                    <FormCheck
                                        id="is_social_manager"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_social_manager ?? false}
                                        onChange={() => switchRoles('is_social_manager', showUser?.is_social_manager)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Fotografo</div>
                                    <FormCheck
                                        id="photo"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_photographer ?? false}
                                        onChange={() => switchRoles('is_photographer', showUser?.is_photographer)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Editor foto</div>
                                    <FormCheck
                                        id="editfoto"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_editor_photo ?? false}
                                        onChange={() => switchRoles('is_editor_photo', showUser?.is_editor_photo)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Editor video</div>
                                    <FormCheck
                                        id="editvideo"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_editor_video ?? false}
                                        onChange={() => switchRoles('is_editor_video', showUser?.is_editor_video)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Editor planimetrie</div>
                                    <FormCheck
                                        id="editoplan"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_editor_plan ?? false}
                                        onChange={() => switchRoles('is_editor_plan', showUser?.is_editor_plan)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                                <li>
                                    <div>Editor render</div>
                                    <FormCheck
                                        id="editrender"
                                        type="switch"
                                        label=""
                                        checked={showUser?.is_editor_render ?? false}
                                        onChange={() => switchRoles('is_editor_render', showUser?.is_editor_render)}
                                        disabled={is_system_block || showUser?.is_agent}
                                    />
                                </li>
                            </ul>
                        </Tab>
                    </Tabs>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasEditUser;