//Logic
import { useRegister } from './useAuth';

//Assets
import registerImage from './img/registration.svg';

//layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import OffCanvasPrivacyPolicy from 'components/offcanvas/OffCanvasPrivacyPolicy';
import InputPassword from 'components/InputPassword';
import SpinnerForButton from 'components/loader/SpinnerForButton';
import { capitalizeFirstLetter } from 'utils/helpers';

export default function Register() {

    const {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        phone, setPhone,
        password, setPassword,
        password2, setPassword2,
        gdprCheck, setGdprCheck,
        error,
        handleRegister,
        showPrivacyPolicy, setShowPrivacyPolicy,
        isInProgress
    } = useRegister();

    const showOffcanvasPrivacyPolicy = () => setShowPrivacyPolicy(true);
    const hideOffcanvasPrivacyPolicy = () => setShowPrivacyPolicy(false);

    return (
        <>
            <Header show_menu={false} show_logo/>

            <Capsule>
                <div className="login-form">
                    <div className="section">
                        <img src={registerImage} alt="registrati" width="150" height="149" style={{maxWidth: '150px'}} className="form-image p-2" />
                    </div>
                    <div className="section">
                        <h2>Registrati</h2>
                        <h3 className="text-14 fw-500">Compila il modulo per creare un nuovo account</h3>
                    </div>
                    <Form className="section mt-1 mb-5" onSubmit={handleRegister}>
                        <div className="row">
                            <div className="col ps-1">
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label className="label-bordered" htmlFor="register-first-name">Nome</Form.Label>
                                        <Form.Control
                                            value={capitalizeFirstLetter(firstName)}
                                            type="text"
                                            id="register-first-name"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            maxLength={25}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col pe-1">
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper not-empty">
                                        <Form.Label className="label-bordered" htmlFor="register-last-name">Cognome</Form.Label>
                                        <Form.Control
                                            value={capitalizeFirstLetter(lastName)}
                                            type="text"
                                            id="register-last-name"
                                            onChange={(e) => setLastName(e.target.value)}
                                            maxLength={25}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="register-email">Email</Form.Label>
                                <Form.Control
                                    value={email.replace(/\s/g, '')}
                                    type="email"
                                    id="register-email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="register-phone">Telefono</Form.Label>
                                <Form.Control
                                    value={phone}
                                    type="phone"
                                    pattern="[0-9]*"
                                    id="register-phone"
                                    maxLength={15}
                                    onChange={(e) => setPhone(e.target.value.replace(/\s/g, ''))}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="register-password">Password</Form.Label>
                                <InputPassword 
                                    value={password}
                                    id="register-password"
                                    maxLength="30"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />                           
                            </div>
                        </div>

                        <div className="form-group boxed custom">
                            <div className="input-wrapper">
                                <Form.Label className="label-bordered" htmlFor="register-password2">Conferma password</Form.Label>
                                <InputPassword 
                                    value={password2}
                                    id="register-password2"
                                    maxLength="30"
                                    onChange={(e) => setPassword2(e.target.value)}
                                    required
                                />        
                            </div>
                        </div>

                        <div className="form-check mb-1 mt-1 text-start">
                            <input 
                                className="form-check-input"
                                type="checkbox"
                                id="register-gdpr"
                                checked={gdprCheck}
                                onChange={() => setGdprCheck(!gdprCheck)}
                            />
                            <Form.Label className="form-check-label text-14 lh-16" htmlFor="register-gdpr">
                                Dichiaro di aver letto la <a onClick={showOffcanvasPrivacyPolicy}><u>privacy policy</u></a> e accetto i termini e condizioni dell'app.
                            </Form.Label>
                        </div>

                        {error && (
                            <Alert className="lh-sm mb-1" variant="danger">
                                {error}
                            </Alert>
                        )}

                        <div className="text-start text-14">
                            <Link to="/login" className="active">Sei già registrato? Clicca qui!</Link>
                        </div>

                        <div className="form-button-group">
                            <Button
                                className={`btn-block ${isInProgress ? 'disabled' : ''}`}
                                type="submit"
                                size="lg"
                            >
                                <SpinnerForButton show={isInProgress} />
                                Registrati
                            </Button>
                        </div>

                    </Form>
                </div>
            </Capsule>

            <OffCanvasPrivacyPolicy show={showPrivacyPolicy} handleClose={hideOffcanvasPrivacyPolicy}/>
        </>
    );
}