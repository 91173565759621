import PriceMatrix from "components/PriceMatrix";
import usePriceMatrix from "hooks/usePriceMatrix";
import { Form } from "react-bootstrap";

export default function OrganizationPriceMatrix({ 
    product,
    priceMatrix,
    setPriceMatrix,
    handleSave,
    isSimple
}) {
    
    const bindSetPriceMatrix = (price_matrix) => {
        setPriceMatrix(product.id, price_matrix);
    }

    const { 
        handleChangeMatrix,
        handleAddRow,
        handleDeleteRow
    } = usePriceMatrix({
        priceMatrix,
        setPriceMatrix: bindSetPriceMatrix,
        isSimple
    });

    return (
        <Form onSubmit={e => handleSave(e, product.id)}>
            <PriceMatrix
                matrix={priceMatrix || []}
                product={product}
                handleChangeMatrix={handleChangeMatrix}
                handleAddRow={handleAddRow}
                handleDeleteRow={handleDeleteRow}
                isCustomPrice
                isSimple={isSimple}
            />
            <div className="form-group basic">
                <button type="submit" className={`btn btn-primary btn-block ${product.needSave ? 'blinking-element' : ''}`}>
                    Aggiorna
                </button>
            </div>
        </Form>
    )
}