import { IoAdd, IoAddCircle, IoAddOutline, IoAttach, IoChevronBackCircle, IoChevronBackOutline, IoCreate, IoDocument, IoInformationCircle, IoListCircle, IoOpenOutline, IoPencil } from "react-icons/io5";
import FilesList from "./FilesList";
import { RENDER_INSTRUCTIONS_FIELDS } from "constants";
import { RenderInputUploadFurniture, RenderInputUploadPlan, RenderInputUploadReference } from "./RenderUploadInputs";
import { Button, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUploads } from "store/selectors";
import { toast } from "react-toastify";
import { isMobile, uploadsField } from "utils/helpers";
import axios from "axios";
import { uploadReset } from "store/actions/uploads";
import { useDispatch } from "react-redux";
import { useAuthValue } from "context/AuthContext";
import Linkify from 'linkify-react';

export default function RenderDetail({
    render,
    handleSaveInstruction
}) {

    const uploads = useSelector(selectUploads);
    const [isAddUpload, setIsAddUpload] = useState(false);
    const instruction = render.instruction;
    const dispatch = useDispatch();
    const { user } = useAuthValue();

    const mustSave = !!Object.entries(uploads).filter(([key, files]) => (
        ['render_plan', 'render_reference', 'render_furniture'].includes(key) && 
        Object.values(files).filter(f => f.isDeletable).length > 0
    )).length;

    const handleConfirmUpload = () => {
        if (!mustSave) return toast.error('Devi caricare almeno un file');
        const render_plan = Object.values(uploads.render_plan ?? {}).filter(f => f.isDeletable).map(f => f.path);
        const render_reference = Object.values(uploads.render_reference ?? {}).filter(f => f.isDeletable).map(f => f.path);
        const render_furniture = Object.values(uploads.render_furniture ?? {}).filter(f => f.isDeletable).map(f => f.path);
        axios.post(`/render-instructions/${instruction.id}/uploads`, {
            uploads: {
                ...render_plan.length && { plan: render_plan }, 
                ...render_reference.length && { reference: render_reference }, 
                ...render_furniture.length && { furniture: render_furniture }
            }
        })
        .then(res => {
            dispatch(uploadReset('*'));
            toast.success('File caricati con successo');
            handleSaveInstruction(res.data);
            setIsAddUpload(false);
        });
    }

    if (isAddUpload) {
        return (
            <Row className="px-3 py-3">
                <div className="mb-3 text-white text-12 text-uppercase fw-600 cursor-pointer">
                    <span onClick={() => setIsAddUpload(false)}>
                        <IoChevronBackCircle
                            className="text-25 me-05" 
                        /> Torna indietro
                    </span>
                </div>
                <Col>
                    <RenderInputUploadPlan 
                        showDownloadable={false}
                    />
                    <RenderInputUploadReference 
                        showDownloadable={false}
                    />
                    <RenderInputUploadFurniture 
                        showDownloadable={false}
                    />
                    <Button
                        className={`btn-block mt-2 btn btn-primary text-uppercase ${mustSave ? 'blinking-element' : ''}`}
                        type="button"
                        onClick={handleConfirmUpload}
                    >SALVA NUOVI FILE</Button>
                </Col>
            </Row>
        )
    }


    return (
        <ul className="listview image-listview no-border">
            <li>
                <div className="item">
                    <div className="in">
                        <div>
                            <header>
                                <IoListCircle /> Riepilogo:
                            </header>
                            <ul className='ps-2'>
                                <li>
                                    <b>Viste:</b> {render.views_label}
                                </li>
                                {render.plan && <li>Planimetria 3D</li>}
                                {render.virtual_tour && <li>Virtual tour interattivo</li>}
                                {render.emotional_view > 0 && <li>{render.emotional_view} vist{render.emotional_view === 1 ? 'a' : 'e'} emozional{render.emotional_view === 1 ? 'e' : 'i'}</li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div className="item">
                    <div className="in">
                        <div>
                            <header>
                                <IoInformationCircle /> Specifiche:
                            </header>
                            <ul className='ps-2'>
                                <li>Altezza soffitto {instruction.ceiling_height}</li>
                                <li>
                                    {RENDER_INSTRUCTIONS_FIELDS.frame_type[instruction.frame_type]}
                                    {instruction.shutter_type ? ` - ${RENDER_INSTRUCTIONS_FIELDS.shutter_type[instruction.shutter_type]}`: ''}
                                    {instruction.shutter_box ? ` - ${RENDER_INSTRUCTIONS_FIELDS.shutter_box[instruction.shutter_box]}`: ''}
                                </li>                                
                                <li>{RENDER_INSTRUCTIONS_FIELDS.heating[instruction.heating]}</li>
                                <li>{RENDER_INSTRUCTIONS_FIELDS.ceiling[instruction.ceiling]}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div className="item align-items-baseline">
                    <div className="in">
                        <div>
                            <header>
                                <IoDocument /> Planimetria in formato dwg o pdf:
                            </header>
                            {<FilesList files={instruction.uploads.plan} />}
                        </div>
                    </div>
                    {user.is_agent && render.task_status !== 'DONE' && <div 
                        className='nowrap cursor-pointer text-success text-uppercase text-12 fw-600 opacity-75'
                        onClick={() => setIsAddUpload(true)}
                    > <IoAddCircle className="vertical-align-n2"/> Aggiungi file</div>}
                </div>

                <div className="item">
                    <div className="in">
                        <div>
                            <header>
                                <IoAttach /> File di riferimento:
                            </header>
                            {<FilesList files={instruction.uploads.reference} />}
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="in">
                        <div>
                            <header>
                                <IoAttach /> Immagini di riferimento:
                            </header>
                            {<FilesList files={instruction.uploads.furniture} />}
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="in">
                        <div className="flex-fill">
                            <header>
                                <IoPencil /> Note aggiuntive:
                            </header>
                            <div className='my-2'>
                                <Linkify options={{
                                    target: '_blank',
                                    className: 'active'
                                }}>
                                    {instruction.note}
                                </Linkify>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
}