import { useEffect, useState } from "react";

export default function InputDebounce({
    onChange,
    debounce = 500,
    ...props
}) {
 
    const [value, setValue] = useState('')
        
    useEffect(() => {
        const timeout = setTimeout(() => {
        onChange(value)
        }, debounce)
    
        return () => clearTimeout(timeout)
    }, [value])
    
    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}