import DonutGraph from "./DonutGraph";

export default function GoalGraph({
    value,
    goal,
}) {

    const remain = goal - value;
    const remain_percentage = remain * 100 / goal;
    const color = remain_percentage < 0 ? '#198754' : (remain_percentage < 30 ? '#ffc107' : '#dc3545')

    return <DonutGraph 
            data={[
                { label: 'Reach', value: value , color: color },
                { label: 'Rest', value: (goal > value ? (goal - value) : 0), color: '#292929'},
            ]}
            label={(value && goal) ? `${value}/${goal}` : ''}
            centerColor="#292929"
        />
}