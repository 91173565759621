import { useEffect, useRef, useState } from "react";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { isMobile } from "utils/helpers";

function useSlider(props) {

    const { 
        children,
        defaultValue,
        onChange = () => {}
    } = props;

    const sliderRef = useRef(null);
    const [scrollPrevSlider, setScrollPrevSlider] = useState();
    const [userIsScrolling, setUserIsScrolling] = useState(false);

    const getCurrentSlide = () => {
        if (!sliderRef.current) return null;
        const { scrollWidth,  scrollLeft } = sliderRef.current;
        return (children.length * scrollLeft / scrollWidth) + 1;
    }

    const setCurrentSlide = num => {
        sliderRef.current.scrollLeft = (sliderRef.current.scrollWidth / children.length) * (num - 1);
    }

    const changeSlide = (op) => {
        const prevSlide = getCurrentSlide();
        const currSlide = prevSlide + op;
        setCurrentSlide(currSlide);
        onChange({ currSlide, prevSlide,  slideObject: children[currSlide - 1]?.props?.slideObject });
    }

    useEffect(() => {
        if (!children) return;
        defaultValue && setCurrentSlide(defaultValue);
    }, [defaultValue, children]);

    return {
        sliderRef,
        getCurrentSlide,
        scrollPrevSlider, setScrollPrevSlider,
        userIsScrolling, setUserIsScrolling,
        setCurrentSlide,
        changeSlide
    }
}

function Slider(props) {

    const {
        children,
        onChange = () => {},
        blockScrolling = false,
        showNavs = true,
        navInset = false,
        fullWidth = false,
        className = ''
    } = props;

    const {
        sliderRef,
        getCurrentSlide,
        scrollPrevSlider, setScrollPrevSlider,
        userIsScrolling, setUserIsScrolling,
        changeSlide
    } = useSlider(props);

    return (
        <div className={`snap-slider ${className}`}>
            {(showNavs && !isMobile()) && <div 
                className={`snap-arrow arrow-back ${navInset ? 'inset' : ''}`}
                onClick={() => changeSlide(-1)}
            >
                <IoArrowBackSharp/>
            </div>}
            <div
                ref={sliderRef}
                className="slides"
                style={{ 
                    ...blockScrolling && {pointerEvents: 'none'},
                    ...fullWidth && { maxWidth: 'none'}
                }}
                onScroll={e => {
                    if(!userIsScrolling) return;
                    !scrollPrevSlider && setScrollPrevSlider(getCurrentSlide());
                    const { scrollWidth,  scrollLeft } = e.target;
                    if(scrollLeft % (scrollWidth / children.length)) return;
                    const currSlide = getCurrentSlide();
                    setUserIsScrolling(false);
                    onChange({
                        currSlide, 
                        prevSlide: scrollPrevSlider > currSlide ? (currSlide + 1) : (currSlide - 1), 
                        slideObject: children[currSlide - 1].props?.slideObject,
                    });
                    setScrollPrevSlider();
                }}
                onTouchStart={() => setUserIsScrolling(true)}
            >
                {children}
            </div>
            {(showNavs && !isMobile()) && <div 
                className={`snap-arrow arrow-next ${navInset ? 'inset' : ''}`}
                onClick={() => changeSlide(1)}
            >
                <IoArrowForwardSharp/>
            </div>}
        </div>
    );
}

function Slide({
    children
}) {
    return (
        <div className="slide">
            {children}
        </div>
    );
}


Slider.Slide = Slide;

export default Slider;