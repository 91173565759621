import { getToken } from "firebase/messaging";
import { useEffect, useState } from "react";
import { messaging } from "utils/firebase-instance";
import { pushIsGranted, checkNotificationsPermission, requestNotificationToken } from 'utils/app-notifications';
import useEventListener from "./useEventListener";
import { toast } from "react-toastify";

export default function useNotifications() {

    const [notificationPermission, setNotificationPermission] = useState();
    const [FCMToken, setFCMToken] = useState();
    
    const onPushState = message => {
        setNotificationPermission(pushIsGranted(message.detail));
    }

    const onPushRequest = message => {
        const isGranted =  pushIsGranted(message.detail);
        if (!isGranted) {
            return toast.error('Permesso disattivato dal sistema, puoi attivare le notifiche dalle impostazioni del tuo smartphone');
        }
        setNotificationPermission(isGranted);
        requestNotificationToken();
    }

    const onFCMToken = message => {
        setFCMToken(message.detail);
    }

    useEventListener({
        'push-permission-state': onPushState,
        'push-permission-request': onPushRequest,
        'fcm-token': onFCMToken
    }, [], window);

    useEffect(() => {
        checkNotificationsPermission();
    }, []);

    useEffect(() => {
        if (!notificationPermission) return setFCMToken();
        requestNotificationToken();
    }, [notificationPermission]);

    return {
        notificationPermission,
        FCMToken
    }

}