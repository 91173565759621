//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import OffcanvasEditUser from './components/OffcanvasEditUser';
import OffCanvasAddUser from './components/OffCanvasAddUser';
import FilterInput from 'components/FilterInput';
import { IoAdd } from 'react-icons/io5';
import PlaceHolderItemList from 'components/loader/PlaceHolderItemList';

//Assets
import defaultAvatar from 'assets/img/avatar_default.jpg';

//Logic
import useUsers from './useUsers';
import { useFilterInput } from 'hooks/useFilterInput';
import { mapRoles } from 'utils/helpers';

function UserItem({ user, dispatchEditUser, isSkeleton }) {
    return (
        <li onClick={() => !isSkeleton && dispatchEditUser(user.id)}>
            <a className="item">
                <div className="avatar me-2">
                    <img src={user?.avatar_url || defaultAvatar} alt="avatar" className="imaged rounded w36" />
                </div>
                {isSkeleton ? <PlaceHolderItemList hideSecondLine /> : <>
                    <div className="in">
                        <div>
                            <span>{user.display_name}</span>
                            <br />
                            <span className={`text-12 text-${!user.roles.length || user.roles.includes('agent') ? 'gray' : `${user.is_system ? 'danger' : 'warning'}`}`}>
                                {!!user.roles.length ? mapRoles(user.roles).join(', ') : 'Non attivo'}
                            </span>
                        </div>
                    </div>
                </>}
            </a>
        </li>
    );
}

export default function Users() {

    const {
        users,
        editUser, dispatchEditUser,
        showOffCanvasAddUser, dispatchAddUser,
        onAddUser,
        onUpdateUser
    } = useUsers();

    const [
        filterInputValue,
        handleFilterInputChange,
        filterSearchArray
    ] = useFilterInput(user => {
        return `${user.display_name}${user.uid}`;
    });

    const totalUsers = users ? users.length : 0;

    return (
        <>
            <Header
                show_agency={false}
                pageTitle={totalUsers > 0 ? `Lista utenti (${totalUsers})` : 'Lista utenti'} 
            />
            <Capsule>
                <FilterInput 
                    placeHolder="Ricerca per nome o cognome"
                    onChange={handleFilterInputChange}
                    value={filterInputValue}
                />
                <div className="section">
                    <ul className="listview image-listview rounded">
                        {<li className='cursor-pointer' onClick={() => dispatchAddUser('OPEN')}>
                            <div className="item">
                                <div className="icon-box bg-success">
                                    <IoAdd />
                                </div>
                                <div className="in text-success">
                                    <div className="fw-500">Aggiungi utente</div>
                                </div>
                            </div>
                        </li>}
                        {!users && new Array(4).fill(null).map((_, index) => (
                            <UserItem
                                key={'SKELETON' + index}
                                isSkeleton
                            />
                        ))}
                        {users && users.filter(filterSearchArray).map(user => <UserItem 
                            key={user.id}
                            user={user}
                            dispatchEditUser={dispatchEditUser}
                        />)}
                    </ul>
                </div>

            </Capsule>
            <OffcanvasEditUser
                editUser={editUser}
                handleClose={() => dispatchEditUser('CLOSE')}
                onUpdateUser={onUpdateUser}
            />
            <OffCanvasAddUser 
                show={showOffCanvasAddUser}
                handleClose={() => dispatchAddUser('CLOSE')}
                onAddUser={onAddUser}
            />
        </>

    );
}