import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';
import { Button } from 'react-bootstrap';


function OffCanvasPrivacyPolicy({ show, handleClose }) {

    return (
        <Offcanvas placement="bottom" className="pb-1" show={show} onHide={handleClose}>
            <OffCanvasCloseBtn handleClose={handleClose} />
            <Offcanvas.Header>
                <h5 className="section offcanvas-title fw-bold">Privacy Policy</h5>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className='section'>
                    Desideriamo informarti su come trattiamo i tuoi <b>dati personali</b>. La seguente informativa sulla privacy illustra le pratiche adottate da Photodaf a riguardo.<br /><br />
                    Raccogliamo solo i dati strettamente necessari per offrirti un'esperienza personalizzata e garantire il <b>corretto funzionamento dell'app</b>. Questi dati vengono trattati nel rispetto delle leggi sulla privacy vigenti.<br /><br />
                    I tuoi dati vengono utilizzati esclusivamente per migliorare i servizi offerti da Photodaf. Non vendiamo, condividiamo o trasferiamo in alcun modo le tue informazioni personali a terzi senza il tuo consenso esplicito.<br /><br />
                    Per una visione dettagliata delle pratiche sulla privacy adottate da Photodaf, ti invitiamo a leggere l'informativa estesa sulla privacy disponibile nel link sottostante.
                </div>
                <a href="https://www.iubenda.com/privacy-policy/78613532/full-legal" target="_blank">
                    <Button
                    className="btn-block mt-3"
                    type="button"
                >
                    Apri Privacy Policy
                </Button>
                </a>
            </Offcanvas.Body>
        </Offcanvas>
    );

}

export default OffCanvasPrivacyPolicy;