import { useEffect, useState } from "react";
import { auth } from 'utils/firebase-instance';
import { onAuthStateChanged } from 'firebase/auth';
import { axiosSetup, axiosReset, AXIOS_BASE_URL } from 'utils/axios-instance';
import { getLocalStorage, loadLaravelUser } from 'utils/helpers';
import { useNavigate } from "react-router";
import useNotifications from "hooks/useNotifications";
import axios from "axios";
import moment from "moment";


export default function useApp() {

    //const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState();
    const [firebaseUser, setFirebaseUser] = useState();
    const navigate = useNavigate();

    const notificationLogic = useNotifications();
    const { FCMToken } = notificationLogic;

    const afterFirebaseLogin = async firebaseUser => {
      setLoaded(false);
      setFirebaseUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        photoURL: firebaseUser.photoURL,
        displayName: firebaseUser.displayName,
        emailVerified: firebaseUser.emailVerified
      });
      await axiosSetup();
      if (firebaseUser.emailVerified) {
        setUser(await loadLaravelUser());
      } else {
        navigate('/confirm-account');
        setLoaded(true);
      }
    }

    const afterNoAuth = () => {
      axiosReset();
      setUser();
      setFirebaseUser();
      setLoaded(true);
    }; 

    useEffect(() => {
      onAuthStateChanged(auth, (firebaseUser) => {
        firebaseUser ? afterFirebaseLogin(firebaseUser) : afterNoAuth();
      });
    }, []);

    useEffect(() => {
      if (typeof FCMToken === 'undefined' || !loaded) return;
      if (firebaseUser) {
        if (!firebaseUser.emailVerified) return;
        const last_fcm_update = moment(localStorage.getItem('last_fcm_update'));
        if (!last_fcm_update.isValid() || moment().diff(last_fcm_update, 'hours') > 2) {
          axios.post('users-fcm', {
            fcm_token: FCMToken,
          })
          .then(() => {
            localStorage.setItem('last_fcm_update', moment().toISOString());
          })
          return;
        }
      }
      const axiosInstance = axios.create();
      axiosInstance.delete(AXIOS_BASE_URL + 'users-fcm', {
        data: { fcm_token: FCMToken }
      });
    }, [FCMToken, loaded, firebaseUser]);
    
    useEffect(() => {
      if (!firebaseUser || !user) return;
      setLoaded(true);
      if (user.is_agent && !user.agent.organizations.length) {
        navigate('/welcome-agent');
    }
    }, [firebaseUser, user]);

    return {
        user, setUser,
        firebaseUser, setFirebaseUser,
        loaded,
        notificationLogic
    }
}