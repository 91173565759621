
const initialState = {
    appointmentProcessId: null
};

const appointmentEdit = (state = initialState, action) => {
    switch(action.type){
        case 'APPOINTMENT_EDIT_START':
            return {
                ...state,
                appointmentProcessId: action.payload
            };
        case 'APPOINTMENT_EDIT_END':
            return {
                ...state,
                appointmentProcessId: null
            };
        default:
            return state;
    }
}

export default appointmentEdit;