import { deepCopy } from "utils/helpers";

export default function usePriceMatrix({
    priceMatrix,
    setPriceMatrix,
    isSimple = false
}) {

    const updateNext = (matrixArray, index) => {
        if (matrixArray[index+1]) {
            matrixArray[index+1].range[0] = matrixArray.length === 1 ? 0 : matrixArray[index].range[1];
        }
    }

    const handleChangeMatrix = (index, field, value) => {
        const updatePriceMatrix = deepCopy(priceMatrix);
        if (field === 'range') {
            updatePriceMatrix[index].range[1] = +value;
        } else {
            if (value === '') {
                updatePriceMatrix[index][field] = '';
            } else {
                updatePriceMatrix[index][field] = +value;
            }
        }
        updateNext(updatePriceMatrix, index);
        setPriceMatrix(updatePriceMatrix);
    }

    const handleChangeMatrixSimple = (e) => {
        const {name, value} = e.target;
        setPriceMatrix({
            ...priceMatrix,
            [name]: value
        });
    }

    const handleAddRow = () => {
        if (!priceMatrix.length) {
            return setPriceMatrix([{
                price: 0,
                price2: 0,
                time: 0,
                range: [0, null]
            }]);
        }
        const updatePriceMatrix = deepCopy(priceMatrix);
        const lastRow = updatePriceMatrix[updatePriceMatrix.length-1];
        updatePriceMatrix.splice(updatePriceMatrix.length - 1, 0, { 
            ...lastRow, 
            range: [lastRow.range[0], lastRow.range[0] +1] 
        });
        updateNext(updatePriceMatrix, updatePriceMatrix.length-2);
        setPriceMatrix(updatePriceMatrix);
    }

    const handleDeleteRow = (index) => {
        const updatePriceMatrix = deepCopy(priceMatrix);
        updatePriceMatrix.splice(index, 1);
        updateNext(updatePriceMatrix, index-1);
        setPriceMatrix(updatePriceMatrix);
    }

    return {
        handleChangeMatrix: isSimple ? handleChangeMatrixSimple : handleChangeMatrix,
        handleAddRow,
        handleDeleteRow
    }

}