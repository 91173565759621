import render_internal from 'assets/img/render_internal.jpeg';
import Checkout from 'components/Checkout';
import { useAuthValue } from 'context/AuthContext';
import useCartItems from 'hooks/useCartItems';
import useOrder from 'hooks/useOrder';
import { useMemo, useState } from 'react';
import { IoCheckmark, IoClose } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectCart, selectRender, selectRenderAmbients } from 'store/selectors';
import ModalConfirm from 'components/ModalConfirm';
import { useNavigate } from 'react-router';

export default function FourthStep() {

    const navigate = useNavigate();
    const { user } = useAuthValue();
    const [completedOrderData, setCompletedOrderData] = useState()
    const [hasAcceptTerms, setHasAcceptTerms] = useState(false);    
    const cart = useSelector(selectCart);
    const render = useSelector(selectRender);
    const selectedProduct = useMemo(() => cart.products.length ? cart.products[0] : null, [cart.products]);
    const renderAmbients = useSelector(selectRenderAmbients);

    const {
        placeOrder,
        isInProgress,
        isCartInProgress,
    } = useOrder();

    const { price_matrix } = selectedProduct;

    const summary = useMemo(() => {
        let views_n = 0;
        const views_labels = render.ambients
            .filter(am => am.views > 0)
            .reduce((acc, am) => {
            const index = acc.findIndex(a => a.id === am.id);
            views_n += am.views;
            if (index > -1) {
                acc[index] = {
                    ...am,
                    views: (acc[index].views ?? 0) + am.views
                }
            } else {
                acc.push(am)
            }
            return acc;
            }, [])
            .sort((a, b) => (renderAmbients.object[a.id].order_by - renderAmbients.object[b.id].order_by))
            .map(am => `${am.views} ${am.label}`).join(', ');

        const views_total = views_n * price_matrix.price_x_view + (render.ambients.filter(v => v.views > 0).length * price_matrix.price_x_ambient);
        const cart_total = views_total + +(price_matrix.plan ? price_matrix.plan_price : 0) + +(price_matrix.virtual_tour ? price_matrix.virtual_tour_price : 0);
        const cart_total_vi = cart_total * 1.22;
        const cart_initial = cart_total * 0.3;
        return {
            views_labels,
            views_total,
            cart_total,
            cart_total_vi,
            cart_initial,
            cart_initial_vi: (cart_initial * 1.22)
        }
    }, [price_matrix, render]);

    const handlePlaceOrder = () => {
        placeOrder({
            organization_id: user.agent.getSelectedOrganization().id,
            products: [{ id: selectedProduct.id, is_priority: false }],
            with_render: true,
            metadata: {
                render: {
                    ...render,
                    ambients: render.ambients.map(a => ({ id: a.id, views: a.views }))
                }
            }
        })
        .then(res => setCompletedOrderData(res.order))
    };

    const handleCloseModalConfirm = () => {
        navigate(`/render-manage/${completedOrderData.id}`);
    }


    return (<>
        <div className='position-relative'>
            <img src={render_internal} style={{ maxHeight: '150px', objectFit: 'cover' }} className="w-100" />
        </div>
    
        <div className='section mt-3'>
    
            <h3 className="card-subtitle text-white text-15 mb-1" style={{ textTransform: 'none' }}>
                Piano selezionato: <span className='active' style={{ textTransform: 'uppercase' }}>{render.is_custom ? 'PERSONALIZZATO' : selectedProduct.description}</span>
            </h3>

        </div>
    
        <div className="section mt-2 mb-2">
            <div className="section-title">
                <h4 className="card-subtitle text-white text-12 mb-0">Riepilogo piano</h4>
            </div>
            <div className="card">
                <ul className="listview simple-listview text-14 rounded">
                    <li> {summary.views_labels} <span className="text-muted nowrap"><span className="right-price">{summary.views_total}€</span></span></li>
                    {price_matrix.plan ?
                        <li> Planimetria 3D <span className="text-muted"><span className="right-price">{price_matrix.plan_price}€</span></span></li>
                    : ''}
                    {price_matrix.virtual_tour ?
                        <li> Virtual tour interattivo <span className="text-muted"><span className="right-price">{price_matrix.virtual_tour_price}€</span></span></li>
                    : ''}
                    {price_matrix.emotional_view ?
                        <li> {price_matrix.emotional_view} Viste emozionali <span className="text-muted"><span className="right-price lh-1" style={{ width: '100px'}}><strike>100€</strike> OMAGGIO</span></span></li>
                    : ''}
                    <li className="bg-gray-dark fw-600">Subtotale<span className="active"><span className="fw-600 right-price">{summary.cart_total}€</span></span></li>
                    <li className="bg-gray-dark fw-600">Totale (IVA inclusa)<span className="active fw-600 right-price ms-2 nowrap">{summary.cart_total_vi.toFixed(2)}€</span></li>
                    <li className="bg-gray-dark fw-600">Acconto da pagare (30%)<span className="active fw-600 right-price ms-2 nowrap">{summary.cart_initial_vi.toFixed(2)}€</span></li>
                </ul>
            </div>
    
            <div className="form-group mt-2 mb-3">
                <div className="form-check mb-1">
                    <input 
                        type="checkbox" 
                        className="form-check-input" 
                        id="accept-terms" 
                        required="" 
                        checked={hasAcceptTerms}
                        onChange={() => setHasAcceptTerms(!hasAcceptTerms)}
                    />
                    <label className="form-check-label text-14" htmlFor="accept-terms">Ho letto e accetto i <a className='active'>termini e condizioni</a>.</label>
                </div>
            </div>
            
        </div>

        {hasAcceptTerms && <Checkout 
            cartItems={{
                price: summary.cart_initial,
                price_vat_included: summary.cart_initial_vi
            }}
            isInProgress={isInProgress || !selectedProduct}
            isCartInProgress={isCartInProgress}
            completeCheckout={handlePlaceOrder}
        />}

        <ModalConfirm 
            show={!!completedOrderData}
            handleClose={() => handleCloseModalConfirm()}
        />

    </>);
    
}