//import useReservationService  from "./useReservationService";
import Capsule from 'layout/Capsule';

//Components
import { IoChevronForward, IoInformationCircle, IoPricetag, IoSparklesSharp, IoTime } from 'react-icons/io5';

//Logic
import useReservationService from './useReservationService';
import { useAuthValue } from 'context/AuthContext';
import OffcanvasServiceInfo from 'components/offcanvas/OffCanvasProductServiceInfo';


function ServiceItemChild({
    product,
    selectProduct,
    showProductDetail
}) {
    return (
        <div className='lh-base mt-1 text-14 d-flex justify-content-between'>
            <span>
                <label className="lh-16" style={{ color: '#dadada' }} htmlFor={`radio_${product.id}`}>
                    <div className="form-switch d-inline p-0">
                        <input
                            type="checkbox"
                            className="form-check-input float-none m-0 me-1 d-inline-block align-middle"
                            name="mainServiceList_child"
                            checked={!!product?.is_selected}
                            id={`radio_${product?.id}`}
                            onChange={() => selectProduct(product)}
                        />
                    </div>
                    {product.description}

                    {!!product.time && (
                        <div className="service-time" style={{ marginLeft: '34px', marginTop: '3px' }}>
                            <IoTime className='vertical-align-n2' />{product.time} minuti
                        </div>
                    )}

                </label>
            </span>

            <span className="d-inline-block active text-14 text-end align-self-baseline nowrap" style={{ minWidth: '55px' }}>
                {product.description_extend && <IoInformationCircle
                    className={`info-icon ${product?.price ? 'float-start' : ''}`}
                    onClick={() => showProductDetail(product.id)}
                />}
                {product?.price ? `${product?.price}€` : ''}
            </span>


        </div>
    );
}

function ServiceItem(props) {

    const {
        product,
        products,
        isSkeleton,
        selectProduct = () => { },
        selectProductPriority = () => { },
        showProductDetail
    } = props;

    return (
        <div className="form-check">
            <input
                type={product?.type === 'PRIMARY' ? 'radio' : 'checkbox'}
                className="form-check-input"
                name="mainServiceList"
                checked={!!product?.is_selected}
                id={`radio_${product?.id}`}
                onChange={() => selectProduct(product)}
            />
            <label className="form-check-label" htmlFor={`radio_${product?.id}`} >
                <div className="flex-fill">
                    <div className="service-title service-title d-flex justify-content-between">
                        {isSkeleton && <span className='placeholder' style={{ width: '200px' }} />}
                        <span>{product?.description}</span>

                        {isSkeleton ?
                            <span className='placeholder' style={{ width: '50px' }} /> : <>
                                <span className="d-inline-block active text-14 text-end align-self-baseline nowrap" style={{ minWidth: '55px' }}>
                                    {product.description_extend && <IoInformationCircle
                                        className={`info-icon ${product?.price ? 'float-start' : ''}`}
                                        onClick={() => showProductDetail(product.id)}
                                    />}
                                    {product?.price ? `${product?.price}€` : ''}
                                </span>
                            </>}

                    </div>
                    {!!product?.time && <div className={`service-time ${isSkeleton ? 'placeholder' : ''}`}>
                        <IoTime className='vertical-align-n2' />
                        {product?.time} minuti
                    </div>}
                    {product && product.is_selected && products && products.filter(p => p.parent_id === product.id).map(child_product => (
                        <ServiceItemChild
                            key={child_product.id}
                            product={child_product}
                            selectProduct={selectProduct}
                            showProductDetail={showProductDetail}
                        />
                    ))}
                    {product?.priority_active && product?.is_selected ?
                        <div className='lh-base mt-1 text-14 d-flex justify-content-between'>
                            <span>
                                <label className="lh-16" style={{ color: '#dadada' }} htmlFor={`priority_${product.id}`}>
                                    <div className="form-switch d-inline p-0">
                                        <input
                                            id={`priority_${product.id}`}
                                            type="checkbox"
                                            className="form-check-input float-none m-0 me-1 d-inline-block align-middle"
                                            checked={!!product.is_priority}
                                            onChange={() => selectProductPriority(product)}
                                        />
                                    </div>
                                    {product.priority_label}
                                </label>
                            </span>
                            <span className='active'>
                                {product.priority_price}€
                            </span>
                        </div>
                        : ''}
                </div>
            </label>
        </div>
    );
}

export default function ReservationService(props) {

    const {
        products,
        handleSelectProduct, handleSelectProductPriority,
        selectedProducts,
        cartItems,
        nextStep,
        buildingData,
        photographers
    } = props;

    const {
        showOffCanvasServiceInfo, setShowOffCanvasServiceInfo,
        handleProductDetail
    } = useReservationService();

    const subscription = buildingData?.organization?.subscription;

    return (
        <>
            <Capsule className='extra-header-active pb-1 with-bottom-menu placeholder-glow'>
                {subscription && <div className='mt-2 text-center alert text-white mx-3 lh-sm bg-amazon py-2'>
                    <span className='opacity-75'><IoSparklesSharp /> Avendo acquistato una ricarica <b>{subscription.description}</b> hai diritto ad un <b>{subscription.discount_percentage}% di sconto</b> su tutti i servizi elencati di seguito, che verrà applicato sul totale. <IoSparklesSharp /></span>
                </div>}
                <div className="section section-full-mobile mt-2">
                    <h4 className="card-subtitle text-white pt-1 text-12">Seleziona servizio principale:</h4>
                    <div className="wide-block p-0 rounded">
                        <div className="input-list service-items">
                            {(!products || !photographers) && Array(2).fill(null).map((_, i) => <ServiceItem key={i} isSkeleton />)}
                            {products && photographers && products.filter(p => p.type === 'PRIMARY' && !p.parent_id).map(product => (
                                <ServiceItem
                                    key={product.id}
                                    product={product}
                                    products={products}
                                    selectProduct={handleSelectProduct}
                                    selectProductPriority={handleSelectProductPriority}
                                    showProductDetail={handleProductDetail}
                                />)
                            )}
                        </div>
                    </div>
                </div>
                {products && products.find(p => p.is_selected) &&
                    <div className="section section-full-mobile mt-3 mb-1">
                        <h4 className="card-subtitle text-white text-12">Seleziona servizi extra:</h4>
                        <div className="wide-block p-0 rounded">
                            <div className="input-list service-items">
                                {products && products.filter(p => p.type === 'EXTRA' && !p.parent_id).map(product => (
                                    <ServiceItem
                                        key={product.id}
                                        product={product}
                                        products={products}
                                        selectProduct={handleSelectProduct}
                                        selectProductPriority={handleSelectProductPriority}
                                        selectedProducts={selectedProducts}
                                        showProductDetail={handleProductDetail}
                                    />)
                                )}
                            </div>
                        </div>
                    </div>
                }
            </Capsule>
            <OffcanvasServiceInfo
                product={showOffCanvasServiceInfo}
                handleClose={() => setShowOffCanvasServiceInfo()}
            />
        </>);
}
