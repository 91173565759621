import moment from "moment";
import { IoHome } from "react-icons/io5";
import PlaceHolderItemList from "./loader/PlaceHolderItemList";

function appointmentStatus(building) {
    switch(building?.last_appointment?.status) {
        case 'CONFIRMED':
        return { color: 'warning', label: `Servizio prenotato per il ${moment(building.last_appointment.estimated_start_at).format('DD/MM/yy')}`}
        case 'ENDED':
        return { color: 'success', label: `Servizio svolto il ${moment(building.last_appointment.estimated_start_at).format('DD/MM/yy')}`}
        default:
        return { color: 'active', label: 'Servizio da prenotare' };
    }
}

export default function ViewBuildingItem(props) {
    const { 
      building,
      isSkeleton,
      handleClickDetail = () => {}
    } = props;
  
    const status = appointmentStatus(building);
  
    return (
      <li onClick={() => handleClickDetail(building?.id)}>
        <a className="item">
          <div className={`icon-box bg-secondary ${isSkeleton ? 'placeholder' : ''}`}>
            {!isSkeleton && <IoHome />}
          </div>
          {isSkeleton ? <PlaceHolderItemList /> : 
            <div className="in">
              <div>
                <span>{building.title}</span>
                <span className="d-block text-muted">{building.address.full}</span>
                <span className={`d-block text-12 text-${status.color}`}>
                    {status.label}
                </span>
              </div>
            </div>
          }
        </a>
      </li>
    );
  }