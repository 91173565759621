import axios from "axios";
import { useAuthValue } from "context/AuthContext";
import useOffCanvasReducer from "hooks/useOffCanvasReducer";
import moment from 'moment';
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { getUrlParam } from "utils/helpers";

function useReservationCalendarUser(props) {
    
    const {
        buildingData,
        cartItems,
      } = props;
    
    const calendarRef = useRef(null);
    const [rangedSlots, setRangedSlots] = useState();
    const [rangeSlotSelected, setRangeSlotSelected] = useState();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [viewTitle, setViewTitle] = useState('');
    const [backDisabled, setBlackDisabled] = useState(true);
    const { user } = useAuthValue();
    const [selectedAppointmentId, dispatchViewAppointment] = useOffCanvasReducer();
    const { appointmentId } = useParams();

    useEffect(() => {
        if (!buildingData || !cartItems || !cartItems?.products.length) return;
        handleViewChange();
    }, [buildingData, cartItems]);

    const handleViewChange = (action = null) => {
        const calendarApi = calendarRef.current.getApi();
        action && calendarApi[action]();
        const {start, end } = calendarApi.currentData.dateProfile.activeRange;
        const start_date = moment(start);
        setBlackDisabled(start_date.isSame(moment(calendarApi.currentData.options.validRange.start), 'day'));
        const end_date = moment(end);
        let firstDate = start_date.clone();
        const skeleton_events = [];
        while (firstDate.isBefore(end_date, 'day')) {
            skeleton_events.push({
                start: firstDate.format('YYYY-MM-DD') + ' 09:00:00',
                end: firstDate.format('YYYY-MM-DD') + ' 13:00:00',
                skeleton: true,
                backgroundColor: 'rgb(117, 115, 115)'
            });
            skeleton_events.push({
                start: firstDate.format('YYYY-MM-DD') + ' 15:00:00',
                end: firstDate.format('YYYY-MM-DD') + ' 19:00:00',
                skeleton: true,
                backgroundColor: 'rgb(117, 115, 115)'
            });
            firstDate.add(1, 'day');
        }
        setCalendarEvents(skeleton_events);
        axios.get('appointments', {
            params: {
                start: start_date.format('YYYY-MM-DD'),
                end: end_date.format('YYYY-MM-DD'),
                events: 1,
                photographer_uid: 1,
                organization_id: user?.agent?.getSelectedOrganization().id || buildingData.organization.id,
                exclude_id: appointmentId
            }
        })
        .then(res => {
            const appointments = res.data;
            const days = [];
            res.data.forEach(app => {
                const date = moment(app.start).format('YYYY-MM-DD');
                if (!days.includes(date)) days.push(date);
            });
            // const photographers_uid = appointments.reduce((acc, app) => {
            //     const date = app.start.match(/^(\d{4}-\d{2}-\d{2})/)[1];
            //     if (!acc[date]) acc[date] = [];
            //     !acc[date].includes(app.photographer_uid) && acc[date].push(app.photographer_uid);
            //     return acc;
            // }, {});
            axios.post(process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + 'calendar-show', {
                start: start_date.format('YYYY-MM-DD'),
                end: end_date.format('YYYY-MM-DD'),
                duration: cartItems.time,
                point: [buildingData.coords.lng, buildingData.coords.lat].join(','),
                edit_appointment_id: appointmentId,
                appointments_days: days,
                //photographers_uid: appointments.length ? photographers_uid : undefined
            })
            .then(res => {
                setCalendarEvents([...appointments, ...res.data]);
                setViewTitle(calendarApi.currentData.viewTitle);
            })
        });
    }


    const handleEventClick = (info) => {
        if (info.event.title) return dispatchViewAppointment(info.event.id);
        if (info.event.disabled) return;
        //setRangeSlotSelected(Object.keys(info.event.extendedProps.ranged_slots)[0]);
        setRangedSlots({
            start: info.event.start,
            slots: info.event.extendedProps.ranged_slots
        });
    }

    const handleCloseOffCanvasSetTime = () => {
        setRangedSlots();
        setRangeSlotSelected();
    }
    

    return {
        handleEventClick,
        rangedSlots,
        rangeSlotSelected, setRangeSlotSelected,
        handleCloseOffCanvasSetTime,
        calendarEvents,
        calendarRef,
        handleViewChange,
        viewTitle,
        backDisabled,
        selectedAppointmentId, dispatchViewAppointment,
        user
    }

}

const DEFAULT_ADMIN_FORM = {
    photographer_id: '',
    date: '',
    time: ''
}

function useReservationCalendarAdmin({
    setSelectedTimeSlot
}) {

    const [formData, setFormData] = useState({
        ...DEFAULT_ADMIN_FORM,
        photographer_id: getUrlParam('ph'),
        date: getUrlParam('date')
    });

    const handleChange = e => {
        const { name, value } = e.target;
        const newForm = {
            ...formData,
            [name]: value
        };
        setFormData(newForm);
        const start = moment(`${newForm.date} ${newForm.time}`);
        if (!newForm.photographer_id || !start.isValid()) return setSelectedTimeSlot();
        setSelectedTimeSlot({
            photographer_id: newForm.photographer_id,
            start: start
        });
    }
    

    return {
        formData,
        handleChange
    }
}

export {
    useReservationCalendarUser,
    useReservationCalendarAdmin
}
