import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { deepCopy, getStaffUser } from 'utils/helpers';
import { useTasks } from './usePostProduction';
import { toast } from 'react-toastify';
import { appShowLoader, appHideLoader } from 'store/actions/appLoader';
import { useDispatch } from 'react-redux';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';

export default function usePostProductionAdmin() {

    const [staff, setStaff] = useState();
    const [products, setProducts] = useState();
    const [organizations, setOrganizations] = useState();
    const [showOffCanvasManageEditor, dispatchManageEditor] = useOffCanvasReducer();
    const dispatch = useDispatch();

    const {
        tasks, setTasks,
        filter, setFilter,
        tab, setTab,
        setIsLoadingTask
    } = useTasks({
        isLoading: false,
        role: 'admin',
        defaultTab: 'WAITING_PHOTOGRAPHER',
        defaultFilter: {
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().endOf('month').format('YYYY-MM-DD'),
            photographer_id: "",
            editor_id: "",
            product_id: "",
            organization_id: ""
        }
    });

    useEffect(() => {
        dispatch(appShowLoader());
        Promise.all([
            getStaffUser('photographer'),
            getStaffUser('editor'),
            axios.get('products'),
            axios.get('organizations?autocomplete=1')
        ])
        .then(([resp, rese, respr, resor]) => {
            const photographers = Object.fromEntries(
                resp.data.map(p => ([p.photographer_id, p.display_name]))
            );
            setStaff({
                p: photographers,
                e: rese.data
            });
            setProducts(respr.data);
            setOrganizations(resor.data);
            dispatch(appHideLoader());
            setIsLoadingTask(true);
        });
    }, []);

    const handleChange = (e, index) => {;
        const { name, value } = e.target;
        const updateTasks = deepCopy(tasks);
        if (name === 'status') {
            if (value.includes(':')) {
                const [newStatus, editorId] = value.split(':');
                updateTasks[index].status = newStatus;
                updateTasks[index].editor_id = editorId;
            } else {
                updateTasks[index].status = value;
                updateTasks[index].editor_id = null;
            }
        } else {
            updateTasks[index][name] = value;
        }
        updateTasks[index].needSave = true;
        setTasks(updateTasks);
    }

    const handleOnSave = (index) => {
        const task = tasks[index];
        if (task.status === 'AUTOMATIC_ASSIGN' && +task.quantity <= 0) {
            return toast.error('Campo quantità obbligatorio');
        }
        (task.status === 'AUTOMATIC_ASSIGN' ? 
            axios.post(`tasks/${task.id}/automatic-assign`, {
                quantity: task.quantity,
            }) : 
            axios.put(`tasks/${task.id}`, {
                update_role: 'admin',
                status: task.status,
                quantity: task.quantity,
                editor_id: task.editor_id
            })
        )
        .then(res => {
            if (res.data.status !== tab) {
                setTasks(tasks.filter(t => t.id !== res.data.id));
            } else {
                setTasks(tasks.map(t => {
                    if (t.id !== res.data.id) return t;
                    return {
                        ...t,
                        ...res.data,
                        needSave: false
                    };
                }));
            }
            toast.success("Informazioni aggiornate con successo.");
        });
    }

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        if (['from', 'to'].includes(name) && !value) return;
        setFilter({
            ...filter,
            [name]: value
        });
    }

    return {
        filter,
        tasks,
        staff,
        products,
        organizations,
        tab, setTab,
        handleChange,
        handleOnSave,
        handleChangeFilter,
        showOffCanvasManageEditor, dispatchManageEditor
    }

}