//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Assets
import defaultAvatar from 'assets/img/avatar_default.jpg';

//Components
import OffcanvasEditPassword from './components/OffcanvasEditPassword.js';
import OffcanvasEditProfile from './components/OffcanvasEditProfile.js';
import FormCheck from 'react-bootstrap/FormCheck';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useAuthValue } from 'context/AuthContext.js';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer.js';
import { useDispatch } from 'react-redux';
import ModalDeleteAccount from './components/ModalDeleteAccount.js';
import axios from 'axios';
import { requestNotificationPermission } from 'utils/app-notifications.js';
import { isIOS, isMobile, mapRoles } from 'utils/helpers.js';
import useLocalStorage from 'hooks/useLocalStorage.js';


function useProfile() {
    const [agentStats, setAgentStats] = useState();
    const [showEditProfile, dispatchShowEditProfile] = useOffCanvasReducer();
    const [showEditPassword, dispatchShowEditPassword] = useOffCanvasReducer();
    const [showDeleteAccount, dispatchShowDeleteAccount] = useOffCanvasReducer();
    const [defaultNavigator, setDefaultNavigator] = useLocalStorage('default_navigator');
    const [experimentalFeature, setExperimentalFeature] = useLocalStorage('experimental_feature');
    const [showDebugFeature, setShowDebugFeature] = useLocalStorage('show_debug_feature');
    const [isDebugActive, setIsDebugActive] = useLocalStorage('is_debug_active');
    const debugCounter = useRef();
    const { user, notification } = useAuthValue();
    const { notificationPermission } = notification;

    const handleRequestPush = (e) => {
        requestNotificationPermission();
    }

    const handleChangeDefaultNavigator = (e) => {
        setDefaultNavigator(e.target.value || undefined);
    }

    useEffect(() => {
        axios.get('agent-stats')
        .then(res => setAgentStats(res.data))
    }, []);

    const startDebugCountodown = () => {
        console.log('start');
        debugCounter.current = setTimeout(() => {
            console.log('debug active');
            setShowDebugFeature(true);
        }, 5000);
    }

    const resetDebugCountdown = () => {
        console.log('reset');
        clearTimeout(debugCounter.current);
    }

    return {
        notificationPermission,
        handleRequestPush,
        showEditPassword, dispatchShowEditPassword,
        showDeleteAccount, dispatchShowDeleteAccount,
        agentStats,
        showEditProfile, dispatchShowEditProfile,
        defaultNavigator, handleChangeDefaultNavigator,
        user,
        experimentalFeature, setExperimentalFeature,
        showDebugFeature, setShowDebugFeature,
        isDebugActive, setIsDebugActive,
        startDebugCountodown, resetDebugCountdown
    }
}

function Profile() {

    const {
        notificationPermission,
        handleRequestPush,
        showEditPassword, dispatchShowEditPassword,
        showDeleteAccount, dispatchShowDeleteAccount,
        agentStats,
        showEditProfile, dispatchShowEditProfile,
        defaultNavigator, handleChangeDefaultNavigator,
        user,
        experimentalFeature, setExperimentalFeature,
        showDebugFeature,
        isDebugActive, setIsDebugActive,
        startDebugCountodown, resetDebugCountdown
    } = useProfile();
    
    return (
        <>
            <Header pageTitle={'Profilo'} show_logout handleBack="home" show_agency={false} />
            <Capsule>
                <div className="section mt-2">
                    <div className="profile-head">
                        <div className="avatar cursor-pointer" onClick={() => dispatchShowEditProfile('OPEN')}>
                            <img 
                                src={user.avatar_url || defaultAvatar} 
                                alt="avatar" 
                                className="imaged w64 rounded"
                            />
                        </div>
                        <div className="in">
                            <h3 className="name">{user.display_name}</h3>
                            <h5 className="subtext">{mapRoles(user.roles).join(', ')}</h5>
                        </div>
                    </div>
                </div>

                {user.is_agent && <div className="section full mt-2">
                    <div className="profile-stats ps-2 pe-2 placeholder-glow">
                        {agentStats ? <>
                            <a className="item">
                                <strong>{agentStats.buildings}</strong>immobil{agentStats.buildings > 1 ? 'i' : 'e'}
                            </a>
                            <a className="item">
                                <strong>{agentStats.orders}</strong>ordin{agentStats.orders > 1 ? 'i' : 'e'}
                            </a>
                            <a className="item">
                                <strong>{agentStats.organizations}</strong>agenzi{agentStats.organizations > 1 ? 'e' : 'a'}
                            </a>
                        </> : <a className='item placeholder w-100'>#</a>}
                    </div>
                </div>}

                <div className="section full mb-2">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="settings" role="tabpanel">
                            <ul className="listview image-listview text flush transparent pt-1">
                                <li>
                                    <div className="item">
                                        <div className="in">
                                            <div>
                                                <b>Ricevi notifiche</b>
                                                <footer>Avvisami in tempo reale sugli aggiornamenti delle mie prenotazioni</footer>
                                            </div>
                                            <FormCheck
                                                id="switchEnabled"
                                                type="switch"
                                                label=""
                                                checked={notificationPermission}
                                                disabled={notificationPermission}
                                                onChange={handleRequestPush}
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div 
                                        className="item cursor-pointer" 
                                        onMouseDown={startDebugCountodown} 
                                        onMouseUp={resetDebugCountdown}
                                        onTouchStart={startDebugCountodown}
                                        onTouchEnd={resetDebugCountdown}
                                    >
                                        <div className="in">
                                            <div>
                                                <b>Funzionalità sperimentali</b>
                                                <footer>Attiva le funzionalità sperimentali dell'app per ricevere gli aggiornamenti in anteprima. Ti ricordiamo che attivando questa opzione alcune funzionalità potrebbero risultare non funzionanti</footer>
                                            </div>
                                            <FormCheck
                                                id="switchExp"
                                                type="switch"
                                                label=""
                                                checked={experimentalFeature ?? false}
                                                onChange={() => setExperimentalFeature(!experimentalFeature)}
                                            />
                                        </div>
                                    </div>
                                </li>
                                {showDebugFeature && <li>
                                    <div className="item cursor-pointer text-danger">
                                        <div className="in">
                                            <div>
                                                <b>Debug APP</b>
                                                <footer>Attivando questa funzionalità potrai effettuare il debug su funzionalità non ancora terminate</footer>
                                            </div>
                                            <FormCheck
                                                id="switchDebug"
                                                type="switch"
                                                label=""
                                                checked={isDebugActive ?? false}
                                                onChange={() => setIsDebugActive(!isDebugActive)}
                                            />
                                        </div>
                                    </div>
                                </li>}
                                {isMobile() && <li>
                                    <div className="item">
                                        <div className="in">
                                            <div>
                                                <b>Navigatore di default</b>
                                                <footer>Seleziona con quale APP di navigazione vuoi aprire di default l'indirizzo degli appuntamenti</footer>
                                            </div>
                                            <div className='flex-fill' style={{ textAlign: 'right' }}>
                                                <div className="form-group boxed custom py-0">
                                                    <div className="input-wrapper">
                                                        <select
                                                            className="form-control form-select d-inline w-auto ms-1"
                                                            style={{ background: '#3a3a3a' }}
                                                            onChange={handleChangeDefaultNavigator}
                                                            value={defaultNavigator || ''}
                                                        >
                                                            <option value="">Sistema</option>
                                                            <option value="waze">Waze</option>
                                                            <option value="google">Google Maps</option>
                                                            {isIOS() && <option value="apple">Apple Maps</option>}                                                  
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>}
                                <li onClick={() => dispatchShowEditProfile('OPEN')}>
                                    <a className="item">
                                        <div className="in">
                                            <div>Modifica profilo</div>
                                        </div>
                                    </a>
                                </li>
                                {user.is_agent && <li>
                                    <Link to="/orders" className="item">
                                        <div className="in">
                                            <div>Storico ordini</div>
                                        </div>
                                    </Link>
                                </li>}
                                {/* <li>
                                    <a className="item">
                                        <div clclassNameass="in">
                                            <div>Fatture</div>
                                        </div>
                                    </a>
                                </li> */}
                                <li onClick={() => dispatchShowEditPassword('OPEN')}>
                                    <a className="item">
                                        <div className="in">
                                            <div>Cambio password</div>
                                        </div>
                                    </a>
                                </li>
                                <li onClick={() => dispatchShowDeleteAccount('OPEN')}>
                                    <a className="item">
                                        <div className="in text-danger">
                                            <div>Elimina account</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Capsule>
            <OffcanvasEditPassword 
                show={showEditPassword}
                handleClose={() => dispatchShowEditPassword('CLOSE')}
            />
            <OffcanvasEditProfile 
                show={showEditProfile}
                handleClose={() => dispatchShowEditProfile('CLOSE')}
            />
            <ModalDeleteAccount 
                show={showDeleteAccount}
                handleClose={() => dispatchShowDeleteAccount('CLOSE')}
            />
        </>

    );
}

export default Profile;