import logo_white from 'assets/img/logo-white.png';
//components
import { Link } from 'react-router-dom';
import OffCanvasSwitchAgency from 'components/offcanvas/OffCanvasSwitchAgency';
import { 
    IoChevronDownCircleOutline,
    IoNotificationsOutline,
    IoLogOutOutline,
    IoMenuOutline,
    IoChevronBackOutline,
    IoCloseOutline
} from 'react-icons/io5';
import OffcanvasMenu from 'components/offcanvas/OffcanvasMenu';


//Logic
import useHeader from './useHeader';
import { useNavigate } from 'react-router-dom';
import { logout } from 'utils/helpers';

function Header(props) {

    const {
        show_logo = false,
        show_logout = false,
        show_agency = true,
        switch_agency = false,
        pageTitle,
        show_rigth = true,
        show_menu = true,
        handleBack,
        handleClose,
        showNotifications,
        RightComponent = () => {}
    } = props;

    const navigate = useNavigate();

    const {
        user,
        organizationSelected,
        showOffCanvasSwitchAgency, setShowOffCanvasSwitchAgency,
        showMenu, dispatchShowMenu
    } = useHeader();

    const unread_notifications = user?.notifications;

    return (
        <>
            <div className="appHeader no-border">
                {<><div className="left">
                    {show_menu && !handleBack && 
                        <a className="headerButton" onClick={() => dispatchShowMenu('OPEN')}>
                            <IoMenuOutline />
                        </a>
                    }
                    {handleBack && 
                        <a className="headerButton"
                            onClick={() => {
                                if (handleBack === 'home') return navigate('/');
                                handleBack();
                            }}
                        >
                            <IoChevronBackOutline />
                        </a>
                    }
                </div></>}
                {show_logo ? (
                    <div className="pageTitle">
                        <img src={logo_white} className="logo" width="58" height="37" />
                    </div>
                ) : (
                    <div className="pageTitle change-agency">
                        {switch_agency ? (
                            <div className="cursor-pointer" onClick={() => setShowOffCanvasSwitchAgency(true)}>
                                <span>Attività selezionata:</span>
                                {organizationSelected && organizationSelected.display_name}
                                <IoChevronDownCircleOutline />
                            </div>
                        ) : (
                            <>
                                {show_agency && organizationSelected && <span>{organizationSelected.display_name}</span>}
                                {pageTitle}
                            </>
                        )}
                    </div>
                )}
                {(show_rigth) &&
                    <div className="right">
                        {show_logout && 
                            <Link onClick={() => logout()} className="headerButton">
                                <IoLogOutOutline />
                            </Link>
                        }
                        {showNotifications && 
                            <Link to="/notifications" className="headerButton">
                                <IoNotificationsOutline/>
                                {unread_notifications > 0 ? <span className="badge badge-danger">{unread_notifications}</span> : ''}
                            </Link>
                        }
                        {handleClose &&
                            <a className='headerButton'>
                                <IoCloseOutline onClick={handleClose} />
                            </a>
                        }
                        <RightComponent />
                    </div>}
            </div>
            {user?.is_agent && 
                <OffCanvasSwitchAgency
                    organizations={user.agent.organizations}
                    show={showOffCanvasSwitchAgency}
                    handleClose={() => setShowOffCanvasSwitchAgency(false)}
                />
            }
            {show_menu && <OffcanvasMenu 
                show={showMenu}
                handleClose={() => dispatchShowMenu('CLOSE')}
            />}
        </>
    );
}

export default Header;