//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import OffcanvasViewBuilding from './components/offcanvas-view-building/OffCanvasViewBuilding';
import { 
  IoHome, 
  IoSearchOutline,
  IoAdd
} from 'react-icons/io5';
import PlaceHolderItemList from 'components/loader/PlaceHolderItemList';
import OffcanvasAddBuilding from 'components/offcanvas/add-building/OffcanvasAddBuilding';


//logic
import useBuildings from './useBuildings';
import { useFilterBuilding } from 'hooks/useFilterInput';
import moment from 'moment';
import ViewBuildingItem from 'components/ViewBuildingItem';
import { useNavigate } from 'react-router';
import { useAuthValue } from 'context/AuthContext';
import { getUrlParam } from 'utils/helpers';


function Buildings() {

  const {
    handleBuildingAdd,
    showOffCanvasAddBuilding, setShowOffCanvasAddBuilding,
    handleCloseViewBuilding,
    buildings,
    handleClickDetail,
    selectedBuildingId,
    handleUpdateData,
    handleDeleteData,
    organizationSelected
  } = useBuildings();

  const [
    filterInputValue,
    handleFilterInputChange,
    filterSearchArray
  ] = useFilterBuilding();

  let filteredBuildings = buildings?.filter(filterSearchArray);
  const { user } = useAuthValue();
  const navigate = useNavigate();

  return (
    <>
      <Header 
        pageTitle={user.is_admin ? getUrlParam('d') : 'Lista immobili'} 
        handleBack={user.is_admin ? () => navigate( '/admin/organizations') : 'home'}
      />
      <Capsule>
        <div className="form-group boxed pt-0 p-2">
          <div id="property_list_bg_img"></div>
          <div className="input-wrapper not-empty">
            <IoSearchOutline className="input-icon-search cursor-pointer"/>
            <input
              type="text"
              className="form-control"
              placeholder="Filtra immobili per via o nome..."
              autoComplete="off"
              value={filterInputValue}
              onChange={handleFilterInputChange}
            />
          </div>
        </div>

        <div className="section">
          <ul className="listview image-listview rounded placeholder-glow">
            <li onClick={() => setShowOffCanvasAddBuilding(true)}>
              <a className="item no-after">
                <div className="icon-box bg-success">
                  <IoAdd />
                </div>
                <div className="in text-success">
                  <div className="fw-500">Aggiungi immobile</div>
                </div>
              </a>
            </li>
            {!buildings && Array(5).fill(null).map((_,i) => <ViewBuildingItem key={i} isSkeleton />)}
            {filteredBuildings?.map(building => <ViewBuildingItem 
              key={building.id}
              {...{
                building,
                handleClickDetail
              }}
            />)}
            {filteredBuildings && !filteredBuildings?.length && 
              <li>
                <a className="item no-after">
                  Nessun immobile trovato
                </a>
              </li>
            }
          </ul>
        </div>
      </Capsule>
      <OffcanvasAddBuilding 
        show={showOffCanvasAddBuilding} 
        handleClose={() => setShowOffCanvasAddBuilding(false)}
        onBuildingAdd={handleBuildingAdd}
        organization={organizationSelected}
      />
      <OffcanvasViewBuilding 
        buildingId={selectedBuildingId}
        handleClose={handleCloseViewBuilding}
        onUpdateData={handleUpdateData}
        onDeleteData={handleDeleteData}
      />
    </>
  );
}

export default Buildings;