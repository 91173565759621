import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { imageURLToBase64 } from "utils/helpers";
import { toast } from 'react-toastify';

export default function useOffcanvasViewBuilding(props) {

    const { 
        buildingId,
        onUpdateData,
        onDeleteData,
        handleClose
    } = props;

    const [buildingData, setBuildingData] = useState();
    const [step, setStep] = useState('view');
    const [buildingTypes, setBuildingTypes] = useState();
    const [editStepInfo, setEditStepInfo] = useState();
    const [editStepInfoResident, setEditStepInfoResident] = useState();
    const [isInProgress, setIsInProgress] = useState(false);
    const [headerImage, setHeaderImage] = useState();
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);

    const handleDelete = () => {
        if (!isConfirmDelete) return setIsConfirmDelete(true);
        axios.delete(`/buildings/${buildingId}`)
        .then(() => {
            toast.success('Immobile archiviato con successo');
            handleClose();
            onDeleteData(buildingId);
        });
    }

    const residentType = useMemo(() => {
        return {
            'OWNER': 'Proprietario',
            'TENANT': 'Inquilino',
            'NOBODY': 'Nessuno'
        }
    }, []);

    useEffect(() => {
        if (!buildingId) {
            setIsConfirmDelete();
            handleCloseOffCanvas();
            return;
        }
        axios.get(`buildings/${buildingId}`)
        .then(res => {
            imageURLToBase64(res.data.image_url)
            .then(image => {
                setHeaderImage(image);
            })
            .catch(error => console.log(error))
            .finally(() => {
                setBuildingData(res.data)
            });
        })
    }, [buildingId]);

    const updateBuildingData = (updateData) => {
        axios.put(`buildings/${buildingId}`, updateData)
        .then(res => {
            setBuildingData({
                ...buildingData,
                ...res.data
            });
            onUpdateData(res.data);
            setIsInProgress(false);
            setStep('view');
        });
    }

    const handleCloseOffCanvas = () => {
        setStep('view');
        setBuildingData();
        setHeaderImage();
    }

    const handleEditInfo = async () => {
        if (!buildingTypes) {
            const buildingTypesRes = await axios.get('buildings-types');
            setBuildingTypes(buildingTypesRes.data);
        }
        setEditStepInfo({
            reference: buildingData.reference,
            owner_first_name: buildingData.owner_first_name,
            owner_last_name: buildingData.owner_last_name,
            buildingType: buildingData.building_type,
            floor: buildingData.floor,
            sqm: buildingData.sqm,
            garden_sqm: buildingData.sqm
        });
        setStep('edit_info');
    }

    const handleSaveInfo = () => {
        setIsInProgress(true);
        const updateData = { 
            building_type_id: editStepInfo.buildingType.id,
            ...editStepInfo,
        }
        delete updateData.buildingType;
        updateBuildingData(updateData);
    }

    const handleEditInfoResident = async () => {
        setEditStepInfoResident({
            resident_type: buildingData.resident_type,
            doorbell_name: buildingData.doorbell_name,
            resident_phone: buildingData.resident_phone || '',
            resident_email: buildingData.resident_email || ''
        });
        setStep('edit_info_resident');
    }

    const handleSaveInfoResident = () => {
        setIsInProgress(true);
        updateBuildingData(editStepInfoResident);
    }

    const stepBefore = () => setStep('view');

    return {
        headerImage,
        buildingData,
        residentType,
        step,
        stepBefore,
        isInProgress,
        buildingTypes,
        editStepInfo, setEditStepInfo,
        editStepInfoResident, setEditStepInfoResident,
        handleEditInfo, handleSaveInfo, 
        handleEditInfoResident, handleSaveInfoResident,
        isConfirmDelete, 
        handleDelete
    }

}