export const dialogOpen = (data) => ({
    type: 'DIALOG_BOX_OPEN',
    payload: data,
});

export const dialogClose = (data) => ({
    type: 'DIALOG_BOX_CLOSE',
    payload: data,
});

export const confirmDialog = () => ({
    type: 'DIALOG_BOX_CONFIRM'
});

export const cancelDialog = () => ({
    type: 'DIALOG_BOX_CANCEL'
});