import useLocalStorage from 'hooks/useLocalStorage';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoBusinessOutline } from 'react-icons/io5';

export default function OffCanvasSwitchAgency(props) {

    const {
        organizations,
        show,
        handleClose
    } = props;

    const [_, setOrganizationdSelectedId] = useLocalStorage('organization_selected_id');

    return (<>
        <Offcanvas placement="bottom" id="changeAgency" className="action-sheet" show={show} onHide={handleClose} style={{ minHeight: 0, maxWidth: 450 }}>
            <Offcanvas.Header>
                <h5 className="offcanvas-title fw500">Seleziona attività:</h5>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ul className="action-button-list">
                    {organizations.map(organization => {
                        return (
                            <li key={organization.id} onClick={() => {
                                setOrganizationdSelectedId(organization.id);
                                window.location.reload();
                            }}>
                                <a className="btn btn-list" data-bs-dismiss="offcanvas">
                                    <span><IoBusinessOutline /> <span className='ms-1'>{organization.display_name}</span></span>
                                </a>
                            </li>
                        );
                    })}
                    <li className="action-divider"></li>
                    <li>
                        <a href="/organizations?action=new" className="btn btn-list text-success fw-600">
                            <span>Aggiungi attività</span>
                        </a>
                    </li>
                </ul>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}