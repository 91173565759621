import { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { useAuthValue } from 'context/AuthContext';
import Joi from 'joi';
import joi_messages from 'utils/joi-translation';
import { loadLaravelUser } from 'utils/helpers';
import { deepCopy } from '@firebase/util';

function useStepInfoFirst(props) {

    const {
        stepInfoFirst, setStepInfoFirst,
        nextStep,
        organizationTypes,
        show
    } = props;

    const [error, setError ] = useState();
    const [addressValue, setAddressValue] = useState(stepInfoFirst?.address?.full ?? '');
    const { user, firebaseUser } = useAuthValue();
    const [newAddress, setNewAddress] = useState();

    const handleRetriveAddress = (autocompleteObj) => {
        setNewAddress({
            address: autocompleteObj.values,
            coords: autocompleteObj.coords
        });
    };

    const handleResetAddress = useCallback(() => {
        setAddressValue('');
        setNewAddress();
        setStepInfoFirst({
            ...stepInfoFirst,
            address: {},
            coords: {}
        });
    }, [stepInfoFirst]);

    useEffect(() => {
        if (!newAddress) return;
        setStepInfoFirst({
            ...stepInfoFirst,
            ...newAddress
        });
        setNewAddress();
    }, [stepInfoFirst, newAddress]);
 
    const handleOrganizationTypeChange = (organization_type_id) => {
        setError();
        const organizationType = organizationTypes.find(ot => ot.id == organization_type_id);
        const updateValue = {};
        updateValue.organizationType = organizationType;
        if (organizationType.is_private) {
            stepInfoFirst.display_name = '';
            if (!stepInfoFirst.phone) {
                updateValue.phone = user.phone || '';
            }
            if (!stepInfoFirst.email) {
                updateValue.email = firebaseUser.email || '';
            }
        }
        setStepInfoFirst({...stepInfoFirst, ...updateValue});
    }

    const handleChange = (e) => {
        const { name, value} = e.target;
        if (name === 'organization_type_id') return handleOrganizationTypeChange(value);
        setStepInfoFirst({...stepInfoFirst, [name]: value});
    }

    const handleSubmit = () => {
        const schema = Joi.object({
            is_business: Joi.boolean().required(),
            display_name: Joi.string().label('Nome attività').when('is_business', {
                is: true,
                then: Joi.string().min(3).max(50),
                otherwise: Joi.string().empty('')
            }),
            phone: Joi.string().label('Telefono').min(3).max(15),
            email: Joi.string().label('Email').max(320).email({tlds:{allow: false}})
        }).messages(joi_messages.it);

        const { error } = schema.validate({
            is_business: !stepInfoFirst.organizationType.is_private,
            display_name: stepInfoFirst.display_name.trim(),
            phone: stepInfoFirst.phone.trim(),
            email: stepInfoFirst.email.trim()
        });
        if (error) {
            return setError(error.details[0].message);
        }
        nextStep();
    }

    return {
        handleChange,
        error,
        handleSubmit,
        handleRetriveAddress, handleResetAddress,
        addressValue, setAddressValue
    }
}   

function useStepInfoSecond(props) {
    const { 
        nextStep,
        stepInfoFirst,
        stepInfoSecond, setStepInfoSecond,
        isEdit
    } = props;

    const [error, setError ] = useState();

    const { organizationType } = stepInfoFirst;

    const handleChange = (e) => {
        const { name, value} = e.target;
        setStepInfoSecond({...stepInfoSecond, [name]: value });
    }

    const handleSubmit = () => {
        axios.get('helper/organizations-check', {
            params: {
                ...(stepInfoFirst.organizationType.is_private ? { 
                    fiscal_code: stepInfoSecond.fiscal_code
                } : { 
                    vat_code: stepInfoSecond.vat_code
                }),
                is_edit: isEdit ? 1 : 0
            }
        })
        .then(res => {
            if (res.data.exists) {
                return setError(res.data.message);
            }
            const schema = Joi.object({
                is_business: Joi.boolean().required(),
                fiscal_code: Joi.string().label('Codice fiscale').min(3).max(16),
                first_name: Joi.string().label('Nome').when('is_business', {
                    is: false,
                    then: Joi.string().min(3).max(30),
                    otherwise: Joi.string().empty('')
                }),
                last_name: Joi.string().label('Cognome').when('is_business', {
                    is: false,
                    then: Joi.string().min(3).max(30),
                    otherwise: Joi.string().empty('')
                }),
                business_name: Joi.string().label('Nome attività').when('is_business', {
                    is: true,
                    then: Joi.string().min(3).max(200),
                    otherwise: Joi.string().empty('')
                }),
                vat_code: Joi.string().label('Partita IVA').when('is_business', {
                    is: true,
                    then: Joi.string().min(3).max(11),
                    otherwise: Joi.string().empty('')
                }),
                pec_sdi: Joi.string().label('Codice univoco/PEC').when('is_business', {
                    is: true,
                    then: Joi.string().min(3).max(50),
                    otherwise: Joi.string().empty('')
                }),
            }).messages(joi_messages.it);

            const { error } = schema.validate({
                is_business: !organizationType.is_private,
                first_name: (stepInfoSecond.first_name || '').trim(),
                last_name: (stepInfoSecond.last_name || '').trim(),
                fiscal_code: (stepInfoSecond.fiscal_code || '').trim(),
                business_name: (stepInfoSecond.business_name || '').trim(),
                vat_code: (stepInfoSecond.vat_code || '').trim(),
                pec_sdi: (stepInfoSecond.pec_sdi || '').trim()
            });

            if (error) {
                return setError(error.details[0].message);
            }
    
            nextStep();
        });
    }

    return {
        error,
        handleChange,
        handleSubmit
    }
}   

function useStepColor(props) {

    const { setStepInfoColor } = props;

    const selectColor = color => {
        setStepInfoColor({color: color });
    };

    return {
        selectColor
    }
}

function useOffcanvasAddActivity(props) {

    const { 
        show,
        onAddOrganization
    } = props;

    const [step, setStep] = useState('infofirst');
    const [organizationTypes, setOrganizationsTypes] = useState(null);
    const [colors, setColors] = useState(null);
    const [isInProgress, setIsInProgress] = useState(false);
    const [stepInfoFirst, setStepInfoFirst] = useState({
        organizationType : null,
        display_name: '',
        address: {},
        coords: {},
        phone: '',
        email: ''
    });
    const [stepInfoSecond, setStepInfoSecond] = useState({
        business_name: '',
        first_name: '',
        last_name: '',
        vat_code: '',
        fiscal_code: '',
        pec_sdi: ''
    });
    const [stepInfoColor, setStepInfoColor] = useState({
        color: null
    });
    const { setUser } = useAuthValue();

    const stepBefore = useCallback(() => {
        if (step === 'infosecond') return setStep('infofirst');
        if (step === 'color') return setStep('infosecond');
    }, [step]);

    const insertOrganization = () => {
        setIsInProgress(true);
        const firstStepData = Object.fromEntries(
            Object.entries(stepInfoFirst).map(([key, value]) => {
                if (key === 'organizationType') {
                    return ['organization_type_id', value.id];
                }
                return [key, value];
            })
        );
        const secondStepData = deepCopy(stepInfoSecond);
        if (stepInfoFirst.organizationType.is_private) {
            delete firstStepData.display_name;
            delete secondStepData.business_name;
            delete secondStepData.vat_code;
            delete secondStepData.pec_sdi;
        } else {
            delete secondStepData.first_name;
            delete secondStepData.last_name;
        }
        axios.post('organizations', {
            ...firstStepData,
            ...secondStepData,
            color_id: stepInfoColor.color.id
        })
        .then(res => {
            loadLaravelUser()
            .then(user => {
                setUser(user);
                setStep('done');
                onAddOrganization && onAddOrganization(res.data)
            });
        });
    }

    useEffect(() => {
        if (!show || (organizationTypes && colors)) return;
        Promise.all([
            axios.get('organizations-types'),
            axios.get('colors')
        ])
        .then(([organization_types, colors]) => {
            setOrganizationsTypes(organization_types.data);
            setColors(colors.data);
        })
    }, [organizationTypes, colors, show]);

    return {
        stepInfoFirst, setStepInfoFirst,
        stepInfoSecond, setStepInfoSecond,
        stepInfoColor, setStepInfoColor,
        organizationTypes,
        colors,
        step, setStep,
        stepBefore,
        isInProgress,
        insertOrganization
    }

}

export {
    useOffcanvasAddActivity,
    useStepInfoFirst,
    useStepInfoSecond,
    useStepColor,
}