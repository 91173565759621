import { ProgressBar } from "react-bootstrap";

const COLORS = [
    '#135CFA',
    'rgb(39, 124, 186)',
    '#13B9FA',
    '#C5F4F7',
    '#3BE7F9',
]

export default function ProgressGraph({
    data = []
}) {

    return (
        <div>
            <ProgressBar>
                {data.map((d, i) => (
                    <ProgressBar now={d.value}  key={d.label}  style={{ backgroundColor: COLORS[i]}}  />
                ))}
            </ProgressBar>
            <div className="d-flex justify-content-between mt-2">
                {data.map((d, i) => (
                    <div className="text-center" key={d.label}>
                        <div className="text-20" style={{ color: COLORS[i]}}>{d.value}%</div>
                        <div className="text-12">{d.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};