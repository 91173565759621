import { cartNextStep, cartSetRender } from 'store/actions/cart';
import render_type from 'assets/img/render-type.jpeg';
import render_type_desktop from 'assets/img/bg-render.webp';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectProducts, selectRender, selectRenderAmbients } from 'store/selectors';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { isMobile } from 'utils/helpers';

import renderInternalImg from 'assets/img/interni-min.jpg';
import renderExternalImg from 'assets/img/esterni-min.jpg';
import OffcanvasExternal from './OffcanvasExternal';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';

export default function FirstStep() {

    const dispatch = useDispatch();
    const [showOffcanvasExternal, dispatchOffcanvasModal] = useOffCanvasReducer();
    const render = useSelector(selectRender);

    const products = useSelector(selectProducts);
    const renderAmbients = useSelector(selectRenderAmbients);

    const handleChange = (e) => {
        dispatch(cartSetRender({
            ...render,
            type: e.target.value
        }))
    }

    const handleNextStep = () => {
        if (render.type === 'EXTERNAL') {
            return dispatchOffcanvasModal('OPEN');
        }
        dispatch(cartNextStep());
    }

    return (<>
        {/* <img src={isMobile() ? render_type : render_type_desktop} style={{ maxHeight: isMobile() ? '400px' : '200px', objectFit: 'cover' }} className="w-100" /> */}
        {(!render?.type || render.type === 'INTERNAL') && <img src={renderInternalImg} style={{ height: render?.type === 'INTERNAL' ? '50vh' : '25vh', objectFit: 'cover' }} className="w-100" />}
        {(!render?.type || render.type === 'EXTERNAL') && <img src={renderExternalImg} style={{ height: render?.type === 'EXTERNAL' ? '50vh' : '25vh', objectFit: 'cover' }} className="w-100" />}

        <div className='section pt-3 text-center'>
            <h2 className='mb-3'>Di quali render hai bisogno?</h2>

            <div className="form-group boxed custom">
                <div className="form-check mb-2">
                    <input 
                        type="radio" 
                        className="form-check-input" 
                        id="render_type_internal" 
                        value="INTERNAL" 
                        onChange={handleChange}
                        checked={render?.type === 'INTERNAL'}
                    />
                    <label className="form-check-label" htmlFor="render_type_internal" style={{ minWidth: '160px', textAlign: 'left' }}>Interni</label>
                </div>
                <div className="form-check">
                    <input 
                        type="radio" 
                        className="form-check-input" 
                        id="render_type_external" 
                        value="EXTERNAL" 
                        onChange={handleChange}
                        checked={render?.type === 'EXTERNAL'}
                    />
                    <label className="form-check-label" htmlFor="render_type_external" style={{ minWidth: '160px', textAlign: 'left' }}>Esterni</label>
                </div>
            </div>

            <div className="form-group mt-3">
                <button 
                    type="button" 
                    className={`btn-block btn btn-primary ${(render?.type && products.data && renderAmbients.data) ? '' : 'disabled'}`}
                    onClick={handleNextStep}
                >Continua <IoChevronForwardOutline className="next-icon-btn" /></button>
            </div>
        </div>
        <OffcanvasExternal 
            show={showOffcanvasExternal}
            handleClose={() => dispatchOffcanvasModal('CLOSE')}
        />
    </>);
}