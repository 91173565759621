import { useAuthValue } from "context/AuthContext";
import { useState, useReducer } from "react";

export default function useHeader() {
    const { user } = useAuthValue();
    const [showOffCanvasSwitchAgency, setShowOffCanvasSwitchAgency] = useState(false);
    const [showMenu, dispatchShowMenu] = useReducer((showMenu, action) => {
        return action === 'CLOSE' ? false : true;
    });
    
    const organizationSelected = user?.agent?.getSelectedOrganization();
    

    return {
        user,
        organizationSelected,
        showOffCanvasSwitchAgency, setShowOffCanvasSwitchAgency,
        showMenu, dispatchShowMenu
    }
}