import { AddressAutofill } from '@mapbox/search-js-react';
import useAutocompleteAddress from './useAutocompleteAddress';
import Autocomplete from "react-google-autocomplete";
import { IoCloseCircle } from "react-icons/io5";
import { useEffect, useRef, useState } from 'react';

export default function AutocompleteAddress(props) {

    const addressRef = useRef(null);

    const {
        value,
        setValue,
        label = 'Indirizzo*',
        placeholder = '',
        inputClassName = '',
        className = '',
        style = {},
        required = false,
        AfterIcon = () => {},
        withGoogle
    } = props;

    const {
        onRetrive,
        onReset,
        isLocked
    } = useAutocompleteAddress(props);

    const [isInvalid, setIsInvalid] = useState(false);
    
    useEffect(() => {
        if (isLocked) {
            setIsInvalid(false);
        }
    }, [isLocked]);


    if (withGoogle) {
        return (
            <div className={`input-wrapper not-empty ${className}`}>
                {label === null ? '' : <label className="form-label" htmlFor="address">{label}</label>}
                {isLocked ? 
                    <IoCloseCircle className="input-icon-search cursor-pointer" onClick={onReset}/> : <AfterIcon />
                }
                <Autocomplete 
                    apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                    inputAutocompleteValue={value}
                    id="address"
                    name="address"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="off"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onPlaceSelected={(res) => onRetrive(res, true)}
                    options={{
                        types: ['address']
                    }}
                    disabled={isLocked}
                    className={`form-control ${inputClassName} ${isInvalid ? 'is-invalid' : ''}`}
                    style={style}
                    required={required}
                    onKeyUp={() => setIsInvalid(false)}
                    onBlur={() => {
                        if (!isLocked) setIsInvalid(true);
                    }}
                />
            </div>
        );
    }


    return (
        <AddressAutofill
            accessToken={process.env.REACT_APP_MAPBOX_API} 
            onRetrieve={onRetrive}
            browserAutofillEnabled={false}
            options={{
                //language: 'it',
                //country: 'al',
                //streets: false
            }}
            // interceptSearch={text => {
            //     if (/\d/.test(text)) return text;
            //     return text + ' 1';
            // }}
            theme={{
                variables: {
                    colorBackground: '#3a3a3a',
                    colorBackgroundHover: '#282828',
                    colorBackgroundActive: '#282828',
                    colorText: '#f6f6f6',
                    fontWeightBold: false,
                    fontWeightSemibold: false,
                    fontFamily: 'inter',
                    borderRadius: '6px',
                    unit: '14px',
                }
            }}
            // onSuggest={data => console.log(data)}
    >
        <div className={`input-wrapper not-empty ${className}`}>
            {label === null ? '' : <label className="form-label" htmlFor="address">{label}</label>}
            {isLocked ? 
                <IoCloseCircle className="input-icon-search cursor-pointer" onClick={onReset}/> : <AfterIcon />
            }
            <input
                type="text"
                className={`form-control ${inputClassName} ${isInvalid ? 'is-invalid' : ''}`}
                id="address"
                name="address"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="off"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={isLocked}
                ref={addressRef}
                placeholder={placeholder}
                style={style}
                required={required}
                onKeyUp={() => setIsInvalid(false)}
                onBlur={() => {
                    if (!isLocked) setIsInvalid(true);
                }}
            />
        </div>
    </AddressAutofill>
    );
}
