import Capsule from "layout/Capsule";
import { useEffect, useMemo } from "react";
import { IoAlertCircle, IoCheckmarkCircle } from "react-icons/io5";
import { getUrlParam } from "utils/helpers";

export default function WindowClose() {

    const message = useMemo(() => getUrlParam('message'), []);

    useEffect(() => {
        if (message) {
            window?.opener?.postMessage({ type: 'WINDOW_CLOSE', message }, '*');
        }
        window.close();
    }, []);

    if (!message) return '';

    return (<>

        <Capsule>
            <div className="error-page max-width-600">
                {message === 'SUCCESS' && <>
                    <span className="iconedbox iconedbox-xl text-success">
                        <IoCheckmarkCircle />
                    </span>
                    <h1 className="title">Pagamento completato!</h1>
                    <div className="text mb-5">
                        Congratulazioni, il pagamento è stato elaborato con successo. Grazie per aver effettuato la transazione. Clicca sul pulsante <b>"Fine"</b> in alto a destra per tornare all'app.
                    </div>
                </>}
                {message === 'CANCEL' && <>
                    <span className="iconedbox iconedbox-xl text-warning">
                        <IoAlertCircle />
                    </span>
                    <h1 className="title">Pagamento annullato!</h1>
                    <div className="text mb-5">
                        Il pagamento non è andato a buon fine. Questo si verifica nel caso in cui sia stato annullato o a causa di un rifiuto da parte della banca. Clicca sul pulsante <b>"Fine"</b> in alto a destra per ritornare al checkout.
                    </div>
                </>}
            </div>
        </Capsule>
    </>);
}