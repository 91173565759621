import { configureStore } from '@reduxjs/toolkit';

//Reducers
import dialogBoxReducer from './reducers/dialogBox';
import appointmentEditReducer from './reducers/appointmentEdit';
import appLoaderReducer from './reducers/appLoader';
import cartReducer from './reducers/cart';
import productsReducer from './reducers/products';
import renderAmbientsReducer from './reducers/renderAmbients';
import uploadsReducer from './reducers/uploads';

//Middlewares
import apiAsyncMiddleware from './middlewares/apiAsync';
import uploadsMiddleware from './middlewares/uploads';

const store = configureStore({
    reducer: {
        dialogBox: dialogBoxReducer,
        appointmentEdit: appointmentEditReducer,
        appLoader: appLoaderReducer,
        cart: cartReducer,
        products: productsReducer,
        renderAmbients: renderAmbientsReducer,
        uploads: uploadsReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(apiAsyncMiddleware)
        .concat(uploadsMiddleware)
});

export default store;