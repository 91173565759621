import { deepCopy } from "@firebase/util";
import axios from "axios";
import useDaySelector from "hooks/useDaySelector";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

export function getTasks(filter) {
    return axios.get('tasks', {
        params: filter
    })
}

export function useTasks(settings) {

    const {
        isLoading,
        role,
        defaultTab,
        defaultFilter = {}
    } = settings;
    

    const [tasks, setTasks] = useState();
    const [stats, setStats] = useState();
    const [filter, setFilter] = useState(defaultFilter);
    const [tab, setTab] = useState(defaultTab);
    const [isLoadingTask, setIsLoadingTask] = useState(isLoading ?? true);

    useEffect(() => {
        setStats();
        if (!isLoadingTask || (tab === 'DONE' && role === 'editor')) return;
        const is_editor_tasks = role === 'editor' && tab === 'WAITING_EDITOR';
        setTasks();
        getTasks({
            ...filter,
            status: tab,
            index_role: role,
            ...(is_editor_tasks && {
                completed_stats: 1
            })
        })
        .then(res => {
            setTasks(is_editor_tasks ? res.data.tasks : res.data);
            is_editor_tasks && setStats({
                task_completed: res.data.completed
            });
        });
    }, [isLoadingTask, tab, filter]);
    

    return {
        getTasks,
        tasks, setTasks,
        filter, setFilter,
        tab, setTab,
        isLoadingTask, setIsLoadingTask,
        stats, setStats
    }
}

export function usePostProduction({ 
    pageRole 
}) {

    const {
        tasks, setTasks,
        tab, setTab,
        setFilter,
        setIsLoadingTask,
        stats, setStats
    } = useTasks({
        role: pageRole, 
        defaultTab: `WAITING_${pageRole.toUpperCase()}`,
    });

    const {
        next, 
        previous, 
        day, 
    } = useDaySelector();

    const handleChange = (e, index) => {
        const { value, name } = e.target;
        const updateTasks = deepCopy(tasks);
        updateTasks[index][name] = value;
        updateTasks[index].needSave = true;
        setTasks(updateTasks);
    }

    const handleSave = (e, index) => {
        e.preventDefault();
        setTasks(tasks.map((t,i) => {
            if (i !== index) return t;
            t.isLoading = true;
            return t;
        }))
        const task = tasks[index];
        axios.put(`tasks/${task.id}`, {
            update_role: pageRole,
            note_in: task.note_in,
            note_out: task.note_out,
            quantity: task.quantity,
        })
        .then(res => {
            if (pageRole === 'photographer' && task.status === 'WAITING_EDITOR') {
                const updateTasks = deepCopy(tasks);
                task.needSave = false;
                updateTasks[index] = {
                    ...task,
                    ...res.data,
                    isLoading: false
                };
                return setTasks(updateTasks);
            }
            toast.success("Servizio consegnato con successo.");
            setStats({
                ...stats,
                task_completed: (stats?.task_completed ?? 0) + task.quantity
            });
            setTasks(tasks.filter(t => t.id !== task.id));
        })
    }

    useEffect(() => {
        if (pageRole !== 'editor' || tab !== 'DONE') return;
        setTasks();
        getTasks({
            status: tab,
            index_role: pageRole,
            date_out: day.format('YYYY-MM-DD')
        })
        .then(res => setTasks(res.data));
    }, [day, pageRole, tab]);

    const taskEdited = useMemo(() => {
        if (stats) {
            return stats.task_completed;
        }
        if (pageRole !== 'editor' || tab !== 'DONE' || !tasks) return null;
        return tasks.reduce((acc, t) => ((+t.quantity) + (+acc)), 0);
    }, [tasks, pageRole, tab, stats]);

    const onTaskCompleted = (task_id) => {
        setTasks(tasks.filter(t => t.id !== task_id));
        setStats({ task_completed: (stats.task_completed + 1) });
    }

    return {
        tab, setTab,
        tasks,
        handleChange,
        handleSave,
        next, 
        previous, 
        day,
        taskEdited,
        onTaskCompleted
    }
}
